import axios from "axios";
import config from "../../config";
import { getToken } from "../_helpers";
import { store } from "../../store";

export const historyService = {
    getListHistory,
    getListAccountHistory,
    getRecentActivity
};

function getListHistory() {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/patron/history`, requestOptions)
}

function getListAccountHistory(query = {}) {
    const { localization } = store.getState();
    const lg = localStorage.getItem("language") || localization.defaultLang;
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Accept-Language': lg, 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() },
        params: query
    }
    return axios.get(`${config.API.URL}/api/v1/patron/account-history`, requestOptions)
}

function getRecentActivity() {
    const { localization } = store.getState();
    const lg = localStorage.getItem("language") || localization.defaultLang;
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Accept-Language': lg, 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() },
        params: {
            per_page: 3,
            page: 1
        }
    }
    return axios.get(`${config.API.URL}/api/v1/patron/account-history`, requestOptions)
}