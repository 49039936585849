import React from 'react'
import DocumentTitle from 'react-document-title'
import { Link } from "react-router";
import { connect } from 'react-redux';
import { Button } from '../../../components/lib/components/button';
import { Icon } from '../../../components/lib/components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSpinnerThird, faArrowToBottom } from '@fortawesome/pro-light-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Files from 'react-files'
import {
  Field,
  Control,
  Label,
  Textarea
} from '../../../components/lib/components/form';
import { Columns } from '../../../components/lib/components/columns';
import { Modal } from '../../../components/lib/components/modal';
import { Box } from '../../../components/lib/components/box';
import { Breadcrumb } from '../../../components/lib/components/breadcrumb';
import { personalService, threadsService } from '../../../_services';
import moment from "moment";
import Slider from "react-slick";
import { setFocusPage } from '../../../_helpers';
import ModalView from '../../../components/lib/components/view/ModalView';
class MessageDetails extends React.Component {

  _isMounted = false;
  state = {
    imgSrc: '/assets/icons/trash_primary.svg',
    activeModal: '',
    personalData: {},
    show: false,
    showFormValue: true,
    showFormSuccess: false,
    showFormError: false,
    search: '',
    topic: '',
    attachment: '',
    messages: [],
    message: null,
    thread: null,
    comment: '',
    files: [],
    size: 0
  }

  open = (activeModal) => {
    this.setState({
      activeModal
    });

    this.setState({
      showFormValue: true,
      showFormSuccess: false,
      showFormError: false
    }, () => {
      // focus page
      // setFocusPage(`#${this.state.activeModal}showFormValue`);
    });
    this.setState({ show: true })
  };

  close = () => this.setState({
    show: false,
    comment: ''
  });

  onChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  setStep = step => {
    this.setState({
      showFormValue: false,
      showFormSuccess: false,
      showFormError: false
    });

    this.setState({
      [step]: true,
    });
    setFocusPage(`#${this.state.activeModal + step}`);
  };

  componentDidMount() {
    this._isMounted = true;
    this.getPersonalData();

    // focus page
    setFocusPage(`#reply-page`)
  }

  componentWillUnmount() {
    // this.getMessagesUnread();
    this._isMounted = false;
  }

  getMessagesUnread = () => {
    threadsService.getMessagesUnread()
      .then(res => {
        try {
          this.props.onRead(res.data.data.count)
        } catch (e) {
          console.log(e);
        }
      })
      .catch((error) => {
        this.props.onLoader('no');
        if (error.response.status == 401) {
          this.props.router.push('/login');
        }
      })
  };

  onNewMessage = event => {
    event.preventDefault();
    event.stopPropagation();
    // this.setStep('showFormSuccess');
  }

  onDeleteThread = () => {
    this.props.onLoader('yes');
    threadsService.onDeleteThread(this.props.params['id'])
      .then(res => {
        this.props.onLoader('no');
        this.props.router.push('/messages');
      })
      .catch((error) => {
        console.log('error.response', error.response)
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status == 401) {
          this.props.router.push('/login');
        }
      })
  }

  getMessages = () => {
    this.props.onLoader('yes');
    threadsService.getMessages(this.props.params['id'])
      .then(res => {
        try {
          this.setState({
            messages: res.data.data
          })
        } catch (e) {
          console.error(e);
        }
        this.props.onLoader('no');
      })
      .catch((error) => {
        console.log('error.response', error.response)
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status == 401) {
          this.props.router.push('/login');
        }
      })
  }

  getPersonalData() {
    this.props.onLoader('yes');
    // get personal data
    personalService.getPersonalData()
      .then(res => {
        const personalData = res.data.data;
        if (this._isMounted) {
          this.setState({ personalData }, () => {
            this.addThread();
          })
        }
      })
      .catch((error) => {
        this.setState({ errors: { username: error.response.data.message }, error });
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status == 401) {
          this.props.router.push('/login');
        }
      })
  }

  addThread = () => {
    this.props.onLoader('yes');
    threadsService.getThread(this.props.params['id'])
      .then(res => {
        try {
          this.setState({
            thread: res.data.data
          })
          // this.getMessagesUnread()
        } catch (e) {
          console.error(e);
        }
        this.props.onLoader('no');
        this.getMessages();
      })
      .catch((error) => {
        console.log('error.response', error.response)
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status == 401) {
          this.props.router.push('/login');
        }
      })
  }

  showMessage = (id) => {
    this.props.onLoader('yes');
    threadsService.getMessage(this.props.params['id'], id)
      .then(res => {
        const message = res.data.data;
        if (message.attachments.length) {
          message.attachments.forEach(attachment => {
            threadsService.getAttachment(attachment.id)
              .then(res => {
                attachment.src = res.data;
                this.forceUpdate();
              })
              .catch(error => {
                console.log('error.response', error.response)
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                  this.props.router.push('/login');
                }
              })
          })
        }
        this.setState({ message }, () => {
          this.props.onLoader("no");
          this.open('modalMessage');
        });
      })
      .catch(error => {
        console.log('error.response', error.response)
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status == 401) {
          this.props.router.push('/login');
        }
      })
  }

  deleteMessage = (id) => {
    this.props.onLoader('yes');
    threadsService.onDeleteMessage(this.props.params['id'], id)
      .then(res => {
        this.props.onLoader('no');
        this.getMessages()
        this.close()
      })
      .catch((error) => {
        console.log('error.response', error.response)
        this.props.onLoader('no');
        this.getMessages()
        this.close()
        // redirect to login page
        if (error.response.status == 401) {
          this.props.router.push('/login');
        }
      })
  }

  onReply = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      files: []
    });
    this.props.onLoader('yes');
    let formData = new FormData();
    formData.append('body', this.state.comment);
    formData.append('file', this.state.files[0]);
    threadsService.addComment(this.state.thread['id'], formData)
      .then(res => {
        this.props.onLoader('no');
        this.getMessages()
        this.close()
      })
      .catch((error) => {
        console.log('error.response', error.response)
        this.props.onLoader('no');
        this.getMessages()
        this.close()
        // redirect to login page
        if (error.response.status == 401) {
          this.props.router.push('/login');
        }
      })
  }

  handleMouseOver = () => this.setState({ imgSrc: '/assets/icons/trash_white.svg' });

  handleMouseOut = () => this.setState({ imgSrc: '/assets/icons/trash_primary.svg' });

  handleInputFocus = () => {
    this.setState({ imgSrc: '/assets/icons/trash_white.svg' });
  };

  handleInputBlur = () => {
    this.setState({ imgSrc: '/assets/icons/trash_primary.svg' });
  };

  onFilesChange = (files) => {
    let size = 0;
    files.map(file => size += file.size);
    this.setState({
      files,
      size
    });
  }

  onFilesError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
  }

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file)
  }

  filesRemoveAll = () => {
    this.refs.files.removeFiles()
  }

  render() {
    const { comment, message, thread, messages, activeModal, showFormValue, show, files, size, personalData } = this.state;
    const { labels } = this.props;
    const settings = {
      dots: false,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
    };

    return (
      <DocumentTitle title={labels['MESSAGE_DETAILS_PAGE_TITLE'] + ' - IndyGo App'}>
        <>
          <Box style={thread ? {} : { display: 'none' }}>
            <Breadcrumb
              size="medium"
              separatorIcon={<FontAwesomeIcon icon={faChevronRight} />}
              items={[
                {
                  name: 'Messages',
                  url: '/messages',
                },
                {
                  name: thread ? thread['title'] : '...',
                  url: '#2',
                  active: true,
                },
              ]}
            />
          </Box>
          <div aria-label="Reply page" role="Reply page" id="reply-page">
            <h1 aria-label={thread ? thread['title'] : '...'} style={thread ? {} : { display: 'none' }} className="is-size-2 has-text-weight-bold pad-bottom-lg pull-top-xl">
              {thread ? thread['title'] : '...'}
            </h1>
            <Columns style={thread ? {} : { display: 'none' }}>
              <Columns.Column size={10}>
                <Button.Group>
                  <Button
                    onMouseOver={this.handleMouseOver}
                    onMouseOut={this.handleMouseOut}
                    onFocus={this.handleInputFocus}
                    onBlur={this.handleInputBlur}
                    size="small"
                    outlined={true}
                    color="primary"
                    style={{ minWidth: '240px' }}
                    onClick={() => this.onDeleteThread()}
                    title={labels["BUTTON_DELETE_CONVERSATION"]}
                  >
                    <Icon size="large" style={{ width: 19 }}>
                      <img src={this.state.imgSrc} alt="" />
                    </Icon>
                    <span className="push-left-md push-top-xxs has-text-weight-light">{labels["BUTTON_DELETE_CONVERSATION"]}</span>
                  </Button>
                </Button.Group>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column size={10}>

                <ul className="list push-top-md">
                  {messages.map((data) =>
                    <li key={data.id} className="list-item">
                      <Link to={this.props.location.pathname} onClick={() => this.showMessage(data.id)}>
                        <Columns>
                          <Columns.Column >
                            <p>
                              <Icon className="push-right-sm" style="width : '11px', height: '14px" style={(data['author']['email'] != personalData['email']) ? {} : { display: 'none' }}>
                                <img src="/assets/icons/fill_green_left.svg" alt="" />
                              </Icon>
                              <span className="has-text-primary has-text-weight-bold">{data['author']['name']}</span></p>
                            <p className="is-size-8 has-text-grey-dark pad-right-xl">{data['body']}</p>
                          </Columns.Column>
                          <Columns.Column narrow={true} style={{ width: '135px' }}>
                            <p className="has-text-grey-dark">
                              {(data['created_at']) ? moment.utc(data['created_at']).local().format('MMM D, YYYY') : ''}
                            </p>
                          </Columns.Column>
                        </Columns>
                      </Link>
                    </li>
                  )}
                </ul>
              </Columns.Column>
            </Columns>
          </div>
          {this.state.thread && (
            <Modal showClose={false} show={show} onClose={this.close}>
              <Modal.Content>
                <Modal.Card>
                  <Modal.Card.Head onClose={this.close}></Modal.Card.Head>
                  <Modal.Card.Body className="centered" >

                    <ModalView
                      name="modalMessage"
                      activeModal={activeModal}
                      active={showFormValue}
                      viewType="ShowFormValue"
                      align="left"
                      head={message ? message['author']['name'] : ''}
                    >
                      <p className="push-bottom-md">
                        <span className="has-text-grey-dark">{labels['MESSAGES_MODAL_TOPIC']}:</span> {thread ? thread['title'] : '...'}
                      </p>
                      <p className="push-bottom-md">
                        <span className="has-text-grey-dark">
                          {message ? moment.utc(message['created_at']).local().format('MMM D, YYYY') : ''}
                        </span>
                      </p>
                      <p className="push-bottom-md has-text-grey-dark">{message ? message['body'] : ''}</p>
                      {message && message.attachments.length !== 0 && (
                        <div className="attachment-wrap push-top-md">
                          <p className="has-text-primary has-text-weight-bold push-top-md is-size-6 push-bottom-md">
                            {`${message.attachments.length} ${labels['MESSAGE_ATTACHMENT_TITLE']}`}
                          </p>
                          <Slider {...settings}>
                            {message.attachments.map((attachment, index) =>
                              <div key={index} className="img-item">
                                <div className="files-list-item-download-hover is-flex push-bottom-sm">
                                  {attachment.src && (
                                    <>
                                      <img
                                        src={window.URL.createObjectURL(attachment.src)}
                                        alt=""
                                      />
                                      <a
                                        className="files-list-item-download has-text-white"
                                        href={window.URL.createObjectURL(attachment.src)}
                                        download={attachment.file}
                                      >
                                        <FontAwesomeIcon icon={faArrowToBottom} className="is-size-3" />
                                        <span className="is-block is-size-8" style={{ marginTop: 3 }}>
                                          {labels["BUTTON_DOWNLOAD"]}
                                        </span>
                                      </a>
                                    </>
                                  )}
                                  {!attachment.src && (
                                    <FontAwesomeIcon icon={faSpinnerThird} className="is-size-3 has-text-primary fa-spin" />
                                  )}
                                </div>
                                <p className="has-text-primary is-size-7 name">{attachment.file}</p>
                                <p className="has-text-grey is-size-8">
                                  {attachment.size} | .{attachment.mime_type.split("/")[1]}
                                </p>
                              </div>
                            )}
                          </Slider>
                        </div>
                      )}
                      <Button.Group className="pad-top-xl" style={{ justifyContent: 'center', marginBottom: 0 }}>
                        {(message && message['author']['email'] !== personalData['email']) ? (
                          <Button
                            size="small"
                            color="info"
                            style={{ minWidth: 190 }}
                            onClick={() => this.open('modalComment')}
                            title={labels["BUTTON_REPLY"]}
                          >
                            <span className="push-left-md">{labels["BUTTON_REPLY"]}</span>
                          </Button>
                        ) :
                          <Button
                            outlined={true}
                            size="small"
                            color="info"
                            style={{ minWidth: 190 }}
                            onClick={this.close}
                            title={labels["BUTTON_CANCEL"]}
                          >
                            {labels["BUTTON_CANCEL"]}
                          </Button>
                        }
                        <Button
                          onMouseOver={this.handleMouseOver}
                          onMouseOut={this.handleMouseOut}
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          size="small"
                          outlined={true}
                          color="primary"
                          style={{ minWidth: 190 }}
                          onClick={() => this.deleteMessage(message['id'])}
                          title={labels["BUTTON_DELETE"]}
                        >
                          <Icon size="large" style={{ width: 19 }}>
                            <img src={this.state.imgSrc} alt="" />
                          </Icon>
                          <span className="push-left-md push-top-xxs has-text-weight-light">{labels["BUTTON_DELETE"]}</span>
                        </Button>
                      </Button.Group>
                    </ModalView>

                    <ModalView
                      name="modalComment"
                      activeModal={activeModal}
                      active={showFormValue}
                      viewType="ShowFormValue"
                      align="left"
                      head={labels['MESSAGES_MODAL_TITLE']}
                    >
                      <p className="push-bottom-md" style={thread ? {} : { display: 'none' }}>
                        <span className="has-text-grey-dark">{labels['MESSAGES_MODAL_TOPIC']}: </span> {thread ? thread['title'] : '...'}
                      </p>
                      <form className="pad-top-md pad-bottom-md" onSubmit={this.onReply}>
                        <Columns>
                          <Columns.Column size={12}>
                            <Field >
                              <Label className="is-sr-only">{labels['MESSAGES_MODAL_ATTACH']}</Label>
                              <Control iconRight={true}>
                                <div className="box-files">
                                  {this.state.files.length > 0
                                    ?
                                    <div className='files-list'>
                                      <ul>{this.state.files.map((file) =>
                                        <li className='files-list-item' key={file.id}>
                                          <div className='files-list-item-preview'>
                                            {file.preview.type === 'image'
                                              ? <img className='files-list-item-preview-image' src={file.preview.url} />
                                              : <div className='files-list-item-preview-extension'>{file.extension}</div>}
                                          </div>

                                          <div
                                            id={file.id}
                                            className='delete files-list-item-remove'
                                            onClick={this.filesRemoveOne.bind(this, file)}
                                          >
                                          </div>
                                        </li>
                                      )}</ul>
                                    </div>
                                    : <p className="text-file-empty">{labels['MESSAGES_MODAL_ATTACH']}</p>
                                  }
                                  <p style={files.length > 0 ? {} : { display: 'none' }}>{files.length} attachments, {size / 1000}kBts</p>
                                  <Files
                                    ref='files'
                                    className='files-dropzone-list'
                                    style={{ height: '21px', width: '21px' }}
                                    onChange={this.onFilesChange}
                                    onError={this.onFilesError}
                                    multiple={false}
                                    maxFiles={3}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                  >
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: '1.5em' }} className="has-text-black" />
                                  </Files>
                                </div>
                              </Control>
                            </Field>
                          </Columns.Column>
                          <Columns.Column>
                            <Field>
                              <Label className="is-sr-only">Comment</Label>
                              <Control>
                                <Textarea value={comment} name="comment" onChange={this.onChange} style={{ backgroundColor: '#F7F7F7', boxShadow: 'none', border: 0, height: '280px' }} placeholder="Comment" />
                              </Control>
                            </Field>
                          </Columns.Column>
                        </Columns>
                        <Button.Group className="pad-top-lg" style={{ justifyContent: 'space-between', marginBottom: 0 }}>
                          <Button
                            disabled={!comment}
                            size="small"
                            color="info"
                            style={{ minWidth: 190, marginRight: 5 }}
                            title={labels["BUTTON_SEND"]}
                          // onClick={() => this.setStep('showFormSuccess')}
                          >
                            {labels["BUTTON_SEND"]}
                          </Button>
                          <Button
                            outlined={true}
                            size="small"
                            color="info"
                            style={{ minWidth: 190 }}
                            onClick={this.close}
                            title={labels["BUTTON_CANCEL"]}
                          >
                            {labels["BUTTON_CANCEL"]}
                          </Button>
                        </Button.Group>
                      </form>
                    </ModalView>

                  </Modal.Card.Body>
                </Modal.Card>
              </Modal.Content>
            </Modal>
          )}
        </>
      </DocumentTitle>
    )
  }
}

export default connect(
  state => ({
    loader: state.loader,
    labels: state.localization.labels
  }),
  dispatch => ({
    onLoader: (status) => {
      dispatch({ type: 'SET_STATUS', payload: status })
    },
    onRead: (status) => {
      dispatch({ type: 'SET_UNREAD', payload: status })
    }
  })
)(MessageDetails)