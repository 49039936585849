import React from 'react'
import { connect } from "react-redux";
import { Button } from '../../components/lib/components/button';
import ModalView from '../../components/lib/components/view/ModalView';
import { setFocusElement } from '../../_helpers';

const ModalTrapAddCard = ({
    activeModal,
    showFormValue,
    showFormSuccess,
    showFormError,
    labels,
    resultUrl,
    close,
    focusOfDeactivate
}) => {
    return (
        <>
            <ModalView
                name="modalAddCard"
                activeModal={activeModal}
                active={showFormValue}
                viewType="showFormValue"
                align="left"
                delay={true}
                focusOptions={{
                    onActivate: () => {
                        // setFocusElement(`#${activeModal}ShowFormSuccess_part`);
                    },
                    onDeactivate: () => {
                        //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
                        setTimeout(() => {
                            // setFocusElement(`${focusOfDeactivate}`)
                        })
                    }
                }}
            >
                <p
                    tabIndex="-1"
                    id={`${activeModal}ShowFormError_part`}
                    className="is-size-4 has-text-primary has-text-weight-bold push-bottom-xs"
                >
                    {labels["BUTTON_ADD_NEW_CARD"]}
                </p>
                <p tabIndex="-1" id="add-new-card-title" className="has-text-grey-dark">{labels['CITATIONS_MODAL_PAYMENT_TEXT']}</p>
                <form action={`${resultUrl}`} className="paymentWidgets" data-brands="VISA MASTER AMEX">
                    {labels['OPTIONS_MODAL_LOADING']}
                </form>
                <span className="is-size-8 has-text-grey-dark ">
                    {labels["OPTIONS_MODAL_POLICY"]}&nbsp;
                    <a target="_blank" href="https://www.indygo.net/mykey-privacy-policy/" className="has-text-info">
                        {labels["GDPR_CHECKBOX_PRIVACY"]}
                    </a>
                </span>
            </ModalView>
            <ModalView
                name="modalAddCard"
                activeModal={activeModal}
                active={showFormSuccess}
                viewType="showFormSuccess"
                icon="success"
                head={labels["MODAL_TITLE_SUCCESSFUL"]}
                messages={[labels["OPTIONS_MODAL_SUCCESS_TEXT"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormSuccess_part`);
                    },
                    onDeactivate: () => {
                        //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
                        setTimeout(() => {
                            setFocusElement(`${focusOfDeactivate}`)
                        })
                    }
                }}
            >
                <Button
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={close}
                    title={labels["BUTTON_CONTINUE"]}
                >
                    {labels["BUTTON_CONTINUE"]}
                </Button>
            </ModalView>
            <ModalView
                name="modalAddCard"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels["MODAL_TITLE_ERROR"]}
                messages={[labels["OPTIONS_MODAL_ERROR_TEXT_1"], labels["OPTIONS_MODAL_ERROR_TEXT_2"], labels["OPTIONS_MODAL_ERROR_TEXT_3"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormError_part`);
                    },
                    onDeactivate: () => {
                        //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
                        setTimeout(() => {
                            setFocusElement(`${focusOfDeactivate}`)
                        })
                    }
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={close}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels["BUTTON_TRY_AGAIN"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        title={labels["BUTTON_CANCEL"]}
                        onClick={close}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        labels: state.localization.labels
      }),
      dispatch => ({})
)(ModalTrapAddCard)