import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Columns } from "../components/lib/components/columns";
import moment from "moment";

class Footer extends React.Component {
  render() {
    const { labels, artifacts, artifacts: { configuration } } = this.props;
    const phoneNumber = artifacts.parameters.customer_support_phone || "";

    return (
      <footer className="footer has-text-grey-dark">
        <div className="container">
          <Columns>

            <Columns.Column size={2}>
              <Link to="/login" title="My Key Homepage">
                <img src="/assets/img/brand/logo.svg" alt="My Key Homepage" />
              </Link>
            </Columns.Column>

            <Columns.Column size={3}>
              <p>{labels["LOGIN_BOTTOM_TEXT"]}</p>
            </Columns.Column>

            {phoneNumber && (
              <Columns.Column size={2} aria-describedby="phone-number-text">
                <p id="phone-number-text" className="has-text-white">{labels["PROFILE_SUPPORT_PHONE_NUMBER"]}</p>
                <a
                  href={`tel:${phoneNumber.replace(/ /gi, "")}`}
                  className="is-family-secondary is-size-6 has-text-info"
                >
                  {phoneNumber}
                </a>
              </Columns.Column>
            )}
                   
            <Columns.Column size={2} offset={1} className="column is-2 is-offset-1">
              <h2 className="is-family-secondary has-text-info push-bottom-md">{labels["LOGIN_QUICK_LINKS"]}</h2>
              <p>
                <Link 
                  to="/create-account" 
                  title={labels["LOGIN_CREATE_ACCOUNT"]}
                  className="has-text-white-ter"
                >
                  {labels["LOGIN_CREATE_ACCOUNT"]}
                </Link>
              </p>
              <p>
                <Link 
                  to="/login" 
                  title={labels["LOGIN_TITLE"]}
                  className="has-text-white-ter"
                >
                  {labels["LOGIN_TITLE"]}
                </Link>
              </p>
            </Columns.Column>

            <Columns.Column size={2}>
              <h2 className="is-family-secondary has-text-info push-bottom-md">{labels["LOGIN_RESOURCES"]}</h2>
              <p>
                <Link 
                  className="has-text-white-ter" 
                  title={labels["LOGIN_FAQs"]} 
                  target="_blank" 
                  to="https://www.indygo.net/mykey/"
                  rel="noopener noreferrer"
                >
                  {labels["LOGIN_FAQs"]}
                </Link>
              </p>
              <p>
                <Link 
                  className="has-text-white-ter" 
                  title={labels["LOGIN_GENERAL_FARES"]} 
                  target="_blank" 
                  to="https://www.indygo.net/fares/"
                  rel="noopener noreferrer"
                >
                  {labels["LOGIN_GENERAL_FARES"]}
                </Link>
              </p>
              <p>
                <Link 
                  className="has-text-white-ter" 
                  title={labels["LOGIN_ROUTE_MAP"]} 
                  target="_blank" 
                  to="https://www.indygo.net/route/"
                  rel="noopener noreferrer"
                >
                  {labels["LOGIN_ROUTE_MAP"]}
                </Link>
              </p>
            </Columns.Column>

          </Columns>

          <Columns className="pad-bottom-md">
            <Columns.Column size={10} offset={2}>
              <Link 
                target="_blank" 
                rel="noopener noreferrer"
                title="IndyGo" 
                to="https://www.indygo.net/" 
                className="footer__service-logo"
              >
                <img src="/assets/img/brand/IndyGo_logo_white.svg" alt="IndyGo" />
              </Link>
              {configuration['portal.footer']['stores']['appStoreURL']  && <a 
                target="_blank" 
                rel="noopener noreferrer"
                title="IndyGo App Store" 
                href={configuration['portal.footer']['stores']['appStoreURL']}
                className="footer__service-logo"
              >
                <img src="/assets/img/brand/app_store_logo.svg" alt="IndyGo App Store" />
              </a>}
              {configuration['portal.footer']['stores']['googlePlayURL'] && <Link 
                target="_blank"
                rel="noopener noreferrer" 
                title="IndyGo Google Play" 
                to={configuration['portal.footer']['stores']['googlePlayURL']}
                className="footer__service-logo"
              >
                <img src="/assets/img/brand/google_play_logo.svg" alt="IndyGo Google Play" />
              </Link>}
            </Columns.Column>
          </Columns>

        </div>

        <div className="footer__copyright has-text-centered has-background-black">
          ©{moment(new Date()).local().format('YYYY')} INDYGO :: {labels["FOOTER_RESERVE"]} :: <a target="_blank" title={labels["FOOTER_TERMS"]} href="https://www.indygo.net/mykey-terms-and-conditions/" className="has-text-white">{labels["FOOTER_TERMS"]}</a> :: <a href="https://www.indygo.net/mykey-privacy-policy/" target="_blank" className="has-text-white" title={labels["FOOTER_PRIVACY"]}>{labels["FOOTER_PRIVACY"]}</a>
        </div>

      </footer>
    )
  }
}

export default connect(
  state => ({
    labels: state.localization.external.labels,
    artifacts: state.artifacts
  }),
  dispatch => ({
  })
)(Footer)