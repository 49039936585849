import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Navigation } from "./components";
import { Link } from "react-router";
import { Icon } from "./components/lib/components/icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "./components/lib/components/modal";
import { userService, threadsService } from "./_services";
import ModalDeleteMyAccount from "./dashboard/ModalDeleteMyAccount";
import ModalLogOut from "./dashboard/ModalLogOut";

class App extends React.Component {

  state = {
    activeModal: "",
    show: false,
    showFormSuccess: false,
    showFormValue: true,
    showFormError: false,
    password: ""
  }

  open = activeModal => {
    this.setState({
      activeModal,
      showFormSuccess: false,
      showFormValue: true,
      showFormError: false,
      show: true
    });
  }

  close = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ show: false })
  }

  onChange = evt => {
    const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    this.setState({
      [evt.target.name]: value
    });
  }

  componentDidMount() {
  }

  setStep = step => {
    this.setState({
      showFormSuccess: false,
      showFormValue: false,
      showFormError: false,
      [step]: true
    });
  }

  getMessagesUnread = () => {
    threadsService.getMessagesUnread()
      .then(res => {
        try {
          this.props.onRead(res.data.data.count)
        } catch (e) {
          console.log(e);
        }
      })
      .catch((error) => {
        this.props.onLoader('no');
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  onDeleteAccount = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onLoader('yes');
    const { password } = this.state;
    userService.deleteAccount(password)
      .then(response => {
        this.props.onLoader('no');
        this.setStep('showFormSuccess');
      })
      .catch((error) => {
        this.props.onLoader('no');
        this.setStep('showFormError');
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  render() {
    const { activeModal, showFormSuccess, showFormValue, password, show, showFormError } = this.state;
    const { labels, unread, loader, location: { pathname }, info } = this.props;

    return (
      <>
        {loader === "yes" && <div className="line-loader" />}
        <div className="page-body">
          <Navigation className="is-size-5 has-text-weight-normal">
            {!info && (
              <div>
                <Link
                  to="/fare-cards"
                  role="menuitem"
                  className={classnames(
                    "navigation-link",
                    { "is-active": (pathname.includes("fare-card") || pathname.includes("/pair-media")) && !show }
                  )}
                >
                  {labels["PROFILE_FARE_CARD_MENU_LEFT"]}
                </Link>
                <Link
                  to="/personal-information"
                  role="menuitem"
                  className={classnames(
                    "navigation-link",
                    { "is-active": pathname === "/personal-information" && !show }
                  )}
                >
                  {labels["PROFILE_PERSONAL_INFO_TEXT"]}
                </Link>
                <Link
                  to="/transaction-history"
                  role="menuitem"
                  className={classnames(
                    "navigation-link",
                    { "is-active": pathname === "/transaction-history" && !show }
                  )}
                >
                  {labels["PROFILE_TRANSACTION_HISTORY"]}
                </Link>
                <Link
                  to="/pay-citations"
                  role="menuitem"
                  className={classnames(
                    "navigation-link",
                    { "is-active": pathname === "/pay-citations" && !show }
                  )}
                >
                  {labels["PROFILE_CITATIONS_TEXT"]}
                </Link>
                <Link
                  to="/payment-options"
                  role="menuitem"
                  className={classnames(
                    "navigation-link",
                    { "is-active": pathname === "/payment-options" && !show }
                  )}
                >
                  {labels["PROFILE_PAYMENT_OPT_TEXT"]}
                </Link>
                <Link
                  to="/messages"
                  role="menuitem"
                  className={classnames(
                    "navigation-link",
                    { "is-active": (pathname === "/messages" || pathname.includes("/threads")) && !show }
                  )}
                >
                  <span className={classnames({ "is-event": unread > 0 })}>
                    {labels["PROFILE_MESSAGE_TEXT"]}
                  </span>
                </Link>
                <Link
                  to="/help-and-support"
                  role="menuitem"
                  className="navigation-link"
                >
                  {labels["PROFILE_HELP_NOTIFICATIONS"]}
                  <Icon>
                    <FontAwesomeIcon icon={faChevronRight} className="is-size-7 has-text-white" />
                  </Icon>
                </Link>
              </div>
            )}
            {info && (
              <div>
                <Link
                  to="/welcome"
                  role="menuitem"
                  className="navigation-link start"
                >
                  <Icon className="pull-left-sm">
                    <FontAwesomeIcon icon={faChevronLeft} className="is-size-7 has-text-info" />
                  </Icon>
                  <p className="push-left-sm has-text-info">{labels["INFO_HELP_NOTIFICATIONS"]}</p>
                </Link>
                <Link
                  to="/help-and-support"
                  role="menuitem"
                  className={classnames(
                    "navigation-link",
                    { "is-active": pathname === "/help-and-support" && !show }
                  )}
                >
                  {labels["INFO_HELP_SUPPORT"]}
                </Link>
                <a
                  className="navigation-link"
                  role="menuitem"
                  target="_blank"
                  href="https://www.indygo.net/mykey-privacy-policy/"
                >
                  {labels["INFO_PRIVACY"]}
                </a>
                <Link
                  to="/notifications"
                  role="menuitem"
                  className={classnames(
                    "navigation-link",
                    { "is-active": pathname === "/notifications" && !show }
                  )}
                >
                  {labels["INFO_NOTIFICATIONS"]}
                </Link>
                <Link
                  to="/contacts"
                  role="menuitem"
                  className={classnames(
                    "navigation-link",
                    { "is-active": pathname === "/contacts" && !show }
                  )}
                >
                  {labels["INFO_CONTACTS"]}
                </Link>
                <a
                  className="navigation-link"
                  role="menuitem"
                  target="_blank"
                  href="https://www.indygo.net/mykey-terms-and-conditions/"
                >
                  {labels["INFO_TERMS"]}
                </a>
              </div>
            )}
            <div>
              <Link
                id="btnDeleteMyAccount"
                to={pathname}
                role="menuitem"
                className={classnames(
                  "navigation-link",
                  { "is-active": activeModal === "modalDeleteMyAccount" && show }
                )}
                onClick={() => this.open("modalDeleteMyAccount")}
              >
                {labels["PROFILE_DELETE_MY_ACCOUNT"]}
                <Icon>
                  <img
                    src="/assets/icons/trash_white.svg"
                    alt=""
                    aria-hidden="true"
                  />
                </Icon>
              </Link>
              <Link
                to={pathname}
                role="menuitem"
                className={classnames(
                  "navigation-link",
                  { "is-active": activeModal === "modalLogOut" && show }
                )}
                onClick={() => this.open("modalLogOut")}
              >
                <p className="has-text-success">{labels["PROFILE_LOG_OUT"]}</p>
              </Link>
            </div>
          </Navigation>

          <main className="page-content is-fluid container">
            {this.props.children || "Welcome to your Inbox"}
          </main>

        </div>

        <Modal showClose={false} show={show} onClose={this.close}>
          <Modal.Content>
            <Modal.Card>
              <Modal.Card.Head className={classnames({ "delete-success": showFormSuccess })} onClose={this.close}>
              </Modal.Card.Head>
              <Modal.Card.Body className="centered">

                {/* Modal Delete My Account */}
                <ModalDeleteMyAccount
                  activeModal={activeModal}
                  showFormValue={showFormValue}
                  showFormSuccess={showFormSuccess}
                  showFormError={showFormError}
                  onChange={this.onChange}
                  close={this.close}
                  setStep={this.setStep}
                  password={password}
                  onDeleteAccount={this.onDeleteAccount}
                  {...this.props}
                ></ModalDeleteMyAccount>

                {/* Modal LogOut */}
                <ModalLogOut
                  activeModal={activeModal}
                  showFormValue={showFormValue}
                  close={this.close}
                  {...this.props}
                ></ModalLogOut>

              </Modal.Card.Body>
            </Modal.Card>
          </Modal.Content>
        </Modal>

      </>
    )
  }
}

export default connect(
  state => ({
    token: state.token,
    loader: state.loader,
    labels: state.localization.labels,
    unread: state.unread,
    routing: state.routing
  }),
  dispatch => ({
    onLoader: (status) => {
      dispatch({ type: 'SET_STATUS', payload: status })
    },
    onRead: (status) => {
      dispatch({ type: 'SET_UNREAD', payload: status })
    }
  })
)(App)