import React from "react";
import { connect } from "react-redux";
import { Button } from "../../../components/lib/components/button";
import { Icon } from "../../../components/lib/components/icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import NumberFormat from "react-number-format";
import { Dropdown } from "../../../components/lib/components/dropdown";
import ModalView from "../../../components/lib/components/view/ModalView";
import { setFocusElement, convertCurrencyToWords, focusSectionHandler, keyPressSectionHandler } from "../../../_helpers";
import { cardService } from "../../../_services";

class ModalTransfer extends React.Component {

  static defaultProps = {
    step: 100,
    minTransfer: 100,
    maxTransfer: 5000
  }

  state = {
    showFormValue: true,
    showFormSuccess: false,
    showFormError: false,
    transferAmount: 500,
    newCardBalance: null,
    sumBalanceToCard: null,
    toFareCard: null
  }
  _sectionValue = React.createRef();
  
  componentDidMount() {
    this.setState({transferAmount: this.props.maxTransfer})
    if (this._sectionValue.current) {
      this._sectionValue.current.addEventListener("focus", (event) => focusSectionHandler(event, this._sectionValue));
      this._sectionValue.current.addEventListener("keydown", (event) => keyPressSectionHandler(event, this._sectionValue, '#fromValue'));
    }
  }
  componentWillUnmount() {
    const { newCardBalance, sumBalanceToCard } = this.state;

    if (newCardBalance && sumBalanceToCard) {
      this.props.onTransferedBalance();
    }
    if (this._sectionValue.current) {
      this._sectionValue.current.removeEventListener("focus", (event) => focusSectionHandler(event, this._sectionValue));
      this._sectionValue.current.removeEventListener("keydown", (event) => keyPressSectionHandler(event, this._sectionValue, '#fromValue'));
    }
  }
  onTryAgain = () => {
    this.setStep("showFormValue");
    //TODO: Hard solution for ADA
    setTimeout(() => {
      if (this._sectionValue.current) {
        this._sectionValue.current.addEventListener("focus", (event) => focusSectionHandler(event, this._sectionValue));
        this._sectionValue.current.addEventListener("keydown", (event) => keyPressSectionHandler(event, this._sectionValue, '#fromValue'));
      }
    })
  }
  setStep = step => {
    this.setState({
      showFormValue: false,
      showFormSuccess: false,
      showFormError: false,
      [step]: true
    });
    // setFocusElement(`#${this.state.activeModal + step}`);
  }

  decrease = () => {
    const { step, minTransfer } = this.props;
    let amount = this.state.transferAmount;
    if (amount > minTransfer && amount % 100 === 0 ) {
      amount -= step;
    }else if(amount > minTransfer && amount % 100 !== 0){
      amount -= amount % 100;
    }
    if (amount < minTransfer) {
      amount = minTransfer;
    }
    this.setState({ transferAmount: amount }, () => {
      // setFocusElement("#transferBalance");
    })
  }

  increase = () => {
    const { step, fromCard, maxTransfer: max } = this.props;
    const toCardBalance = fromCard.balance_int; /// 100;
    const maxTransfer = toCardBalance < max ? toCardBalance : max;
    let amount = this.state.transferAmount;
    if (amount < maxTransfer) {
      amount += step;
    }
    if (amount > maxTransfer) {
      amount = maxTransfer;
    }
    this.setState({ transferAmount: amount }, () => {
      // setFocusElement("#transferBalance");
    })
  }

  onTransferBalance = () => {
    const body = {
      "transfer_id": "" + this.state.toFareCard.id,
      "value": this.state.transferAmount
    }
    this.props.onLoader("yes");
    cardService.transferBalance(this.props.fromCard.id, body)
      .then(res => {
        const { toFareCard, transferAmount } = this.state;
        this.setState({
          sumBalanceToCard: (toFareCard.balance_int + Number(transferAmount)) / 100,
          newCardBalance: res.data.data.balance.formatted
        });
        this.setStep("showFormSuccess");
        this.props.onLoader("no");
      })
      .catch(err => {
        console.log("error.response", err.response);
        this.setStep("showFormError");
        this.props.onLoader("no");
        // redirect to login page
        if (err.response.status === 401) {
          this.props.router.push("/login");
        }
      })
  }

  onChangeFareCard = cardId => {
    const toFareCard = this.props.fareCardsList.find(card => card.id === cardId);
    if (toFareCard) {
      this.setState({ toFareCard });
    }
  }

  render() {
    const { showFormValue, showFormSuccess, showFormError, transferAmount, newCardBalance, sumBalanceToCard, toFareCard } = this.state;
    const { activeModal, labels, loader, fromCard, close, minTransfer, maxTransfer, fareCardsList, onTransferedBalance } = this.props;

    return (
      <>
        <ModalView
          name="modalTransfer"
          activeModal={activeModal}
          active={showFormValue}
          viewType="ShowFormValue"
          align="left"
          head={labels["FARE_CARD_TRANSFER_BALANCE"]}
          focusOptions={{
            onActivate: () => {
              // setFocusElement("#modalTransferShowFormValue_label");
            },
            onDeactivate: () => {}
          }}
        >
          <div
            className="balance-card push-top-md pad-bottom-xl push-bottom-md line"
            tabIndex="0"
            id="sectionValue"
            ref={this._sectionValue}
          >
            <p className="has-text-grey-dark has-text-weight-semibold">
              {labels["FARE_CARD_TRANSFER_VALUE"]}
            </p>
            <p className="is-flex space-between is-size-2 pull-top-sm has-text-weight-light" style={{ maxWidth: 275 }}>
              <NumberFormat
                // id="transferBalance"
                tabIndex="-1"
                aria-label={`${labels["FARE_CARD_TRANSFER_VALUE"]}: ${convertCurrencyToWords(transferAmount / 100)}`}
                displayType="text"
                decimalSeparator="."
                fixedDecimalScale={true}
                decimalScale={2}
                prefix="$"
                value={transferAmount/100}
              />
              <span className="balance-card__buttons push-top-md">
                <button
                  type="button"
                  tabIndex="-1"
                  onClick={this.decrease}
                  title={labels["FARE_CARD_VALUE_DECREASE"]}
                  className={`is-borderless has-background-none ${transferAmount <= minTransfer ? "has-text-grey" : "has-text-success"}`}
                  style={{ padding: 5, lineHeight: 1 }}
                // disabled={transferAmount <= minTransfer}
                >
                  <Icon size="medium">
                    <FontAwesomeIcon className="is-size-3" icon={faMinusCircle} />
                  </Icon>
                </button>
                <button
                  type="button"
                  tabIndex="-1"
                  onClick={this.increase}
                  title={labels["FARE_CARD_VALUE_INCREASE"]}
                  className={`is-borderless has-background-none ${transferAmount >= maxTransfer || transferAmount >= fromCard.balance_int ? "has-text-grey" : "has-text-success"}`}
                  style={{ padding: 5, lineHeight: 1 }}
                // disabled={transferAmount >= maxTransfer || transferAmount >= fromCard.balance_int / 100}
                >
                  <Icon size="medium">
                    <FontAwesomeIcon className="is-size-3" icon={faPlusCircle} />
                  </Icon>
                </button>
              </span>
            </p>
          </div>

          <div className="line">
            <p id="from_payment_title" className="has-text-grey-dark push-bottom-md">
              {labels["FARE_CARD_TRANSFER_FROM"]}
            </p>
            <Dropdown
              id="fromValue"
              value={fromCard.id}
              toggleDrop={false}
              className="pad-bottom-md select-fare-card"
              label={labels["FARE_CARD_SELECT"]}
              labelledBy="from_payment_title"
            >
              <Dropdown.Item
                value={fromCard.id}
                aria-label={`${fromCard.label || labels["FARE_CARD_MOBILE_CARD"]}: ${fromCard.id}. ${labels["FARE_CARD_TRANSFER_CURRENT"]}: ${convertCurrencyToWords(fromCard.balance.formatted)}`}
              >
                <div className="dropdown-item__box">
                  <div className="dropdown-item__box__description">
                    <p className="name">{fromCard.label || labels["FARE_CARD_MOBILE_CARD"]}</p>
                    <NumberFormat
                      className="has-text-primary"
                      value={fromCard.id}
                      displayType="text"
                      format="#### #### #### ####"
                    />
                  </div>
                  <div className="dropdown-item__box__price pad-right-xl">
                    <p className="is-size-8 has-text-grey-dark">{labels["FARE_CARD_TRANSFER_CURRENT"]}</p>
                    <p aria-label={convertCurrencyToWords(fromCard.balance.formatted)} className="has-text-info">
                      {fromCard.balance.formatted}
                    </p>
                  </div>
                </div>
              </Dropdown.Item>
            </Dropdown>
          </div>

          <p id="to_payment_title" className="has-text-grey-dark push-bottom-md push-top-md">
            {labels["FARE_CARD_TRANSFER_TO"]}
          </p>
          <Dropdown
            id="to_payment_dropdown"
            value={toFareCard && toFareCard.id}
            onChange={this.onChangeFareCard}
            className="pad-bottom-md line"
            label={labels["FARE_CARD_SELECT"]}
            labelledBy="to_payment_title"
          >
            {fareCardsList.map((data, k) => (
              <Dropdown.Item
                key={k}
                value={data.id}
                aria-label={`${data.label || labels["FARE_CARD"]}: ${data.id}. ${labels["FARE_CARD_TRANSFER_CURRENT"]}: ${data.balance.formatted}`}
              >
                <div className="dropdown-item__box">
                  <div className="dropdown-item__box__image">
                    <Icon style={{ width: 20, height: 22 }}>
                      <img
                        src="/assets/img/MyKey_Card.png"
                        alt=""
                        aria-hidden="true"
                      />
                    </Icon>
                  </div>
                  <div className="dropdown-item__box__description">
                    <NumberFormat
                      className="has-text-primary"
                      value={data.id}
                      displayType="text"
                      format="#### #### #### ####"
                    />
                    <p className="name">{data.label || labels["FARE_CARD"]}</p>
                  </div>
                  <div className="dropdown-item__box__price pad-right-xl">
                    <p className="is-size-8 has-text-grey-dark">{labels["FARE_CARD_TRANSFER_CURRENT"]}</p>
                    <p className="has-text-info">{data.balance.formatted}</p>
                  </div>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown>

          <Button.Group className="push-top-lg space-between">
            <Button
              size="small"
              color="info"
              style={{ minWidth: 190, marginRight: 5 }}
              onClick={this.onTransferBalance}
              loading={loader === "yes"}
              aria-label={labels["BUTTON_TRANSFER_BALANCE"]}
              disabled={!(fromCard.id && toFareCard)}
            >
              {labels["BUTTON_TRANSFER_BALANCE"]}
            </Button>
            <Button
              outlined={true}
              size="small"
              color="info"
              style={{ minWidth: 190 }}
              onClick={close}
              disabled={loader === "yes"}
              aria-label={labels["BUTTON_CANCEL"]}
            >
              {labels["BUTTON_CANCEL"]}
            </Button>
          </Button.Group>
        </ModalView>

        <ModalView
          name="modalTransfer"
          activeModal={activeModal}
          active={showFormSuccess}
          icon="success"
          viewType="ShowFormSuccess"
          head={labels["FARE_CARD_TRANSFER_SUCCESS_TITLE"]}
          messages={[labels["FARE_CARD_TRANSFER_SUCCESS_TEXT"]]}
          focusOptions={{
            initialFocus: `#${activeModal}ShowFormSuccess_part`,
            onDeactivate: () => {
              setTimeout(() => setFocusElement(`#btnTransfer`), 100)
            }
          }}
        >
          <div className="is-flex-tablet">
            <div className="pad-sm" style={{ flex: 1 }}>
              <p className="has-text-grey-dark has-text-weight-bold is-size-5">{labels["FARE_CARD_TRANSFER_SUCCESS_FROM"]}</p>
              <p className="has-text-grey-dark push-bottom-md">
                <NumberFormat
                  value={fromCard.id}
                  displayType="text"
                  format="#### #### #### ####"
                  style={{ whiteSpace: "nowrap" }}
                />
              </p>
              <p className="has-text-grey-dark">{labels["FARE_CARD_NEW_BALANCE"]}:</p>
              <p className="has-text-primary is-size-4" aria-label={convertCurrencyToWords(newCardBalance)}>
                {newCardBalance}
              </p>
            </div>
            <div className="pad-sm" style={{ flex: 1 }}>
              <p className="has-text-grey-dark has-text-weight-bold is-size-5">{labels["FARE_CARD_TRANSFER_SUCCESS_TO"]}</p>
              <p className="has-text-grey-dark push-bottom-md">
                <NumberFormat
                  value={toFareCard && toFareCard.id}
                  displayType="text"
                  format="#### #### #### ####"
                  style={{ whiteSpace: "nowrap" }}
                />
              </p>
              <p className="has-text-grey-dark">{labels["FARE_CARD_NEW_BALANCE"]}:</p>
              <p className="has-text-primary is-size-4" aria-label={convertCurrencyToWords(sumBalanceToCard)}>
                <NumberFormat
                  value={sumBalanceToCard}
                  displayType="text"
                  decimalSeparator="."
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix="$"
                />
              </p>
            </div>
          </div>
          <Button
            className="push-top-xl"
            size="small"
            color="info"
            style={{ minWidth: 190 }}
            onClick={onTransferedBalance}
            title={labels["BUTTON_CONTINUE"]}
          >
            {labels["BUTTON_CONTINUE"]}
          </Button>
        </ModalView>

        <ModalView
          name="modalTransfer"
          activeModal={activeModal}
          active={showFormError}
          viewType="ShowFormError"
          icon="danger"
          head={labels["FARE_CARD_TRANSFER_ERROR_TITLE"]}
          messages={[labels["FARE_CARD_RELOAD_ERROR_TEXT_1"], labels["FARE_CARD_RELOAD_ERROR_TEXT_2"], labels["FARE_CARD_RELOAD_ERROR_TEXT_3"], labels["FARE_CARD_TRANSFER_ERROR_TEXT"]]}
          focusOptions={{
            initialFocus: `#${activeModal}ShowFormError_part`,
            onDeactivate: () => {
              setTimeout(() => setFocusElement(`#btnTransfer`), 100)
            }
          }}
        >
          <Button.Group className="push-top-xl space-between">
            <Button
              size="small"
              color="info"
              style={{ minWidth: 190, marginRight: 5 }}
              onClick={this.onTryAgain}
              title={labels["BUTTON_TRY_AGAIN"]}
            >
              {labels["BUTTON_TRY_AGAIN"]}
            </Button>
            <Button
              outlined={true}
              size="small"
              color="info"
              style={{ minWidth: 190 }}
              onClick={close}
              title={labels["BUTTON_CANCEL"]}
            >
              {labels["BUTTON_CANCEL"]}
            </Button>
          </Button.Group>
        </ModalView>
      </>
    )
  }

}

export default connect(
  state => ({
    loader: state.loader,
    labels: state.localization.labels
  }),
  dispatch => ({
    onLoader: (status) => {
      dispatch({ type: "SET_STATUS", payload: status })
    }
  })
)(ModalTransfer)