import React from 'react'
import DocumentTitle from 'react-document-title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';
import { Columns } from '../../components/lib/components/columns';
import { Icon } from '../../components/lib/components/icon';
import { setFocusPage } from '../../_helpers';

class HelpAndSupport extends React.Component {

  componentDidMount() {
    // focus page
    setFocusPage("#support-page");
  }

  render() {
    const { labels, artifacts: { parameters } } = this.props;
    const phoneNumber = parameters.customer_support_phone;
    const emailSupport = parameters.customer_support_email;

    return (
      <DocumentTitle title={labels['HELP_AND_SUPPORT_PAGE_TITLE'] + ' - IndyGo App'}>
        <div id="support-page" aria-label={labels['HELP_AND_SUPPORT_PAGE_TITLE']} role={labels['HELP_AND_SUPPORT_PAGE_TITLE']}>
            
          <h1 className="is-size-3 is-size-2-tablet has-text-weight-bold pad-bottom-lg pad-top-xl">
            {labels["SUPPORT_TITLE"]}
          </h1>

          <Columns className="pad-top-md">

            {phoneNumber && (
              <Columns.Column size={4} desktop={{ size: 3 }} aria-describedby="support_number_text">
                  <Icon size="large" className="has-background-success radius-50-percent push-bottom-sm">
                    <FontAwesomeIcon icon={faPhone} className="is-size-7 has-text-white is-rotate-90"/>
                  </Icon>
                  <p id="support_number_text" className="has-text-grey has-text-weight-semibold push-bottom-sm">
                    {labels['SUPPORT_NUMBER']}
                  </p>
                  <a 
                    href={`tel:${phoneNumber.replace(/ /gi, "")}`} 
                    className="is-size-5 has-text-success has-text-weight-bold"
                  >
                    {phoneNumber}
                  </a>
              </Columns.Column>
            )}

            {emailSupport && (
              <Columns.Column size={4} aria-describedby="support_email_text">
                <Icon size="large" className="has-background-success radius-50-percent push-bottom-sm">
                  <FontAwesomeIcon icon={faEnvelope} className="is-size-7 has-text-white"/>
                </Icon>
                <p id="support_email_text" className="has-text-grey has-text-weight-semibold push-bottom-sm">
                  {labels['SUPPORT_EMAIL']}
                </p>
                <a 
                  target="_blank"
                  href={`mailto:${emailSupport}`} 
                  className="is-size-5 has-text-success has-text-weight-bold"
                >
                  {emailSupport}
                </a>
              </Columns.Column>
            )}

          </Columns>
        </div>
      </DocumentTitle>
    )
  }
}
  
export default connect(
    state => ({
      loader: state.loader,
      labels: state.localization.labels,
      artifacts: state.artifacts
    }),
    dispatch => ({
    })
  )(HelpAndSupport)