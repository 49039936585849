import React from 'react'
import { connect } from "react-redux";
import { Button } from '../../../components/lib/components/button';
import { Field, Control, Label, Input } from '../../../components/lib/components/form';
import ModalView from '../../../components/lib/components/view/ModalView';

const ModalJoinToProgram = ({
    activeModal,
    showFormValue,
    showFormSuccess,
    showFormError,
    labels,
    onSubmit,
    onChange,
    close,
    setStep,
    programconfirmationcode,
    loader,
    refreshProfiles
}) => {
    const isEnabled = programconfirmationcode.length > 3;
    return (
        <>
            <ModalView
                name="modalJoinToProgram"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                align="left"
                head={labels['FARE_CARD_JOIN_PROGRAM']}
                messages={[labels["FARE_CARD_JOIN_PROGRAM_TEXT"]]}
                // containerLabel={labels['FARE_CARD_JOIN_PROGRAM']}
            >
                <form className="pad-top-md pad-bottom-md" onSubmit={onSubmit}>
                    <Field>
                        <Label className={`${programconfirmationcode === "" ? "" : "has-text-grey-light"}`}>{labels['FARE_CARD_JOIN_PROGRAM']}</Label>
                        <Control>
                            <Input onChange={onChange} style={{ backgroundColor: '#F7F7F7', boxShadow: 'none', border: 0 }} value={programconfirmationcode} name="programconfirmationcode" type="text" placeholder={labels['FARE_CARD_JOIN_PROGRAM_CONFIRM_PLACEHOLDER']} />
                        </Control>
                    </Field>
                    <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                        <Button
                            disabled={!isEnabled || loader === 'yes'}
                            size="small"
                            color="info"
                            style={{ minWidth: 190, marginRight: 5 }}
                            loading={loader === 'yes'}
                            title={labels["BUTTON_JOIN_THIS_PROGRAM"]}
                        >
                            {labels["BUTTON_JOIN_THIS_PROGRAM"]}
                        </Button>
                        <Button
                            outlined={true}
                            size="small"
                            color="info"
                            style={{ minWidth: 190 }}
                            onClick={close}
                            disabled={loader === 'yes'}
                            title={labels["BUTTON_CANCEL"]}
                        >
                            {labels["BUTTON_CANCEL"]}
                        </Button>
                    </Button.Group>
                </form>
            </ModalView>
            <ModalView
                name="modalJoinToProgram"
                activeModal={activeModal}
                active={showFormSuccess}
                icon="success"
                viewType="ShowFormSuccess"
                head={labels["MODAL_TITLE_CONFIRMATION"]}
                messages={[labels["FARE_CARD_JOIN_PROGRAM_SUCCESS"]]}
                // containerLabel={labels["MODAL_TITLE_CONFIRMATION"]}
            >
                <Button
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={refreshProfiles}
                    title={labels["BUTTON_CONTINUE"]}
                >
                    {labels['BUTTON_CONTINUE']}
                </Button>
            </ModalView>
            <ModalView
                name="modalJoinToProgram"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels["MODAL_TITLE_ERROR"]}
                messages={[labels["FARE_CARD_JOIN_PROGRAM_ERROR_TEXT"]]}
                // containerLabel={labels["MODAL_TITLE_ERROR"]}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={() => setStep('showFormValue')}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels["BUTTON_TRY_AGAIN"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={refreshProfiles}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        labels: state.localization.labels,
        loader: state.loader
    }),
    dispatch => ({})
)(ModalJoinToProgram)
