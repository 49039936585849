import React from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import { Button } from "../../../components/lib/components/button";
import { Modal } from "../../../components/lib/components/modal";
import { Field, Control, Label, Input } from "../../../components/lib/components/form";
import { Columns } from "../../../components/lib/components/columns";
import { cardService } from "../../../_services";
import { setFocusPage } from "../../../_helpers";
import ModalAddFareCardByLink from "./ModalAddFareCardByLink";

class AddFareCardLink extends React.Component {

  state = {
    show: false,
    showFormSuccess: false,
    showFormError: false,
    activeModal: "",
    cardName: ""
  }

  componentDidMount() {
    setFocusPage("#add-fare-card");
  }

  open = activeModal => {
    this.setState({
      activeModal,
      showFormSuccess: false,
      showFormError: false,
      show: true
    })
  }

  close = () => this.setState({ show: false });

  setStep = step => {
    this.setState({
      showFormSuccess: false,
      showFormError: false,
      [step]: true
    }, () => {
      setFocusPage(`#${this.state.activeModal + (step.charAt(0).toUpperCase() + step.slice(1))}_part`);
    });
  }

  onChange = event => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value
    })
  }

  onAddFareCard = event => {
    event.preventDefault();
    event.stopPropagation();
    const { cardName } = this.state;

    const params = {
      "label": cardName.trim(),
      "token": this.props.location.query.token
    }
    this.props.onLoader("yes");
    cardService.linkVerify(params)
      .then(res => {
        this.open("modalAddFareCardByLink");
        this.setStep("showFormSuccess");
        this.props.onLoader("no");
      })
      .catch(error => {
        this.open("modalAddFareCardByLink");
        this.setStep("showFormError");
        this.props.onLoader("no");
      })
  }

  render() {
    const { activeModal, showFormSuccess, showFormError, cardName, show } = this.state;
    const { labels, loader } = this.props;
    const isEnabled = cardName.trim().length >= 3;

    return (
      <DocumentTitle title={labels["FARE_CARD_LINK_PAGE_TITLE"] + " - IndyGo App"}>
        <div id="add-fare-card" aria-label={labels["FARE_CARD_LINK_PAGE_TITLE"]}>

          <h1 className="is-size-3 is-size-2-tablet has-text-weight-bold pad-bottom-md pad-bottom-xl-tablet pad-top-xl pad-top-md-tablet">
            {labels["FARE_CARDS_ADD"]}
          </h1>

          <h2
            className="is-size-4 is-size-3-tablet has-text-weight-bold has-text-h2rimary push-bottom-md"
            dangerouslySetInnerHTML={{ __html: labels["FARE_CARD_ADD_CARD_SUBTITLE"] }}
          ></h2>

          <p
            className="has-text-grey-light"
            dangerouslySetInnerHTML={{ __html: labels["FARE_CARD_ADD_CARD_TEXT"] }}
          ></p>

          <form className="pad-top-xl pad-bottom-md" onSubmit={this.onAddFareCard}>
            <Columns>
              <Columns.Column size={4}>
                <Field>
                  <Label
                    htmlFor="cardName"
                    className={cardName.trim() ? "has-text-grey-light" : ""}
                  >
                    {labels["WELCOME_FARE_CARD_NAME_TEXT"]}
                  </Label>
                  <Control>
                    <Input
                      id="cardName"
                      name="cardName"
                      autoComplete="off"
                      onChange={this.onChange}
                      value={cardName}
                      placeholder={labels["FARE_CARD_ADD_CARD_PLACEHOLDER"]}
                    />
                  </Control>
                </Field>
                <Button
                  size="small"
                  color="info"
                  aria-label={labels["BUTTON_ADD_FARE_CARD"]}
                  loading={loader === "yes"}
                  disabled={!isEnabled}
                >
                  {labels["BUTTON_ADD_FARE_CARD"]}
                </Button>
              </Columns.Column>
            </Columns>
          </form>

          <Modal showClose={false} show={show} onClose={this.close}>
            <Modal.Content>
              <Modal.Card>
                <Modal.Card.Head onClose={this.close}></Modal.Card.Head>
                <Modal.Card.Body className="centered">
                  <ModalAddFareCardByLink
                    activeModal={activeModal}
                    showFormSuccess={showFormSuccess}
                    showFormError={showFormError}
                    close={this.close}
                  ></ModalAddFareCardByLink>
                </Modal.Card.Body>
              </Modal.Card>
            </Modal.Content>
          </Modal>

        </div>
      </DocumentTitle>
    )
  }
}

export default connect(
  state => ({
    loader: state.loader,
    routing: state.routing,
    labels: state.localization.labels
  }),
  dispatch => ({
    onLoader: status => {
      dispatch({ type: "SET_STATUS", payload: status })
    }
  })
)(AddFareCardLink)
