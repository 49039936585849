import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import FocusTrap from '../../../focusTrap';

const ModalView = ({
    name,
    activeModal,
    viewType,
    // containerLabel,
    children,
    active,
    icon,
    head,
    messages,
    align,
    focusOptions,
    delay,
}) => {
    const _viewRef = useRef(null);
    useEffect(() => {
        if (delay && _viewRef && _viewRef.current) {
            _viewRef.current.classList.add('view-enter-active');
            setTimeout(() => {
                _viewRef.current.classList.remove('view-enter-active');
                _viewRef.current.classList.add('view-leave');
            }, 3000)
        }
        return () => {
            if (delay && _viewRef && _viewRef.current) {
                _viewRef.current.classList.remove('view-enter-active');
                _viewRef.current.classList.remove('view-leave');
            }
        }
    }, [activeModal])
    return (
        <>
            {activeModal === name && active && (
                <FocusTrap
                    focusTrapOptions={{
                        clickOutsideDeactivates: true,
                        fallbackFocus: `#${activeModal + viewType}`,
                        ...(focusOptions ? focusOptions : {})
                    }}
                >
                    <div
                        ref={_viewRef}
                        id={activeModal + viewType}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby={`${activeModal + viewType}_label`}
                        aria-describedby={`${activeModal + viewType}_desc`}
                        aria-modal="true"
                        className={`has-text-${align} ${delay > 0 ? 'view-enter' : ''}`}
                    >
                        {icon && <FontAwesomeIcon icon={faExclamationCircle} size="3x" className={`has-text-${icon || 'primary'}`} />}
                        {head && (
                            <h2
                                tabIndex="-1"
                                id={`${activeModal + viewType}_label`}
                                className={`is-size-3 has-text-${icon || 'primary'} has-text-weight-bold push-bottom-md push-top-sm is-capitalized`}
                            >
                                {head}
                            </h2>
                        )}
                        {messages.length > 0 && <div >
                            {messages.map((item, i) => (
                                (i === 0) ?
                                    <p
                                        tabIndex="-1"
                                        key={i}
                                        id={`${activeModal + viewType}_part`}
                                        className={`has-text-grey-dark  ${(i === messages.length - 1) ? 'push-bottom-lg' : 'push-bottom-xs'}`}
                                        dangerouslySetInnerHTML={{ __html: item }}
                                    ></p>
                                    :
                                    <p
                                        key={i}
                                        className={`has-text-grey-dark ${(i === messages.length - 1) ? 'push-bottom-lg' : 'push-bottom-xs'}`}
                                        dangerouslySetInnerHTML={{ __html: item }}
                                    ></p>
                            ))}
                        </div>}
                        {children}
                    </div>
                </FocusTrap>
            )
            }
        </>
    )
}

ModalView.propTypes = {
    name: PropTypes.string,
    activeModal: PropTypes.string,
    viewType: PropTypes.string,
    // containerLabel: PropTypes.string,
    icon: PropTypes.string,
    head: PropTypes.string,
    messages: PropTypes.array,
    align: PropTypes.string,
    focusOptions: PropTypes.object,
    delay: PropTypes.bool
};

ModalView.defaultProps = {
    name: undefined,
    activeModal: undefined,
    viewType: 'showFormValue',
    // containerLabel: '',
    icon: undefined,
    head: undefined,
    messages: [],
    align: 'centered',
    focusOptions: {},
    delay: false
};
export default ModalView