import React from "react";
import { Router, Route } from "react-router";
import { store, history } from "../store";

import App from "../app/App";
import { Main, Login, CreateAccount, PasswordResetFirst, PasswordResetSecond, CreateAccountConfirm } from "../app/main";
import {
  Welcome,
  FareCards,
  FareCardQR,
  FareCard,
  AddFareCardLink,
  FareCardProfile,
  PersonalInformation,
  TransactionHistory,
  PayCitations,
  PaymentOptions,
  Messages,
  MessageDetails,
  HelpAndSupport,
  PrivacyPolicy,
  TermsConditions,
  Notifications,
  Contacts,
  PaymentStatus
} from "../app/dashboard";
import ClearStorage from "../app/components/error/ClearStorage";

function requireAuth(nextState, replaceState) {
  // const state = store.getState()
  const isLoggedIn = Boolean(localStorage.getItem('token'))
  if (!isLoggedIn) {
    replaceState({
      nextPathname: nextState.location.pathname
    }, '/login');
    // case automatic redirect to deep link after login
    localStorage.setItem('lastUrl', nextState.location.pathname + nextState.location.search);
  }
}

function logout() {
  store.dispatch({ type: "DELETE_TOKEN" });
  localStorage.removeItem("user");
  localStorage.removeItem("token");
}

const routes = (
  <>

    {/* /clear-storage */}
    <Route path="/clear-storage" exact onEnter={logout} component={props => (
      <ClearStorage />
    )} />

    {/* / */}
    <Route path="/" exact onEnter={logout} component={props => (
      <Main {...props}>
        <Login {...props} />
      </Main>
    )} />

    {/* /login */}
    <Route path="/login" exact onEnter={logout} component={props => (
      <Main {...props}>
        <Login {...props} />
      </Main>
    )} />

    {/* /create-account */}
    <Route path="/create-account" exact onEnter={logout} component={props => (
      <Main {...props}>
        <CreateAccount {...props} />
      </Main>
    )} />

    {/* /activate */}
    <Route path="/activate" exact onEnter={logout} component={props => (
      <Main {...props}>
        <CreateAccountConfirm {...props} />
      </Main>
    )} />

    {/* /password-reset */}
    <Route path="/password-reset" exact onEnter={logout} component={props => (
      <Main {...props}>
        <PasswordResetFirst {...props} />
      </Main>
    )} />

    {/* /password-reset-confirm */}
    <Route path="/password-reset-confirm" exact onEnter={logout} component={props => (
      <Main {...props}>
        <PasswordResetSecond {...props} />
      </Main>
    )} />

    {/* /welcome */}
    <Route path="/welcome" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <Welcome {...props} />
      </App>
    )} />

    {/* /fare-cards */}
    <Route path="/fare-cards" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <FareCards {...props} />
      </App>
    )} />

    {/* /fare-card-qr/:id */}
    <Route path="/fare-card-qr/:id" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <FareCardQR {...props} />
      </App>
    )} />

    {/* /fare-card/:id */}
    <Route path="/fare-card/:id" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <FareCard {...props} />
      </App>
    )} />

    {/* /pair-media */}
    <Route path="/pair-media" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <AddFareCardLink {...props} />
      </App>
    )} />

    {/* /fare-card-profile/:type/:number */}
    <Route path="/fare-card-profile/:type/:number" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <FareCardProfile {...props} />
      </App>
    )} />

    {/* /personal-information */}
    <Route path="/personal-information" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <PersonalInformation {...props} />
      </App>
    )} />

    {/* /transaction-history */}
    <Route path="/transaction-history" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <TransactionHistory {...props} />
      </App>
    )} />

    {/* /pay-citations */}
    <Route path="/pay-citations" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <PayCitations {...props} />
      </App>
    )} />

    {/* /payment-options */}
    <Route path="/payment-options" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <PaymentOptions {...props} />
      </App>
    )} />

    {/* /payment-status */}
    <Route path="/payment-status" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <PaymentStatus {...props} />
      </App>
    )} />

    {/* /messages */}
    <Route path="/messages" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <Messages {...props} />
      </App>
    )} />

    {/* /threads/:id */}
    <Route path="/threads/:id" exact onEnter={requireAuth} component={props => (
      <App {...props}>
        <MessageDetails {...props} />
      </App>
    )} />

    {/* /help-and-support */}
    <Route path="/help-and-support" exact onEnter={requireAuth} component={props => (
      <App info {...props}>
        <HelpAndSupport {...props} />
      </App>
    )} />

    {/* /privacy-policy */}
    <Route path="/privacy-policy" exact onEnter={requireAuth} component={props => (
      <App info {...props}>
        <PrivacyPolicy {...props} />
      </App>
    )} />

    {/* /terms-conditions */}
    <Route path="/terms-conditions" exact onEnter={requireAuth} component={props => (
      <App info {...props}>
        <TermsConditions {...props} />
      </App>
    )} />

    {/* /notifications */}
    <Route path="/notifications" exact onEnter={requireAuth} component={props => (
      <App info {...props}>
        <Notifications {...props} />
      </App>
    )} />

    {/* /contacts */}
    <Route path="/contacts" exact onEnter={requireAuth} component={props => (
      <App info {...props}>
        <Contacts {...props} />
      </App>
    )} />
  
  </>
)

class Routes extends React.Component {
  render() {
    return (
      <Router history={history}>
        {routes}
      </Router>
    )
  }
}

export default Routes;