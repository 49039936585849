import axios from "axios";
import config from "../../config";
import { getToken } from "../_helpers";

export const cardService = {
    getFareCardDigital,
    getCareCards,
    addValue,
    transferBalance,
    autoRefill,
    linkCard,
    addFareCard,
    getFareCard,
    editLabel,
    deleteCard,
    reportLostCard,
    getProfiles,
    addProfile,
    linkVerify,
    getFareCardId,
    getFareCardPatrons,
    deleteLinkOwn
}

function getFareCardDigital() {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/patron/media/digital`, requestOptions)
}

function getFareCard() {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/patron/media`, requestOptions)
}

function getFareCardId(id) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/patron/media/${id}`, requestOptions)
}

function getCareCards() {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/patron/media`, requestOptions)
}

function getFareCardPatrons(id) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/patron/media/${id}/patrons`, requestOptions)
}

function deleteLinkOwn(id, number) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.delete(`${config.API.URL}/api/v1/patron/media/${id}/link/${number}`, requestOptions)
}

function reportLostCard(id) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/media/${id}/report`, {}, requestOptions)
}

function addValue(number, body) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/media/${number}/add-value`, body, requestOptions)
}

function editLabel(number, body) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/media/${number}`, body, requestOptions)
}

function transferBalance(number, body) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/media/${number}/transfer`, body, requestOptions)
}

function autoRefill(number, body) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/media/${number}/auto-refill`, body, requestOptions)
}

function linkCard(number, body) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/media/${number}/link`, body, requestOptions)
}

function addFareCard(body) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.post(`${config.API.URL}/api/v1/patron/media`, body, requestOptions)
}

function deleteCard(number) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.delete(`${config.API.URL}/api/v1/patron/media/${number}`, requestOptions)
}

function getProfiles(number) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/patron/media/${number}/profiles`, requestOptions)
}

function addProfile(number, body) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/media/${number}/profile`, body, requestOptions)
}

function linkVerify(body) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/media/link/verify`, body, requestOptions)
}