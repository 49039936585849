import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';
import { Button } from '../../components/lib/components/button';
import { Icon } from '../../components/lib/components/icon';
import { Level } from '../../components/lib/components/level';
import { Modal } from '../../components/lib/components/modal';
import { Label } from '../../components/lib/components/form';
import { Columns } from '../../components/lib/components/columns';
import ActivateButton from "../../components/toggler";
import { paymentService } from "../../_services";
import { loadScript, setFocusPage, setFocusElement } from "../../_helpers";
import { toast, ToastContainer } from 'react-toastify';
import Card from "../../components/card/card";
import update from "immutability-helper";
import ModalDeleteCard from './ModalDeleteCard';
import ModalTrapAddCard from './ModalTrapAddCard';

class PaymentOptions extends React.Component {

  _isMounted = false;
  state = {
    resultUrl: window.location.origin + '/payment-options',
    activeModal: '',
    show: false,
    showFormValue: false,
    showFormSuccess: false,
    showFormError: false,
    removingCard: {},
    paymentCards: []
  }

  componentDidMount() {
    this._isMounted = true;
    try {
      if (this.props.location.query.id) {
        this.props.onLoader('yes');
        const body = {
          checkoutId: this.props.location.query.id
        }
        paymentService.checkCardStatus(body)
          .then(res => {
            this.setState({
              activeModal: 'modalAddCard',
              show: true,
              showFormSuccess: true
            })
            setTimeout(() => {
              this.props.router.push('/payment-options');
              this.getPaymentCards();
            }, 1000);
          })
          .catch(error => {
            this.props.onLoader('no');
            this.setState({
              activeModal: 'modalAddCard',
              show: true,
              showFormError: true
            })
            setTimeout(() => {
              this.props.router.push('/payment-options');
              this.getPaymentCards();
              // toast error
              // toast.error('An error occurred while adding a payment card.', {
              //   position: toast.POSITION.TOP_RIGHT
              // });
            }, 1000)
            // redirect to login page
            if (error.response.status === 401) {
              this.props.router.push('/login');
            }
          })
      } else {
        this.getPaymentCards();
      }
    } catch (e) { console.error(e) }

    // focus page
    setFocusPage('#payment-options-page');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openModal = activeModal => {
    this.setState({
      activeModal,
      showFormValue: true,
      showFormSuccess: false,
      showFormError: false,
      show: true
    });
  }

  openDeleteModal = (activeModal, cardId) => {
    let removingCard = this.state.paymentCards.find(card => card.id === cardId);
    this.setState({ removingCard }, () => {
      this.openModal(activeModal);
    })
  }

  open = activeModal => {
    this.setState({
      activeModal,
      showFormValue: true,
      showFormSuccess: false,
      showFormError: false
    }, () => {
      this.props.onLoader('yes');
      paymentService.getPaymentFormData()
        .then(res => {
          let body = {
            id: "payment",
            src: "/assets/js/payment.js?n=" + (new Date).getTime()
          }
          window['labels'] = this.props.labels;
          loadScript(body, () => {
            body = {
              id: "form-payment",
              src: res.data['url']
            }
            loadScript(body, () => {
              if (this._isMounted) {
                this.setState({
                  show: true
                });
                setTimeout(() => {
                  // focus page
                  setFocusElement(`#add-new-card-title`)
                  this.props.onLoader('no');
                }, 1500)
              }
            });
          });
        })
        .catch(error => {
          console.log('error.response', error.response);
          this.props.onLoader('no');
          toast.error('An error occurred while adding a payment card.', {
            position: toast.POSITION.TOP_RIGHT
          });
          this.close();
          // redirect to login page
          if (error.response.status === 401) {
            this.props.router.push('/login');
          }
        })
    })

  }

  close = () => {
    const formPayment = document.getElementById('form-payment');
    const payment = document.getElementById('payment');
    if (formPayment) {
      formPayment.parentNode.removeChild(formPayment);
    }
    if (payment) {
      payment.parentNode.removeChild(payment);
    }
    this.setState({
      show: false,
      showFormValue: false,
      showFormError: false
    })
  }

  onChange = evt => {
    const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    this.setState({
      [evt.target.name]: value,
    });
  }

  getPaymentCards = () => {
    this.props.onLoader('yes');
    paymentService.getPaymentCardsList()
      .then(res => {
        if (this._isMounted) {
          const paymentCards = res.data.data || [];
          this.setState({ paymentCards });
        }
        this.props.onLoader('no');
      })
      .catch(error => {
        console.log('error.response', error.response);
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  setStep = step => {
    this.setState({
      showFormValue: false,
      showFormError: false,
      showFormSuccess: false,
      // isFocusTrap: false,
      [step]: true
    });
  }

  onPress = index => {
    if (this.props.loader === "yes") return;

    const { paymentCards } = this.state;
    const updatePaymentCards = update(paymentCards[index], { is_primary: { $set: paymentCards[index].is_primary == 0 ? 1 : 0 } });
    const newPaymentCards = update(paymentCards, {
      $splice: [[index, 1, updatePaymentCards]]
    });
    this.setState({ paymentCards: newPaymentCards }, () => {
      const body = {
        is_primary: (paymentCards[index]['is_primary'] === 0) ? 1 : 0
      }
      this.props.onLoader('yes');
      paymentService.updatePaymentCard(paymentCards[index]['id'], body)
        .then(res => {
          this.getPaymentCards()
        })
        .catch(error => {
          console.log('error.response', error.response);
          this.props.onLoader('no');
          // redirect to login page
          if (error.response.status === 401) {
            this.props.router.push('/login');
          }
        })
    })
  }

  removeCard = id => {
    this.props.onLoader('yes');
    paymentService.deletePaymentCard(id)
      .then(res => {
        this.getPaymentCards();
        this.close();
      })
      .catch(error => {
        this.getPaymentCards()
        // redirect to login page
        this.setStep('showFormError');
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  render() {
    const { resultUrl, activeModal, showFormValue, showFormSuccess, showFormError, show, paymentCards, removingCard } = this.state;
    const { labels, tts, loader } = this.props;

    return (
      <DocumentTitle title={labels['PAYMENT_OPTIONS_PAGE_TITLE'] + ' - IndyGo App'}>
        <div id="payment-options-page" aria-label={labels['PAYMENT_OPTIONS_PAGE_TITLE']} role={labels['PAYMENT_OPTIONS_PAGE_TITLE']}>

          <h1 className="is-size-3 is-size-2-tablet has-text-weight-bold pad-bottom-md pad-top-xl">
            {labels["OPTIONS_TITLE"]}
          </h1>

          <Columns>
            <Columns.Column size={10}>
              <Button
                id="btnAddPaymentCard"
                className="push-bottom-lg"
                size="small"
                color="info"
                style={{ minWidth: 210 }}
                aria-label={labels["BUTTON_ADD_NEW_CARD"]}
                onClick={() => this.open('modalAddCard')}
                loading={loader === "yes"}
              >
                {labels["BUTTON_ADD_NEW_CARD"]}
              </Button>
            </Columns.Column>
          </Columns>

          <Columns>
            {paymentCards.map((card, index) => (
              <Columns.Column
                tabIndex="0"
                aria-label={`${tts["OPTIONS_CARD_ENDING"]} ${card.last_4_digits}`}
                renderAs="section"
                size={10}
                key={index}
              >
                <Level className="level level--card-normal push-bottom-md">
                  <Level.Side align="left">
                    <Card
                      name={card.card_name}
                      label={card.card_label}
                      number={card.last_4_digits}
                      brand={card.card_brand}
                      date={card.expiration_date}
                    />
                  </Level.Side>
                  <Level.Side align="right" style={{ justifyContent: 'center' }}>
                    <div className="centered push-xs">
                      <Icon size="large" style={{ width: 30 }}>
                        <img
                          src="/assets/icons/card.svg"
                          alt=""
                          aria-hidden="true"
                        />
                      </Icon>
                      <Label
                        htmlFor="use-primary"
                        className="is-size-6 has-text-weight-normal push-left-md push-right-xl has-text-primary"
                        style={{ marginBottom: 0 }}
                      >
                        {labels['OPTIONS_PRIMARY']}
                      </Label>
                      <ActivateButton
                        labelEnabled={tts["OPTIONS_CARD_SWITCH_ON"]}
                        labelDisabled={tts["OPTIONS_CARD_SWITCH_OFF"]}
                        active={card.is_primary}
                        onPress={() => this.onPress(index)}
                      />
                    </div>
                    <Button
                      id={`btnDeletePaymentCard_${index}`}
                      size="medium"
                      className="push-xs has-text-weight-normal is-family-sans-serif"
                      style={{ border: 'none', textTransform: 'none', padding: 0, background: 'none', letterSpacing: 'normal', minWidth: "auto" }}
                      onClick={() => this.openDeleteModal('modalDeleteCard', card.id)}
                      aria-label={labels['OPTIONS_REMOVE']}
                    >
                      <Icon size="large" style={{ width: 30, margin: 0 }}>
                        <img
                          src="/assets/icons/trash_primary.svg"
                          alt=""
                          aria-hidden="true"
                        />
                      </Icon>
                      <span className="push-left-md has-text-primary is-size-6">{labels['OPTIONS_REMOVE']}</span>
                    </Button>
                  </Level.Side>
                </Level>
              </Columns.Column>
            ))}
          </Columns>

          <Modal showClose={false} show={show} onClose={this.close}>
            <Modal.Content>
              <Modal.Card>
                <Modal.Card.Head onClose={this.close}></Modal.Card.Head>
                <Modal.Card.Body className="centered">

                  {/* Modal Delete card */}
                  <ModalDeleteCard
                    activeModal={activeModal}
                    showFormValue={showFormValue}
                    showFormError={showFormError}
                    close={this.close}
                    onSubmit={this.removeCard}
                    card={removingCard || {}}
                  ></ModalDeleteCard>

                  {/* Modal Add Payment Card */}
                  <ModalTrapAddCard
                    activeModal={activeModal}
                    showFormValue={showFormValue}
                    showFormSuccess={showFormSuccess}
                    showFormError={showFormError}
                    resultUrl={resultUrl}
                    // isFocusTrap={isFocusTrap}
                    close={this.close}
                    setStep={this.setStep}
                    focusOfDeactivate={'#btnAddPaymentCard'}
                    {...this.props}
                  ></ModalTrapAddCard>

                  {/* <div className={`${activeModal === 'modalAddCard_' ? '' : 'is-sr-only'}`}>
                    <div className="is-transition-all" style={showFormValue ? {} : { display: 'none' }}>
                      <p aria-label={labels['BUTTON_ADD_NEW_CARD']} id="add-new-card-title" className="is-size-4 has-text-primary has-text-weight-bold push-bottom-xs">
                        {labels['BUTTON_ADD_NEW_CARD']}
                      </p>
                      <p className="has-text-grey-dark">{labels['CITATIONS_MODAL_PAYMENT_TEXT']}</p>
                      <form action={`${resultUrl}`} className="paymentWidgets" data-brands="VISA MASTER AMEX">
                        {labels['OPTIONS_MODAL_LOADING']}
                      </form>
                      <span className="is-size-8 has-text-grey-dark ">{labels["OPTIONS_MODAL_POLICY"]}<a target="_blank" href="https://www.indygo.net/mykey-privacy-policy/" className="has-text-info">{labels["GDPR_CHECKBOX_PRIVACY"]}</a></span>
                    </div>
                    <div className="push-bottom-xxl has-text-centered" style={showFormError ? {} : { display: 'none' }}>
                      <FontAwesomeIcon icon={faExclamationCircle} size="4x" className="has-text-danger" />
                      <h2 className="is-size-3 has-text-danger has-text-weight-bold push-bottom-sm push-top-sm">
                        {labels['MODAL_TITLE_ERROR']}
                      </h2>
                      <p className="has-text-grey-dark push-bottom-md">{labels['OPTIONS_MODAL_ERROR_TEXT_1']}</p>
                      <p className="has-text-grey-dark push-bottom-md">{labels['OPTIONS_MODAL_ERROR_TEXT_2']}</p>
                      <p className="has-text-grey-dark">{labels['OPTIONS_MODAL_ERROR_TEXT_3']}</p>
                      <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                        <Button
                          size="small"
                          color="info"
                          style={{ minWidth: 190, marginRight: 5 }}
                          onClick={() => this.setStep('showFormValue')}
                          title={labels["BUTTON_TRY_AGAIN"]}
                        >
                          {labels['BUTTON_TRY_AGAIN']}
                        </Button>
                        <Button
                          outlined={true}
                          size="small"
                          color="info"
                          style={{ minWidth: 190 }}
                          title={labels["BUTTON_CANCEL"]}
                          onClick={this.close}
                        >
                          {labels['BUTTON_CANCEL']}
                        </Button>
                      </Button.Group>
                    </div>
                  </div> */}

                </Modal.Card.Body>
              </Modal.Card>
            </Modal.Content>
          </Modal>

          <ToastContainer autoClose={5000} newestOnTop closeOnClick />

        </div>
      </DocumentTitle>
    )
  }
}

export default connect(
  state => ({
    loader: state.loader,
    labels: state.localization.labels,
    tts: state.localization.tts
  }),
  dispatch => ({
    onLoader: (status) => {
      dispatch({ type: 'SET_STATUS', payload: status })
    }
  })
)(PaymentOptions)