import React from "react";
import { connect } from "react-redux";
import { Button } from "../lib/components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

class ClearStorage extends React.Component {

    state = {
        isClearStorage: true
    }

    componentDidMount() {
        setTimeout(() => {
            localStorage.clear();
            this.props.onClearArtifacts();
            this.props.onClearLocalization();
            this.setState({
                isClearStorage: false
            })
        }, 1000)
    }

    render() {
        const { isClearStorage } = this.state
        return (
            <div
                className="centered pad-lg has-text-centered"
                style={{ flexDirection: "column", width: "100vw", height: "100vh" }}
            >
                {isClearStorage && (<div>
                    <FontAwesomeIcon icon={faExclamationCircle} size="4x" className="has-text-danger" />
                    <h1 className="is-size-3 has-text-danger has-text-weight-bold push-bottom-md push-top-sm">
                        Cleaning up storage
                    </h1>
                </div>)}
                {!isClearStorage && (<div>
                    <FontAwesomeIcon icon={faExclamationCircle} size="4x" className="has-text-success" />
                    <h1 className="is-size-3 has-text-success has-text-weight-bold push-bottom-md push-top-sm">
                        Storage cleared
                    </h1>
                    <p>Please click button for load app with clean storage</p>
                    <Button
                        size="small"
                        color="info"
                        className="push-top-lg"
                        onClick={() => window.location.href = '/login'}
                    >
                        Go to login page
                    </Button>
                </div>)}

            </div>
        )
    }

}

export default connect(
    state => ({}),
    dispatch => ({
        onClearArtifacts: artifacts => {
            dispatch({ type: "DELETE_ARTIFACTS" })
        },
        onClearLocalization: artifacts => {
            dispatch({ type: "DELETE_LOCALIZATION" })
        }
    })
)(ClearStorage)