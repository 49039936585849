const initialState = {};

export default function artifacts(state = initialState, action){
  if (action.type === 'ADD_ARTIFACTS'){
    return action.payload
  }
  else if (action.type === 'UPDATE_ARTIFACTS') {
    return {
      ...state,
      ...action.payload
    }
  }
  else if (action.type === 'DELETE_ARTIFACTS'){
    return {}
  }
  return state;
}