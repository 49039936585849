const dev = {
  API: {
    URL: "http://localhost:8001",
    passwordValidation: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})"
  }
};

const prod = {
  API: {
    URL: "",
    passwordValidation: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})"
  }
};

const config = process.env.NODE_ENV === 'production'
  ? prod
  : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};