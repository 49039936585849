import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from 'react-transition-group';

class Navigation extends React.Component {

  _menuRef = React.createRef();
  _menuButtonRef = React.createRef();
  state = {
    isOpen: false
  }

  componentDidMount() {
    this._menuRef.current.addEventListener("keydown", this.keyPressHandler);
  }

  componentWillUnmount() {
    this._menuRef.current.removeEventListener("keydown", this.keyPressHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.state;

    if (prevState.isOpen !== isOpen) {
      if (isOpen) {
        document.addEventListener("click", this.closeMenu);
      } else {
        document.removeEventListener("click", this.closeMenu);
      }
    }
  }

  keyPressHandler = event => {
    event.stopPropagation();
    const { code } = event;

    const menuElementDOM = this._menuRef.current.querySelectorAll("[role='menuitem']");
    const menuElements = Array.from(menuElementDOM).map(item => {
      item.tabIndex = (item === document.activeElement) ? "0" : "-1";
      return {
        node: item,
        active: item === document.activeElement
      }
    });
    const activeElementIndex = menuElements.findIndex(item => item.active);

    // Helper function for work with tabIndex and focus active element
    const focusElement = index => {
      if (menuElements[index]) {
        menuElements[index].node.focus();
        menuElements[index].node.tabIndex = "0";
      }
    }

    switch (code) {
      case "Tab":
        this.closeMenu();
        break;
      case "Escape":
        this._menuButtonRef.current.focus();
        this.closeMenu();
        break;
      case "Home":
        event.preventDefault();
        menuElements[0].node.focus();
        break;
      case "End":
        event.preventDefault();
        focusElement(menuElements.length - 1);
        break;
      case "ArrowUp":
        event.preventDefault();
        if (activeElementIndex !== 0) {
          focusElement(activeElementIndex - 1);
        } else {
          focusElement(menuElements.length - 1);
        }
        break;
      case "ArrowDown":
        event.preventDefault();
        if (activeElementIndex !== menuElements.length - 1) {
          focusElement(activeElementIndex + 1);
        } else {
          focusElement(0);
        }
        break;
      default: break;
    }
  }

  closeMenu = e => {
    if (!e || e.target.closest(".navigation-link") || e.target.closest(".navbar-item")) {
      this.setState({ isOpen: false });
    }
  }

  toggleMenu = () => {
    this.setState(state => ({
      isOpen: !state.isOpen
    }), () => {
      if (this.state.isOpen) {
        const { current } = this._menuRef;
        const activeElement = current.querySelector(".is-active") || current.querySelector("[role='menuitem']");
        if (activeElement) activeElement.focus();
      }
    });
  }

  renderChildren = () => {
    const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];

    // Find element with "is-active" class
    let activeElement = null;
    children.forEach(element => {
      if (element) {
        const isActiveElement = element.props.children.find(item => item.props.className.includes("is-active"));
        if (isActiveElement) {
          activeElement = isActiveElement
        }
      }
    })

    // Clone elements and set tabIndex
    const newChildren = children.map((component, index) => {
      if (!component) return false;

      const newChildren = component.props.children.map((component, i) => {
        return (
          <React.Fragment key={i}>
            {React.cloneElement(component, {
              tabIndex: (activeElement && activeElement === component) || (!activeElement && index === 0 && i === 0) ? "0" : "-1"
            })}
          </React.Fragment>
        )
      })

      return (
        <React.Fragment key={index}>
          {React.cloneElement(component, { }, newChildren)}
        </React.Fragment>
      )
    });
    return newChildren;	
  }

  render() {
    const { isOpen } = this.state;
    const { labels, tts, className } = this.props;

    return (
      <div className={`navigation-app ${className}`}>
        <div className="navigation-content">
          <div className="navigation-content-header">
            <Link
              to="/welcome"
              className="navbar-item"
              title={labels["NAVIGATION_LOGO_TITLE"]}
            >
              <img src="/assets/img/brand/logo.svg" alt={labels["NAVIGATION_LOGO_ALT"]} />
            </Link>
            <button
              ref={this._menuButtonRef}
              type="button"
              className="menu-toggle-button"
              onClick={this.toggleMenu}
              aria-label={tts["NAVIGATION_TOGGLE_MENU"]}
              aria-controls="mainMenu"
              aria-expanded={isOpen}
            >
              <FontAwesomeIcon icon={!isOpen ? faBars : faTimes} />
            </button>
          </div>
          <CSSTransition in={isOpen} timeout={500} classNames="navigation">
            <div
              ref={this._menuRef}
              id="mainMenu"
              role="menu"
              aria-label={tts["NAVIGATION_MENU"]}
              className="navigation-contentlist"
            >
              {this.renderChildren()}
            </div>
          </CSSTransition>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    labels: state.localization.labels,
    tts: state.localization.tts
  }),
  dispatch => ({ })
)(Navigation)
