import React from 'react';
import DocumentTitle from 'react-document-title';
import { Columns } from '../../components/lib/components/columns';
import { Level } from '../../components/lib/components/level';
import ActivateButton from "../../components/toggler";
import { connect } from 'react-redux';
import { userService } from '../../_services';
import { setFocusPage } from '../../_helpers';

class Notifications extends React.Component {

  state = { 
    show: false,
    activateEmail: false,
    activateText: false,
    activateReceiptEmail: false
  }

  componentDidMount() {
    this.getNotifiAll();

    setFocusPage("#notifications-page");
  }

  getNotifiAll = () => {
    this.props.onLoader('yes');
    userService.getNotifiAll()
      .then(res => {
        try {
          const { authorize_contact_by_email, authorize_contact_by_text, send_credit_card_receipt_by_email } = res.data.data;
          this.setState({
            activateEmail: authorize_contact_by_email === "1" ? true: false,
            activateText: authorize_contact_by_text === "1" ? true: false,
            activateReceiptEmail: send_credit_card_receipt_by_email === "1" ? true: false
          })
        } catch (e) { console.error(e) }
        this.props.onLoader('no');
        this.setState({ show: true })
      })
      .catch(error => {
        console.log('error.response', error.response)
        this.props.onLoader('no')
        this.setState({ show: true })
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  onPressEmail = () => {
    const model = {
      authorize_contact_by_email: !this.state.activateEmail ? 1 : 0
    }
    this.setState({ activateEmail: !this.state.activateEmail  })
    this.props.onLoader('yes');
    userService.setNotifiEmail(model)
      .then(res => {
        try {
          const activateEmail = res.data.data['authorize_contact_by_email'] === "1" ? true: false;
          this.setState({ activateEmail })
        } catch (e) { console.error(e) }
        this.props.onLoader('no');
      })
      .catch(error => {
        console.log('error.response', error.response)
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  onPressText = () => {
    const model = {
      authorize_contact_by_text: !this.state.activateText ? 1 : 0
    }
    this.setState({ activateText: !this.state.activateText  })
    this.props.onLoader('yes');
    userService.setNotifiText(model)
      .then(res => {
        try {
          const activateText = res.data.data['authorize_contact_by_text'] === "1" ? true: false;
          this.setState({ activateText })
        } catch (e) { console.error(e) }
        this.props.onLoader('no');
      })
      .catch(error => {
        console.log('error.response', error.response)
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  onPressReceiptEmail = () => {
    const model = {
      send_credit_card_receipt_by_email: !this.state.activateReceiptEmail ? 1 : 0
    }
    this.setState({ activateReceiptEmail: !this.state.activateReceiptEmail  })
    this.props.onLoader('yes');
    userService.setNotifiReceiptsEmail(model)
      .then(res => {
        try {
          const activateReceiptEmail = res.data.data['send_credit_card_receipt_by_email'] === "1" ? true: false;
          this.setState({ activateReceiptEmail })
        } catch (e) { console.error(e) }
        this.props.onLoader('no');
      })
      .catch(error => {
        console.log('error.response', error.response)
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  render() {
    const { show, activateEmail, activateText, activateReceiptEmail } = this.state;
    const { labels, tts } = this.props;

    return (
      <DocumentTitle title={labels['NOTIFICATIONS_PAGE_TITLE'] + ' - IndyGo App'}>
        <div id="notifications-page" aria-label={labels['NOTIFICATIONS_PAGE_TITLE']} role={labels['NOTIFICATIONS_PAGE_TITLE']}>
                
          <h1 className="is-size-3 is-size-2-tablet has-text-weight-bold pad-bottom-md pad-top-xl">
            {labels['NOTIFICATIONS_TITLE']}
          </h1>

          {show && (
            <Columns>
              <Columns.Column mobile={{ size: 12 }} tablet={{ size: 7 }} widescreen={{ size: 4 }}>
                
                <Level className="is-mobile push-top-xl">
                  <Level.Side align="left" className="notifi-title" style={{ flexShrink: 'inherit' }}>
                    <Level.Item style={{ flexShrink: 'inherit' }}>
                      <div className="is-size-6 pad-right-md">{labels['NOTIFICATIONS_EMAIL']}</div>
                    </Level.Item>
                  </Level.Side>
                  <Level.Side>
                    <Level.Item className="level-item">
                      <ActivateButton
                        labelEnabled={tts["NOTIFICATIONS_EMAIL_SWITCH_ON"]}
                        labelDisabled={tts["NOTIFICATIONS_EMAIL_SWITCH_OFF"]}
                        active={activateEmail} 
                        onPress={this.onPressEmail}
                      />
                    </Level.Item>
                  </Level.Side>
                </Level>

                <Level className="is-mobile push-top-xl">
                  <Level.Side align="left" className="notifi-title" style={{ flexShrink: 'inherit' }}>
                    <Level.Item style={{ flexShrink: 'inherit' }}>
                      <div className="is-size-6 pad-right-md">{labels['NOTIFICATIONS_TEXT']}</div>
                    </Level.Item>
                  </Level.Side>
                  <Level.Side>
                    <Level.Item className="level-item">
                      <ActivateButton
                        labelEnabled={tts["NOTIFICATIONS_TEXT_SWITCH_ON"]}
                        labelDisabled={tts["NOTIFICATIONS_TEXT_SWITCH_OFF"]}
                        active={activateText} 
                        onPress={this.onPressText}
                      />
                    </Level.Item>
                  </Level.Side>
                </Level>

                <Level className="is-mobile push-top-xl">
                  <Level.Side align="left" className="notifi-title"  style={{ flexShrink: 'inherit' }}>
                    <Level.Item style={{ flexShrink: 'inherit' }}>
                      <div className="is-size-6 pad-right-md">{labels['NOTIFICATIONS_EMAIL_RECEIPTS']}</div>
                    </Level.Item>
                  </Level.Side>
                  <Level.Side>
                    <Level.Item className="level-item">
                      <ActivateButton
                        labelEnabled={tts["NOTIFICATIONS_EMAIL_RECEIPTS_SWITCH_ON"]}
                        labelDisabled={tts["NOTIFICATIONS_EMAIL_RECEIPTS_SWITCH_OFF"]}
                        active={activateReceiptEmail} 
                        onPress={this.onPressReceiptEmail}
                      />
                    </Level.Item>
                  </Level.Side>
                </Level>

              </Columns.Column>
            </Columns>
          )}

        </div>
      </DocumentTitle>
    )
  }
}
  
export default connect(
  state => ({
    loader: state.loader,
    labels: state.localization.labels,
    tts: state.localization.tts
  }),
  dispatch => ({
    onLoader: payload => {
      dispatch({ type: 'SET_STATUS', payload })
    }
  })
)(Notifications)