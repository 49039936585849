import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import {Provider} from "react-redux";
import IndyGo from "./IndyGo";
import { store } from "./store";

import "./index.css";

ReactDOM.render((
  <Provider store={store}>
    <IndyGo />
  </Provider>
), document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
