import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`

const Icon = styled.svg`
  fill: none;
  stroke: #62b292;
  stroke-width: 2px;
`
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  position: absolute;
  white-space: nowrap;  
  width: 20px !important;
  height: 20px !important;
  &:focus + div {
    box-shadow: 0 0 0 1px #353535;
  }
`

const StyledCheckbox = styled.div`
  border: 1px solid #EEEFEF;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 2px;
  transition: all 150ms;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`

const Checkbox = ({ className, checked, style, ...props }) => (
  <CheckboxContainer style={style} className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default Checkbox
