import React, { useState } from 'react'
import { connect } from "react-redux";
import { Button } from '../components/lib/components/button';
import { Icon } from '../components/lib/components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons'
import {
    Field,
    Control,
    Label,
    Input
} from '../components/lib/components/form';
import config from "../../config";
import ModalView from '../components/lib/components/view/ModalView';
import { setFocusElement } from '../_helpers';

const passwordRegex = new RegExp(config.API.passwordValidation);

const ModalDeleteMyAccount = ({
    activeModal,
    showFormValue,
    showFormSuccess,
    showFormError,
    labels,
    tts,
    onChange,
    close,
    setStep,
    password,
    onDeleteAccount,
    router,
    loader
}) => {
    const [isPassIcon, setIsPassIcon] = useState(false)
    const isEnabled = passwordRegex.test(password);

    const switchPassIcon = (isPassIcon) => {
        setIsPassIcon(isPassIcon);
        setFocusElement(`#password`);
    }

    const logout = () => {
        close();
        setTimeout(() => router.push("/login"));
    }

    return (
        <>
            {activeModal === "modalDeleteMyAccount" && showFormValue && <ModalView
                name="modalDeleteMyAccount"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                icon="danger"
                head={labels['PROFILE_DELETE_MY_ACCOUNT']}
                messages={[labels["PROFILE_DELETE_MY_ACCOUNT_DESC_1"]]}
                focusOptions={{
                    // initialFocus: `#password`,
                    // setReturnFocus: `#btnDeleteMyAccount`,
                    onActivate: () => {
                        setFocusElement(`#password`);
                    },
                    onDeactivate: () => {
                        //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
                        setFocusElement(`#btnDeleteMyAccount`)
                    }
                }}
            >
                <form className="has-text-left pad-top-md pad-bottom-md" onSubmit={onDeleteAccount}>
                    <Field>
                        <Label htmlFor="password" className={password ? "has-text-grey-light" : ""}>
                            {labels["LOGIN_PASSWORD"]}
                        </Label>
                        <Control className="has-icons-right-second" iconRight={true}>
                            <Input
                                id="password"
                                onChange={onChange}
                                style={{ backgroundColor: '#F7F7F7', boxShadow: 'none', border: 0 }}
                                value={password}
                                name="password"
                                type={isPassIcon ? "text" : "password"}
                                placeholder={labels["LOGIN_PASSWORD_PLACEHOLDER"]}
                            />
                            <Icon
                                role="button"
                                tabIndex="0"
                                align="right"
                                onClick={() => switchPassIcon(!isPassIcon)}
                                onKeyPress={() => switchPassIcon(!isPassIcon)}
                                aria-label={isPassIcon ? tts["HIDE_PASSWORD"] : tts["SHOW_PASSWORD"]}
                            >
                                <FontAwesomeIcon icon={isPassIcon ? faEye : faEyeSlash} className="has-text-black" />
                            </Icon>
                        </Control>
                        <p className="has-text-grey-dark push-top-md">{labels['PROFILE_DELETE_MY_ACCOUNT_DESC_2']}</p>
                    </Field>
                    <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                        <Button
                            size="small"
                            color="info"
                            style={{ minWidth: 190 }}
                            disabled={!isEnabled || loader === 'yes'}
                            loading={loader === 'yes'}

                        >
                            {labels["BUTTON_DELETE"]}
                        </Button>
                        <Button
                            outlined={true}
                            size="small"
                            color="info"
                            style={{ minWidth: 190 }}
                            onClick={close}
                        >
                            {labels["BUTTON_CANCEL"]}
                        </Button>
                    </Button.Group>
                </form>
            </ModalView>}
            {activeModal === "modalDeleteMyAccount" && showFormSuccess && <ModalView
                name="modalDeleteMyAccount"
                activeModal={activeModal}
                active={showFormSuccess}
                icon="success"
                viewType="ShowFormSuccess"
                head={labels["MODAL_TITLE_CONFIRMATION"]}
                messages={[labels["PROFILE_DELETE_MY_ACCOUNT_SUCCESS"]]}
                containerLabel={labels["MODAL_TITLE_CONFIRMATION"]}
            >
                <Button
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={logout}
                >
                    {labels["BUTTON_GOODBYE"]}
                </Button>
            </ModalView>}
            {activeModal === "modalDeleteMyAccount" && showFormError && <ModalView
                name="modalDeleteMyAccount"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels['PROFILE_DELETE_ACCCOUNT_ERROR']}
                messages={[labels["PROFILE_DELETE_MY_ACCOUNT_ERROR"]]}
                focusOptions={{
                    initialFocus: `#${activeModal}ShowFormError_part`,
                    onActivate: () => {
                        // setFocusElement(`#${activeModal}ShowFormError_button_first`);
                    },
                    onDeactivate: () => {
                        //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
                        setFocusElement(`#btnDeleteMyAccount`)
                    }
                }}
            >
                <Button
                    id={`${activeModal}ShowFormError_button_first`}
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={() => setStep('showFormValue')}
                >
                    {labels["BUTTON_TRY_AGAIN"]}
                </Button>
            </ModalView>}
        </>
    )
}

export default connect(
    state => ({
        labels: state.localization.labels,
        tts: state.localization.tts,
        loader: state.loader
      }),
      dispatch => ({})
)(ModalDeleteMyAccount)