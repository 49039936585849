import React, { useRef } from 'react'
import { connect } from "react-redux";
import { Button } from '../../components/lib/components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import {
    Field,
    Control,
    Label,
    Input,
    Textarea
} from '../../components/lib/components/form';
import { Columns } from '../../components/lib/components/columns';
import ModalView from '../../components/lib/components/view/ModalView';
import Files from 'react-files'
import { setFocusElement } from '../../_helpers';

const ModalAddNewMessage = ({
    activeModal,
    showFormValue,
    showFormSuccess,
    showFormError,
    labels,
    onChange,
    close,
    setStep,
    onSubmit,
    files,
    topic,
    size,
    onFilesChange,
    onFilesError,
    message,
    loader
}) => {
    const inputRef = useRef(null);

    const filesRemoveOne = (file) => {
        inputRef.current.removeFile(file)
    }
    const isEnabled = topic.length > 0 && message.length > 0;
    return (
        <>
            <ModalView
                name="modalAddNewMessage"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                align="left"
                head={labels['MESSAGES_MODAL_TITLE']}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#topic`);
                    }
                }}
            >
                <form className="pad-top-md pad-bottom-md" onSubmit={onSubmit}>
                    <Columns>
                        <Columns.Column size={12}>
                            <Field>
                                <Label className="is-sr-only">{labels['MESSAGES_MODAL_TOPIC']}</Label>
                                <Control>
                                    <Input id="topic" onChange={onChange} style={{ backgroundColor: '#F7F7F7', boxShadow: 'none', border: 0 }} value={topic} name="topic" type="text" placeholder={labels['MESSAGES_MODAL_TOPIC']} />

                                </Control>
                            </Field>
                        </Columns.Column>
                        <Columns.Column size={12}>
                            <Field >
                                <Label className="is-sr-only">Attachment</Label>
                                <Control iconRight={true}>
                                    <div className="box-files">
                                        {files.length > 0
                                            ?
                                            <div className='files-list'>
                                                <ul>{files.map((file) =>
                                                    <li className='files-list-item' key={file.id}>
                                                        <div className='files-list-item-preview'>
                                                            {file.preview.type === 'image'
                                                                ? <img className='files-list-item-preview-image' src={file.preview.url} />
                                                                : <div className='files-list-item-preview-extension'>{file.extension}</div>}
                                                        </div>

                                                        <div
                                                            aria-label="Remove the attached file"
                                                            tabIndex="0"
                                                            id={file.id}
                                                            className='delete files-list-item-remove'
                                                            onClick={() => filesRemoveOne(file)}
                                                            onKeyPress={() => filesRemoveOne(file)}
                                                        >
                                                        </div>
                                                    </li>
                                                )}</ul>
                                            </div>
                                            : <p className="text-file-empty">{labels['MESSAGES_MODAL_ATTACH']}</p>
                                        }
                                        <p style={files.length > 0 ? {} : { display: 'none' }}>{files.length} attachments, {size / 1000}kBts</p>
                                        <Files
                                            // ref='files'
                                            ref={inputRef}
                                            className='files-dropzone-list'
                                            style={{ height: '21px', width: '21px' }}
                                            onChange={onFilesChange}
                                            onError={onFilesError}
                                            multiple={false}
                                            maxFiles={3}
                                            maxFileSize={10000000}
                                            minFileSize={0}
                                            clickable
                                        >
                                            <FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: '1.5em' }} className="has-text-black" />
                                        </Files>
                                    </div>
                                </Control>
                            </Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Field>
                                <Label className="is-sr-only">{labels['MESSAGES_MODAL_MESSAGE']}</Label>
                                <Control>
                                    <Textarea value={message} name="message" onChange={onChange} style={{ backgroundColor: '#F7F7F7', boxShadow: 'none', border: 0, height: '280px' }} placeholder={labels['MESSAGES_MODAL_MESSAGE']} />
                                </Control>
                            </Field>
                        </Columns.Column>
                    </Columns>
                    <Button.Group className="pad-top-lg" style={{ justifyContent: 'space-between', marginBottom: 0 }}>
                        <Button
                            disabled={!isEnabled || loader === 'yes'}
                            size="small"
                            color="info"
                            style={{ minWidth: 190, marginRight: 5 }}
                            title={labels["BUTTON_SEND"]}
                            loading={loader === 'yes'}
                        >
                            {labels["BUTTON_SEND"]}
                        </Button>
                        <Button
                            outlined={true}
                            size="small"
                            color="info"
                            style={{ minWidth: 190 }}
                            onClick={close}
                            disabled={loader === 'yes'}
                            title={labels["BUTTON_CANCEL"]}
                        >
                            {labels["BUTTON_CANCEL"]}
                        </Button>
                    </Button.Group>
                </form>
            </ModalView>
            <ModalView
                name="modalAddNewMessage"
                activeModal={activeModal}
                active={showFormSuccess}
                icon="success"
                viewType="ShowFormSuccess"
                head={labels["MODAL_TITLE_SUCCESS"]}
                messages={[labels["MESSAGES_MODAL_SUCCESS_TEXT_1"], labels["MESSAGES_MODAL_SUCCESS_TEXT_2"]]}
                focusOptions={{
                    initialFocus: `#${activeModal}ShowFormSuccess_part`
                }}
            >
                <Button
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={close}
                    title={labels["BUTTON_CONTINUE"]}
                >
                    {labels["BUTTON_CONTINUE"]}
                </Button>
            </ModalView>
            <ModalView
                name="modalAddNewMessage"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels["MODAL_TITLE_ERROR"]}
                messages={[labels["MESSAGES_MODAL_ERROR_TEXT_1"], labels["MESSAGES_MODAL_ERROR_TEXT_2"]]}
                focusOptions={{
                    initialFocus: `#${activeModal}ShowFormError_part`
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={() => setStep('showFormValue')}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels["BUTTON_TRY_AGAIN"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels
      }),
      dispatch => ({})
)(ModalAddNewMessage)