import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import modifiers from '../../modifiers';
import Element from '../element';
import {Link} from "react-router";

const Breadcrumb = ({
  className,
  items,
  separatorIcon,
  renderAs,
  hrefAttr,
  separator,
  size,
  align,
  children,
  ...props
}) => (
  <Element
    renderAs="nav"
    {...props}
    className={classnames('breadcrumb', className, {
      [`has-${separator}-separator`]: separator,
      [`is-${size}`]: size,
      [`is-${align}`]: align,
    })}
  >
    <ul>
      {
        items.map((item, k) => {
          const p = {
            renderAs,
            //[hrefAttr]: item.url,
          };
          return (
            <li
              key={item.url}
              className={classnames({
                'is-active': item.active,
              })}
            >
              {children}
              {(k > 0) ? separatorIcon : ''}
              <Link 
              title={item.name + ' link'}
              to={item.url}>
                  {item.name}
              {/*<Element {...p}>*/}
                {/*{item.name}*/}
                {/**/}
              {/*</Element>*/}
              </Link>
            </li>
          );
        })
      }
    </ul>
  </Element>
);
const CardContent = ({
  className,
  children,
  ...props
}) => (
  <div className="sss">!!!{children}</div>
);
Breadcrumb.separatorr = CardContent;

Breadcrumb.propTypes = {
  ...modifiers.propTypes,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  separatorIcon: PropTypes.object,
  separator: PropTypes.oneOf(['arrow', 'bullet', 'dot', 'succeeds']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  align: PropTypes.oneOf(['right', 'center']),
  items: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    active: PropTypes.bool,
    name: PropTypes.node,
  })),
  renderAs: PropTypes.oneOfType([
    PropTypes.oneOf(['a']),
    PropTypes.func,
  ]),
  hrefAttr: PropTypes.string,
};

Breadcrumb.defaultProps = {
  ...modifiers.defaultProps,
  items: [],
  separatorIcon: {},
  hrefAttr: 'href',
  separator: undefined,
  renderAs: 'a',
  className: undefined,
  style: undefined,
  size: undefined,
  align: undefined,
};

export default Breadcrumb;
