import React from 'react'
import { connect } from "react-redux";
import { Button } from '../../components/lib/components/button';
import { Icon } from '../../components/lib/components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Field, Control, Label } from '../../components/lib/components/form';
import { Columns } from '../../components/lib/components/columns';
import ModalView from '../../components/lib/components/view/ModalView';
import ReactPhoneInput from 'react-phone-input-2';
import NumberFormat from 'react-number-format';
import { setFocusElement } from '../../_helpers';

const ModalUpdatePhoneNumber = ({
    activeModal,
    showFormValue,
    showFormSuccess,
    showFormError,
    labels,
    onSubmit,
    onChange,
    setStep,
    close,
    loader,
    originalData,
    errors,
    newPhonenumber,
    handleOnChange,
    onContinue,
}) => {
    const isEnabled = newPhonenumber.length > 9;
    return (
        <>
            <ModalView
                name="modalUpdatePhoneNumber"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                align="left"
                head={labels['PROFILE_ADD_PHONE']}
                className="modalShow"
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#newPhonenumber`);
                    }
                }}
            >
                <p className="has-text-grey-dark push-bottom-lg"
                    dangerouslySetInnerHTML={{ __html: originalData.phonenumber == "" ? labels["PROFILE_ADD_PHONE_DESC"] : labels["PROFILE_ADD_PHONE_DESC_EXIST"] }}></p>
                <form className="pad-top-md pad-bottom-md" onSubmit={onSubmit}>
                    <Columns>
                        <Columns.Column size={4}>
                            <Field>
                                <Label>{labels['PROFILE_COUNTRY_CODE_TEXT']}</Label>
                                <Control>
                                    <ReactPhoneInput
                                        disableAreaCodes={true}
                                        defaultCountry={'us'}
                                        onChange={handleOnChange}
                                    />
                                </Control>
                            </Field>
                        </Columns.Column>
                        <Columns.Column size={8}>
                            <Field>
                                <Label className={`${!errors['newPhoneNumber'] ? "" : "has-text-danger"}`}>{labels['PROFILE_PHONE_TEXT']}</Label>
                                <Control iconRight={true}>
                                    <NumberFormat id="newPhonenumber" format="(###) ### ####" className={`input ${!errors['newPhoneNumber'] ? "" : "is-danger"}`} onChange={onChange} style={{ backgroundColor: '#F7F7F7', boxShadow: 'none', border: 0 }} value={newPhonenumber} name="newPhonenumber" type="text" placeholder="(xxx) xxx xxxx" />
                                    <p className="help is-danger has-text-right" style={!errors['newPhoneNumber'] ? { display: 'none' } : {}}>{errors['newPhoneNumber']}</p>
                                    <Icon style={!errors['newPhoneNumber'] ? { display: 'none' } : {}} align="right">
                                        <FontAwesomeIcon style={{ fontSize: '19px' }} icon={faExclamationCircle} className="has-text-danger" />
                                    </Icon>
                                </Control>
                            </Field>
                        </Columns.Column>
                    </Columns>
                    <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                        <Button disabled={!isEnabled || loader === 'yes'}
                            loading={loader === 'yes'}
                            size="small"
                            color="info"
                            title={labels["BUTTON_SAVE"]}
                            style={{ minWidth: 190, marginRight: 5 }}>
                            {labels["BUTTON_SAVE"]}
                        </Button>
                        <Button
                            disabled={loader === 'yes'}
                            outlined={true}
                            size="small"
                            color="info"
                            style={{ minWidth: 190 }}
                            onClick={close}
                            title={labels["BUTTON_CANCEL"]}
                        >
                            {labels["BUTTON_CANCEL"]}
                        </Button>
                    </Button.Group>
                </form>
            </ModalView>
            <ModalView
                name="modalUpdatePhoneNumber"
                activeModal={activeModal}
                active={showFormSuccess}
                icon="success"
                viewType="ShowFormSuccess"
                head={labels['PROFILE_PHONE_TEXT']}
                messages={[labels["PROFILE_PHONE_VERIFIED_SUCCESS"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormSuccess_part`);
                    }
                }}
            >
                <Button
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={onContinue}
                    title={labels["BUTTON_CONTINUE"]}
                >
                    {labels["BUTTON_CONTINUE"]}
                </Button>
            </ModalView>
            <ModalView
                name="modalUpdatePhoneNumber"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels['FARE_CARD_SEND_EMAIL_ERROR_TITLE']}
                messages={[labels["FARE_CARD_SEND_EMAIL_ERROR_TEXT_1"], labels["FARE_CARD_SEND_EMAIL_ERROR_TEXT_2"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormError_part`);
                    }
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={() => setStep('showFormValue')}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels["BUTTON_TRY_AGAIN"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels
    }),
    dispatch => ({})
)(ModalUpdatePhoneNumber)