
export default {
  SIZES: {
    THREEQUARTERS: 'three-quarters',
    TWOTHIRDS: 'two-thirds',
    HALF: 'half',
    ONETHIRD: 'one-third',
    ONEQUARTER: 'one-quarter',
    ONEFIFTH: 'one-fifth',
    TWOFIFTHS: 'two-fifths',
    THREEFIFTHS: 'three-fifths',
    FOURFIFTHS: 'four-fifths',
  },
};
