import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/pro-light-svg-icons'

class Pagination extends React.Component {

  static propTypes = {
    items: PropTypes.array.isRequired,
    onSetPage: PropTypes.func.isRequired,
    pageSize: PropTypes.number,
    totalItems: PropTypes.number,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    location: PropTypes.object,
    labels: PropTypes.object
  }

  static defaultProps = {
    items: [],
    pageSize: 1,
    totalItems: 1,
    totalPages: 1,
    currentPage: 1,
    location: {},
    labels: {}
  }

  render() {
    const { labels, tts, currentPage, location, totalPages, totalItems, pageSize, onSetPage } = this.props

    let pages = [...Array(totalPages).keys()].map(i => i + 1);

    if (!pages || pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }

    return (
      <nav className="pagination is-centered is-medium" role="navigation" aria-label={tts["PAGINATION_ARIA_MAIN"]}>
        <Link to={location.pathname}
          className={`${currentPage === 1 ? 'disabled' : ''} pagination-previous`}
          onClick={() => { onSetPage(1) }}
          title={labels['PAGINATION_FIRST_PAGE']}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: '1em' }} />
        </Link>
        <Link to={location.pathname}
          className={`${currentPage === totalPages ? 'disabled' : ''} pagination-next`}
          onClick={() => { onSetPage(totalPages) }}
          title={labels['PAGINATION_LAST_PAGE']}
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: '1em' }} />
        </Link>
        <ul className="pagination-list">
          {currentPage === totalPages && totalItems > pageSize * 2 && <li >
            <Link to={location.pathname}
              className={`pagination-link`}
              onClick={() => { onSetPage(totalPages - 2) }}
              title={labels['PAGINATION_PREVIOUS_PAGE']}
            >
              ..
              </Link>
          </li>}
          {pages.map((page, index) =>
            ((currentPage - 1 === page) || (currentPage + 1 === page) || (currentPage === page)) && <li key={index}>
              <Link to={location.pathname}
                className={`${currentPage === page ? 'is-current' : ''} pagination-link`}
                onClick={() => { onSetPage(page) }}
                aria-label={`${tts["PAGINATION_ARIA_PAGE"]} ${index + 1}`}
                aria-current={currentPage === index + 1 ? "page" : null}
              >
                {page}
              </Link>
            </li>
          )}
          {currentPage === 1 && totalItems > pageSize * 2 && <li >
            <Link to={location.pathname}
              className={`pagination-link`}
              onClick={() => { onSetPage(3) }}
              title={labels['PAGINATION_NEXT_PAGE']}
            >
              ..
              </Link>
          </li>}
        </ul>
      </nav>
    );
  }
}

export default connect(
  state => ({
    labels: state.localization.labels,
    tts: state.localization.tts
  }),
  dispatch => ({})
)(Pagination)