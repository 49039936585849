import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from '../../components/icon';
import DropdownItem from './components/item';
import DropdownDivider from './components/divider';
import modifiers from '../../modifiers';
import Element from '../element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons'

export default class Dropdown extends PureComponent {
  static Item = DropdownItem;

  static Divider = DropdownDivider;

  static propTypes = {
    ...modifiers.propTypes,
    className: PropTypes.string,
    style: PropTypes.shape({}),
    children: PropTypes.node,
    value: PropTypes.any,
    onChange: PropTypes.func,
    right: PropTypes.bool,
    up: PropTypes.bool,
    align: PropTypes.oneOf(['right']),
    hoverable: PropTypes.bool,
    label: PropTypes.string,
    toggleDrop: PropTypes.bool,
    labelledBy: PropTypes.string
  }

  static defaultProps = {
    ...modifiers.defaultProps,
    className: undefined,
    renderAs: 'div',
    domRef: React.createRef(),
    style: undefined,
    value: undefined,
    children: [],
    onChange: undefined,
    align: undefined,
    hoverable: undefined,
    label: undefined,
    toggleDrop: true,
    labelledBy: undefined
  }

  _dropdownRef = React.createRef();
  _dropdownContentRef = React.createRef();
  state = {
    open: false
  }

  componentDidMount() {
    if (this.props.hoverable) {
      this._dropdownRef.current.addEventListener("focus", this.toggle);
      this._dropdownRef.current.addEventListener("blur", this.close);
    }
  }

  componentWillUnmount() {
    if (this.props.hoverable) {
      this._dropdownRef.current.removeEventListener("focus", this.toggle);
      this._dropdownRef.current.removeEventListener("blur", this.close);
    }
  }

  close = (evt) => {
    // IDK yet how to test using the ref in enzime
    if (evt && this.props.domRef && this.props.domRef.current.contains(evt.target)) {
      return;
    }
    if (this._dropdownRef.current) {
      this._dropdownRef.current.focus();
      this.setState({ open: false });
      this._dropdownContentRef.current.removeEventListener("keydown", this.keyPressHandler);
    }
    document.removeEventListener("click", this.clickHandler);
  }

  toggle = (evt) => {
    if (!this.props.toggleDrop){
      this.setState({ open: false });
      return;
    }
    // if (this.props.hoverable) {
    //   return;
    // }
    if (evt) {
      evt.preventDefault();
    }
    this.setState(({ open }) => {
      const { current } = this._dropdownContentRef;
      if (!open) {
        current.addEventListener("keydown", this.keyPressHandler);
        document.addEventListener("click", this.clickHandler);
      } else {
        current.removeEventListener("keydown", this.keyPressHandler);
        document.removeEventListener("click", this.clickHandler);
      }
      return { open: !open };
    }, () => {
      const dropdownContent =  this._dropdownContentRef.current;
      const activeElement = Array.from(dropdownContent.children).find(item => item.classList.contains("is-active"));
      if (this.state.open) {
        activeElement ? activeElement.focus() : dropdownContent.focus();
      }
    });
  }

  keyPressHandler = event => {
    event.stopPropagation();
    const { keyCode } = event;

    let dropdownElements = [];
    Array.from(this._dropdownContentRef.current.children).forEach(item => {
      dropdownElements.push({
        node: item,
        active: item === document.activeElement
      })
    });
    const activeElementIndex = dropdownElements.findIndex(item => item.active);
    
    switch (keyCode) {
      case 9:
      case 27:
        this.close();
        break;
      case 36:
        // Home
        event.preventDefault();
        dropdownElements[0].node.focus();
        break;
      case 35:
        // End
        event.preventDefault();
        dropdownElements[dropdownElements.length - 1].node.focus();
        break;
      case 38:
        // Up
        if (activeElementIndex === -1) {
          dropdownElements[0].node.focus();
        } else if (activeElementIndex === 0) {
          return;
        } else {
          dropdownElements[activeElementIndex - 1].node.focus();
        }
        break;
      case 40:
        // Down
        event.preventDefault();
        if (activeElementIndex === -1) {
          dropdownElements[0].node.focus();
        } else if (activeElementIndex === dropdownElements.length - 1) {
          return;
        } else {
          dropdownElements[activeElementIndex + 1].node.focus();
        }
        break;
      default: break;
    }
  }

  clickHandler = event => {
    this.close();
  }

  select = value => () => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    this.close();
  }

  render() {
    const { open } = this.state;
    const {
      id,
      className,
      children,
      value,
      align,
      right,
      up,
      hoverable,
      label,
      onChange,
      toggleDrop,
      labelledBy,
      ...props
    } = this.props;
    let current = { id: null, node: label };

    const childrenArray = React.Children.map(children, (child, i) => {
      if (child.type === DropdownItem && ((i === 0 && !label) || child.props.value === value)) {
        current = {
          id: child.props.id,
          node: React.createElement("div", { "aria-label": child.props["aria-label"] }, child.props.children)
        }          
      }
      return React.cloneElement(child, child.type === DropdownItem ? {
        active: child.props.value === value,
        onClick: this.select(child.props.value),
        onKeyPress: this.select(child.props.value)
      } : {});
    });

    if (align === 'right') {
      // eslint-disable-next-line no-console
      console.warn('react-bulma-components: "Align" prop will be replaced by "right" prop in future releases. Please update your code to avoid breaking changes.');
    }

    return (
      <Element
        {...props}
        className={classnames('dropdown', className, {
          'is-active': open,
          'is-up': up,
          'is-right': right || align === 'right',
          'is-hoverable': hoverable,
        })}
      >
        <div 
          id={id}
          className="dropdown-trigger" 
          role="button" 
          tabIndex="0"
          ref={this._dropdownRef}
          aria-haspopup="listbox"
          aria-labelledby={labelledBy ? `${labelledBy} ${id}` : null}
          aria-expanded={open ? "true" : null}
          onKeyPress={this.toggle}
          onClick={this.toggle}
          aria-disabled={!toggleDrop}
        >
          {current.node}
          <Icon className={toggleDrop ? '': 'is-sr-only'}>
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </Icon>
        </div>
        <div id="dropdown-menu" className="dropdown-menu">
          <div
            className="dropdown-content"
            tabIndex="-1"
            role="listbox"
            aria-labelledby={labelledBy}
            aria-activedescendant={current.id}
            ref={this._dropdownContentRef}
          >
            {childrenArray}
          </div>
        </div>
      </Element>
    );
  }
}
