import React from 'react'
import DocumentTitle from 'react-document-title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons'
import { connect } from 'react-redux';
let update = require('immutability-helper');

class Contacts extends React.Component {
    state = { 
      contactList : []
    }

    componentDidMount() {
      setTimeout(()=>{
        const { labels } = this.props;
        this.setState({contactList : [
          {
            title: labels['CONTACTS_CALL_CENTER_TITLE'],
            isOpen: false,
            content: `<p class="push-bottom-md">317.635.3344<br/>
            Relay Indiana: 711 (Indiana)<br/>
            800.743.3333</p>
            <p class="push-bottom-md">Hours of Operation:<br/>
            Monday – Friday: 8:00 AM – 6:00 PM<br/>
            Saturday: 9:00 AM – 12:00 PM<br/>
            Sunday: Closed</p>
            <p class="push-bottom-md">Call customer service for questions, ticketing information, trip advice, route detours and delays, or to share a <a href="#">comment or complaint</a>. You can also purchase bus passes over the phone 
            or <a href="https://www.indygo.net/plan-your-trip/" target="_blank">request route maps.</a></p>
            <p class="push-bottom-md">Our call center’s language line provides interpretive services for more than 150 languages.</p>`
          },
          {
            title: labels['CONTACTS_RETAIL_CENTER_TITLE'],
            isOpen: false,
            content: `<p class="push-bottom-md">The Julia M. Carson Transit Center<br/>
            201 E. Washington St.<br/>
            Indianapolis, IN 46204</br>
            317.635.3344</p>
            <p class="push-bottom-md">Hours of Operation:<br/>
            Monday – Friday: 8 a.m. – 6 p.m.<br/>
            Saturday: 9 a.m. – Noon<br/>
            Sunday: Closed</p>
            <p class="push-bottom-md">Visit the Customer Service Center to purchase passes, get an IndyGo Half Fare ID card, receive trip planning assistance, pick-up maps and schedules, or claim a lost item.</p>`
          },
          {
            title: labels['CONTACTS_OPEN_DOOR_TITLE'],
            isOpen: false,
            content: `<p class="push-bottom-md">Open Door Reservations/Cancellations: 317.917.8747<br/>
            Dispatch: 317.917.8758<br/>
            Certification Appointments: 317.614.9260</p>
            <p class="push-bottom-md">Hours of Operation:<br/>
            Monday – Friday: 6 a.m. – 6 p.m.<br/>
            Saturday & Sunday: 7 a.m. – 4 p.m.</p>`
          },
          {
            title: labels['CONTACTS_ADMIN_OFFIICE_TITLE'],
            isOpen: false,
            content: `<p class="push-bottom-md">1501 W. Washington St.<br/>
            Indianapolis, IN 46222<br/>
            317.635.2100</p>
            <p class="push-bottom-md">Hours of Operation:<br/>
            Monday – Friday: 8 a.m. – 5 p.m.</p>
            <p class="push-bottom-md">When you visit our offices, you’ll be asked to sign in at the receptionist desk and show a valid photo ID.</p>`
          }
        ]})
      }, 2000);

      // focus page
      let resetFocusItem = document.getElementById('contact-page');
      resetFocusItem.setAttribute("tabindex", "-1");
      resetFocusItem.style.outline = "none";
      resetFocusItem.focus();
    }

    accordionContact(index) {
      const contactList = this.state.contactList;

      const updateContactList = update(contactList[index], {isOpen :  {$set: contactList[index].isOpen == true ? false : true}})
      const newContactList = update(contactList, {
        $splice: [[index, 1, updateContactList]]
      });
      this.setState({contactList: newContactList});
    }
    keyDown(event,index ) {
      if (event.keyCode === 13) {
          this.accordionContact(index);
      }
    }
    render() {
      const { contactList } = this.state;
      const { labels } = this.props;
      
      return (
        <DocumentTitle title={labels['CONTACT_PAGE_TITLE'] + ' - IndyGo App'}>
          <div aria-label="Contact page" role="Contact page" id="contact-page">
            <h1 className="is-size-2 has-text-weight-bold pad-bottom-md pad-top-xl">
              {labels['CONTACTS_TITLE']}
            </h1>
            <div className="push-bottom-xl">
              {contactList && contactList.map((item, idx) => {
                  return(
                    <div key={idx} 
                    onKeyDown={(event)=> this.keyDown(event, idx)}
                    tabIndex="0" id={"contact-" + idx} className={item.isOpen == true ? 'contact-item has-background-white' : 'contact-item'}>
                      <div className="contact-head pad-md is-relative" 
                      onClick={()=> this.accordionContact(idx)}>
                        <h3 className="is-size-5 has-text-weight-bold">{item.title}</h3>
                        <span className="is-absolute icon is-medium has-background-white radius-50-percent">
                            <FontAwesomeIcon style={{ fontSize: '18px'}} icon={item.isOpen == true ? faAngleUp : faAngleDown}/>
                        </span>
                      </div>
                      <div tabIndex="0" className="contact-content has-text-grey pad-md" dangerouslySetInnerHTML={{__html: item.content}}>
                      </div>
                    </div>
                  )
              })}
              {/* END ITEM */}
            </div>
          </div>
        </DocumentTitle>
      )
    }
  }
  
export default connect(
    state => ({
      labels: state.localization.labels
    }),
    dispatch => ({
    })
  )(Contacts)