import { store } from "../../store";

export function getStatusColor(status = "") {
    switch (status) {
        case 1:
            return "has-text-danger";
        case "Activated":
            return "has-text-success";
        case "Suspended":
            return "has-text-suspended";
        case 4:
            return "has-text-danger";
        case "Lost or Stolen":
            return "has-text-danger";
        default:
            return "";
    }
}
export function loadScript(body, callback) {
    const existingScript = document.getElementById(body.id);
    if (existingScript) {
        existingScript.parentNode.removeChild(existingScript);
    }
    if (body.src) {
        const script = document.createElement('script');
        script.src = body.src;
        script.id = body.id;
        document.body.appendChild(script);

        script.onload = () => {
            if (callback) callback();
        };
    }

    if (existingScript && callback) callback();
};

export function setFocusPage(selector) {
    let focusElement = document.querySelector(selector);
    if (focusElement) {
        focusElement.setAttribute("tabindex", "-1");
        focusElement.style.outline = "none";
        focusElement.focus();
    }
}
export function setFocusElement(selector) {
    let focusElement = document.querySelector(selector);
    if (focusElement) {
        focusElement.focus();
    }
}
export function convertCurrencyToWords(price) {
    try {
        const { localization: { tts } } = store.getState();
        if (!price || !tts) {
            return price || "";
        }
        if (typeof price !== "string") {
            price = price.toString();
        }
        if (price[0] !== "$") {
            price = "$" + price;
        }
        const firtCurrency = price.split("$")[1].split(".")[0];
        const lastCurrency = price.split("$")[1].split(".")[1];

        if (lastCurrency && lastCurrency !== "00") {
            return `${firtCurrency} ${tts["DOLLARS"]} ${lastCurrency} ${tts["CENTS"]}`;
        } else {
            return `${firtCurrency} ${tts["DOLLARS"]}`;
        }
    } catch (e) {
        console.error("error.code", e);
        return price || "";
    }

}
export function getToken() {
    return localStorage.getItem("token");
}
export const focusSectionHandler = (event, section) => {
    event.stopPropagation();
    section.current.querySelector("[tabIndex='-1']").focus();
}
export const keyPressSectionHandler = (event, section, after) => {
    event.stopPropagation();
    const { code } = event;
    let elements = [];

    const menuElementDOM = section.current.querySelectorAll("[tabIndex='-1']");
    Array.from(menuElementDOM).forEach(item => {
        elements.push({
            node: item,
            active: item === document.activeElement
        })
    });
    const activeElementIndex = elements.findIndex(item => item.active);

    switch (code) {
        case "Tab":
            event.preventDefault();
            if (activeElementIndex !== elements.length - 1) {
                elements[activeElementIndex + 1].node.focus();
            } else {
                elements[0].node.focus();
            }
            break;
        case "Escape":
            event.preventDefault();
            if (typeof after === 'object' && after.current) {
                after.current.focus()
            } else if (typeof after === 'string' || after instanceof String) {
                setFocusElement(after);
            }
            break;
        case "KeyF":
            event.preventDefault();
            if (typeof after === 'object' && after.current) {
                after.current.focus()
            } else if (typeof after === 'string' || after instanceof String) {
                setFocusElement(after);
            }
            break;
        case "Home":
            event.preventDefault();
            elements[0].node.focus();
            break;
        case "End":
            event.preventDefault();
            elements[elements.length - 1].node.focus();
            break;
        case "ArrowLeft":
            event.preventDefault();
            if (activeElementIndex !== 0) {
                elements[activeElementIndex - 1].node.focus();
            } else {
                elements[elements.length - 1].node.focus();
            }
            break;
        case "ArrowUp":
            event.preventDefault();
            if (activeElementIndex !== 0) {
                elements[activeElementIndex - 1].node.focus();
            } else {
                elements[elements.length - 1].node.focus();
            }
            break;
        case "ArrowRight":
            event.preventDefault();
            if (activeElementIndex !== elements.length - 1) {
                elements[activeElementIndex + 1].node.focus();
            } else {
                elements[0].node.focus();
            }
            break;
        case "ArrowDown":
            event.preventDefault();
            if (activeElementIndex !== elements.length - 1) {
                elements[activeElementIndex + 1].node.focus();
            } else {
                elements[0].node.focus();
            }
            break;
        default: break;
    }
}
export const setDocumentLanguage = code => {
    document.querySelector("html").setAttribute("lang", code);
}
export function convertFareCardNumber(number) {
    try {
        if (!/^[0-9 ]+$/.test(number)) {
            return null;
        }
        let result = number.replace(/ +/g, "");
        result = result.split("").reduce((a, i) => a + ` ${i}`);
        return result;
    } catch (e) {
        console.error(e);
        return number;
    }
}