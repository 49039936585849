import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown, faRedoAlt, faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import Select, { components } from 'react-select';
import { Field, Control, Label } from '../../components/lib/components/form';
import { Button } from '../../components/lib/components/button';
import { Columns } from '../../components/lib/components/columns';
import { Pagination } from '../../components/lib/components/pagination';
import { Icon } from '../../components/lib/components/icon';
import DatePicker from "react-datepicker";
import moment from "moment";
import { historyService } from "../../_services";
import { setFocusPage, setFocusElement, convertCurrencyToWords } from "../../_helpers";

class TransactionHistory extends React.Component {

  _isMounted = false;
  state = {
    'filter[date]': '',
    'filter[media_id]': '',
    'filter[type]': '',
    per_page: 10,
    page: 1,
    historyList: null,
    mediaList: [],
    operationTypesList: [],
    locale: null,
    pages: {
      pageSize: 0,
      totalItems: 0,
      currentPage: 0,
      totalPages: 0
    }
  }

  static formatToGroupList(list) {
    try {
      let objectList = {}
      let listFormat = [];
      list.forEach((element) => {
        let field = moment(element['datetime']).format("YYYY-MM-DD");
        if (objectList.hasOwnProperty(field)) {
          objectList[field].push(element)
        } else {
          objectList[field] = [];
          objectList[field].push(element)
        }
      })
      Object.keys(objectList).map((key) => {
        listFormat.push({
          groupDate: key,
          list: objectList[key]
        })
      });
      return listFormat;
    } catch (e) { return [] }

  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.router.location.search) {
      this.props.router.replace(this.props.location.pathname + '?per_page=10&page=1');
    }
    this.setState({ ...this.props.location.query })
    this.getListHistory();
    this.getDatePickerLocale();

    // focus page
    setFocusPage('#transaction-history-page');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getDatePickerLocale() {
    const locale = await import("date-fns/locale");
    const { supported_languages } = this.props.artifacts;
    if (supported_languages && this._isMounted) {
      const active_language = localStorage.getItem("language") || this.props.defaultLang;
      const locale_code = supported_languages.find(lang => lang.locale_code === active_language);
      if (locale_code && locale_code.code) {
        this.setState({
          locale: locale[locale_code.code] || null
        });
      } else {
        this.setState({
          locale: locale['en']
        });
      }
    }
  }

  getListHistory() {
    this.props.onLoader('yes');
    let objQuery = { ...this.props.router.location.query };
    if (objQuery['filter[date]']) {
      objQuery['filter[datefrom]'] = moment(objQuery['filter[date]']).startOf('day').toISOString();
      objQuery['filter[dateto]'] = moment(objQuery['filter[date]']).endOf('day').toISOString();
      delete objQuery['filter[date]'];
    }
    historyService.getListAccountHistory(objQuery)
      .then(res => {
        try {
          if (!this._isMounted) return;
          res.data.data.forEach(item => {
            item['startDate'] = moment(item.datetime).format("YYYY-MM-DD");
            // convert currency to words
            item['priceTitle'] = convertCurrencyToWords(item.amount);
          });

          this.setState({
            historyList: TransactionHistory.formatToGroupList(res.data.data || []),
            mediaList: res.data.media || [],
            operationTypesList: res.data.operation_types || [],
            pages: {
              pageSize: res.data.meta.per_page,
              totalItems: res.data.meta.total,
              currentPage: res.data.meta.current_page,
              totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page)
            }
          }, () => {
            this.props.onLoader('no');
          });

        } catch (e) { console.error(e) }
      })
      .catch(error => {
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  handleChange = (data) => {
    this.setState({ ...data }, () => {
      let query = this.props.router.location.query
      const url = new URLSearchParams({
        ...query,
        ...data,
        ...{ page: 1 }
      }).toString();
      this.props.router.replace(`${this.props.location.pathname}?${url}`);
      this.getListHistory()
    })
  }

  onSetPage = (page) => {
    let query = this.props.router.location.query
    this.setState({ page: page }, () => {
      const url = new URLSearchParams({
        ...query,
        ...{ page: page }
      }).toString();
      this.props.router.replace(`${this.props.location.pathname}?${url}`);
      this.getListHistory()
    })
  }

  onResetFilter = () => {
    this.setState({
      'filter[date]': '',
      'filter[media_id]': '',
      'filter[type]': ''
    }, () => {
      this.props.router.replace(this.props.location.pathname + '?per_page=10&page=1');
      this.getListHistory();
    })
  }

  openDatepicker = () => this._calendar.setOpen(true);

  closeDatepicker = () => this._calendar.setOpen(false);

  render() {
    const { historyList, mediaList, operationTypesList, locale, pages } = this.state;
    const { labels, tts } = this.props;
    const DropdownIndicator = props => (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon style={{ color: "$input-arrow" }} className="is-size-4" icon={props.selectProps.menuIsOpen ? faAngleUp : faAngleDown} />
        </components.DropdownIndicator>
      )
    )
    const offsetOfDate = moment.tz(moment.tz.guess(true))['_offset'] * -1 + 120;
    return (
      <DocumentTitle title={labels['TRANSACTION_HISTORY_PAGE_TITLE'] + ' - IndyGo App'}>
        <div id="transaction-history-page" aria-label={labels['TRANSACTION_HISTORY_PAGE_TITLE']} role={labels['TRANSACTION_HISTORY_PAGE_TITLE']}>

          <h1 className="is-size-3 is-size-2-tablet has-text-weight-bold pad-bottom-md pad-top-xl">
            {labels["HISTORY_TITLE"]}
          </h1>

          <div className="has-text-weight-bold push-bottom-sm">
            {labels['HISTORY_SEARCH_FOR']}
          </div>

          <Columns>
            <Columns.Column size={2}>
              <Field>
                <Label className="is-sr-only" htmlFor="filter_by_date">{tts["HISTORY_SEARCH_BY_DATE"]}</Label>
                <Control id="box-filter_by_date" aria-label={(this.state['filter[date]']) ? moment(new Date(this.state['filter[date]']).setMinutes(new Date(this.state['filter[date]']).getMinutes() + offsetOfDate)).format("MM/DD/yy") : tts["HISTORY_SEARCH_BY_DATE_PLACEHOLDER"]} tabIndex="0" iconRight={true} className="react-datepicker-control">
                  <Icon
                    align="right"
                    tabIndex="0"
                    role="button"
                    className="is-medium"
                    onKeyPress={this.openDatepicker}
                    onClick={this.openDatepicker}
                    aria-label={tts["PROFILE_OPEN_CALENDAR"]}
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} className="has-text-grey" />
                  </Icon>
                  <DatePicker
                    tabIndex="-1"
                    id="filter_by_date"
                    dateFormat="MM/dd/yyyy"
                    autoComplete="off"
                    placeholderText="MM/DD/YYYY"
                    aria-label={tts["HISTORY_SEARCH_BY_DATE_PLACEHOLDER"]}
                    className="input"
                    selected={(this.state['filter[date]']) ? new Date(this.state['filter[date]']).setMinutes(new Date(this.state['filter[date]']).getMinutes() + offsetOfDate) : ''}
                    onChange={(e) => { this.handleChange({ 'filter[date]': moment(e).format("YYYY-MM-DD") }); setFocusElement('#box-filter_by_date') }}
                    showYearDropdown
                    scrollabelYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    minDate={new Date("01/01/2000")}
                    maxDate={new Date()}
                    locale={locale}
                    ref={(c) => this._calendar = c}
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column size={3}>
              {operationTypesList && operationTypesList.length > 1 && <Field>
                <Label className="is-sr-only" htmlFor="filter_by_operation">{tts["HISTORY_SEARCH_BY_OPERATION"]}</Label>
                <Control className="react-datepicker-control">
                  <Select
                    inputId="filter_by_operation"
                    className='react-select-container'
                    classNamePrefix="react-select"
                    value={operationTypesList.find((element) => element.id === this.state['filter[type]']) || ''}
                    onChange={(e) => this.handleChange({ 'filter[type]': e.id })}
                    options={operationTypesList}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    components={{ DropdownIndicator }}
                    placeholder={labels['HISTORY_SEARCH_BY_OPERATION_PLACEHOLDER']}
                    isSearchable={false}
                  />
                </Control>
              </Field>}
            </Columns.Column>
            <Columns.Column size={3}>
              {mediaList && mediaList.length > 1 && <Field>
                <Label className="is-sr-only" htmlFor="filter_by_card">{tts["HISTORY_SEARCH_BY_CARD"]}</Label>
                <Control className="react-datepicker-control">
                  <Select
                    inputId="filter_by_card"
                    className='react-select-container'
                    classNamePrefix="react-select"
                    value={mediaList.find((element) => element.id === this.state['filter[media_id]']) || ''}
                    onChange={(e) => this.handleChange({ 'filter[media_id]': e.id })}
                    options={mediaList}
                    components={{ DropdownIndicator }}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    placeholder={labels['HISTORY_SEARCH_BY_CARD_PLACEHOLDER']}
                    isSearchable={false}
                  />
                </Control>
              </Field>}
            </Columns.Column>


            <Columns.Column size={2}>
              {(this.state['filter[date]'] || this.state['filter[media_id]'] || this.state['filter[type]']) && (
                <Field className="push-top-sm">
                  <Button
                    onClick={this.onResetFilter}
                    className="pad-left-sm pad-right-sm pad-top-xs pad-bottom-xs has-text-success is-fullheight has-text-weight-bold"
                    style={{ background: "none", textTransform: "none", minWidth: "auto", height: "auto", border: "none", letterSpacing: 'normal' }}
                    title={labels['HISTORY_RESET_FILTERS']}
                  >
                    <FontAwesomeIcon icon={faRedoAlt} className="is-size-6 push-right-sm" />
                    <span>{labels['HISTORY_RESET_FILTERS']}</span>
                  </Button>
                </Field>
              )}
            </Columns.Column>

            <Columns.Column size={2}>
              {pages.totalItems > 10 && (
                <Pagination
                  pageSize={pages.pageSize}
                  currentPage={pages.currentPage}
                  totalPages={pages.totalPages}
                  totalItems={pages.totalItems}
                  onSetPage={page => this.onSetPage(page)}
                  items={historyList}
                  {...this.props}
                />
              )}
            </Columns.Column>
          </Columns>

          {historyList && historyList.length === 0 && (
            <p className="has-text-weight-bold">{labels["HISTORY_NO_DATE"]}</p>
          )}

          {historyList && historyList.length !== 0 && historyList.map((item, idx) => (
            <Columns key={idx} role="table" aria-label={labels["HISTORY_TITLE"]} aria-describedby={`day_${idx}`}>
              <Columns.Column size={12} className="is-size-4 has-text-info has-text-weight-bold">
                <p id={`day_${idx}`}>
                  {moment(item.groupDate).format("MMMM DD, YYYY") === moment().format("MMMM DD, YYYY") ? 'Today' : moment(item.groupDate).format("MMMM DD, YYYY")}
                </p>
              </Columns.Column>
              <Columns.Column size={12}>
                <div className="list history-list">
                  {item.list && item.list.map((data, index) => (
                    <div key={index} className="list-item" role="rowgroup">
                      <Columns role="row">

                        <Columns.Column size={1} role="cell">
                          <p>{moment.utc(data['datetime']).local().format('h:mma')}</p>
                        </Columns.Column>

                        <Columns.Column size={4} role="cell">
                          <p className="has-text-weight-bold">{data['operation_title']}</p>
                        </Columns.Column>

                        <Columns.Column size={3} role="cell">
                          <p className="is-size-8 has-text-grey-dark">{data['operation_detail_1_title']}</p>
                          <p className="is-size-8">{data['operation_detail_1_data']}</p>
                        </Columns.Column>

                        <Columns.Column size={3} className="has-text-right" role="cell">
                          <p className="is-size-8 has-text-grey-dark">{data['operation_detail_2_title']}</p>
                          <p className="is-size-8">{data['operation_detail_2_data']}</p>
                        </Columns.Column>

                        <Columns.Column size={1} className="has-text-right" role="cell">
                          <span aria-label={data.priceTitle}></span>
                          <span aria-hidden="true" aria-label={data.priceTitle}>{data['amount']}</span>
                        </Columns.Column>

                      </Columns>
                    </div>
                  ))}
                </div>
              </Columns.Column>
            </Columns>
          ))}
        </div>
      </DocumentTitle>
    )
  }
}

export default connect(
  state => ({
    loader: state.loader,
    labels: state.localization.labels,
    tts: state.localization.tts,
    defaultLang: state.localization.defaultLang,
    artifacts: state.artifacts
  }),
  dispatch => ({
    onLoader: (status) => {
      dispatch({ type: 'SET_STATUS', payload: status })
    }
  })
)(TransactionHistory)