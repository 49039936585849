import React from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import { Button } from "../../components/lib/components/button";
import { Icon } from "../../components/lib/components/icon";
import { Level } from "../../components/lib/components/level";
import { Columns } from "../../components/lib/components/columns";
import { Link } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faRedo } from "@fortawesome/pro-light-svg-icons";
import { cardService, personalService, historyService } from "../../_services";
import { getStatusColor, setFocusPage, convertCurrencyToWords } from "../../_helpers";
import moment from "moment";

class Welcome extends React.Component {

  _isMounted = false;
  state = {
    fullname: "",
    digital_balance: " $-.--",
    digital_number: "",
    recentActivities: [],
    recentAccessed: "",
    isDigitalError: false,
    iteration: 1
  }

  componentDidMount() {
    this._isMounted = true;
    // get user data
    this.getUserData();
    this.getRecentActivity();

    // focus page
    setFocusPage("#welcome-page");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserData = () => {
    this.props.onLoader("yes");
    // get personal data
    personalService.getPersonalData()
      .then(res => {
        if (this._isMounted) {
          const personalData = res.data.data;
          this.setState({
            fullname: personalData.name || "",
          });
        }
        this.props.onLoader("no");
      })
      .catch(error => {
        console.error("error.response", error);
        this.props.onLoader("no");
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push("/login");
        }
      })
  }

  getDigital = () => {
    this.props.onLoader("yes");
    cardService.getFareCardDigital()
      .then(res => {
        if (this._isMounted) {
          this.setState({
            digital_balance: res.data.data.balance.formatted,
            digital_number: res.data.data.id,
            recentAccessed: res.data.data
          })
        }
        this.props.onLoader("no");
      })
      .catch(error => {
        console.error("error.response", error);
        this.props.onLoader("no");
        this.setState({
          isDigitalError: true
        })
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push("/login");
        }
      })
  }

  getFareCardID = () => {
    
    try {
      let media_id = this.state.recentActivities[this.state.iteration - 1]["operation_detail_1_data"];
      if (media_id) {
        media_id = String(media_id).replace(/\s+/g, "");
        this.props.onLoader("yes");
        cardService.getFareCardId(media_id)
        .then(res => {
          try {
            this.setState({
              digital_balance: res.data.data.balance.formatted,
              digital_number: res.data.data.id,
              recentAccessed: res.data.data
            });
          } catch (e) {
            this.getDigital();
            console.error(e)
          }
          this.props.onLoader("no");
        })
        .catch(error => {
          this.setState({
            iteration: ++this.state.iteration
          }, () => {
            if (this.state.iteration <= this.state.recentActivities.length) {
              this.getFareCardID();
            } else {
              this.getDigital();
            }
          })
          this.props.onLoader("no");
          // redirect to login page
          if (error.response.status === 401) {
            this.props.router.push("/login");
          }
        })
      }else{
        this.setState({
          iteration: ++this.state.iteration
        }, () => {
          if (this.state.iteration <= this.state.recentActivities.length) {
            this.getFareCardID();
          } else {
            this.getDigital();
          }
        })
      }

    } catch (e) {
      this.getDigital();
      console.error(e)
      return;
    }
  }

  getRecentActivity = () => {
    this.props.onLoader("yes");
    historyService.getRecentActivity()
      .then(res => {
        if (this._isMounted) {
          try {
            if (res.data.data.length !== 0) {
              let recentActivities = res.data.data;
              this.setState({ recentActivities });
              // get fare card by id
              this.getFareCardID();
            } else {
              // case list recent activicy is empty and then get mobike card
              this.getDigital();
            }
          } catch (e) { console.error(e) }
        }
        this.props.onLoader("no");
      })
      .catch(error => {
        this.props.onLoader("no");
        // redirect to login page
        if (error.response.status == 401) {
          this.props.router.push("/login");
        }
      })
  }

  render() {
    const { fullname, digital_balance, digital_number, recentActivities, recentAccessed, isDigitalError } = this.state;
    const { labels, tts, loader, router, unread } = this.props;

    return (
      <DocumentTitle title={labels["WELCOME_PAGE_TITLE"] + " - IndyGo App"}>
        <div id="welcome-page" aria-label={labels["WELCOME_PAGE_TITLE"]}>

          <h1 className="is-size-3 is-size-2-tablet has-text-weight-bold pad-bottom-md pad-bottom-xl-tablet pad-top-md">
            {labels["WELCOME_TITLE"]}{fullname ? ", " + fullname : ""}
          </h1>

          <Columns>
            <Columns.Column size={12} className="is-flex" style={{ flexWrap: "wrap" }}>
              <Link
                to="/messages"
                title={labels["PROFILE_MESSAGE_TEXT"]}
                className="quick-card"
              >
                <Icon className={`is-event--medium ${unread > 0 ? "is-event" : ""}`}>
                  <img src="/assets/icons/mail-icon.svg" alt="" aria-hidden="true" />
                </Icon>
                <p className="quick-card__label">{labels["PROFILE_MESSAGE_TEXT"]}</p>
              </Link>
              <Link
                to="/pay-citations"
                title={labels["PROFILE_CITATIONS_TEXT"]}
                className="quick-card"
              >
                <Icon>
                  <img src="/assets/icons/shop-receive.svg" alt="" aria-hidden="true" />
                </Icon>
                <p className="quick-card__label">{labels["PROFILE_CITATIONS_TEXT"]}</p>
              </Link>
              <Link
                to="/payment-options"
                title={labels["PROFILE_PAYMENT_OPT_TEXT"]}
                className="quick-card"
              >
                <Icon>
                  <img src="/assets/icons/black-and-white-credit-cards.svg" alt="" aria-hidden="true" />
                </Icon>
                <p className="quick-card__label">{labels["PROFILE_PAYMENT_OPT_TEXT"]}</p>
              </Link>
              <Link
                to="/personal-information"
                title={labels["PROFILE_PERSONAL_INFO_TEXT"]}
                className="quick-card"
              >
                <Icon>
                  <img src="/assets/icons/personal-icon.svg" alt="" aria-hidden="true" />
                </Icon>
                <p className="quick-card__label">{labels["PROFILE_PERSONAL_INFO_TEXT"]}</p>
              </Link>
            </Columns.Column>
          </Columns>

          <Columns tabIndex="0" className={`is-multiline ${(isDigitalError) ? 'is-hidden' : ''}`}>
            <Columns.Column size={12}>
              <h2 className="title is-3 has-text-primary has-text-weight-bold">
                {labels["WELCOME_RECENTLY_ACCESS_TEXT"]}
              </h2>
            </Columns.Column>
            <Columns.Column size={10}>
              <Level className="level--card push-bottom-xl">
                <Level.Side align="left">
                  {/* Case mobile card */}
                  {recentAccessed && recentAccessed.qr_data !== null && recentAccessed.is_owner && (
                    <Level.Item className="has-text-centered">
                      <div className="push-bottom-sm">
                        <FontAwesomeIcon style={{ width: 16, height: 16 }} className="has-text-success push-right-md push-bottom-xxl" icon={faCircle} />
                      </div>
                      <img src="/assets/img/navoverlay.png" height="140" alt="" />
                    </Level.Item>
                  )}
                  {/* Case fare card */}
                  {recentAccessed && (recentAccessed.qr_data === null || (recentAccessed.qr_data !== null && !recentAccessed.is_owner)) && (
                    <Level.Item className="has-text-centered welcome-card">
                      <div className="push-bottom-md is-flex card-info">
                        <FontAwesomeIcon style={{ width: 16, height: 16 }} className={`push-right-sm ${getStatusColor(recentAccessed.status)}`} icon={faCircle} />
                        <h2 tabIndex="0" className="title is-5 owner_name has-text-weight-bold">
                          {recentAccessed.label || recentAccessed.owner_name}
                        </h2>
                      </div>
                      <div>
                        <img src="/assets/img/MyKey_Card.png" height="140" alt="" />
                      </div>
                    </Level.Item>
                  )}
                </Level.Side>
                <Level.Side align="right" className={`${!recentAccessed.qr_data ? "push-top-lg" : ""}`}>
                  <div tabIndex="0">
                    <p className="has-text-grey-dark">{labels["WELCOME_BALANCE_TEXT"]}</p>
                    <p className="level__card-price" aria-label={convertCurrencyToWords(digital_balance)}>
                      {digital_balance}
                      {/* Case mobile card */}
                      {recentAccessed && recentAccessed.qr_data !== null && recentAccessed.is_owner && (
                        <button
                          type="button"
                          className="pad-sm is-borderless"
                          onClick={this.getDigital}
                          aria-label={tts["FARE_CARD_RELOAD_BALANCE"]}
                        >
                          <FontAwesomeIcon icon={faRedo} spin={loader === "yes"} className="is-size-6 has-text-primary" />
                        </button>
                      )}
                      {/* Case fare card */}
                      {recentAccessed && (recentAccessed.qr_data === null || (recentAccessed.qr_data !== null && !recentAccessed.is_owner)) && (
                        <button
                          type="button"
                          className="pad-sm is-borderless"
                          onClick={this.getFareCardID}
                          aria-label={tts["FARE_CARD_RELOAD_BALANCE"]}
                        >
                          <FontAwesomeIcon icon={faRedo} spin={loader === "yes"} className="is-size-6 has-text-primary" />
                        </button>
                      )}
                    </p>
                  </div>
                  {/* Case mobile card */}
                  {recentAccessed.qr_data && recentAccessed.is_owner && (
                    <Button
                      color="info"
                      size="small"
                      loading={loader === "yes" || !recentAccessed}
                      disabled={loader === "yes" || !recentAccessed}
                      onClick={() => router.push("/fare-card-qr/" + digital_number)}
                      title={labels["BUTTON_MANAGE"]}
                    >
                      {labels["BUTTON_MANAGE"]}
                    </Button>
                  )}
                  {/* Case fare card */}
                  {(!recentAccessed.qr_data || (recentAccessed.qr_data && !recentAccessed.is_owner)) && (
                    <Button
                      color="info"
                      size="small"
                      loading={loader === "yes" || !recentAccessed}
                      disabled={loader === "yes" || !recentAccessed}
                      onClick={() => router.push("/fare-card/" + digital_number)}
                      title={labels["BUTTON_MANAGE"]}
                    >
                      {labels["BUTTON_MANAGE"]}
                    </Button>
                  )}
                </Level.Side>
              </Level>
            </Columns.Column>
          </Columns>

          <Columns>
            <Columns.Column size={6}>
              <h2 tabIndex="0" className="title is-3 has-text-primary has-text-weight-bold">
                {labels["WELCOME_RECENT_ACTIVITY_TEXT"]}
              </h2>

              {recentActivities && recentActivities.length === 0 && (
                <p className="title is-5 has-text-primary">{labels["WELCOME_NO_RECORD_TEXT"]}</p>
              )}

              {recentActivities && recentActivities.length !== 0 && recentActivities.map((data, index) => (
                <div
                  key={index}
                  tabIndex="0"
                  className="fare-card fare-card--primary"
                >
                  <div
                    className="fare-card__date is-size-9 is-uppercase"
                    aria-label={moment((data.datetime.slice(-1) == "Z") ? data.datetime : data.datetime + "Z").format("dddd, MMMM Do, h:mm A.")}
                  >
                    <span className="has-text-weight-bold">{moment((data.datetime.slice(-1) == "Z") ? data.datetime : data.datetime + "Z").format("ddd")}</span>
                    <span className="has-text-weight-bold">{moment((data.datetime.slice(-1) == "Z") ? data.datetime : data.datetime + "Z").format("MMM DD")}</span>
                    <span>{moment((data.datetime.slice(-1) == "Z") ? data.datetime : data.datetime + "Z").format("h:mm A")}</span>
                  </div>
                  <div className="fare-card__content is-size-8">
                    <div className="has-text-weight-bold">{data.operation_title}</div>
                    <div>
                      {data.operation_detail_1_title} <span aria-label={data.operation_detail_1_data}>{data.operation_detail_1_data}</span>
                    </div>
                  </div>
                  <div className="fare-card__price" aria-label={convertCurrencyToWords(data.amount)}>
                    {data.amount}
                  </div>
                </div>
              ))}
            </Columns.Column>
            <Columns.Column size={6}>
              {/*is-event is-event--medium  - dot*/}
              <h2 className="title is-3 has-text-primary has-text-weight-bold">{labels["WELCOME_ALERTS_TEXT"]}</h2>
              <p className="title is-5 has-text-primary">{labels["WELCOME_NO_RECORD_TEXT"]}</p>
            </Columns.Column>
          </Columns>

        </div>
      </DocumentTitle>
    )
  }
}

export default connect(
  state => ({
    loader: state.loader,
    labels: state.localization.labels,
    tts: state.localization.tts,
    unread: state.unread
  }),
  dispatch => ({
    onLoader: status => {
      dispatch({ type: "SET_STATUS", payload: status })
    }
  })
)(Welcome)