import React from 'react'
import { connect } from "react-redux";
import { Button } from '../../../components/lib/components/button';
import { Icon } from '../../../components/lib/components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle as faCheckCircleLight } from "@fortawesome/pro-light-svg-icons";
import { Field, Control, Label, Input } from '../../../components/lib/components/form';
import { Columns } from '../../../components/lib/components/columns';
import ModalView from '../../../components/lib/components/view/ModalView';
import { setFocusElement } from '../../../_helpers';

const ModalLinkFareCard = ({
    activeModal,
    showFormValue,
    showFormSuccess,
    showFormError,
    labels,
    onSubmit,
    onChange,
    setStep,
    close,
    isLoading,
    invitedUsers,
    email,
    disabledEmail,
    resendEmail,
    deleteUser,
    loader
}) => {
    return (
        <>
            <ModalView
                name="modalLinkFareCard"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                align="left"
                head={labels['FARE_CARD_LINK']}
                messages={[labels["FARE_CARD_LINK_TEXT"]]}
                containerLabel={labels['FARE_CARD_LINK']}
                className="is-fullheight"
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#email`);
                    }
                }}
            >
                <Field>
                    <Label className={`${email === "" ? "" : "has-text-grey-light"}`}>{labels['FARE_CARD_EMAIL_LABEL']}</Label>
                    <Control >
                        <Input id="email" className="has-background-white-ter" onChange={onChange} value={email} name="email" type="text" placeholder={labels['FARE_CARD_EMAIL_PLACEHOLDER']} />
                    </Control>
                </Field>
                <Button.Group className="push-top-lg push-bottom-xxl " style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 0 }}
                        disabled={disabledEmail || loader === 'yes'}
                        loading={loader === 'yes'}
                        onClick={onSubmit}
                        title={labels["BUTTON_SEND_INVITATION"]}
                    >
                        {labels["BUTTON_SEND_INVITATION"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        disabled={loader === 'yes'}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
                <div className="line push-top-lg" />
                <div aria-label={`${labels['FARE_CARD_INVITED_USERS_TITLE']} list`} tabIndex="0">
                    <h2 className="is-size-4 has-text-grey-dark push-top-xl push-bottom-md">
                        {labels['FARE_CARD_INVITED_USERS_TITLE']}
                    </h2>
                    {isLoading === true && <span className="button is-warning is-loading is-block is-bg-none">Loading</span>}
                    {isLoading === false && <ul className="list  is-scrollable ">
                        {invitedUsers.length !== 0 && invitedUsers.map((data, k) =>
                            <li className="list-item-modal" key={k}>
                                <Columns>
                                    <Columns.Column tabIndex="0" aria-label={`user ${data.email}`}  className={data.verified_at != null ? "has-text-success" : ""}>
                                        {data.email}
                                    </Columns.Column>
                                    <Columns.Column size={3}>
                                        {data.verified_at != null && <FontAwesomeIcon  className="has-text-success is-size-4" icon={faCheckCircleLight} />}
                                        {data.verified_at == null && (
                                            <p
                                                className="is-underline is-clickable"
                                                role="button"
                                                tabIndex="0"
                                                aria-label={`${labels["BUTTON_RESEND"]} ${data.email}`}
                                                onClick={() => resendEmail(data.email)}
                                                onKeyPress={() => resendEmail(data.email)}
                                            >
                                                {labels["BUTTON_RESEND"]}
                                            </p>
                                        )}
                                    </Columns.Column>
                                    <Columns.Column size={1}>
                                        <Icon tabIndex="0" aria-label={`remove ${data.email} from ${labels['FARE_CARD_INVITED_USERS_TITLE']} list`}  role="button" size="large" style={{ width: 13, cursor: 'pointer' }} onClick={() => deleteUser(data.id)} onKeyPress={() => deleteUser(data.id)}>
                                            <img src="/assets/icons/trash_primary.svg" alt="" />
                                        </Icon>
                                    </Columns.Column>
                                </Columns>
                            </li>
                        )}
                        {invitedUsers.length === 0 && <p tabIndex="0">
                            {labels['FARE_CARD_LINK_NO_DATA']}
                        </p>}
                    </ul>}
                </div>
            </ModalView>
            <ModalView
                name="modalLinkFareCard"
                activeModal={activeModal}
                active={showFormSuccess}
                icon="success"
                viewType="ShowFormSuccess"
                head={labels['FARE_CARD_SEND_EMAIL_SUCCESS_TITLE']}
                messages={[labels["FARE_CARD_SEND_EMAIL_SUCCESS_TEXT"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormSuccess_part`);
                    },
                    onDeactivate: () => {
                        setTimeout(() => setFocusElement(`#btnLinkMyCard`))
                    }
                }}
            >
                <Button
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={close}
                    title={labels["BUTTON_CONTINUE"]}
                >
                    {labels['BUTTON_CONTINUE']}
                </Button>
            </ModalView>
            <ModalView
                name="modalLinkFareCard"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels['FARE_CARD_SEND_EMAIL_ERROR_TITLE']}
                messages={[labels["FARE_CARD_SEND_EMAIL_ERROR_TEXT_1"], labels["FARE_CARD_SEND_EMAIL_ERROR_TEXT_2"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormError_part`);
                    },
                    onDeactivate: () => {
                        setTimeout(() => setFocusElement(`#btnLinkMyCard`))
                    }
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={() => setStep('showFormValue')}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels["BUTTON_TRY_AGAIN"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels
      }),
      dispatch => ({})
)(ModalLinkFareCard)