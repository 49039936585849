import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';
import { Button } from '../../../components/lib/components/button';
import { Box } from '../../../components/lib/components/box';
import { Breadcrumb } from '../../../components/lib/components/breadcrumb';
import { Modal } from '../../../components/lib/components/modal';
import { Level } from '../../../components/lib/components/level';
import { Columns } from '../../../components/lib/components/columns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { cardService } from '../../../_services';
import ModalJoinToProgram from './ModalJoinToProgram';
import moment from "moment";
import { setFocusPage } from '../../../_helpers';

class FareCardProfile extends React.Component {

    state = {
        activeModal: '',
        show: false,
        termsAccepted: false,
        showFormValue: true,
        showFormSuccess: false,
        showFormError: false,
        programconfirmationcode: '',
        profilesList: [],
        cardId: ''
    }

    componentDidMount() {
        this.getProfiles();

        // set card id
        this.setState({
            cardId: this.props.params['number'].toString().slice(-4)
        })

        // focus page
        setFocusPage('#profile-page');
    }

    open = (activeModal) => {
        this.setState({
            activeModal
        });

        this.setState({
            showFormValue: true,
            showFormSuccess: false,
            showFormError: false
        }, () => {
            // focus page
            // setFocusPage(`#${this.state.activeModal}showFormValue`);
        });
        this.setState({ show: true })
    };

    close = () => this.setState({
        show: false,
        programconfirmationcode: ''
    });

    onChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            [event.target.name]: value,
        });
    };

    onChangeDropDown = (selected) => {
        this.setState({ selected });
    }

    setStep = (step) => {
        this.setState({
            showFormValue: false,
            showFormSuccess: false,
            showFormError: false
        });

        this.setState({
            [step]: true,
        });
        // setFocusPage(`#${this.state.activeModal + step}`);
    };

    onSignIn = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const body = {
            enrollment_code: this.state.programconfirmationcode
        }
        this.props.onLoader('yes');
        cardService.addProfile(this.props.params['number'], body)
            .then(res => {
                try {
                    this.setStep('showFormSuccess');
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.log('error.response', error.response)
                this.setStep('showFormError');
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    getProfiles = () => {
        this.props.onLoader('yes');
        cardService.getProfiles(this.props.params['number'])
            .then(res => {
                try {
                    this.setState({
                        profilesList: res.data.data
                        // profilesList: [{program_name: "Test profile - Case #1",program_expired_at: null,program_enrolled_at: null,program_approved_at: new Date()},{program_name: "Test profile - Case #2",program_expired_at: null,program_enrolled_at: "2019-09-15T04:00:00.000000Z",program_approved_at: null},{program_name: "Test profile - Case #3",program_expired_at: null,program_enrolled_at: "2019-09-01T04:00:00.000000Z",program_approved_at: "2019-09-01T04:00:00.000000Z"},{program_name: "Test profile - Case #4",program_expired_at: "2019-10-01T04:00:00.000000Z",program_enrolled_at: null,program_approved_at: null},{program_name: "Test profile - Case #5",program_expired_at: "2019-09-01T04:00:00.000000Z",program_enrolled_at: null,program_approved_at: "2019-09-01T04:00:00.000000Z"},{program_name: "Test profile - Case #6",program_expired_at: "2019-12-31T05:00:00.000000Z",program_enrolled_at: "2019-10-01T04:00:00.000000Z",program_approved_at: "2019-12-31T05:00:00.000000Z"},{program_name: "Test profile - Case #7",program_expired_at: "2019-10-01T04:00:00.000000Z",program_enrolled_at: "2019-09-01T04:00:00.000000Z",program_approved_at: "2019-10-01T04:00:00.000000Z"},{program_name: "Test profile - Case #8",program_expired_at: "2019-09-03T04:00:00.000000Z",program_enrolled_at: "2019-07-01T04:00:00.000000Z",program_approved_at: null},{program_name: "Test profile - Case #9",program_expired_at: "2019-10-01T04:00:00.000000Z",program_enrolled_at: "2019-10-01T04:00:00.000000Z",program_approved_at: null},{program_name: "Test profile - Case #10",program_expired_at: "2019-09-01T04:00:00.000000Z",program_enrolled_at: "2019-09-01T04:00:00.000000Z",program_approved_at: "2019-09-01T04:00:00.000000Z"},{program_name: "Test profile - Case #11",program_expired_at: new Date(),program_enrolled_at: new Date(),program_approved_at: new Date()}]
                    })
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.log('error.response', error.response)
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    refreshProfiles = () => {
        this.close();
        this.getProfiles();
    }

    render() {
        const { activeModal, showFormValue, showFormSuccess, showFormError, programconfirmationcode, show, profilesList, cardId } = this.state;
        const { labels } = this.props;
        return (
            <DocumentTitle title={labels['FARE_CARD_PROFILE_PAGE_TITLE'] + ' - IndyGo App'}>
                <>
                    <Box>
                        <Breadcrumb
                            size="medium"
                            separatorIcon={<FontAwesomeIcon icon={faChevronRight} />}
                            items={[
                                {
                                    name: labels['PROFILE_FARE_CARD'],
                                    url: '/fare-cards',
                                },
                                {
                                    name: (this.props.params['type'] == 'mobile') ? labels['FARE_CARD_MOBILE'] : labels['WELCOME_CARD_TEXT'] + ' ' + cardId,
                                    url: (this.props.params['type'] == 'mobile') ? `/fare-card-qr/${this.props.params['number']}` : `/fare-card/${this.props.params['number']}`
                                },
                                {
                                    name: labels['FARE_CARD_PROFILES_TITLE'],
                                    url: '#3',
                                    active: true,
                                },
                            ]}
                        />
                    </Box>
                    <div role="My profiles page" id="profile-page" aria-label="My profiles page">
                        <h1 aria-label={labels['FARE_CARD_PROFILES']} className="is-size-2 has-text-primary has-text-weight-bold pull-top-xl push-bottom-lg">{labels['FARE_CARD_PROFILES']}</h1>
                        <Columns>
                            <div className="column">
                                <Button
                                    loading={this.props.loader == 'yes'}
                                    disabled={this.props.loader == 'yes'}
                                    size="small"
                                    color="info"
                                    style={{ minWidth: 190 }}
                                    tabIndex="0"
                                    onClick={() => this.open('modalJoinToProgram')}
                                    title={labels["FARE_CARD_JOIN_PROGRAM"]}
                                >
                                    {labels['FARE_CARD_JOIN_PROGRAM']}
                                </Button>
                            </div>
                        </Columns>
                        <Columns className="pad-top-lg">
                            {/* waiting API for profiles*/}
                            {profilesList && profilesList.length > 0 && <ul className="column is-10">
                                {profilesList.map((data, k) =>
                                    <Level renderAs="li" className={data.program_approved_at !== null ? "line" : "is-hidden"} key={k}>
                                        {data.program_approved_at !== null && <Level.Side className="pad-bottom-md push-bottom-sm" align="left">
                                            <Level.Item className="start" style={{ maxWidth: 220, minWidth: 220, width: '100%' }}>
                                                <p className="has-text-weight-bold">{data.program_name}</p>
                                            </Level.Item>
                                            <Level.Item>
                                                <p>
                                                    {/* Case: 1 - PSD = NULL and PED = NULL */}
                                                    {data.program_enrolled_at == null && data.program_expired_at == null && <span className="has-text-grey-light">Unlimited validity</span>}
                                                    {/* Case: PSD not NULL and PED = NULL */}
                                                    {data.program_enrolled_at != null && data.program_expired_at == null && <span>
                                                        {/* Case: 2 - PSD > Today (in the future) */}
                                                        {moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') > moment().utc().format('YYYY-MM-DD') && <span><span className="has-text-grey-light">Eligible from </span> {moment(data.program_enrolled_at).format('MM/DD/YYYY')}</span>}
                                                        {/* Case: 3 - PSD <= Today */}
                                                        {/* {moment(data.program_enrolled_at) <= moment() && <span><span className="has-text-grey-light">Valid since </span> {moment(data.program_enrolled_at).format('MM/DD/YYYY')}</span>} */}
                                                        {moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') <= moment().utc().format('YYYY-MM-DD') && <span><span className="has-text-grey-light">Eligible from </span> {moment(data.program_enrolled_at).format('MM/DD/YYYY')}</span>}
                                                    </span>}
                                                    {/* Case: PSD = NULL and PED not NULL */}
                                                    {data.program_enrolled_at == null && data.program_expired_at != null && <span>
                                                        {/* Case: 4- PED > Today (in the future) */}
                                                        {moment(data.program_expired_at).utc().format('YYYY-MM-DD') > moment().utc().format('YYYY-MM-DD') && <span><span className="has-text-grey-light">Valid until </span> {moment(data.program_expired_at).format('MM/DD/YYYY')}</span>}
                                                        {/* Case: 5 - PED <= Today */}
                                                        {moment(data.program_expired_at).utc().format('YYYY-MM-DD') <= moment().utc().format('YYYY-MM-DD') && <span><span className="has-text-grey-light">Expired since </span> {moment(data.program_expired_at).format('MM/DD/YYYY')}</span>}
                                                        {/* {moment(data.program_expired_at) <= moment() && <span><span className="has-text-grey-light">Valid until </span> {moment(data.program_expired_at).format('MM/DD/YYYY')}</span>} */}
                                                    </span>}
                                                    {/* Case: PSD not NULL and PED not NULL */}
                                                    {data.program_enrolled_at != null && data.program_expired_at != null && moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') != moment(data.program_expired_at).utc().format('YYYY-MM-DD') && <span>
                                                        {/* Case: 6 - PSD > Today (in the future) */}
                                                        {moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') > moment().utc().format('YYYY-MM-DD') && <span><span className="has-text-grey-light">Eligible from </span> {moment(data.program_enrolled_at).format('MM/DD/YYYY')}<span className="has-text-grey-light"> to </span> {moment(data.program_expired_at).format('MM/DD/YYYY')}</span>}
                                                        {/* Case: 7 - PSD <= Today and PED > Today */}
                                                        {moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') <= moment().utc().format('YYYY-MM-DD') && moment(data.program_expired_at).utc().format('YYYY-MM-DD') > moment().utc().format('YYYY-MM-DD') && <span><span className="has-text-grey-light">Valid from </span> {moment(data.program_enrolled_at).format('MM/DD/YYYY')}<span className="has-text-grey-light"> to </span> {moment(data.program_expired_at).format('MM/DD/YYYY')}</span>}
                                                        {/* Case: 8 - PED <= Today */}
                                                        {moment(data.program_expired_at).utc().format('YYYY-MM-DD') <= moment().utc().format('YYYY-MM-DD') && <span><span className="has-text-grey-light">Expired since </span> {moment(data.program_expired_at).format('MM/DD/YYYY')}</span>}
                                                        {/* {moment(data.program_expired_at) <= moment() && <span><span className="has-text-grey-light">Valid until </span> {moment(data.program_expired_at).format('MM/DD/YYYY')}</span>} */}
                                                    </span>}
                                                    {/* Case: PSD = PED and not NULL */}
                                                    {data.program_enrolled_at != null && data.program_expired_at != null && moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') == moment(data.program_expired_at).utc().format('YYYY-MM-DD') && <span>
                                                        {/* Case: 9 - PSD > Today (in the future) */}
                                                        {moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') > moment().utc().format('YYYY-MM-DD') && <span><span className="has-text-grey-light">Valid on </span> {moment(data.program_enrolled_at).format('MM/DD/YYYY')}</span>}
                                                        {/* Case: 10 - PSD < Today */}
                                                        {moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') < moment().utc().format('YYYY-MM-DD') && <span><span className="has-text-grey-light">Expired </span> {moment(data.program_enrolled_at).format('MM/DD/YYYY')}</span>}
                                                        {/* Case: 11 - PSD = Today */}
                                                        {moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') == moment().utc().format('YYYY-MM-DD') && <span><span className="has-text-grey-light">Valid today </span></span>}
                                                    </span>}
                                                </p>
                                            </Level.Item>
                                        </Level.Side>}
                                        {data.program_approved_at !== null && <Level.Side className="pad-bottom-md push-bottom-sm" align="right">
                                            <Level.Item>
                                                {/* Case: PSD = NULL and PED = NULL */}
                                                {data.program_enrolled_at == null && data.program_expired_at == null && <p className="has-text-info push-right-lg">Active</p>}
                                                {/* Case: PSD not NULL and PED = NULL & PSD <= Today */}
                                                {data.program_enrolled_at != null && data.program_expired_at == null && moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') <= moment().utc().format('YYYY-MM-DD') && <p className="has-text-info push-right-lg">Active</p>}
                                                {/* Case: PSD = NULL and PED not NULL */}
                                                {data.program_enrolled_at == null && data.program_expired_at != null && <p className="push-right-lg">
                                                    {/* Case: PED > Today (in the future) */}
                                                    {moment(data.program_expired_at).utc().format('YYYY-MM-DD') > moment().utc().format('YYYY-MM-DD') && <span className="has-text-info">Active</span>}
                                                    {/* Case: PED <= Today */}
                                                    {moment(data.program_expired_at).utc().format('YYYY-MM-DD') <= moment().utc().format('YYYY-MM-DD') && <span className="has-text-danger">Expired</span>}
                                                </p>}
                                                {/* Case: Case: PSD not NULL and PED not NULL */}
                                                {data.program_enrolled_at != null && data.program_expired_at != null && moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') != moment(data.program_expired_at).utc().format('YYYY-MM-DD') && <p className="push-right-lg">
                                                    {/* Case: PSD <= Today and PED > Today */}
                                                    {/* {moment(data.program_enrolled_at) <= moment() && moment(data.program_expired_at) > moment() && <span className="has-text-info">Active</span>} */}
                                                    {/* Case: PED <= Today */}
                                                    {moment(data.program_expired_at).utc().format('YYYY-MM-DD') <= moment().utc().format('YYYY-MM-DD') && <span className="has-text-danger">Expired</span>}
                                                </p>}
                                                {/* Case: PSD = PED and not NULL */}
                                                {data.program_enrolled_at != null && data.program_expired_at != null && moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') == moment(data.program_expired_at).utc().format('YYYY-MM-DD') && <p className="push-right-lg">
                                                    {/* Case: 10 - PSD < Today */}
                                                    {moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') < moment().utc().format('YYYY-MM-DD') && <span className="has-text-danger">Expired</span>}
                                                    {/* Case: 11 - PSD = Today */}
                                                    {moment(data.program_enrolled_at).utc().format('YYYY-MM-DD') == moment().utc().format('YYYY-MM-DD') && <span className="has-text-info">Active</span>}
                                                </p>}
                                            </Level.Item>
                                        </Level.Side>}
                                    </Level>
                                )}
                            </ul>}
                        </Columns>
                    </div>

                    <Modal showClose={false} show={show} onClose={this.close}>
                        <Modal.Content>
                            <Modal.Card>
                                <Modal.Card.Head onClose={this.close}>
                                </Modal.Card.Head>
                                <Modal.Card.Body className={`centered`} >

                                    {/* Modal Add FareCard */}
                                    <ModalJoinToProgram
                                        activeModal={activeModal}
                                        showFormValue={showFormValue}
                                        showFormSuccess={showFormSuccess}
                                        showFormError={showFormError}
                                        onSubmit={this.onSignIn}
                                        onChange={this.onChange}
                                        close={this.close}
                                        setStep={this.setStep}
                                        programconfirmationcode={programconfirmationcode}
                                        refreshProfiles={this.refreshProfiles}
                                    ></ModalJoinToProgram>

                                </Modal.Card.Body>
                            </Modal.Card>
                        </Modal.Content>
                    </Modal>

                </>
            </DocumentTitle>
        )
    }
}

export default connect(
    state => ({
        labels: state.localization.labels,
        loader: state.loader
    }),
    dispatch => ({
        onLoader: (status) => {
            dispatch({ type: 'SET_STATUS', payload: status })
        }
    })
)(FareCardProfile)
