import axios from "axios";
import config from "../../config";
import { getToken } from "../_helpers";

export const personalService = {
    getPersonalData,
    updatePersonalData,
    updatePin,
    updatePassword,
    updatePhone,
    getPersonalAddress,
    updatePersonalAddress,
    createPersonalAddress,
    activateAccount
}

function getPersonalData() {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/patron`, requestOptions)
}

function updatePersonalData(params) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron`, params, requestOptions)
}

function activateAccount(params) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json' }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/activate`, params, requestOptions)
}

function updatePin(params) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/pin`, params, requestOptions)
}

function updatePassword(params) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/password`, params, requestOptions)
}

function updatePhone(params) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/phone`, params, requestOptions)
}

function getPersonalAddress() {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/patron/addresses`, requestOptions)
}

function createPersonalAddress(params) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.post(`${config.API.URL}/api/v1/patron/addresses`, params, requestOptions)
}

function updatePersonalAddress(id, params) {
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/addresses/${id}`, params, requestOptions)
}