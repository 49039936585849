import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';

class TermsConditions extends React.Component {
    state = { 
    }

    render() {
    //   const { } = this.state;
      return (
        <DocumentTitle title={this.props.labels['TERMS_PAGE_TITLE'] + ' - IndyGo App'}>
          <>
            <h1 className="is-size-2 has-text-weight-bold pad-bottom-md pad-top-xl ">
              {this.props.labels['INFO_TERMS']}
            </h1>
            <div className="has-text-grey is-size-6">
                <p className="push-bottom-md">Access to IndyGo’s website, IndyGo.net (the “site”) is provided subject to the following terms and conditions. Your use of this site will indicate your acceptance of these terms. IndyGo may periodically change the terms without notice, so please check them from time to time as your continued use of the site signifies your acceptance of the terms and conditions, including changed items.</p>
                <p className="push-bottom-md">Why We Collect Information</p>
                <p className="push-bottom-md">Our principal purpose for collecting personal information online is to provide you with information that you need and want, address security and virus concerns, and to ease the use of our website.</p>
                <p className="push-bottom-md">We collect information from you and about you, which may include your name, physical address, email address, telephone number, and credit card information.</p>
                <p className="push-bottom-md">Information about your visit to this site.  We collect information from you passively. We use tracking tools like browser cookies and Google Analytics to track non-specific user information such as operating systems, browsers, device type, and estimated connection types.  We may gather information about your visit to this site, such as the name of the internet service provider and the internet protocol (IP) address through which you access the internet; the date and time you access the site and which portions of the site you visit; the pages that you access while at the site and the internet address of the website from which you linked directly to our site.  We might also gather information about what site you came from or what site you visit when you leave us.  The technical information collected will not personally identify you.  This information is used to help improve the site, analyze trends, and administer the site.</p>
            </div>
          </>
        </DocumentTitle>
      )
    }
  }
  
export default connect(
    state => ({
      labels: state.localization.labels
    }),
    dispatch => ({
    })
  )(TermsConditions)