import 'focus-visible';
import styled from 'styled-components'

const FocusVisible = styled.div`
&.js-focus-visible :focus:not(.focus-visible) {
   outline: none;
}
&.js-focus-visible .focus-visible:not(.input) {
    outline: 2px solid #159ce4;
    outline-offset: 2px;
}
`;

export default FocusVisible;