import React from 'react'
import DocumentTitle from 'react-document-title'
import { Link } from "react-router";
import { connect } from 'react-redux';
import { Button } from '../../components/lib/components/button';
import { Icon } from '../../components/lib/components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'
import {
    Field,
    Control,
    Label,
    Input,
} from '../../components/lib/components/form';
import { Columns } from '../../components/lib/components/columns';
import { Pagination } from '../../components/lib/components/pagination';
import { Modal } from '../../components/lib/components/modal';
import { threadsService } from '../../_services';
import { setFocusPage } from '../../_helpers';
import moment from "moment";
import ModalAddNewMessage from './ModalAddNewMessage';

class Messages extends React.Component {
    _isMounted = false;
    state = {
        activeModal: '',
        show: false,
        showFormValue: true,
        showFormSuccess: false,
        showFormError: false,
        search: '',
        topic: '',
        attachment: '',
        message: '',
        files: [],
        size: 0,
        threads: [],
        filtered: [],
        pages: {
            pageSize: 0,
            totalItems: 0,
            currentPage: 0,
            totalPages: 0
        }
    }
    open = (activeModal) => {
        this.setState({
            activeModal
        });

        this.setState({
            showFormValue: true,
            showFormSuccess: false,
            showFormError: false
        }, () => {
            // focus page
            // setFocusPage(`#${this.state.activeModal}showFormValue`);
        });
        this.setState({ show: true })
    };

    close = () => this.setState({
        show: false,
        search: '',
        topic: '',
        attachment: '',
        message: '',
    });

    componentDidMount() {
        this._isMounted = true;
        if (localStorage.getItem('token')) {
            this.getThreads(1);
        }

        // focus page
        setFocusPage(`#messages-page`)
    }

    onSetPage(page) {
        this.setState({
            threads: [],
            filtered: []
        }, () => {
            this.getThreads(page);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            [event.target.name]: value,
        });
        if (event.target.name == 'search') {
            let currentList = [];
            let newList = [];
            if (event.target.value !== "") {
                currentList = this.state.threads;
                newList = currentList.filter(item => {
                    const lc = item['title'].toLowerCase();
                    const filter = value.toLowerCase();
                    return lc.includes(filter);
                });
            } else {
                newList = this.state.threads;
            }
            this.setState({
                filtered: newList
            });
        }
    };

    setStep = step => {
        this.setState({
            showFormValue: false,
            showFormSuccess: false,
            showFormError: false
        });

        this.setState({
            [step]: true,
        });
        // setFocusPage(`#${this.state.activeModal + step}`);
    };

    getThreads = (page) => {
        this.props.onLoader('yes');
        threadsService.getThreads(page)
            .then(res => {
                try {
                    if (this._isMounted) {
                        this.setState({
                            threads: res.data.data,
                            filtered: res.data.data,
                            pages: {
                                pageSize: res.data.meta.per_page,
                                totalItems: res.data.meta.total,
                                currentPage: res.data.meta.current_page,
                                totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page)
                            }
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }
    addThreads = () => {
        this.props.onLoader('yes');
        let formData = new FormData();
        formData.append('title', this.state.topic);
        formData.append('body', this.state.message);
        formData.append('file', this.state.files[0]);

        threadsService.addThread(formData)
            .then(res => {
                try {
                    this.props.onLoader('no');
                    this.getThreads(1);
                    this.setStep('showFormSuccess');
                    // this.getMessagesUnread()
                    // clear attachments
                    this.setState({
                        files: []
                    })
                } catch (e) {
                    console.error(e);
                }

            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }
    getMessagesUnread = () => {
        threadsService.getMessagesUnread()
            .then(res => {
                try {
                    this.props.onRead(res.data.data.count)
                } catch (e) {
                    console.error(e);
                }
            })
            .catch((error) => {
                this.props.onLoader('no');
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    };

    onNewMessage = event => {
        event.preventDefault();
        event.stopPropagation();
        this.addThreads()
    }

    onFilesChange = (files) => {
        this.setState({
            files
        }, () => {
            let size = 0;
            this.state.files.map((file) => {
                size += file.size
            })
            this.setState({ size })
        })

    }
    onFilesError = (error, file) => {
        console.error('error code ' + error.code + ': ' + error.message)
    }
    openModalNewMessage() {
        // clear attachments
        this.setState({
            files: []
        }, () => {
            this.open('modalAddNewMessage')
        });
    }
    render() {
        const { filtered, pages, files, size, message, topic, search, activeModal, showFormValue, showFormSuccess, showFormError, show } = this.state;
        const { labels } = this.props;

        return (
            <DocumentTitle title={labels['MESSAGES_PAGE_TITLE'] + ' - IndyGo App'}>
                <div aria-label="Messages page" role="Messages page" id="messages-page">
                    <h1 className="is-size-2 has-text-weight-bold pad-bottom-md pad-top-xl">
                        {labels['MESSAGES_TITLE']}
                    </h1>
                    <Columns>
                        <Columns.Column size={4}>
                            <Field>
                                <Label className="is-sr-only">Search</Label>
                                <Control iconRight={true}>
                                    <Input className="is-search" onChange={this.onChange} value={search} name="search" type="text" placeholder={labels['MESSAGES_PLACEHOLDER']} />
                                    <Icon align="right">
                                        <FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.5em' }} className="has-text-black" />
                                    </Icon>
                                </Control>
                            </Field>
                        </Columns.Column>
                        <Columns.Column size={3}>
                            <Button
                                tabIndex="0"
                                size="small"
                                color="info"
                                onClick={() => this.openModalNewMessage()}
                                style={{ minWidth: '210px' }}
                                title={labels["BUTTON_NEW_MESSAGE"]}
                            >
                                <span className="push-right-md">{labels["BUTTON_NEW_MESSAGE"]}</span>
                                <Icon size="large" style={{ width: 26, marginBottom: '3px' }}>
                                    <img src="/assets/icons/shape.svg" alt="" />
                                </Icon>
                            </Button>
                        </Columns.Column>
                        <Columns.Column size={3}>
                            <Pagination
                                pageSize={pages.pageSize}
                                currentPage={pages.currentPage}
                                totalPages={pages.totalPages}
                                totalItems={pages.totalItems}
                                onSetPage={page => this.onSetPage(page)}
                                items={filtered}
                                {...this.props}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column size={10}>
                            <div className="list push-top-md">
                                {filtered.map((data) =>
                                    <div key={data.id} className={`list-item ${data.is_read ? '' : ' has-background-white'}`}>
                                        <Link to={'/threads/' + data['id']} >
                                            <Columns>
                                                <Columns.Column>
                                                    <p><span className={`has-text-primary has-text-weight-bold ${data.is_read ? '' : 'is-event'}`} >{data['title']} ({data['unread_replies']})</span></p>
                                                    <p className="is-size-8 has-text-grey-dark">{(data['body']) ? data['body'] : ''}</p>
                                                </Columns.Column>
                                                <Columns.Column narrow={true} style={{ width: '135px' }}>
                                                    <p className="has-text-grey-dark">
                                                        {(data['created_at']) ? moment.utc(data['created_at']).local().format('MMM D, YYYY') : ''}
                                                    </p>
                                                </Columns.Column>
                                            </Columns>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </Columns.Column>
                    </Columns>

                    <Modal showClose={false} show={show} onClose={this.close}>
                        <Modal.Content>
                            <Modal.Card>
                                <Modal.Card.Head onClose={this.close}>
                                </Modal.Card.Head>
                                <Modal.Card.Body className={`${showFormValue ? '' : 'centered'}`} >

                                    {/* Modal Add Message */}
                                    <ModalAddNewMessage
                                        activeModal={activeModal}
                                        showFormValue={showFormValue}
                                        showFormSuccess={showFormSuccess}
                                        showFormError={showFormError}
                                        onChange={this.onChange}
                                        close={this.close}
                                        setStep={this.setStep}
                                        onSubmit={this.onNewMessage}
                                        files={files}
                                        topic={topic}
                                        size={size}
                                        onFilesChange={this.onFilesChange}
                                        onFilesError={this.onFilesError}
                                        message={message}
                                        {...this.props}
                                    ></ModalAddNewMessage>
                                </Modal.Card.Body>
                            </Modal.Card>
                        </Modal.Content>
                    </Modal>
                </div>
            </DocumentTitle>
        )
    }
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels
    }),
    dispatch => ({
        onLoader: (status) => {
            dispatch({ type: 'SET_STATUS', payload: status })
        },
        onRead: (status) => {
            dispatch({ type: 'SET_UNREAD', payload: status })
        }
    })
)(Messages)