import React from "react";
import "./card.scss";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Card extends React.Component {

  getBrandIcon = () => {
    const { brand } = this.props;
    switch (brand) {
      case "MASTER":
      case "VISA":
      case "AMEX":
        return (
          <img
            src={`/assets/img/${brand}.png`}
            alt=""
            className="push-left-sm"
            style={{ height: 14 }}
            aria-hidden="true"
          />
        )
      default: 
        return <FontAwesomeIcon icon={faCreditCard} className="has-text-white push-left-sm"/>
    }
  }

  convertedDate = () => {
    const { date } = this.props;
    const month = date.substr(5, 2);
    const year = date.substr(2, 2);
    return month + "/" + year;
  }

  render() {
    const { label, number, name, brand } = this.props;

    return (
      <div
        className="card__main"
        aria-label={`${brand} payment card ${number}. Valid thru: ${this.convertedDate()}`}
      >
        <div className="has-text-weight-bold">{label}</div>
        <div className="has-text-centered">
          <div className="is-size-8 push-bottom-sm">
            {`XXXX XXXX XXXX ${number}`}
          </div>
          <div className="is-size-9 opacity">Valid thru</div>
          <div className="is-size-8 pull-top-xs">{this.convertedDate()}</div>
        </div>
        <div className="is-flex space-between centered">
          <div className="is-size-8">{name}</div>
          {this.getBrandIcon()}
        </div>
      </div>
    )
  }
}

export default Card;