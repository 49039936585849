import React from 'react'
import {connect} from 'react-redux';
import {paymentService} from "../_services";

class PaymentStatus extends React.Component {
    state = {
        error: ''
    };
    closeMessage = event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            error: ''
        })
    }
    componentDidMount() {
        try{
            if(this.props.location.query.id){
                this.props.onLoader('yes');
                let body = {
                    checkoutId: this.props.location.query.id
                }
                paymentService.checkCardStatus(body)
                    .then(res => {
                        setTimeout(()=>{
                            this.props.router.push('/payment-options');
                        }, 1000)                  
                    })
                    .catch((error) => {
                        this.props.onLoader('no');
                        setTimeout(()=>{                   
                            this.setState({
                                error: 'An error occurred while adding a payment card.'
                            });
                            setTimeout(()=>{
                                this.setState({
                                    error: ''
                                });
                                this.props.router.push('/payment-options');
                            },3000);
                        }, 1000)
                        // redirect to login page
                        if(error.response.status == 401) {
                            this.props.router.push('/login');
                        }
                    })
            }else{
                // this.getPaymentCards();
                this.props.router.push('/payment-options');
            }
        }catch(e){
            console.error(e);
        }
    }
    render() {
        const {error} = this.state;
        return (
            <>            
                <div className="loader-root"><img src="/assets/img/brand/logo_white.svg" alt="" /></div>
                <article className={`message is-danger message-custom ${!!error ? "shw" : ""}`}>
                    <div className="message-body">
                        <button className="delete" aria-label="delete" onClick={this.closeMessage}></button>
                       {error}
                    </div>
                </article>
            </>
        )
    }
}

export default connect(
    state => ({
        loader: state.loader
    }),
    dispatch => ({
        onLoader: (status) => {
            dispatch({type: 'SET_STATUS', payload: status})
        }
    })
)(PaymentStatus)