import React from 'react';
import { connect } from 'react-redux';
import { Site, Header, Content, Footer} from '../layout';
import { ToastContainer  } from 'react-toastify';

class Main extends React.Component {
  render() {
    const { loader, children } = this.props;

    return (
      <Site>
        <Header /> 
        <Content>
          {loader === "yes" && <div className="line-loader" />}
          {children || "Welcome to your Inbox"}
        </Content>
        <ToastContainer autoClose={5000} newestOnTop closeOnClick/>  
        <Footer />
      </Site>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    loader: state.loader
  }),
  dispatch => ({
  })
)(Main)