import React, { useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { Button } from '../../../components/lib/components/button';
import { Icon } from '../../../components/lib/components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle, faPlus } from "@fortawesome/pro-light-svg-icons";
import NumberFormat from 'react-number-format';
import { Field, Checkbox } from '../../../components/lib/components/form';
import { Dropdown } from '../../../components/lib/components/dropdown';
import ModalView from '../../../components/lib/components/view/ModalView';
import { setFocusElement, focusSectionHandler, keyPressSectionHandler } from '../../../_helpers';
import numWords from 'num-words';

const ModalAddValue = ({
    activeModal,
    showFormValue,
    showFormSuccess,
    showFormError,
    labels,
    data,
    onChange,
    close,
    setStep,
    openAddCard,
    paymentCardsList,
    addValue,
    minValue,
    maxValue,
    walletId,
    onDecrease,
    onIncrease,
    onChangeWalletId,
    onSubmit,
    termsAcceptedAddValue,
    loader
}) => {
    const _sectionValue = useRef();
    useEffect(() => {
        if (_sectionValue.current && !showFormError && !showFormSuccess && showFormValue) {
            _sectionValue.current.addEventListener("focus", (event) => focusSectionHandler(event, _sectionValue));
            _sectionValue.current.addEventListener("keydown", (event) => keyPressSectionHandler(event, _sectionValue, '#payment'));
        }
        return () => {
            if (_sectionValue.current) {
                _sectionValue.current.removeEventListener("focus", (event) => focusSectionHandler(event, _sectionValue));
                _sectionValue.current.removeEventListener("keydown", (event) => keyPressSectionHandler(event, _sectionValue, '#payment'));
            }
        }
    }, [showFormValue, showFormError, showFormSuccess])

    return (
        <>
            <ModalView
                name="modalAddValue"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                align="left"
                head={labels["BUTTON_ADD_VALUE"]}
                messages={[labels["FARE_CARD_ADD_VALUE_TEXT"]]}
                containerLabel={labels["BUTTON_ADD_VALUE"]}
                focusOptions={{
                    initialFocus: `#modalAddValueShowFormValue_part`,
                }}
            >
                <div
                    className="balance-card balance-card--medium push-top-xl pad-bottom-xl push-bottom-md line"
                    tabIndex="0"
                    ref={_sectionValue}
                >
                    <p
                        aria-label={labels['FARE_CARD_RELOAD'] + ' ' + numWords(addValue) + ' dollar'}
                        className="has-text-grey-dark is-size-4 has-text-weight-semibold">{labels['FARE_CARD_RELOAD']}</p>
                    <p className="is-flex space-between is-size-10 pull-top-lg has-text-weight-light">
                        <NumberFormat value={addValue}
                            tabIndex="-1"
                            aria-label={labels['FARE_CARD_RELOAD'] + ' ' + numWords(addValue) + ' dollar'}
                            displayType={'text'} decimalSeparator={"."} fixedDecimalScale={true} decimalScale={2} prefix={'$'} />
                        <span className="balance-card__buttons push-top-md">
                            <button
                                type="button"
                                tabIndex="-1"
                                onClick={() => onDecrease('addValue')}
                                title={labels["FARE_CARD_VALUE_DECREASE"]}
                                className={`is-borderless has-background-none ${addValue <= minValue ? "has-text-grey" : "has-text-success"}`}
                                style={{ padding: 5, lineHeight: 1 }}
                            // disabled={transferAmount <= minTransfer}
                            >
                                <Icon size="medium">
                                    <FontAwesomeIcon className="is-size-3" icon={faMinusCircle} />
                                </Icon>
                            </button>
                            <button
                                type="button"
                                tabIndex="-1"
                                onClick={() => onIncrease('addValue')}
                                title={labels["FARE_CARD_VALUE_INCREASE"]}
                                className={`is-borderless has-background-none ${addValue >= maxValue ? "has-text-grey" : "has-text-success"}`}
                                style={{ padding: 5, lineHeight: 1 }}
                            // disabled={transferAmount >= maxTransfer || transferAmount >= fromCard.balance_int / 100}
                            >
                                <Icon size="medium">
                                    <FontAwesomeIcon className="is-size-3" icon={faPlusCircle} />
                                </Icon>
                            </button>
                            {/* <Icon
                                role="button"
                                size="large"
                                tabIndex="-1"
                                aria-label={labels['FARE_CARD_VALUE_DECREASE']}
                                style={{ width: 28 }}
                                onClick={() => onDecrease('addValue')}
                                onKeyPress={() => onDecrease('addValue')}>
                                <FontAwesomeIcon className={`is-size-3 ${addValue <= minValue ? 'has-text-grey' : 'has-text-success'}`} icon={faMinusCircle} />
                            </Icon>
                            <Icon
                                role="button"
                                size="large"
                                tabIndex="-1"
                                aria-label={labels['FARE_CARD_VALUE_INCREASE']}
                                style={{ width: 28 }}
                                onClick={() => onIncrease('addValue')}
                                onKeyPress={() => onIncrease('addValue')}>
                                <FontAwesomeIcon className={`is-size-3 ${addValue >= maxValue ? 'has-text-grey' : 'has-text-success'}`} icon={faPlusCircle} />
                            </Icon> */}
                        </span>
                    </p>
                </div>

                {paymentCardsList.length === 0 ? (
                    <div className="push-bottom-lg line" aria-describedby="no_payment_title">
                        <p className="has-text-grey-dark push-bottom-sm">{labels["FARE_CARD_NO_PAYMENTS"]}</p>
                        <button
                            id="payment"
                            type="button"
                            className="is-size-7 has-text-primary is-clickable pad-sm is-borderless has-background-none"
                            onClick={() => openAddCard("modalAddCard")}
                        >
                            <Icon size="small" className="icon-add-new-card push-right-sm">
                                <FontAwesomeIcon icon={faPlus} className="is-size-6" />
                            </Icon>
                            {labels["BUTTON_ADD_NEW_CARD"]}
                        </button>
                    </div>
                ) :
                    <div className="line">
                        <p id="payment_modal_label" className="has-text-grey-dark push-bottom-md">
                            {labels["CITATIONS_MODAL_PAYMENT"]}
                        </p>
                        <Dropdown
                            id="payment"
                            value={walletId}
                            onChange={onChangeWalletId}
                            className="pad-bottom-md"
                            label={labels["OPTIONS_DROPDOWN_LABEL"]}
                            labelledBy="payment_modal_label"
                        >
                            {paymentCardsList.map((data, k) => (
                                <Dropdown.Item
                                    id={`payment_${k + 1}`}
                                    key={k}
                                    value={data.id}
                                    aria-label={`${data.card_brand}: ${data.last_4_digits}. ${data.card_label ? data.card_label : ""}`}
                                >
                                    <div className="dropdown-item__box">
                                        <div className="dropdown-item__box__image">
                                            <Icon style={{ width: 20, height: 22 }}>
                                                <img
                                                    src={`/assets/img/brand/${data.card_brand.toUpperCase()}.png`}
                                                    alt=""
                                                    aria-hidden="true"
                                                />
                                            </Icon>
                                        </div>
                                        <div className="dropdown-item__box__description">
                                            <p>XXXX-XXXX-XXXX-{data.last_4_digits}</p>
                                            <p>{data.card_label}</p>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            ))}
                            <button
                                type="button"
                                className="item-add-card"
                                role={labels["BUTTON_ADD_NEW_CARD"]}
                                onClick={() => openAddCard("modalAddCard")}
                            >
                                <Icon size="small" className="push-right-md">
                                    <FontAwesomeIcon className="is-size-6" icon={faPlus} />
                                </Icon>
                                <span>{labels["BUTTON_ADD_NEW_CARD"]}</span>
                            </button>
                        </Dropdown>
                    </div>
                }

                <Field className="push-top-md">
                    <label className="checkbox">
                        <Checkbox
                            name="termsAcceptedAddValue"
                            checked={termsAcceptedAddValue}
                            onChange={onChange}
                        />
                        <span className="is-size-8 has-text-grey-dark">
                            {labels["GDPR_CHECKBOX_AGREE"]} <a tabIndex="-1" className="has-text-info" target="_blank" href="https://www.indygo.net/mykey-terms-and-conditions/">{labels["GDPR_CHECKBOX_TERMS"]}</a> {labels["GDPR_CHECKBOX_AND"]} <a tabIndex="-1" className="has-text-info" target="_blank" href="https://www.indygo.net/mykey-privacy-policy/">{labels["GDPR_CHECKBOX_PRIVACY"]}.</a></span>
                    </label>
                </Field>
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        disabled={!(termsAcceptedAddValue === true && walletId) || loader === 'yes'}
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={onSubmit}
                        loading={loader === 'yes'}
                        title={labels["BUTTON_RELOAD_FARE_CARD"]}
                    >
                        {labels["BUTTON_RELOAD_FARE_CARD"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        disabled={loader === 'yes'}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
            <ModalView
                name="modalAddValue"
                activeModal={activeModal}
                active={showFormSuccess}
                icon="success"
                viewType="ShowFormSuccess"
                head={labels["MODAL_TITLE_RELOAD_SUCCESSFUL"]}
                messages={[labels["FARE_CARD_RELOAD_SUCCESS_TEXT"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormSuccess_part`);
                    },
                    onDeactivate: () => {
                        setTimeout(() => setFocusElement(`#btnValue`))
                    }
                }}
            >
                <p
                    className="has-text-grey-dark pull-top-lg">{labels['FARE_CARD_NEW_BALANCE']}: <span className="has-text-weight-light is-size-4 has-text-primary  push-bottom-xs">{data.balance.formatted}</span></p>
                <Button
                    id={`${activeModal}ShowFormSuccess_button_first`}
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={close}
                    title={labels["BUTTON_CONTINUE"]}
                >
                    {labels['BUTTON_CONTINUE']}
                </Button>
            </ModalView>
            <ModalView
                name="modalAddValue"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels["MODAL_TITLE_RELOAD_ERROR"]}
                messages={[labels["FARE_CARD_RELOAD_ERROR_TEXT_1"], labels["FARE_CARD_RELOAD_ERROR_TEXT_2"], labels["FARE_CARD_RELOAD_ERROR_TEXT_3"], labels["FARE_CARD_RELOAD_ERROR_TEXT_4"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormError_part`);
                    },
                    onDeactivate: () => {
                        setTimeout(() => setFocusElement(`#btnValue`))
                    }
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={() => setStep('showFormValue')}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels["BUTTON_TRY_AGAIN"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels
      }),
      dispatch => ({})
)(ModalAddValue)