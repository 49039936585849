import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Button } from "../../components/lib/components/button";
import { Icon } from "../../components/lib/components/icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";
import { Field, Control, Label, Input } from "../../components/lib/components/form";
import { Columns } from "../../components/lib/components/columns";
import ModalView from "../../components/lib/components/view/ModalView";
import { setFocusElement } from "../../_helpers";

const ModalAddFareCard = ({
  activeModal,
  showFormValue,
  showFormSuccess,
  showFormError,
  labels,
  loader,
  farecardnumber,
  errFareCardNumber,
  errSecurityCode,
  securitycode,
  cardname,
  onSignIn,
  onNumberFormatChange,
  onChange,
  close,
  setStep
}) => {
  const isEnabled = farecardnumber.length >= 16 && securitycode.length === 4 && !securitycode.match(/[^0-9\.]/g);

  return (
    <>
      <ModalView
        name="modalAddFareCard"
        activeModal={activeModal}
        active={showFormValue}
        viewType="ShowFormValue"
        align="left"
        head={labels["FARE_CARDS_ADD_TEXT"]}
        messages={[labels["FARE_CARDS_ADD_DESC"]]}
        focusOptions={{
          setReturnFocus: "#btnAddFareCard",
          onActivate: () => setFocusElement(`#farecardnumber`),
          onDeactivate: () => {
            //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
            setTimeout(() => setFocusElement(`#btnAddFareCard`))
          }
        }}
      >
        <Columns>
          <Columns.Column>
            <img
              className="shadow"
              src="/assets/img/MyKey_Card_2.png"
              alt=""
              aria-hidden="true"
              width="186"
            />
          </Columns.Column>
          <Columns.Column>
            <img
              className="shadow"
              src="/assets/img/MyKey_Card_2.1.png"
              alt=""
              aria-hidden="true"
              width="186"
            />
          </Columns.Column>
        </Columns>
        <form autoComplete="off" className="pad-top-md pad-bottom-md" onSubmit={onSignIn}>
          
          <Field>
            <Label
              htmlFor="farecardnumber"
              className={classnames({
                "has-text-grey-light": farecardnumber && !errFareCardNumber,
                "has-text-danger": errFareCardNumber
              })}
            >
              {labels["FARE_CARDS_NUMBER_TEXT"]}
            </Label>
            <Control className="has-icons-right-second" iconRight={true}>
              <NumberFormat
                id="farecardnumber"
                name="farecardnumber"
                autoComplete="off"
                format="#### #### #### ####"
                className={classnames(
                  "input",
                  { "is-danger": errFareCardNumber }
                )}
                onValueChange={values => onNumberFormatChange(values)}
                style={{ backgroundColor: "#F7F7F7" }}
                value={farecardnumber}
                placeholder={labels["FARE_CARDS_NUMBER_PLACEHOLDER"]}
              />
              {errFareCardNumber && (
                <>
                  <p className="help is-danger has-text-right">{labels["FARE_CARDS_NUMBER_ERROR_INCORECT"]}</p>
                  <Icon align="right">
                    <FontAwesomeIcon icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                  </Icon>
                </>
              )}
            </Control>
          </Field>

          <Field>
            <Label
              htmlFor="securitycode"
              className={classnames({
                "has-text-grey-light": securitycode && !errSecurityCode,
                "has-text-danger": errSecurityCode
              })}
            >
              {labels["FARE_CARDS_SECURITY_CODE_TEXT"]}
            </Label>
            <Control className="has-icons-right-second" iconRight={true}>
              <Input
                id="securitycode"
                name="securitycode"
                maxLength="4"
                autoComplete="off"
                className={classnames(
                  { "is-danger": errSecurityCode }
                )}
                onChange={onChange}
                style={{ backgroundColor: "#F7F7F7" }}
                value={securitycode}
                placeholder={labels["FARE_CARDS_SECURITY_CODE_PLACEHOLDER"]}
              />
              {errSecurityCode && (
                <>
                  <p className="help is-danger has-text-right">{labels["FARE_CARDS_SECURITY_CODE_ERROR_INCORECT"]}</p>
                  <Icon align="right">
                    <FontAwesomeIcon icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                  </Icon>
                </>
              )}
            </Control>
          </Field>

          <Field>
            <Label
              htmlFor="cardname"
              className={classnames({
                "has-text-grey-light": cardname
              })}
            >
              {labels["FARE_CARDS_CARD_NAME_TEXT"]}
            </Label>
            <Control>
              <Input
                id="cardname"
                name="cardname"
                autoComplete="off"
                onChange={onChange}
                style={{ backgroundColor: "#F7F7F7" }}
                value={cardname}
                placeholder={labels["FARE_CARDS_CARD_NAME_PLACEHOLDER"]}
              />
            </Control>
          </Field>

          <Button.Group className="push-top-xl space-between">
            <Button
              disabled={!isEnabled}
              size="small"
              color="info"
              style={{ minWidth: 190, marginRight: 5 }}
              title={labels["BUTTON_ADD_THIS_FARE_CARD"]}
              loading={loader === "yes"}
            >
              {labels["BUTTON_ADD_THIS_FARE_CARD"]}
            </Button>
            <Button
              outlined={true}
              size="small"
              color="info"
              style={{ minWidth: 190 }}
              onClick={close}
              title={labels["BUTTON_CANCEL"]}
            >
              {labels["BUTTON_CANCEL"]}
            </Button>
          </Button.Group>

        </form>
      </ModalView>
      <ModalView
        name="modalAddFareCard"
        activeModal={activeModal}
        active={showFormSuccess}
        icon="success"
        viewType="ShowFormSuccess"
        head={labels['FARE_CARDS_ADD_SUCCESS_TITLE']}
        messages={[labels["FARE_CARDS_ADD_SUCCESS_TEXT"]]}
        focusOptions={{
          initialFocus: `#${activeModal}ShowFormError_part`,
          onActivate: () => {
            // setFocusElement(`#${activeModal}ShowFormError_button_first`);
          },
          onDeactivate: () => {
            //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
            setFocusElement(`#btnAddFareCard`)
          }
        }}
      >
        <Button
          className="push-top-xl"
          size="small"
          color="info"
          onClick={close}
          title={labels["BUTTON_CONTINUE"]}
        >
          {labels["BUTTON_CONTINUE"]}
        </Button>
      </ModalView>
      <ModalView
        name="modalAddFareCard"
        activeModal={activeModal}
        active={showFormError}
        viewType="ShowFormError"
        icon="danger"
        head={labels["MODAL_TITLE_SORRY"]}
        messages={[labels["FARE_CARDS_ADD_SUCCESS_TEXT_1"], labels["FARE_CARDS_ADD_SUCCESS_TEXT_2"], labels["FARE_CARDS_ADD_SUCCESS_TEXT_3"]]}
        focusOptions={{
          initialFocus: `#${activeModal}ShowFormError_part`,
          setReturnFocus: `#btnAddFareCard`,
          onDeactivate: () => {
            //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
            setTimeout(() => setFocusElement(`#btnAddFareCard`))
          }
        }}
      >
        <Button.Group className="push-top-xl space-between">
          <Button
            size="small"
            color="info"
            style={{ minWidth: 190, marginRight: 5 }}
            onClick={() => setStep("showFormValue")}
            title={labels["BUTTON_TRY_AGAIN"]}
          >
            {labels["BUTTON_TRY_AGAIN"]}
          </Button>
          <Button
            outlined={true}
            size="small"
            color="info"
            style={{ minWidth: 190 }}
            onClick={close}
            title={labels["BUTTON_CANCEL"]}
          >
            {labels["BUTTON_CANCEL"]}
          </Button>
        </Button.Group>
      </ModalView>
    </>
  )
}

export default connect(
  state => ({
    loader: state.loader,
    labels: state.localization.labels
  }),
  dispatch => ({})
)(ModalAddFareCard)