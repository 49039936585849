const initialState = {
  defaultLang: "en-US",
  supportedLangs: {},
  external: {
    labels: {},
    tts: {}
  },
  labels: {},
  tts: {}
};

export default function localization(state = initialState, action) {
  if (action.type === 'ADD_LOCALIZATION') {
    return {
      ...state,
      supportedLangs: action.payload
    }
  }
  else if (action.type === 'SET_LOCALIZATION') {
    return {
      ...state,
      labels: action.payload.LABELS,
      tts: action.payload.TTS
    }
  }
  else if (action.type === 'SET_EXTERNAL_LOCALIZATION') {
    return {
      ...state,
      external: {
        labels: action.payload.LABELS,
        tts: action.payload.TTS
      }
    }
  }
  else if (action.type === 'SET_DEFAULT_LOCALIZATION') {
    return {
      ...state,
      defaultLang: action.payload || "en-US"
    }
  }
  else if (action.type === 'DELETE_LOCALIZATION') {
    return {}
  }
  return state;
}