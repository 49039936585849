import axios from 'axios';
import config from '../../config';

export const paymentService = {
    getPaymentCardsList,
    updatePaymentCard,
    deletePaymentCard,
    getPaymentFormData,
    checkCardStatus
};

function getPaymentCardsList() {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };
    return axios.get(`${config.API.URL}/api/v1/patron/payment-cards`, requestOptions)
}
function updatePaymentCard(id, body) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };
    return axios.put(`${config.API.URL}/api/v1/patron/payment-cards/${id}`, body, requestOptions)
}
function getPaymentFormData() {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };
    let list = JSON.parse(localStorage.getItem("manifest"));
    let credentialsArtifactlist = list && list.artifacts.filter((item) => item.artifact_type_id === 9).reverse() || [];
    return axios.post(`${config.API.URL}/api/v1/patron/payment-form-data`, credentialsArtifactlist[0], requestOptions)
}
function checkCardStatus(body) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };
    let list = JSON.parse(localStorage.getItem("manifest"));
    let credentialsArtifactlist = list && list.artifacts.filter((item) => item.artifact_type_id === 9).reverse() || [];

    return axios.post(`${config.API.URL}/api/v1/patron/check-card-status`, {...body, ...credentialsArtifactlist[0]}, requestOptions)
}
function deletePaymentCard(id) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };
    return axios.delete(`${config.API.URL}/api/v1/patron/payment-cards/${id}`, requestOptions)
}