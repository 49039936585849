import React from 'react'
import { connect } from "react-redux";
import { Button } from '../../../components/lib/components/button';
import { Field, Label, Control, Input } from '../../../components/lib/components/form';
import ModalView from '../../../components/lib/components/view/ModalView';
import { setFocusElement } from '../../../_helpers';

const ModalEditLabel = ({
    activeModal,
    showFormValue,
    labels,
    onChange,
    close,
    onSubmit,
    cardName,
    loader
}) => {
    return (
        <>
            <ModalView
                name="modalEditLabel"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                align="left"
                head={labels['WELCOME_FARE_CARD_NAME_TEXT']}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#cardName`)
                    }
                }}
            >
                <Field>
                    <Label className={`${cardName === "" ? "" : "has-text-grey-light"}`}>{labels['FARE_CARDS_CARD_NAME_TEXT']}</Label>
                    <Control>
                        <Input id="cardName" aria-label={labels['FARE_CARDS_CARD_NAME_TEXT']} autoComplete="off" style={{ backgroundColor: '#F7F7F7', boxShadow: 'none', border: 0 }} onChange={onChange} value={cardName} name="cardName" type="text" />
                    </Control>
                </Field>
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        disabled={!(cardName !== "") || loader === 'yes'}
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 0 }}
                        onClick={onSubmit}
                        loading={loader === 'yes'}
                        title={labels["BUTTON_SAVE"]}
                    >
                        {labels["BUTTON_SAVE"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        disabled={loader === 'yes'}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
            {/* TODO: awaiting feedback from customer */}
            {/* <ModalView
                name="modalEditLabel"
                activeModal={activeModal}
                active={showFormSuccess}
                icon="success"
                viewType="ShowFormSuccess"
                head={labels['MODAL_TITLE_RELOAD_SUCCESSFUL']}
                messages={[labels["FARE_CARD_RELOAD_SUCCESS_TEXT"]]}
                containerLabel={labels['MODAL_TITLE_RELOAD_SUCCESSFUL']}
            >
                <p
                    tabIndex="0"
                    className="has-text-grey-dark pull-top-lg">{labels['FARE_CARD_NEW_BALANCE']}: <span className="has-text-weight-light is-size-4 has-text-primary  push-bottom-xs">{data.balance.formatted}</span></p>
                <Button
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={close}
                    title={labels["BUTTON_CONTINUE"]}
                >
                    {labels['BUTTON_CONTINUE']}
                </Button>
            </ModalView> */}
            {/* <ModalView
                name="modalEditLabel"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels['MODAL_TITLE_RELOAD_ERROR']}
                messages={[labels["FARE_CARD_RELOAD_ERROR_TEXT_1"], labels["FARE_CARD_RELOAD_ERROR_TEXT_2"], labels["FARE_CARD_RELOAD_ERROR_TEXT_3"], labels["FARE_CARD_RELOAD_ERROR_TEXT_4"]]}
                containerLabel={labels['MODAL_TITLE_RELOAD_ERROR']}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={() => setStep('showFormValue')}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels['BUTTON_TRY_AGAIN']}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels['BUTTON_CANCEL']}
                    </Button>
                </Button.Group>
            </ModalView> */}
        </>
    )
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels
      }),
      dispatch => ({})
)(ModalEditLabel)