import React from 'react'
import { connect } from "react-redux";
import { Button } from '../components/lib/components/button';
import ModalView from '../components/lib/components/view/ModalView';
import { setFocusElement } from '../_helpers';

const ModalLogOut = ({
    activeModal,
    showFormValue,
    close,
    router,
    labels,
    loader
}) => {
    const logout = () => {
        close();
        setTimeout(() => {
            localStorage.removeItem('lastUrl');
            router.push('/login');
        })
    }
    return (
        <>
            <ModalView
                name="modalLogOut"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                icon="danger"
                head={labels["MODAL_TITLE_CONFIRMATION_REQUIRED"]}
                messages={[labels["PROFILE_LOG_OUT_MODAL_TEXT"]]}
                focusOptions={{
                    // initialFocus: `#${activeModal}ShowFormError_button_second`
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormError_button_second`);
                    }
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        disabled={loader === "yes"}
                        loading={loader === "yes"}
                        onClick={logout}
                    >
                        {labels["BUTTON_YES"]}
                    </Button>
                    <Button
                        id={`${activeModal}ShowFormError_button_second`}
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        labels: state.localization.labels,
        loader: state.loader
    }),
    dispatch => ({})
)(ModalLogOut)