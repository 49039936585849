import React, { useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { Button } from '../../../components/lib/components/button';
import { Icon } from '../../../components/lib/components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle, faPlus } from "@fortawesome/pro-light-svg-icons";
import NumberFormat from 'react-number-format';
import { Field, Checkbox } from '../../../components/lib/components/form';
import ActivateButton from "../../../components/toggler";
import { Dropdown } from '../../../components/lib/components/dropdown';
import ModalView from '../../../components/lib/components/view/ModalView';
import { setFocusElement, focusSectionHandler, keyPressSectionHandler } from '../../../_helpers';
import numWords from 'num-words';

const ModalAutoReload = ({
    activeModal,
    showFormValue,
    showFormSuccess,
    showFormError,
    showFormLocked,
    labels,
    tts,
    onChange,
    close,
    minValue,
    maxValue,
    onDecrease,
    onIncrease,
    setStep,
    isActivateAutoReload,
    isTermsAccepted,
    openAddCard,
    paymentCardsList,
    amountReload,
    balance,
    walletId,
    onChangeWalletId,
    onPress,
    onSubmit,
    loader,
    step,
    maxAmount,
    maxThreshold
}) => {
    const _sectionReload = useRef();
    const _sectionBalance = useRef();

    useEffect(() => {
        if (_sectionReload.current && _sectionBalance.current && !showFormError && !showFormSuccess && showFormValue) {
            _sectionReload.current.addEventListener("focus", (event) => focusSectionHandler(event, _sectionReload));
            _sectionReload.current.addEventListener("keydown", (event) => keyPressSectionHandler(event, _sectionReload, _sectionBalance));
            _sectionBalance.current.addEventListener("focus", (event) => focusSectionHandler(event, _sectionBalance));
            _sectionBalance.current.addEventListener("keydown", (event) => keyPressSectionHandler(event, _sectionBalance, '#payment'));
        }
        return () => {
            if (_sectionReload.current && _sectionBalance.current) {
                _sectionReload.current.removeEventListener("focus", (event) => focusSectionHandler(event, _sectionReload));
                _sectionReload.current.removeEventListener("keydown", (event) => keyPressSectionHandler(event, _sectionReload, _sectionBalance));
                _sectionBalance.current.removeEventListener("focus", (event) => focusSectionHandler(event, _sectionBalance));
                _sectionBalance.current.removeEventListener("keydown", (event) => keyPressSectionHandler(event, _sectionBalance, '#payment'));
            }
        }
    }, [showFormValue, showFormSuccess, showFormError])

    return (
        <>
            <ModalView
                name="modalAutoReload"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                align="left"
                head={labels['FARE_CARD_AUTO_RELOAD']}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormValue_part_label`);
                    }
                }}
            >
                <p
                    tabIndex="-1"
                    id={`${activeModal}ShowFormValue_part_label`}
                    className={`has-text-grey-dark push-bottom-lg`}
                    aria-label={tts["FARE_CARD_RELOAD_TEXT_ARIA"]}
                >{labels["FARE_CARD_RELOAD_TEXT"]}</p>
                <div className="column is-7 pad-left-none">
                    <div className="level push-bottom-xs">
                        <div className="level-left has-text-grey-dark has-text-weight-semibold pad-right-md">{labels['FARE_CARD_ENABLE_RELOAD']}</div>
                        <div className="level-right">
                            <ActivateButton
                                id="switch-reload"
                                active={isActivateAutoReload}
                                onPress={onPress}
                                labelEnabled={tts["FARE_CARD_RELOAD_SWITCH_ON"]}
                                labelDisabled={tts["FARE_CARD_RELOAD_SWITCH_OFF"]}
                                />
                        </div>
                    </div>
                </div>
                <div
                    className="balance-card push-top-md pad-bottom-xl push-bottom-md line"
                    tabIndex="0"
                    ref={_sectionReload}
                >
                    <p className="has-text-grey-dark has-text-weight-semibold">{labels['FARE_CARD_AMOUNT_RELOAD']}</p>
                    <p className="is-flex space-between is-size-2 pull-top-sm has-text-weight-light">
                        <NumberFormat value={amountReload}
                            tabIndex="-1"
                            aria-label={labels["FARE_CARD_RELOAD"] + ' ' + numWords(amountReload) + ' dollar'}
                            displayType={'text'} decimalSeparator={"."} fixedDecimalScale={true} decimalScale={2} prefix={'$'} />
                        <span className="balance-card__buttons push-top-sm push-right-xxl">
                            <button
                                type="button"
                                tabIndex="-1"
                                onClick={() => onDecrease('amountReload')}
                                title={labels["FARE_CARD_RELOAD_DECREASE"]}
                                className={`is-borderless has-background-none ${(amountReload <= minValue || amountReload <= (balance + step)) ? "has-text-grey" : "has-text-success"}`}
                                style={{ padding: 5, lineHeight: 1 }}
                            // disabled={transferAmount <= minTransfer}
                            >
                                <Icon size="medium">
                                    <FontAwesomeIcon className="is-size-3" icon={faMinusCircle} />
                                </Icon>
                            </button>
                            <button
                                type="button"
                                tabIndex="-1"
                                onClick={() => onIncrease('amountReload')}
                                title={labels["FARE_CARD_RELOAD_INCREASE"]}
                                className={`is-borderless has-background-none ${amountReload >= maxAmount ? "has-text-grey" : "has-text-success"}`}
                                style={{ padding: 5, lineHeight: 1 }}
                            // disabled={transferAmount >= maxTransfer || transferAmount >= fromCard.balance_int / 100}
                            >
                                <Icon size="medium">
                                    <FontAwesomeIcon className="is-size-3" icon={faPlusCircle} />
                                </Icon>
                            </button>

                            {/* <Icon role="button" size="large" tabIndex="-1" aria-label={labels['FARE_CARD_RELOAD_DECREASE']} style={{ width: 28 }} onClick={() => onDecrease('amountReload')} onKeyPress={() => onDecrease('amountReload')}>
                                <FontAwesomeIcon className={`is-size-3 ${(amountReload <= minValue || amountReload <= (balance + step)) ? 'has-text-grey' : 'has-text-success'}`} icon={faMinusCircle} />
                            </Icon>
                            <Icon role="button" size="large" tabIndex="-1" aria-label={labels['FARE_CARD_RELOAD_INCREASE']} style={{ width: 28 }} onClick={() => onIncrease('amountReload')} onKeyPress={() => onIncrease('amountReload')}>
                                <FontAwesomeIcon className={`is-size-3 ${amountReload >= maxValue ? 'has-text-grey' : 'has-text-success'}`} icon={faPlusCircle} />
                            </Icon> */}
                        </span>
                    </p>
                </div>
                <div className="balance-card  push-top-md pad-bottom-xl push-bottom-lg line"
                    tabIndex="0"
                    ref={_sectionBalance}>
                    <p className="has-text-grey-dark has-text-weight-semibold">{labels['FARE_CARD_BALANCE_BELOW_TEXT']}</p>
                    <p className="is-flex space-between is-size-2 pull-top-sm has-text-weight-light">
                        <NumberFormat
                            tabIndex="-1"
                            value={balance}
                            aria-label={tts["FARE_CARD_BALANCE_BELOW_ARIA"] + ' ' + numWords(balance) + ' dollar'}
                            displayType={'text'} decimalSeparator={"."} fixedDecimalScale={true} decimalScale={2} prefix={'$'} />
                        <span className="balance-card__buttons push-top-sm push-right-xxl">
                            <button
                                type="button"
                                tabIndex="-1"
                                onClick={() => onDecrease('balance')}
                                title={labels["FARE_CARD_THRESHOLD_DECREASE"]}
                                className={`is-borderless has-background-none ${balance <= minValue ? "has-text-grey" : "has-text-success"}`}
                                style={{ padding: 5, lineHeight: 1 }}
                            // disabled={transferAmount <= minTransfer}
                            >
                                <Icon size="medium">
                                    <FontAwesomeIcon className="is-size-3" icon={faMinusCircle} />
                                </Icon>
                            </button>
                            <button
                                type="button"
                                tabIndex="-1"
                                onClick={() => onIncrease('balance')}
                                title={labels["FARE_CARD_THRESHOLD_INCREASE"]}
                                className={`is-borderless has-background-none ${balance >= (maxThreshold - step) ? "has-text-grey" : "has-text-success"}`}
                                style={{ padding: 5, lineHeight: 1 }}
                            // disabled={transferAmount >= maxTransfer || transferAmount >= fromCard.balance_int / 100}
                            >
                                <Icon size="medium">
                                    <FontAwesomeIcon className="is-size-3" icon={faPlusCircle} />
                                </Icon>
                            </button>
                            {/* <Icon role="button" size="large" tabIndex="-1" aria-label={labels['FARE_CARD_THRESHOLD_DECREASE']} style={{ width: 28 }} onClick={() => onDecrease('balance')} onKeyPress={() => onDecrease('balance')}>
                                <FontAwesomeIcon className={`is-size-3 ${balance <= minValue ? 'has-text-grey' : 'has-text-success'}`} icon={faMinusCircle} />
                            </Icon>
                            <Icon role="button" size="large" tabIndex="-1" aria-label={labels['FARE_CARD_THRESHOLD_INCREASE']} style={{ width: 28 }} onClick={() => onIncrease('balance')} onKeyPress={() => onIncrease('balance')}>
                                <FontAwesomeIcon className={`is-size-3 ${balance >= maxValue ? 'has-text-grey' : 'has-text-success'}`} icon={faPlusCircle} />
                            </Icon> */}
                        </span>
                    </p>
                </div>

                {paymentCardsList.length === 0 ? (
                    <div className="push-bottom-lg line" aria-describedby="no_payment_title">
                        <p className="has-text-grey-dark push-bottom-sm">{labels["FARE_CARD_NO_PAYMENTS"]}</p>
                        <button
                            id="payment"
                            type="button"
                            className="is-size-7 has-text-primary is-clickable pad-sm is-borderless has-background-none push-bottom-sm"
                            onClick={() => openAddCard("modalAddCard")}
                        >
                            <Icon size="small" className="icon-add-new-card push-right-sm">
                                <FontAwesomeIcon icon={faPlus} className="is-size-6" />
                            </Icon>
                            {labels["BUTTON_ADD_NEW_CARD"]}
                        </button>
                    </div>
                ) :
                    <div className="line">
                        <p id="payment_modal_label" tabIndex="-1" className=" has-text-grey-dark has-text-weight-semibold push-bottom-md">
                            {labels["CITATIONS_MODAL_PAYMENT"]}
                        </p>
                        <Dropdown
                            id="payment"
                            value={walletId}
                            onChange={onChangeWalletId}
                            className="pad-bottom-md"
                            label={labels["OPTIONS_DROPDOWN_LABEL"]}
                            labelledBy="payment_modal_label"
                        >
                            {paymentCardsList.map((data, k) => (
                                <Dropdown.Item value={data.id} key={k}>
                                    <div className="dropdown-item__box">
                                        <div className="dropdown-item__box__image">
                                            <Icon style={{ width: 20, height: 22 }}>
                                                <img
                                                    src={`/assets/img/brand/${data.card_brand.toUpperCase()}.png`}
                                                    alt=""
                                                    aria-hidden="true"
                                                />
                                            </Icon>
                                        </div>
                                        <div className="dropdown-item__box__description">
                                            <p>XXXX-XXXX-XXXX-{data.last_4_digits}</p>
                                            <p>{data.card_label}</p>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            ))}
                            <button
                                type="button"
                                className="item-add-card"
                                role={labels["BUTTON_ADD_NEW_CARD"]}
                                onClick={() => openAddCard("modalAddCard")}
                            >
                                <Icon size="small" className="push-right-md">
                                    <FontAwesomeIcon className="is-size-6" icon={faPlus} />
                                </Icon>
                                <span>{labels["BUTTON_ADD_NEW_CARD"]}</span>
                            </button>
                        </Dropdown>
                    </div>
                }

                <Field className="push-top-md">
                    <label className="checkbox">
                        <Checkbox
                            name="isTermsAccepted"
                            checked={isTermsAccepted}
                            onChange={onChange}
                        />
                        <span className="is-size-8 has-text-grey-dark">{labels["GDPR_CHECKBOX_AGREE"]} <a tabIndex="-1" className="has-text-info" target="_blank" href="https://www.indygo.net/mykey-terms-and-conditions/">{labels["GDPR_CHECKBOX_TERMS"]}</a> {labels["GDPR_CHECKBOX_AND"]} <a tabIndex="-1" className="has-text-info" target="_blank" href="https://www.indygo.net/mykey-privacy-policy/">{labels["GDPR_CHECKBOX_PRIVACY"]}.</a></span>
                    </label>
                </Field>
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        disabled={!(isTermsAccepted === true && walletId) || loader === 'yes'}
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={onSubmit}
                        loading={loader === 'yes'}
                        title={labels["BUTTON_SAVE"]}
                    >
                        {labels["BUTTON_SAVE"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        disabled={loader === 'yes'}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
            <ModalView
                name="modalAutoReload"
                activeModal={activeModal}
                active={showFormSuccess}
                icon="success"
                viewType="ShowFormSuccess"
                head={labels["MODAL_TITLE_CONFIRMATION"]}
                messages={[labels["FARE_CARD_CHANGES_SUCCESS_TEXT"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormSuccess_part`);
                    },
                    onDeactivate: () => {
                        setTimeout(() => setFocusElement(`#btnAutoReload`))
                    }
                }}
            >
                <Button
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={close}
                    title={labels["BUTTON_CONTINUE"]}
                >
                    {labels['BUTTON_CONTINUE']}
                </Button>
            </ModalView>
            <ModalView
                name="modalAutoReload"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels["MODAL_TITLE_ERROR"]}
                messages={[labels["FARE_CARD_CHANGES_ERROR_TEXT_1"], labels["FARE_CARD_CHANGES_ERROR_TEXT_2"]]}
                focusOptions={{
                    initialFocus: `#${activeModal}ShowFormError_part`,
                    onDeactivate: () => {
                        setTimeout(() => setFocusElement(`#btnAutoReload`))
                    }
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={() => setStep('showFormValue')}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels["BUTTON_TRY_AGAIN"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
            <ModalView
                name="modalAutoReload"
                activeModal={activeModal}
                active={showFormLocked}
                icon="note"
                viewType="ShowFormLocked"
                head={labels['FARE_CARD_RELOAD_LOCKED_TITLE']}
                messages={[labels["FARE_CARD_RELOAD_LOCKED_TEXT"]]}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'center' }}>
                    <Button
                        className="push-top-xl"
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        title={labels["BUTTON_CONTINUE"]}
                    >
                        {labels['BUTTON_CONTINUE']}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels,
        tts: state.localization.tts
      }),
      dispatch => ({})
)(ModalAutoReload)