import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'

import tracks from './tracks';
import token from './token';
import loader from './loader';
import states from './states';
import unread from './unread';
import artifacts from './artifacts';
import localization from './localization';

export default combineReducers({
    routing: routerReducer,
    tracks,
    token,
    loader,
    states,
    unread,
    artifacts,
    localization
})