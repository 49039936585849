import React from 'react'
import { connect } from "react-redux";
import { Button } from '../../components/lib/components/button';
import ModalView from '../../components/lib/components/view/ModalView';
import { setFocusElement } from '../../_helpers';

const ModalDeleteCard = ({
    activeModal,
    showFormValue,
    showFormError,
    labels,
    close,
    onSubmit,
    card,
    loader
}) => {
    return (
        <>
            <ModalView
                name="modalDeleteCard"
                activeModal={activeModal}
                active={showFormValue}
                icon="danger"
                viewType="ShowFormValue"
                head={labels["MODAL_TITLE_DELETE_CONFIRMATION"]}
                messages={
                    card.is_used_in_auto_reload 
                    ? [labels["OPTIONS_MODAL_DELETE_TEXT_1"], labels["OPTIONS_MODAL_DELETE_TEXT_2"], labels["OPTIONS_MODAL_DELETE_TEXT_4"]]
                    : [labels["OPTIONS_MODAL_DELETE_TEXT_3"], labels["OPTIONS_MODAL_DELETE_TEXT_4"]]
                }
                focusOptions={{
                    // initialFocus: `#${activeModal}ShowFormValuer_button_second`,
                    setReturnFocus: `#btnDeletePaymentCard_${card.id}`,
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormValue_part`);
                    },
                    onDeactivate: () => {
                        //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus                      
                        setFocusElement(`#btnDeletePaymentCard_${card.id}`)
                    }

                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={() => onSubmit(card.id)}
                        loading={loader === 'yes'}
                    >
                        {labels["BUTTON_CONFIRM"]}
                    </Button>
                    <Button
                        id={`${activeModal}ShowFormValuer_button_second`}
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
            <ModalView
                name="modalDeleteCard"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels["MODAL_TITLE_ERROR"]}
                messages={[labels["OPTIONS_MODAL_DELETE_ERROR_TEXT_1"], labels["OPTIONS_MODAL_DELETE_ERROR_TEXT_2"], labels["OPTIONS_MODAL_DELETE_ERROR_TEXT_3"]]}
                focusOptions={{
                    setReturnFocus: `#btnDeletePaymentCard_${card.id}`,
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormError_part`);
                    },
                    onDeactivate: () => {
                        //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
                        setTimeout(() => {
                            setFocusElement(`#btnDeletePaymentCard_${card.id}`)
                        })
                    }
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={() => onSubmit(card.id)}
                        loading={loader === 'yes'}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels["BUTTON_TRY_AGAIN"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels
      }),
      dispatch => ({})
)(ModalDeleteCard)