import React from "react";
import { Button } from "../lib/components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const langList = [
  {
    "code": "en-US",
    "INTERNAL_SERVER_ERROR_TITLE": "Sorry !",
    "INTERNAL_SERVER_ERROR_TEXT": "Looks like you caught us at a bad time. <br />We are busy updating the system. <br />We’ll be back soon and we apologize for the inconvenience.",
    "INTERNAL_SERVER_ERROR_BUTTON": "Reload"
  },
  {
    "code": "es",
    "INTERNAL_SERVER_ERROR_TITLE": "Lo siento !",
    "INTERNAL_SERVER_ERROR_TEXT": "Parece que nos atrapaste en un mal momento. <br />Estamos ocupados actualizando el sistema. <br />Volveremos pronto y le pedimos disculpas por las molestias.",
    "INTERNAL_SERVER_ERROR_BUTTON": "Recargar"
  }
];

class InternalServer extends React.Component {

  state = {
    labels: {}
  }

  componentDidMount() {
    const lg = localStorage.getItem("language") || "en-US";
    const labels = langList.find(lang => lang.code === lg) || langList.find(lang => lang.code === "en-US");
    this.setState({ labels });
  }

  render() {
    const { labels } = this.state;

    return (
      <div 
        className="centered pad-lg has-text-centered" 
        style={{ flexDirection: "column", width: "100vw", height: "100vh" }}
        {...this.props}
      >
        <FontAwesomeIcon icon={faExclamationCircle} size="4x" className="has-text-danger" />
        <h1 className="is-size-3 has-text-danger has-text-weight-bold push-bottom-md push-top-sm">
          {labels["INTERNAL_SERVER_ERROR_TITLE"]}
        </h1>
        <p
          dangerouslySetInnerHTML={{ __html: labels["INTERNAL_SERVER_ERROR_TEXT"] }}
        ></p>
        <Button
          size="small"
          color="info"
          className="push-top-lg"
          onClick={() => window.location.reload(false)}
        >
          {labels["INTERNAL_SERVER_ERROR_BUTTON"]}
        </Button>
      </div>
    )
  }

}

export default InternalServer;