import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons'
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {Link} from "react-router";
import { faExclamationCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import {
  Field,
  Control,
  Label,
  Input
} from '../components/lib/components/form';
import { Icon } from '../components/lib/components/icon';
import { Button } from '../components/lib/components/button';
import { Columns } from '../components/lib/components/columns';
import config from "../../config";
import ReactTooltip from 'react-tooltip'
import { userService } from '../_services';

const passwordRegex = new RegExp(config.API.passwordValidation);
class PasswordResetSecond extends React.Component {
  state = {
    passIcon: false,
    passConfirmIcon: false,
    password: '',
    confirm_password: '',
    isMatchPassword: '',
    errNewPassword: '',
    errConfirmPassword: '',
    changePassSuccess: false
  };
  onChange = evt => {
    const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    this.setState({
      [evt.target.name]: value,
      isMatchPassword: '',
      errNewPassword: '',
      errConfirmPassword: ''
    }, function() {
      // check new password and confirm password
      if(this.state.password != '' && this.state.confirm_password != '') {
        // validate confirm password
        if(!passwordRegex.test(this.state.confirm_password)) {
          this.setState({
              errConfirmPassword: this.props.labels['PROFILE_PASS_ERROR_INCORECT_2']
          })
        } else {
          this.setState({
              errConfirmPassword: ''
          },() => {
            // case password and confirm password not match
            if(this.state.password !== this.state.confirm_password) {
              this.setState({
                  isMatchPassword: this.props.labels['PROFILE_PASS_ERROR_INCORECT_2']
              })
            } else {
                this.setState({
                    isMatchPassword: ''
                })
            }
          })
        }
      }
      // check new password
      if(this.state.password != '') {
        if(!this.state.password.match("^(?=.*[a-z])")) {
          this.setState({
            errNewPassword: this.props.labels['RESETPASS_MISSING_LOWERCASE']
          })
        } else if(!this.state.password.match("^(?=.*[A-Z])")) {
          this.setState({
            errNewPassword: this.props.labels['RESETPASS_MISSING_UPPERCASE']
          })
        } else if(!this.state.password.match("^(?=.*[0-9])")) {
          this.setState({
            errNewPassword: this.props.labels['RESETPASS_MISSING_NUMBER']
          })
        } else if(!this.state.password.match("^(?=.*[!@#\$%\^&\*\_\-])")) {
          this.setState({
            errNewPassword: this.props.labels['RESETPASS_MISSING_SPECIAL_CHARACTER']
          })
        } else if(!this.state.password.match("^(?=.{10,})")) {
          this.setState({
            errNewPassword: this.props.labels['RESETPASS_MISSING_LENGTH']
          })
        } else if(!this.state.password.match("^(?!.* )")) {
          this.setState({
            errNewPassword: this.props.labels['RESETPASS_NOT_ALLOWED_BLANK']
          })
        } else {
            this.setState({
                errNewPassword: ''
            })
        }
      }
    });
  }
  changePassIcon = event =>{
    this.setState({
      passIcon: !this.state.passIcon
    })
  };
  changePassConfirmIcon = event =>{
    this.setState({
      passConfirmIcon: !this.state.passConfirmIcon
    })
  };
  
  onResetPassword = event => {
    event.preventDefault();
    event.stopPropagation();
    
    const { password, confirm_password } = this.state;
    const token = window.location.search.split('?token=')[1];
    const params = {
      'password': password,
      'password_confirmation': confirm_password,
      'token': token
    };

    this.props.onLoader('yes');
    userService.resetPass(params)
    .then(res => {
      this.props.onLoader('no');
      this.setState({changePassSuccess: true})
    })
    .catch((error) => {
        this.props.onLoader('no');
        this.setState({errors: {username: error}});
    })
  }
  
    render() {
      const { password, confirm_password, isMatchPassword, errNewPassword, errConfirmPassword, changePassSuccess } = this.state;
      const { labels, artifacts } = this.props;
      const phoneNumber = artifacts.parameters.customer_support_phone || "";
      const isEnabled = passwordRegex.test(password) && passwordRegex.test(password) && password === confirm_password;

      return (
        <DocumentTitle title={labels['PASSWORD_RESET_PAGE_TITLE'] + ' - IndyGo App'}>
          <div className="container container--fluid-md">
            <Columns>
              <Columns.Column size={4}>
              <h1 className="title is-2 has-text-weight-bold has-text-primary">{labels["LOGIN_PASSWORD"]} <br />{labels["LOGIN_RESET_PASSWORD"]}</h1>
              <p className="has-text-grey-light push-bottom-lg"> {labels["RESETPASS_DESCRIPTION_TEXT"]} <span className="has-text-info">{phoneNumber}</span></p>          
              </Columns.Column>
              <Columns.Column size={1}></Columns.Column>
              <Columns.Column size={4}>
                {changePassSuccess == false && <form className="pad-top-md pad-bottom-md" onSubmit={this.onResetPassword}>
                    <Field>
                      <Label className={`${(!errNewPassword) ? "" : "has-text-danger"} ${password == "" ? "" : "has-text-grey-light"}`}>
                      {labels["RESETPASS_PASS_TEXT"]}
                      <Icon align="right" style={{verticalAlign: '-4px'}} data-tip data-for='tooltipPass' 
                        className="push-left-sm" >
                            <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '18px'}} />
                        </Icon> 
                        <ReactTooltip id='tooltipPass' aria-haspopup='true' html={true} className="has-text-centered	">
                          {labels["SIGNUP_TOOLTIP_PASSWORD"]}
                        </ReactTooltip>
                      </Label>
                      <Control iconRight={true} className="has-icons-right-second">
                        <Input className={`${(!errNewPassword) ? "" : "is-danger"}`} onChange={this.onChange} value={password} name="password" type={this.state.passIcon ? "text" : "password"} placeholder={labels["RESETPASS_PASS_PLACEHOLDER_TEXT"]} />
                        {/* {errNewPassword && <p className="help no-absolute is-danger has-text-right"
                        dangerouslySetInnerHTML={{__html: labels['RESETPASS_ERROR_REQUIREMENT']}}> */}
                        {errNewPassword && <p className="help no-absolute is-danger has-text-right"
                        dangerouslySetInnerHTML={{__html: errNewPassword}}>
                          </p>}
                        {!errNewPassword && <Icon   align="right">
                            <FontAwesomeIcon onClick={this.changePassIcon} icon={this.state.passIcon ? faEye : faEyeSlash} className="has-text-black" />
                        </Icon>}
                        <Icon style={!errNewPassword ? {display: 'none' } : {}} align="right">
                            <FontAwesomeIcon style={{fontSize: '19px'}} icon={faExclamationCircle } className="has-text-danger" />
                        </Icon>
                      </Control>
                    </Field>
                    <Field>
                      <Label className={`${(!isMatchPassword && !errConfirmPassword) ? "" : "has-text-danger"} ${confirm_password == "" ? "" : "has-text-grey-light"}`} >{labels["RESETPASS_REPASS_TEXT"]}</Label>
                      <Control iconRight={true} className="has-icons-right-second">
                        <Input className={`${(!isMatchPassword && !errConfirmPassword) ? "" : "is-danger"}`} onChange={this.onChange} value={confirm_password} name="confirm_password" type={this.state.passIcon ? "text" : "password"} placeholder={labels["RESETPASS_REPASS_PLACEHOLDER_TEXT"]} />
                        {isMatchPassword && <p className="help is-danger has-text-right">{isMatchPassword}</p>}
                        {errConfirmPassword && <p className="help is-danger has-text-right">{errConfirmPassword}</p>}
                        <Icon style={(!isMatchPassword && !errConfirmPassword) ? {display: 'none' } : {}} align="right">
                            <FontAwesomeIcon style={{fontSize: '19px'}} icon={faExclamationCircle } className="has-text-danger" />
                        </Icon>
                        {confirm_password.length > 0 && password === confirm_password && <Icon   align="right">
                            <FontAwesomeIcon style={{marginRight: '-80px'}} icon={faCheck} className="has-text-success" />
                        </Icon> }
                      </Control>
                    </Field>
                    <Button title={labels["RESETPASS_SAVE_NEW_PASS"]} disabled={!isEnabled || this.props.loader=='yes'} loading={this.props.loader=='yes'}  color="info" size="small">{labels['RESETPASS_SAVE_NEW_PASS']}</Button>
                </form>}

                {changePassSuccess == true && <div className="change-pass-success">
                    <p className="has-text-primary push-bottom-md">{labels['RESET_PASS_CONFIRMATION_SUCCESS']}</p>
                    <Link to="/login" className="button is-info">{labels["LOGIN_TITLE"]}</Link>
                </div>}
              </Columns.Column>
            </Columns>     
          </div>
        </DocumentTitle>
      )
    }
  }
  
export default connect(
    state => ({
      loader: state.loader,
      labels: state.localization.external.labels,
      artifacts: state.artifacts
    }),
    dispatch => ({
      onLoader: (status) => {
        dispatch({type: 'SET_STATUS', payload: status})
      }
    })
  )(PasswordResetSecond)