const initialState = [
    {
        id: 1234,
        name: 'My treck'
    }
];

export default function playList(state = initialState, action){
if (action.type === 'ADD_TRACK'){
    return [
        ...state,
        action.payload
    ]
}else if (action.type === 'FETCH_TRACKS_SUCCES'){
    return action.payload
}
return state;
}