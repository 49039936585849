import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';
import { Button } from '../../components/lib/components/button';
import { Icon } from '../../components/lib/components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEye, faEyeSlash, faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faExclamationCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp, faAngleDown, faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { Modal } from '../../components/lib/components/modal';
import NumberFormat from 'react-number-format';
import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip'
import classnames from "classnames";
import { Field, Control, Label, Input } from '../../components/lib/components/form';
import { Columns } from '../../components/lib/components/columns';
import DatePicker from "react-datepicker";
import { personalService } from '../../_services';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import config from "../../../config";
import { setFocusPage, setFocusElement, setDocumentLanguage } from '../../_helpers';
import _ from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import ModalUpdatePhoneNumber from './ModalUpdatePhoneNumber';

const passwordRegex = new RegExp(config.API.passwordValidation);

class PersonalInformation extends React.Component {

    _isMounted = false;
    state = {
        selectedState: { value: "IN", label: "Indiana" },
        selectedCountry: { value: "US", label: "United States" },
        selectedLanguage: null,
        activeModal: '',
        oldPassIcon: false,
        newPassIcon: false,
        confirmPassIcon: false,
        pinCodeIcon: false,
        show: false,
        showFormValue: true,
        showFormSuccess: false,
        showFormError: false,
        fullname: '',
        date: '',
        dateText: '',
        address: '',
        apartment: '',
        city: '',
        zipcode: '',
        country: '',
        oldpassword: '',
        pin: '',
        newpassword: '',
        confirmnewpassword: '',
        phonenumber: '',
        newPhonenumber: '',
        errors: {},
        states: [],
        countries: [],
        caseCreateAddress: false,
        addressID: '',
        originalData: {},
        originalAddress: {},
        phoneNumberFormat: '',
        passwordReadOnly: true,
        locale: null,
        languages: []
    }

    componentDidMount() {
        this._isMounted = true;

        // get form data
        this.getAppParams();
        this.getStates();
        this.getCounties();
        this.getLocalizationList();
        this.getDatePickerLocale();

        // focus page
        setFocusPage('#personal-page');
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getDatePickerLocale() {
        const locale = await import("date-fns/locale");
        const { supported_languages } = this.props.artifacts;
        if (supported_languages && this._isMounted) {
            const active_language = localStorage.getItem("language") || this.props.localization.defaultLang;
            const locale_code = supported_languages.find(lang => lang.locale_code === active_language);
            if (locale_code && locale_code.code) {
                this.setState({
                    locale: locale[locale_code.code] || null
                });
            } else {
                this.setState({
                    locale: locale['en']
                });
            }
        }
    }

    getStates = () => {
        const { countries } = this.props.artifacts;
        const statesFromArtifact = countries.US && countries.US.states || {};
        let states = [];

        for (const value of Object.keys(statesFromArtifact)) {
            const state = {
                value,
                label: statesFromArtifact[value]
            }
            states.push(state);
        }
        this.setState({ states });
    }

    getCounties = () => {
        const countriesFromArtifact = this.props.artifacts.countries || {};
        let countries = [];

        for (const value of Object.keys(countriesFromArtifact)) {
            const state = {
                value,
                label: countriesFromArtifact[value].display_label || ''
            }
            countries.push(state);
        }
        this.setState({ countries }, () => {
            this.getPersonalAddress();
        })
    }

    getPersonalData = () => {
        this.props.onLoader('yes');
        // get personal data
        personalService.getPersonalData()
            .then(res => {
                const personalData = res.data.data;
                let date = moment.tz(moment.tz.guess(true))['_offset'] * -1 + 120;
                // ( date>0 ) ? date+=120:date+=120;

                if (this._isMounted) {
                    this.setState({
                        fullname: personalData.name ? personalData.name : '',
                        date: personalData.birthday ? new Date(personalData.birthday).setMinutes(new Date(personalData.birthday).getMinutes() + date) : '',
                        dateText: personalData.birthday ? moment(new Date(personalData.birthday).setMinutes(new Date(personalData.birthday).getMinutes() + date)).format('LL') : '',
                        pin: personalData.pin ? personalData.pin.toString() : '',
                        phone_country: personalData.phone_country ? personalData.phone_country : '',
                        phonenumber: personalData.phone_number ? personalData.phone_number : '',
                        originalData: {
                            fullname: personalData.name ? personalData.name : '',
                            date: personalData.birthday ? new Date(personalData.birthday).setMinutes(new Date(personalData.birthday).getMinutes() + date) : '',
                            pin: personalData.pin ? personalData.pin.toString() : '',
                            phone_country: personalData.phone_country ? personalData.phone_country : '',
                            phonenumber: personalData.phone_number ? personalData.phone_number : '',
                        }
                    });
                    this.props.onLoader('no');

                    // get language
                    const { languages } = this.state;
                    const { defaultLang } = this.props.localization;
                    const language = localStorage.getItem("language");
                    let selectedLanguage = {};
                    if (language) {
                        selectedLanguage = languages.find(lang => lang.value === language) || languages.find(lang => lang.value === defaultLang);
                    } else {
                        selectedLanguage = languages.find(lang => lang.value === defaultLang);
                    }
                    this.setState({ selectedLanguage });

                }
            })
            .catch((error) => {
                this.setState({ errors: { username: error.response.data.message }, error });
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    getPersonalAddress = () => {
        this.props.onLoader('yes');
        // get personal data
        personalService.getPersonalAddress()
            .then(res => {
                if (res.data.data.length != 0) {
                    // case update address
                    const dataAddress = res.data.data[0];
                    if (this._isMounted) {
                        const idxCountry = _.findIndex(this.state.countries, { 'label': dataAddress.country });
                        const idxState = _.findIndex(this.state.states, { 'value': dataAddress.state });

                        this.setState({
                            address: dataAddress.address_1 ? dataAddress.address_1 : '',
                            apartment: dataAddress.address_2 ? dataAddress.address_2 : '',
                            city: dataAddress.city ? dataAddress.city : '',
                            zipcode: dataAddress.zip ? dataAddress.zip : '',
                            selectedCountry: idxCountry != -1 ? this.state.countries[idxCountry] : { value: "US", label: "United States" },
                            selectedState: idxState != -1 ? this.state.states[idxState] : { value: "IN", label: "Indiana" },
                            addressID: dataAddress.id ? dataAddress.id : '',
                            originalAddress: {
                                address: dataAddress.address_1 ? dataAddress.address_1 : '',
                                apartment: dataAddress.address_2 ? dataAddress.address_2 : '',
                                city: dataAddress.city ? dataAddress.city : '',
                                zipcode: dataAddress.zip ? dataAddress.zip : '',
                                selectedCountry: idxCountry != -1 ? this.state.countries[idxCountry] : { value: "US", label: "United States" },
                                selectedState: idxState != -1 ? this.state.states[idxState] : { value: "IN", label: "Indiana" },
                                addressID: dataAddress.id ? dataAddress.id : '',
                            }
                        });
                        this.props.onLoader('no');
                    }
                } else {
                    // case create new address
                    this.setState({
                        caseCreateAddress: true
                    })
                }
            })
            .catch((error) => {
                this.setState({ errors: { username: error.response.data.message }, error });
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    handleChangeState = (selectedState) => this.setState({ selectedState });

    handleChangeCountry = (selectedCountry) => this.setState({ selectedCountry });

    handleChangeLanguage = (selectedLanguage) => {
        try {
            this.setState({ selectedLanguage });
            const { supportedLangs, defaultLang } = this.props.localization;
            const language = supportedLangs[selectedLanguage.value] || supportedLangs[localStorage.getItem("language") || defaultLang];
            this.props.onSetLocalization(language);
            localStorage.setItem("language", selectedLanguage.value);
            this.getDatePickerLocale();

            // Set document html language
            if (selectedLanguage.label) {
                setDocumentLanguage(selectedLanguage.label);
            }
        } catch (err) { console.error(err) }
    }

    open = (activeModal) => {
        this.setState({ activeModal }, () => {
            // reset country code
            localStorage.setItem('country_code', '1');
            // init country
            let flag = document.querySelector(".flag");
            if (flag) {
                flag.innerHTML = '<div class="code">+1</div><div class="arrow"></div>';
            }
        });

        this.setState({
            showFormValue: true,
            showFormSuccess: false,
            showFormError: false,
        }, () => {
            // focus page
            // setFocusPage(`#${this.state.activeModal}showFormValue`);
        });
        this.setState({ show: true });
    }

    close = () => this.setState({ show: false });

    onChange = evt => {
        const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
        this.setState({
            [evt.target.name]: value,
            errors: { [evt.target.name]: "" },
            isMatchPassword: "",
            errNewPassword: "",
            errPin: "",
            currentPassword: "",
            errZipCode: ""
        }, function () {
            const { newpassword, confirmnewpassword, pin, zipcode } = this.state;
            const { labels } = this.props;

            // check new password and confirm password
            if (newpassword && confirmnewpassword) {
                if (newpassword !== confirmnewpassword) {
                    this.setState({ isMatchPassword: labels["SIGNUP_ERROR_PASS_NOT_MATCH"] });
                }
            }
            // check new password
            if (newpassword && !passwordRegex.test(newpassword)) {
                this.setState({ errNewPassword: labels["PROFILE_PASS_ERROR_INCORECT_1"] });
            }
            // check pin code
            if (pin && (pin.match(/[^0-9\.]/g) || pin.length !== 4)) {
                this.setState({ errPin: labels["PROFILE_PIN_ERROR_INCORECT"] });
            }
            // check zip code
            if (zipcode && (zipcode.length < 5 || zipcode.match(/[^0-9\.]/g))) {
                this.setState({ errZipCode: labels["PROFILE_ZIP_CODE_INNCORECT"] });
            }

        });
    }

    handleOnChange = (value) => {
        localStorage.setItem('country_code', value);
        let flag = document.querySelector(".flag");
        flag.innerHTML = '<div class="code">+' + value + '</div><div class="arrow"></div>';
    }

    handleChangeDate = (date) => {
        this.setState({
            date: date,
            dateText: moment(date).format('LL')
        }, () => {
            setFocusElement('#box-birthday');
        })
    };

    setStep = step => {
        this.setState({
            showFormValue: false,
            showFormSuccess: false,
            showFormError: false,
            [step]: true
        });
        // setFocusPage(`#${this.state.activeModal + step}`);
    }

    onPersonal = (event) => {
        event.preventDefault();

        const { newpassword, confirmnewpassword, caseCreateAddress,
            fullname, date, pin, address, apartment, city, zipcode, selectedCountry, selectedState,
            originalData, originalAddress, errPin } = this.state;

        // update api Personal Data
        if ((fullname && date) && (fullname !== originalData.fullname || moment(date).format("YYYY-MM-DD") !== moment(originalData.date).format("YYYY-MM-DD"))) {
            this.updatePersonalData();
        }

        // update api PIN
        if (!errPin && pin !== originalData.pin) {
            this.updatePin();
        }

        // update password
        if (passwordRegex.test(newpassword) && passwordRegex.test(confirmnewpassword) && newpassword === confirmnewpassword) {
            this.updatePassword();
        }

        // case create address
        if (caseCreateAddress === true) {
            if (address || apartment || city || zipcode || selectedCountry || selectedState) {
                this.createPersonalAddress();
            }
        } else {
            // case update address
            if (address !== originalAddress.address || apartment !== originalAddress.apartment
                || city !== originalAddress.city || zipcode !== originalAddress.zipcode
                || selectedState.value !== originalAddress.selectedState.value || selectedCountry.label !== originalAddress.selectedCountry.label) {
                this.updatePersonalAddress();
            }
        }
    }

    updatePersonalData = () => {
        const { fullname, date } = this.state;
        this.props.onLoader('yes');

        const personalParam = {
            "name": fullname,
            "birthday": moment(date).format("YYYY-MM-DD")
        }
        personalService.updatePersonalData(personalParam)
            .then(res => {
                this.props.onLoader('no');
                // toast success
                toast.success(this.props.labels['PROFILE_UPDATE_PERSONAL_DATA_SUCCESS'], {
                    position: toast.POSITION.TOP_RIGHT
                });
                // refresh data
                this.getPersonalData();
            })
            .catch((error) => {
                // toast error
                toast.error(this.props.labels['PROFILE_UPDATE_PERSONAL_DATA_ERROR'], {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({ errors: { personal: error.response.data.message }, error });
                this.props.onLoader('no');
            })
    }

    updatePin = () => {
        const { pin } = this.state;
        this.props.onLoader('yes');

        const params = {
            "pin": pin,
        }
        personalService.updatePin(params)
            .then(res => {
                this.props.onLoader('no');
                // toast success
                toast.success(this.props.labels['PROFILE_UPDATE_PIN_SUCCESS'], {
                    position: toast.POSITION.TOP_RIGHT
                });
                // refresh data
                this.getPersonalData();
            })
            .catch((error) => {
                this.setState({ errors: { pin: error.response.data.message }, error });
                this.props.onLoader('no');
                // toast error
                toast.error(this.props.labels['PROFILE_UPDATE_PIN_ERROR'], {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
    }

    updatePassword = () => {
        const { oldpassword, newpassword, confirmnewpassword } = this.state;
        this.props.onLoader('yes');

        const params = {
            "current_password": oldpassword,
            "password": newpassword,
            "password_confirmation": confirmnewpassword
        }
        personalService.updatePassword(params)
            .then(res => {
                this.props.onLoader('no');
                this.setState({
                    oldpassword: '',
                    newpassword: '',
                    confirmnewpassword: ''
                });

                // toast success
                toast.success(this.props.labels['PROFILE_UPDATE_PERSONAL_PASS_SUCCESS'], {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                // toast error
                toast.error(this.props.labels['PROFILE_UPDATE_PERSONAL_PASS_ERROR'], {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({
                    errors: {
                        // currentPassword: error.response.data.errors? error.response.data.errors.current_password[0] : '',
                        // password: !error.response.data.errors ? error.response.data.message : ''
                        currentPassword: this.props.labels['PROFILE_OLD_PASS_ERROR_INCORECT']
                    }, error
                });
                this.props.onLoader('no');
            })
    }

    createPersonalAddress = () => {
        const { address, city, zipcode, apartment, selectedCountry, selectedState } = this.state;
        this.props.onLoader('yes');

        const addressParams = {
            "address_1": address,
            'address_2': apartment,
            "city": city,
            "state": selectedState.value,
            "country": selectedCountry.value,
            "zip": zipcode,
            "is_primary": 1
        }

        personalService.createPersonalAddress(addressParams)
            .then(res => {
                this.props.onLoader('no');

                // toast success
                toast.success(this.props.labels['PROFILE_UPDATE_PERSONAL_ADDRESS_SUCCESS'], {
                    position: toast.POSITION.TOP_RIGHT
                });
                // refresh address
                this.getPersonalAddress();
            })
            .catch((error) => {
                // toast error
                toast.error(this.props.labels['PROFILE_UPDATE_PERSONAL_ADDRESS_ERROR'], {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({ errors: { address: error.response.data.errors } });
                this.props.onLoader('no');
            })
    }

    updatePersonalAddress = () => {
        const { addressID, address, city, zipcode, apartment, selectedCountry, selectedState } = this.state;
        this.props.onLoader('yes');

        const addressParams = {
            "address_1": address,
            'address_2': apartment,
            "city": city,
            "state": selectedState.value,
            "country": selectedCountry.value,
            "zip": zipcode
        }

        personalService.updatePersonalAddress(addressID, addressParams)
            .then(res => {
                this.props.onLoader('no');

                // toast success
                toast.success(this.props.labels['PROFILE_UPDATE_PERSONAL_ADDRESS_SUCCESS'], {
                    position: toast.POSITION.TOP_RIGHT
                });
                // refresh address
                this.getPersonalAddress();
            })
            .catch((error) => {
                // toast error
                toast.error(this.props.labels['PROFILE_UPDATE_PERSONAL_ADDRESS_ERROR'], {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ errors: { address: error.response.data.errors } });
                this.props.onLoader('no');
            })
    }

    onAddPhone = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { newPhonenumber } = this.state;
        if (localStorage.getItem('country_code')) {
            const country_code = localStorage.getItem('country_code');
            this.props.onLoader('yes');

            const params = {
                "phone_number": newPhonenumber,
                "phone_country": parseInt(country_code)
            }
            personalService.updatePhone(params)
                .then(res => {
                    this.props.onLoader('no');
                    this.setStep('showFormSuccess');
                })
                .catch((error) => {
                    this.setState({
                        errors: {
                            newPhonenumber: error.response.data.message
                        }, error
                    });
                    this.props.onLoader('no');
                    if (error.response.status == 401) {
                        this.props.router.push('/login');
                    }
                    this.setStep('showFormError');
                })
        }
    }

    closeMessage = event => {
        event.preventDefault();
        event.stopPropagation();
    }

    continue = event => {
        event.preventDefault();
        event.stopPropagation();
        this.getPersonalData();
        this.getPersonalAddress();
        this.close();
    }

    changeOldPassIcon = () => this.setState({ oldPassIcon: !this.state.oldPassIcon });

    changeNewPassIcon = () => this.setState({ newPassIcon: !this.state.newPassIcon });

    changeConfirmPassIcon = () => this.setState({ confirmPassIcon: !this.state.confirmPassIcon });

    changePinCodeIcon = () => this.setState({ pinCodeIcon: !this.state.pinCodeIcon });

    getAppParams = () => {
        const { countries } = this.props.artifacts;
        this.setState({
            phoneNumberFormat: countries.US ? countries.US.phone_number_format : "(###) ###-####"
        }, this.getPersonalData);
    }

    getLocalizationList = () => {
        const { supported_languages } = this.props.artifacts;
        if (supported_languages) {
            const languages = supported_languages.map(({ code, locale_code }) => ({
                icon: `assets/icons/${code}.svg`,
                value: locale_code,
                label: code
            }));
            this.setState({ languages });
        }
    }

    openDatepicker = () => this._calendar.setOpen(true);
    closeDatepicker = () => this._calendar.setOpen(false);

    render() {
        const {
            phonenumber, phone_country, newPhonenumber, confirmnewpassword, newpassword, pin, oldpassword,
            activeModal, showFormValue, showFormSuccess, showFormError, show, fullname, date, dateText, address, apartment, city, zipcode, errors,
            isMatchPassword, errNewPassword, errPin, selectedState, selectedCountry, selectedLanguage, errZipCode, originalData,
            phoneNumberFormat, countries, pinCodeIcon, locale, states, passwordReadOnly, oldPassIcon, newPassIcon, languages
        } = this.state;
        const { labels, tts, loader, artifacts: { supported_languages } } = this.props;

        const DropdownIndicator = props => (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FontAwesomeIcon
                        style={{ color: "$input-arrow" }}
                        className="is-size-4"
                        icon={props.selectProps.menuIsOpen ? faAngleUp : faAngleDown}
                    />
                </components.DropdownIndicator>
            )
        );

        const IconOption = (props) => (
            <components.Option {...props}>
                <div className="select-option">
                    {props.data.label}
                    <img src={props.data.icon} alt="" aria-hidden="true" className="select-svg" />
                </div>
            </components.Option>
        );

        const LanguageOption = (props) => (
            <components.SingleValue {...props}>
                <div className="select-option">
                    {props.data.label}
                    <img src={props.data.icon} alt="" aria-hidden="true" className="select-svg" />
                </div>
            </components.SingleValue>
        );

        return (
            <DocumentTitle title={labels["PERSONAL_INFORMATION_PAGE_TITLE"] + " - IndyGo App"}>
                <div id="personal-page" aria-label={labels["PERSONAL_INFORMATION_PAGE_TITLE"]} role={labels["PERSONAL_INFORMATION_PAGE_TITLE"]}>
                    <h1 className="is-size-2 has-text-weight-bold pad-bottom-md pad-top-xl">
                        {labels['PROFILE_PERSONAL_INFO_TEXT']}
                    </h1>
                    <form autoComplete="off" className="pad-top-lg pad-bottom-xl" onSubmit={this.onPersonal}>

                        <h2 className="has-text-grey-dark has-text-weight-bold is-size-4 push-bottom-md">
                            {labels["PROFILE_PERSONAL_DATA_TEXT"]}
                        </h2>
                        <Columns>
                            <Columns.Column size={12}>
                                <Columns role={labels["PROFILE_PERSONAL_DATA_TEXT"]}>
                                    <Columns.Column size={4}>
                                        <Field>
                                            <Label
                                                htmlFor="fullname"
                                                className={classnames({
                                                    "has-text-grey-light": fullname && !errors["personal"],
                                                    "has-text-danger": errors["personal"]
                                                })}
                                            >
                                                {labels['PROFILE_FULLNAME']}
                                            </Label>
                                            <Control iconRight={true}>
                                                <Input
                                                    id="fullname"
                                                    name="fullname"
                                                    disabled={loader === "yes"}
                                                    className={classnames({
                                                        "is-danger": errors["personal"]
                                                    })}
                                                    onChange={this.onChange}
                                                    value={fullname}
                                                    placeholder={labels["PROFILE_FULLNAME_PLACEHOLDER"]}
                                                />
                                                {errors["personal"] &&
                                                    <>
                                                        <p className="help is-danger has-text-right">{errors["personal"]}</p>
                                                        <Icon align="right">
                                                            <FontAwesomeIcon icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                                                        </Icon>
                                                    </>
                                                }
                                            </Control>
                                        </Field>
                                    </Columns.Column>
                                    <Columns.Column size={4}>
                                        <Field>
                                            <Label
                                                htmlFor="birthday"
                                                className={classnames({
                                                    "has-text-grey-light": date
                                                })}
                                            >
                                                {labels["PROFILE_DOB"]}
                                            </Label>
                                            <Control id="box-birthday" aria-label={(dateText) ? labels["PROFILE_DOB"] + ' ' + (dateText) : labels["PROFILE_DOB_EMPTY"]} tabIndex="0" iconRight={true} className="react-datepicker-control">
                                                <Icon
                                                    align="right"
                                                    tabIndex="0"
                                                    role="button"
                                                    className="is-medium"
                                                    onKeyPress={this.openDatepicker}
                                                    aria-label={tts["PROFILE_OPEN_CALENDAR"]}
                                                >
                                                    <FontAwesomeIcon icon={faCalendarAlt} className="has-text-grey" />
                                                </Icon>
                                                <DatePicker
                                                    tabIndex="-1"
                                                    id="birthday"
                                                    disabled={loader === "yes"}
                                                    dateFormat="MMMM d, yyyy"
                                                    placeholderText="MM/DD/YY"
                                                    className="input"
                                                    selected={date}
                                                    onChange={this.handleChangeDate}
                                                    showYearDropdown
                                                    scrollabelYearDropdown
                                                    showMonthDropdown
                                                    minDate={new Date("01/01/1900")}
                                                    dropdownMode="select"
                                                    maxDate={new Date(moment().subtract(7, "years"))}
                                                    locale={locale}
                                                    ref={(c) => this._calendar = c}
                                                />
                                            </Control>
                                        </Field>
                                    </Columns.Column>
                                </Columns>
                                <div className="line"></div>
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column size={4}>
                                {phonenumber.length === 0 && (
                                    <div
                                        id="link-add"
                                        tabIndex="0"
                                        role="button"
                                        className="link-add has-text-primary has-text-weight-semibold"
                                        onClick={() => this.open("modalUpdatePhoneNumber")}
                                        onKeyPress={() => this.open("modalUpdatePhoneNumber")}
                                        aria-label={labels["PROFILE_ADD_PHONE"]}
                                    >
                                        <Icon className="is-medium has-background-warning">
                                            <FontAwesomeIcon icon={faPlus} className="is-size-8 has-text-primary" />
                                        </Icon>
                                        {labels["PROFILE_ADD_PHONE"]}
                                    </div>
                                )}
                                {phonenumber.length !== 0 && (
                                    <Field>
                                        <Label
                                            htmlFor="phonenumber"
                                            className={classnames({
                                                "has-text-grey-light": phonenumber
                                            })}
                                        >
                                            {labels["PROFILE_PHONE_TEXT"]}
                                        </Label>
                                        <Control
                                            iconRight={true}
                                            onClick={() => this.open("modalUpdatePhoneNumber")}
                                        >
                                            <NumberFormat
                                                id="phonenumber"
                                                name="phonenumber"
                                                value={phonenumber}
                                                className="input"
                                                disabled={loader === "yes"}
                                                format={"+" + phone_country + " " + phoneNumberFormat}
                                                onChange={this.onChange}
                                            />
                                            <p className="is-clickable help is-success has-text-right push-bottom-md">
                                                {labels["PROFILE_PHONE_VERIFIED_TEXT"]}
                                            </p>
                                            <Icon
                                                align="right"
                                                tabIndex="0"
                                                role="button"
                                                onKeyPress={() => this.open("modalUpdatePhoneNumber")}
                                                aria-label={tts["PROFILE_EDIT_PHONE"]}
                                            >
                                                <FontAwesomeIcon icon={faPencilAlt} className="has-text-grey" />
                                            </Icon>
                                        </Control>
                                    </Field>
                                )}
                            </Columns.Column>
                        </Columns>
                        <h2 className="has-text-grey-dark has-text-weight-bold is-size-4 push-bottom-md">
                            {labels["PROFILE_MAILING_ADD_TEXT"]}
                        </h2>
                        <Columns role={labels["PROFILE_MAILING_ADD_TEXT"]}>
                            <Columns.Column size={12}>
                                <Columns>
                                    <Columns.Column size={5}>
                                        <Field>
                                            <Label
                                                htmlFor="address"
                                                className={classnames({
                                                    "has-text-grey-light": address && !(errors.address && errors.address.address_1),
                                                    "has-text-danger": errors.address && errors.address.address_1
                                                })}
                                            >
                                                {labels["PROFILE_ADDRESS"]}
                                            </Label>
                                            <Control iconRight={true}>
                                                <Input
                                                    id="address"
                                                    name="address"
                                                    value={address}
                                                    className={classnames({
                                                        "is-danger": errors.address && errors.address.address_1
                                                    })}
                                                    autoComplete="address_1"
                                                    disabled={loader === "yes"}
                                                    onChange={this.onChange}
                                                    placeholder={labels["PROFILE_ADDRESS_PLACEHOLDER"]}
                                                />
                                                {(errors.address && errors.address.address_1) && (
                                                    <>
                                                        <p className="help is-danger has-text-right">{labels["PROFILE_ADDRESS_REQUIRED"]}</p>
                                                        <Icon align="right">
                                                            <FontAwesomeIcon icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                                                        </Icon>
                                                    </>
                                                )}
                                            </Control>
                                        </Field>
                                    </Columns.Column>
                                    <Columns.Column size={5}>
                                        <Field>
                                            <Label
                                                htmlFor="apartment"
                                                className="is-invisible"
                                            >
                                                {labels["PROFILE_APARTMENT"]}
                                            </Label>
                                            <Control>
                                                <Input
                                                    id="apartment"
                                                    name="apartment"
                                                    value={apartment}
                                                    autoComplete="address_2"
                                                    disabled={loader === "yes"}
                                                    onChange={this.onChange}
                                                    placeholder={labels["PROFILE_APARTMENT"]}
                                                />
                                            </Control>
                                        </Field>
                                    </Columns.Column>
                                </Columns>
                                <Columns>
                                    <Columns.Column size={5}>
                                        <Field>
                                            <Label
                                                htmlFor="city"
                                                className={classnames({
                                                    "has-text-danger": errors.address && errors.address.city,
                                                    "has-text-grey-light": city && !(errors.address && errors.address.city)
                                                })}
                                            >
                                                {labels["PROFILE_TOWN_CITY"]}
                                            </Label>
                                            <Control iconRight={true}>
                                                <Input
                                                    id="city"
                                                    name="city"
                                                    value={city}
                                                    className={classnames({
                                                        "is-danger": errors.address && errors.address.city
                                                    })}
                                                    autoComplete="city"
                                                    disabled={loader === "yes"}
                                                    onChange={this.onChange}
                                                    placeholder={labels["PROFILE_TOWN_CITY_PLACEHOLDER"]}
                                                />
                                                {(errors.address && errors.address.city) && (
                                                    <>
                                                        <p className="help is-danger has-text-right">{labels["PROFILE_CITY_REQUIRED"]}</p>
                                                        <Icon align="right">
                                                            <FontAwesomeIcon icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                                                        </Icon>
                                                    </>
                                                )}
                                            </Control>
                                        </Field>
                                    </Columns.Column>
                                </Columns>

                                <Columns>
                                    <Columns.Column size={5}>
                                        <Field>
                                            <Label
                                                htmlFor="countries"
                                                className={classnames({
                                                    "has-text-grey-light": selectedCountry
                                                })}
                                            >
                                                {labels['PROFILE_COUNTRY']}
                                            </Label>
                                            <Select
                                                inputId="countries"
                                                aria-label={labels["PROFILE_COUNTRY"]}
                                                disabled={loader === "yes"}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={selectedCountry}
                                                onChange={this.handleChangeCountry}
                                                options={countries}
                                                components={{ DropdownIndicator }}
                                                placeholder={labels["PROFILE_SELECT_TEXT"]}
                                                isSearchable={false}
                                            />
                                        </Field>
                                    </Columns.Column>
                                </Columns>
                                <Columns>
                                    <Columns.Column size={5}>
                                        <Columns>
                                            <Columns.Column>
                                                <Field>
                                                    <Label
                                                        htmlFor="zipcode"
                                                        className={classnames({
                                                            "has-text-grey-light": zipcode && !(errZipCode || (errors.address && errors.address.zip)),
                                                            "has-text-danger": errZipCode || (errors.address && errors.address.zip)
                                                        })}
                                                    >
                                                        {labels["PROFILE_ZIP_CODE"]}
                                                    </Label>
                                                    <Control iconRight={true}>
                                                        <Input
                                                            id="zipcode"
                                                            name="zipcode"
                                                            value={zipcode}
                                                            className={classnames({
                                                                "is-danger": errZipCode || (errors.address && errors.address.zip)
                                                            })}
                                                            autoComplete="zip_code"
                                                            disabled={loader === "yes"}
                                                            onChange={this.onChange}
                                                            placeholder={labels["PROFILE_ZIP_CODE_PLACEHOLDER"]}
                                                        />
                                                        {(errZipCode || (errors.address && errors.address.zip)) && (
                                                            <>
                                                                <p className="help is-danger has-text-right">
                                                                    {(errors.address && errors.address.zip) ? labels["PROFILE_ZIP_CODE_REQUIRED"] : errZipCode}
                                                                </p>
                                                                <Icon align="right">
                                                                    <FontAwesomeIcon icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                                                                </Icon>
                                                            </>
                                                        )}
                                                    </Control>
                                                </Field>
                                            </Columns.Column>
                                            {selectedCountry && selectedCountry.value === "US" && (
                                                <Columns.Column>
                                                    <Field className={classnames({ "blocked": loader === "yes" })}>
                                                        <Label
                                                            htmlFor="states"
                                                            className={classnames({
                                                                "has-text-grey-light": selectedState
                                                            })}
                                                        >
                                                            {labels["PROFILE_STATE"]}
                                                        </Label>
                                                        <Select
                                                            inputId="states"
                                                            disabled={loader === "yes"}
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            value={selectedState}
                                                            onChange={this.handleChangeState}
                                                            options={states}
                                                            components={{ DropdownIndicator }}
                                                            placeholder={labels["PROFILE_SELECT_TEXT"]}
                                                            isSearchable={false}
                                                        />
                                                    </Field>
                                                </Columns.Column>
                                            )}
                                        </Columns>
                                    </Columns.Column>
                                </Columns>
                                <div className="push-top-lg line"></div>
                            </Columns.Column>
                        </Columns>

                        <Columns>
                            <Columns.Column size={12}>
                                <Columns>
                                    <Columns.Column role={labels["PROFILE_PASS_PIN_TEXT"]} size={5}>
                                        <h2 className="has-text-grey-dark has-text-weight-bold is-size-4 push-bottom-md">
                                            {labels["PROFILE_PASS_PIN_TEXT"]}
                                        </h2>
                                        <Field>
                                            <Label
                                                htmlFor="oldpassword"
                                                className={classnames({
                                                    "has-text-grey-light": oldpassword && !errors["currentPassword"],
                                                    "has-text-danger": errors["currentPassword"]
                                                })}
                                            >
                                                {labels["PROFILE_OLD_PASS"]}
                                            </Label>
                                            <Control className="has-icons-right-second" iconRight={true}>
                                                <Input
                                                    id="oldpassword"
                                                    value={oldpassword}
                                                    name="oldpassword"
                                                    readOnly={passwordReadOnly}
                                                    onClick={() => this.setState({ passwordReadOnly: false })}
                                                    onFocus={() => this.setState({ passwordReadOnly: false })}
                                                    className={classnames({
                                                        "is-danger": errors["currentPassword"]
                                                    })}
                                                    disabled={loader === "yes"}
                                                    onChange={this.onChange}
                                                    type={oldPassIcon ? "text" : "password"}
                                                    placeholder={labels["PROFILE_OLD_PASS_PLACEHOLDER"]}
                                                />
                                                <Icon
                                                    tabIndex="0"
                                                    role="button"
                                                    aria-controls="oldpassword"
                                                    aria-expanded={oldPassIcon}
                                                    onClick={this.changeOldPassIcon}
                                                    onKeyPress={this.changeOldPassIcon}
                                                    align="right"
                                                    aria-label={tts["PROFILE_OLD_PASSWORD_SHOW"]}
                                                >
                                                    <FontAwesomeIcon icon={oldPassIcon ? faEye : faEyeSlash} className="has-text-black" />
                                                </Icon>
                                                {errors["currentPassword"] && (
                                                    <>
                                                        <p className="help is-danger has-text-right">{labels["PROFILE_OLD_PASS_ERROR_INCORECT"]}</p>
                                                        <Icon align="right">
                                                            <FontAwesomeIcon style={{ marginRight: 50 }} icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                                                        </Icon>
                                                    </>
                                                )}
                                                {oldpassword.length > 9 && oldpassword.length < 17 && !errors["password"] && !isMatchPassword && !errors["currentPassword"] && (
                                                    <Icon align="right">
                                                        <FontAwesomeIcon icon={faCheck} className="has-text-success" />
                                                    </Icon>
                                                )}
                                            </Control>
                                        </Field>
                                        <Field>
                                            <Label
                                                htmlFor="newpassword"
                                                className={classnames({
                                                    "has-text-grey-light": newpassword,
                                                    "has-text-danger": errors["password"] || errNewPassword
                                                })}
                                            >
                                                {labels["RESETPASS_PASS_TEXT"]}
                                                <Icon align="right" style={{ verticalAlign: '-4px' }} className="push-left-sm" data-tip data-for="tooltipPass">
                                                    <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '18px' }} />
                                                </Icon>
                                                <ReactTooltip id="tooltipPass" aria-haspopup="true" html={true} className="has-text-centered">
                                                    {labels["SIGNUP_TOOLTIP_PASSWORD"]}
                                                </ReactTooltip>
                                            </Label>
                                            <Control className="has-icons-right-second" iconRight={true}>
                                                <Input
                                                    id="newpassword"
                                                    name="newpassword"
                                                    value={newpassword}
                                                    className={classnames({
                                                        "is-danger": errors["password"] || errNewPassword
                                                    })}
                                                    disabled={loader === "yes"}
                                                    onChange={this.onChange}
                                                    type={newPassIcon ? "text" : "password"}
                                                    placeholder={labels['RESETPASS_PASS_PLACEHOLDER_TEXT']}
                                                />
                                                {!errNewPassword && (
                                                    <Icon
                                                        tabIndex="0"
                                                        role="button"
                                                        aria-controls="newpassword"
                                                        aria-expanded={newPassIcon}
                                                        onClick={this.changeNewPassIcon}
                                                        onKeyPress={this.changeNewPassIcon}
                                                        align="right"
                                                        aria-label={tts["PROFILE_NEW_PASSWORD_SHOW"]}
                                                    >
                                                        <FontAwesomeIcon icon={newPassIcon ? faEye : faEyeSlash} className="has-text-black" />
                                                    </Icon>
                                                )}
                                                {errNewPassword && (
                                                    <>
                                                        <p className="help is-danger has-text-right">{labels["PROFILE_PASS_ERROR_INCORECT_1"]}</p>
                                                        <Icon align="right">
                                                            <FontAwesomeIcon icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                                                        </Icon>
                                                    </>
                                                )}
                                            </Control>
                                        </Field>
                                        <Field>
                                            <Label
                                                htmlFor="confirmnewpassword"
                                                className={classnames({
                                                    "has-text-grey-light": confirmnewpassword && !(isMatchPassword || errors["password"]),
                                                    "has-text-danger": isMatchPassword || errors["password"]
                                                })}
                                            >
                                                {labels["RESETPASS_REPASS_TEXT"]}
                                            </Label>
                                            <Control className="has-icons-right-second" iconRight={true}>
                                                <Input
                                                    id="confirmnewpassword"
                                                    name="confirmnewpassword"
                                                    value={confirmnewpassword}
                                                    className={classnames({
                                                        "is-danger": isMatchPassword || errors["password"]
                                                    })}
                                                    disabled={loader === "yes"}
                                                    onChange={this.onChange}
                                                    type={newPassIcon ? "text" : "password"}
                                                    placeholder={labels["RESETPASS_REPASS_PLACEHOLDER_TEXT"]}
                                                />
                                                {isMatchPassword && (
                                                    <>
                                                        <p className="help is-danger has-text-right">{isMatchPassword}</p>
                                                        <Icon align="right">
                                                            <FontAwesomeIcon icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                                                        </Icon>
                                                    </>
                                                )}
                                                {confirmnewpassword.length > 3 && confirmnewpassword.length < 17 && newpassword === confirmnewpassword && !isMatchPassword && !errors['password'] && (
                                                    <Icon align="right">
                                                        <FontAwesomeIcon style={{ marginRight: '-80px' }} icon={faCheck} className="has-text-success" />
                                                    </Icon>
                                                )}
                                            </Control>
                                        </Field>
                                    </Columns.Column>
                                    <Columns.Column role={labels["PROFILE_PIN_TEXT"]} size={4}>
                                        <Field>
                                            <h2 className="has-text-grey-dark has-text-weight-bold is-size-4 push-bottom-md">
                                                {labels["PROFILE_PIN_TEXT"]}
                                            </h2>
                                            <Label
                                                htmlFor="pin"
                                                className={classnames({
                                                    "has-text-grey-light": pin && !(errPin || errors["pin"]),
                                                    "has-text-danger": errPin || errors["pin"]
                                                })}
                                            >
                                                {labels["PROFILE_PIN"]}
                                            </Label>
                                            <Control className="has-icons-right-second" iconRight={true}>
                                                <Input
                                                    id="pin"
                                                    name="pin"
                                                    value={pin}
                                                    maxLength="4"
                                                    disabled={loader === "yes"}
                                                    className={classnames({
                                                        "is-danger": errPin || errors["pin"]
                                                    })}
                                                    onChange={this.onChange}
                                                    type={pinCodeIcon ? "text" : "password"}
                                                    placeholder={labels["PROFILE_PIN_PLACEHOLDER"]}
                                                />
                                                {!errPin && !errors["pin"] && (
                                                    <Icon
                                                        tabIndex="0"
                                                        onClick={this.changePinCodeIcon}
                                                        onKeyPress={this.changePinCodeIcon}
                                                        align="right"
                                                    >
                                                        <FontAwesomeIcon icon={pinCodeIcon ? faEye : faEyeSlash} className="has-text-black" />
                                                    </Icon>
                                                )}

                                                {(errPin || errors["pin"]) && (
                                                    <>
                                                        <p className="help is-danger has-text-right">
                                                            {errors["pin"] || errPin}
                                                        </p>
                                                        <Icon align="right">
                                                            <FontAwesomeIcon icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                                                        </Icon>
                                                    </>
                                                )}
                                            </Control>
                                        </Field>
                                    </Columns.Column>
                                </Columns>
                                <div className="push-top-lg line"></div>
                            </Columns.Column>
                        </Columns>

                        {supported_languages && (
                            <>
                                <h2 className="has-text-grey-dark has-text-weight-bold is-size-4 push-bottom-md">
                                    {labels["PROFILE_PREFERRED_LANG"]}
                                </h2>
                                <Columns role={labels["PROFILE_PREFERRED_LANG"]}>
                                    <Columns.Column size={3}>
                                        <Field>
                                            <Label htmlFor="language">{labels["PROFILE_PREFERRED_LANG"]}</Label>
                                            <Select
                                                inputId="language"
                                                aria-label={labels["PROFILE_PREFERRED_LANG"]}
                                                disabled={loader === "yes"}
                                                className="react-select-container is-uppercase react-select-custom"
                                                classNamePrefix="react-select"
                                                components={{ SingleValue: LanguageOption, Option: IconOption, DropdownIndicator }}
                                                value={selectedLanguage}
                                                onChange={this.handleChangeLanguage}
                                                options={languages}
                                                placeholder={labels["PROFILE_SELECT_TEXT"]}
                                                isSearchable={false}
                                            />
                                        </Field>
                                    </Columns.Column>
                                </Columns>
                            </>
                        )}

                        <Button
                            loading={loader === "yes"}
                            disabled={loader === "yes"}
                            className="push-top-md"
                            color="info"
                            size="small"
                            title={labels["BUTTON_SAVE_CHANGES"]}
                        >
                            {labels["BUTTON_SAVE_CHANGES"]}
                        </Button>
                    </form>

                    <Modal showClose={false} show={show} onClose={this.close}>
                        <Modal.Content>
                            <Modal.Card>
                                <Modal.Card.Head onClose={this.close}>
                                </Modal.Card.Head>
                                <Modal.Card.Body className="centered">

                                    {/* Modal Update Phone */}
                                    <ModalUpdatePhoneNumber
                                        activeModal={activeModal}
                                        showFormValue={showFormValue}
                                        showFormSuccess={showFormSuccess}
                                        showFormError={showFormError}
                                        onSubmit={this.onAddPhone}
                                        onChange={this.onChange}
                                        setStep={this.setStep}
                                        close={this.close}
                                        originalData={originalData}
                                        errors={errors}
                                        newPhonenumber={newPhonenumber}
                                        handleOnChange={this.handleOnChange}
                                        onContinue={this.continue}
                                        {...this.props}
                                    ></ModalUpdatePhoneNumber>

                                </Modal.Card.Body>
                            </Modal.Card>
                        </Modal.Content>
                    </Modal>

                    <ToastContainer autoClose={5000} newestOnTop closeOnClick />

                </div>
            </DocumentTitle>
        )
    }
}

export default connect(
    state => ({
        loader: state.loader,
        localization: state.localization,
        labels: state.localization.labels,
        tts: state.localization.tts,
        artifacts: state.artifacts
    }),
    dispatch => ({
        onSetLocalization: localization => {
            dispatch({ type: "SET_LOCALIZATION", payload: localization })
        },
        onAddStates: (states) => {
            dispatch({ type: 'ADD_STATE', payload: states })
        },
        onLoader: (status) => {
            dispatch({ type: 'SET_STATUS', payload: status })
        }
    })
)(PersonalInformation)