import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Dropdown } from "../components/lib/components/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons";
import { setDocumentLanguage } from "../_helpers";

class Header extends React.Component {

  _navbarRef = React.createRef();
  _toggleButton = React.createRef();
  state = {
    isActive: false,
    language: localStorage.getItem("external_language") || this.props.localization.defaultLang
  }

  componentDidMount() {
    this._navbarRef.current.addEventListener("keydown", this.keyPressHandler);
  }

  componentWillUnmount() {
    this._navbarRef.current.removeEventListener("keydown", this.keyPressHandler);
  }

  setLanguage = language => {
    try {
      this.props.onSetExternalLocalization(this.props.localization.supportedLangs[language])
      this.setState({ language });
      localStorage.setItem("external_language", language);

      // Set document html language
      const { supported_languages } = this.props.artifacts;
      const documentLanguage = supported_languages.find(lang => lang.locale_code === language);
      if (documentLanguage && documentLanguage.code) {
        setDocumentLanguage(documentLanguage.code);
      }
    } catch (e) { console.error(e) }
  }

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }), () => {
      if (this.state.isActive) {
        const activeElement = this._navbarRef.current.querySelector("[tabIndex='0']");
        if (activeElement) {
          activeElement.focus();
        }
      }
    })
  }

  keyPressHandler = event => {
    event.stopPropagation();
    const { code } = event;

    const navElementsDOM = this._navbarRef.current.querySelectorAll("[role='menuitem'],[role='button']");
    let navElements = [];
    Array.from(navElementsDOM).map(item => {
      item.tabIndex = (item === document.activeElement) ? "0" : "-1";
      navElements.push({
        node: item,
        active: item === document.activeElement
      })
    });
    const activeElementIndex = navElements.findIndex(item => item.active);

    // Helper function for work with tabIndex and focus active element
    const focusElement = index => {
      if (navElements[index]) {
        navElements[index].node.focus();
        navElements[index].node.tabIndex = "0";
      }
    }

    const focusPrevElement = () => {
      event.preventDefault();
      if (activeElementIndex !== -1) {
        if (activeElementIndex !== 0) {
          focusElement(activeElementIndex - 1);
        } else {
          focusElement(navElements.length - 1);
        }
      }
    }

    const focusNextElement = () => {
      event.preventDefault();
      if (activeElementIndex !== -1) {
        if (activeElementIndex !== navElements.length - 1) {
          focusElement(activeElementIndex + 1);
        } else {
          focusElement(0);
        }
      }
    }

    switch (code) {
      case "Tab":
        this.setState({ isActive: false });
        break;
      case "Escape":
        this.setState({ isActive: false });
        this._toggleButton.current.focus();
        break;
      case "Home":
        event.preventDefault();
        focusElement(0);
        break;
      case "End":
        event.preventDefault();
        focusElement(navElements.length - 1);
        break;
      case "ArrowLeft":
        focusPrevElement();
        break;
      case "ArrowRight":
        focusNextElement();
        break;
      case "ArrowUp":
        focusPrevElement();
        break;
      case "ArrowDown":
        focusNextElement();
        break;
      default: break;
    }
  }

  render() {
    const { language, isActive } = this.state;
    const { labels, tts, artifacts: { supported_languages } } = this.props;

    return (
      <header className="has-background-white">
        <div className="container">
          <div className="navbar">
            <div className="navbar-brand">
              <Link
                to="/login"
                role="logo"
                className="navbar-item"
                title={labels["NAVIGATION_LOGO_TITLE"]}
              >
                <img src="/assets/img/brand/logo_white.svg" alt={labels["NAVIGATION_LOGO_ALT"]} />
              </Link>
              <button
                ref={this._toggleButton}
                type="button"
                onClick={this.toggleNav}
                className="navbar-burger burger centered is-hidden-desktop is-borderless has-background-none"
                aria-label={tts["NAVIGATION_TOGGLE_NAVBAR"]}
                aria-expanded={isActive}
                aria-controls="navbarMenu"
              >
                <FontAwesomeIcon icon={!isActive ? faBars : faTimes} className="is-size-4" />
              </button>
            </div>
            <nav id="navbarMenu" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
              <div
                className="navbar-end"
                role="menubar"
                ref={this._navbarRef}
              >

                <Link
                  to="/create-account"
                  className="navbar-item is-uppercase"
                  role="menuitem"
                  tabIndex="0"
                >
                  {labels["LOGIN_CREATE_ACCOUNT"]}
                </Link>

                <Link
                  to="/login"
                  className="navbar-item is-uppercase"
                  role="menuitem"
                  tabIndex="-1"
                >
                  {labels["LOGIN_TITLE"]}
                </Link>

                {supported_languages && (
                  <>
                    <p id="land_dropdown_label" className="is-sr-only">{tts["LOGIN_LANG_DROPDOWN"]}</p>
                    <Dropdown
                      id="land_dropdown"
                      value={language}
                      onChange={this.setLanguage}
                      label={labels["language"]}
                      labelledBy="land_dropdown_label"
                      hoverable={true}
                      tabIndex="-1"
                    >
                      {supported_languages.map(({ locale_code, language }, index) => (
                        <Dropdown.Item
                          key={index}
                          id={`lang_${index}`}
                          value={locale_code}
                          aria-label={language}
                        >
                          { language}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  </>
                )}

                <div className="navbar-brand">
                  <a
                    href="https://www.indygo.net"
                    target="_blank"
                    className="navbar-item"
                    role="menuitem"
                    tabIndex="-1"
                  >
                    <img
                      src="/assets/img/brand/IndyGo_logo.svg"
                      alt={labels["NAVIGATION_INDYGO_WEBSITE"]}
                    />
                  </a>
                </div>

              </div>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}

export default connect(
  state => ({
    labels: state.localization.external.labels,
    tts: state.localization.external.tts,
    localization: state.localization,
    artifacts: state.artifacts
  }),
  dispatch => ({
    onSetExternalLocalization: localization => {
      dispatch({ type: "SET_EXTERNAL_LOCALIZATION", payload: localization })
    },
    onLoader: status => {
      dispatch({ type: 'SET_STATUS', payload: status })
    }
  })
)(Header)