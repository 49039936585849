import React from "react";
import { connect } from "react-redux";
import { Button } from "../../../components/lib/components/button";
import ModalView from "../../../components/lib/components/view/ModalView";

const ModalAddFareCardByLink = ({ activeModal, showFormSuccess, showFormError, labels, close }) => {
  return (
    <>
      <ModalView
        name="modalAddFareCardByLink"
        activeModal={activeModal}
        active={showFormSuccess}
        icon="success"
        viewType="ShowFormSuccess"
        head={labels["FARE_CARD_ADD_CARD_SUCCESS_TITLE"]}
        messages={[labels["FARE_CARD_ADD_CARD_SUCCESS_TEXT"]]}
      >
        <Button
          className="push-top-xl"
          size="small"
          color="info"
          onClick={close}
          title={labels["BUTTON_CONTINUE"]}
        >
          {labels["BUTTON_CONTINUE"]}
        </Button>
      </ModalView>
      <ModalView
        name="modalAddFareCardByLink"
        activeModal={activeModal}
        active={showFormError}
        viewType="ShowFormError"
        icon="danger"
        head={labels["MODAL_TITLE_PAIRING_ERROR"]}
        messages={[labels["FARE_CARD_ADD_CARD_ERROR_TEXT"]]}
        >
          <Button
            className="push-top-lg"
            size="small"
            color="info"
            onClick={close}
            title={labels["BUTTON_OK"]}
          >
            {labels["BUTTON_OK"]}
          </Button>
        </ModalView>
    </>
  )
}

export default connect(
  state => ({
    labels: state.localization.labels
  }),
  dispatch => ({})
)(ModalAddFareCardByLink)