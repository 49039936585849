import React from 'react'
import { connect } from "react-redux";
import { Button } from '../../../components/lib/components/button';
import ModalView from '../../../components/lib/components/view/ModalView';
import { setFocusElement } from '../../../_helpers';

const ModalDeleteCard = ({
    activeModal,
    showFormValue,
    showFormError,
    labels,
    close,
    setStep,
    onSubmit,
    loader
}) => {
    return (
        <>
            <ModalView
                name="modalDeleteCard"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                icon="danger"
                head={labels["MODAL_TITLE_CONFIRMATION_REQUIRED"]}
                messages={[labels["FARE_CARD_REMOVE_CONFIRM_TEXT"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormValue_part`)
                    }
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 0 }}
                        loading={loader === 'yes'}
                        disabled={loader === 'yes'}
                        onClick={onSubmit}
                        title={labels["BUTTON_YES"]}
                    >
                        {labels["BUTTON_YES"]}
                    </Button>
                    <Button
                        id={`${activeModal}ShowFormValue_button_second`}
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        disabled={loader === 'yes'}
                        onClick={close}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
            {/* <ModalView
                name="modalDeleteCard"
                activeModal={activeModal}
                active={showFormSuccess}
                icon="success"
                viewType="ShowFormSuccess"
                head={labels['FARE_CARD_SEND_EMAIL_SUCCESS_TITLE']}
                messages={[labels["FARE_CARD_SEND_EMAIL_SUCCESS_TEXT"]]}
                containerLabel={labels['FARE_CARD_SEND_EMAIL_SUCCESS_TITLE']}
            >
                <Button
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    onClick={close}
                    title={labels["BUTTON_CONTINUE"]}
                >
                    {labels['BUTTON_CONTINUE']}
                </Button> 
            </ModalView>*/}
            <ModalView
                name="modalDeleteCard"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels["MODAL_TITLE_ERROR"]}
                messages={[labels["FARE_CARD_REMOVE_ERROR_TEXT_1"], labels["FARE_CARD_REMOVE_ERROR_TEXT_2"]]}
                focusOptions={{
                    onActivate: () => {
                        setFocusElement(`#${activeModal}ShowFormError_part`);
                    }
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 0 }}
                        onClick={() => setStep('showFormValue')}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels["BUTTON_TRY_AGAIN"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels
      }),
      dispatch => ({})
)(ModalDeleteCard)