import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import {
  Field,
  Control,
  Label,
  Input
} from '../components/lib/components/form';
import { Icon } from '../components/lib/components/icon';
import { Button } from '../components/lib/components/button';
import { Columns } from '../components/lib/components/columns';
import { userService } from '../_services';

class PasswordResetFirst extends React.Component {
  state = {
    username: '',
    errors: {},
    statusConfirm: false
  };
  onChange = evt => {
    const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    this.setState({
      [evt.target.name]: value,
      errors: {}
    });
  };
  onResetPassword = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onLoader('yes');
    const { username } = this.state;

      if (username) {
        const params = {
          'email': username
        };
        userService.forgotPass(params)
        .then(res => {
          this.props.onLoader('no');

          this.setState({statusConfirm: true});
        })
        .catch((error) => {
            this.props.onLoader('no');
            this.setState({errors: {username: error}});
        })
      }
  }
  
    render() {
      const { username, errors, statusConfirm } = this.state;
      const { labels, artifacts } = this.props;
      const phoneNumber = artifacts.parameters.customer_support_phone || "";
      const isEnabled = username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

      return (
        <DocumentTitle title={labels['PASSWORD_RESET_PAGE_TITLE'] + ' - IndyGo App'}>
          <div className="container container--fluid-md">
            <Columns className="columns">
              <Columns.Column size={4}>
                <h1 className="title is-2 has-text-weight-bold has-text-primary">{labels["LOGIN_PASSWORD"]} <br />{labels["LOGIN_RESET_PASSWORD"]}</h1>
                <p className="has-text-grey-light push-bottom-lg"> {labels["RESETPASS_DESCRIPTION_TEXT"]} <span className="has-text-info">{phoneNumber}</span></p>          
              </Columns.Column>
              <Columns.Column size={1}></Columns.Column>
              <Columns.Column size={4} style={statusConfirm ? {display: 'none' } : {}}>
                <form className="pad-top-md pad-bottom-md" onSubmit={this.onResetPassword}>
                    <Field className="pad-bottom-sm">
                      <Label className={`${!errors['username'] ? "" : "has-text-danger"} ${username == "" ? "" : "has-text-grey-light"}`}>{labels["LOGIN_USERNAME"]}</Label>
                      <Control  iconRight={true}>
                        <Input disabled={this.props.loader=='yes'}  className={`${!errors['username'] ? "" : "is-danger"}`} onChange={this.onChange} value={username} name="username" type="text" placeholder={labels["LOGIN_USERNAME_PLACEHOLDER"]} />
                        <p style={!errors['username']  ? {display: 'none' } : {}} className="help is-danger has-text-right">
                          {labels['RESETPASS_ERROR_USERNAME']}
                        </p>
                        <Icon style={!errors['username']  ? {display: 'none' } : {}} align="right">
                            <FontAwesomeIcon style={{fontSize: '19px'}} icon={faExclamationCircle} className="has-text-danger" />
                        </Icon>
                      </Control>
                    </Field>
                    <Button
                      title={labels["BUTTON_RESET_MY_PASSWORD"]}
                      loading={this.props.loader=='yes'}
                      disabled={!isEnabled || this.props.loader=='yes'}
                      color="info"
                      size="small"
                    >
                      {labels["BUTTON_RESET_MY_PASSWORD"]}
                    </Button>
                </form>
              </Columns.Column>
              <Columns.Column size={4} style={!statusConfirm ? {display: 'none' } : {}}>
                <p  className="push-bottom-lg" dangerouslySetInnerHTML={{__html: labels["RESET_PASS_CONFIRMATION_TEXT_1"]}}></p>
                <Button 
                title={labels["RESET_PASS_CONFIRMATION_TEXT_2"]}
                className="push-bottom-lg" 
                  onClick={()=> this.props.router.push('/login') }
                  color="info" 
                  size="small">{labels["BUTTON_OK"]}</Button>
                <p  className="has-text-grey-light" dangerouslySetInnerHTML={{__html: labels["RESET_PASS_CONFIRMATION_TEXT_2"]}}></p>
              </Columns.Column>
            </Columns>     
          </div>
        </DocumentTitle>
      )
    }
  }
  
export default connect(
  state => ({
    loader: state.loader,
    labels: state.localization.external.labels,
    artifacts: state.artifacts
  }),
  dispatch => ({
      onLoader: (status) => {
          dispatch({type: 'SET_STATUS', payload: status})
      }
  })
)(PasswordResetFirst)