import React from "react";
import Routes from "./routes";
import { userService } from "./app/_services";
import { connect } from "react-redux";
import InternalServer from "./app/components/error/InternalServer";
import FocusVisible from "./app/components/FocusVisible";
import { setDocumentLanguage } from "./app/_helpers";

const loader = document.querySelector(".loader-root");
const hideLoader = () => loader.classList.add("loader--hide");

class IndyGo extends React.Component {
    state = {
        show: false,
        error: false,
    };

    componentDidMount() {
        let manifest = JSON.parse(localStorage.getItem("manifest")) || {
            artifacts: [],
        };
        let fetchParametersAtrifact = false;
        let fetchLocalizationAtrifact = false;
        if (localStorage.getItem("language") === "en") {
            localStorage.setItem("language", "en-US");
        }
        if (localStorage.getItem("external_language") === "en") {
            localStorage.setItem("external_language", "en-US");
        }
        userService
            .checkManifest(manifest)
            .then((res) => {
                let data = res.data;
                if (!data || res.status === 204) {
                    data = { artifacts: [] };
                }
                //filtering artifact
                let filteredData = data.artifacts.filter(
                    (item) =>
                        item.artifact_type_id === 2 ||
                        item.artifact_type_id === 8 ||
                        item.artifact_type_id === 9
                );
                try {
                    if (filteredData.length > 0) {
                        //Remove the previous artifacts with the corresponding types
                        for (const element of filteredData) {
                            if (element.artifact_type_id === 2) {
                                fetchParametersAtrifact = true;
                            }
                            if (element.artifact_type_id === 8) {
                                fetchLocalizationAtrifact = true;
                            }
                            manifest.artifacts = manifest.artifacts.filter(
                                (item) => {
                                    return (
                                        item.artifact_type_id !==
                                        element.artifact_type_id
                                    );
                                }
                            );
                        }
                        const artifacts = manifest.artifacts.concat(
                            filteredData
                        );
                        localStorage.setItem(
                            "manifest",
                            JSON.stringify({ artifacts })
                        );
                    }

                    let arrRequests = [];
                    // Check Application parameters artifact
                    if (
                        !fetchParametersAtrifact &&
                        JSON.parse(localStorage.getItem("parameters"))
                    ) {
                        this.props.onAddArtifacts(
                            JSON.parse(localStorage.getItem("parameters"))
                        );
                    } else {
                        arrRequests.push(this.getParametersArtifacts());
                    }
                    // Check Localization artifact
                    if (
                        !fetchLocalizationAtrifact &&
                        JSON.parse(localStorage.getItem("localization"))
                    ) {
                        this.props.onAddlocalization(
                            JSON.parse(localStorage.getItem("localization"))
                        );
                    } else {
                        arrRequests.push(this.getLocalizationArtifacts());
                    }

                    Promise.all(arrRequests)
                        .then((res) => {
                            // Set default lang code
                            const {
                                supported_languages,
                            } = this.props.artifacts;
                            const defaulLanguage = supported_languages.find(
                                (lang) => lang.is_default
                            );
                            if (defaulLanguage) {
                                this.props.onSetDefaultLocalization(
                                    defaulLanguage.locale_code
                                );
                            }

                            // Set selected language
                            const {
                                localization: { supportedLangs, defaultLang },
                            } = this.props;
                            const userLanguage = localStorage.getItem(
                                "language"
                            );
                            const language =
                                supportedLangs[userLanguage] ||
                                supportedLangs[defaultLang];
                            this.props.onSetLocalization(language);

                            const userExternalLanguage = localStorage.getItem(
                                "external_language"
                            );
                            const externalLanguage =
                                supportedLangs[userExternalLanguage] ||
                                supportedLangs[defaultLang];
                            this.props.onSetExternalLocalization(
                                externalLanguage
                            );

                            // Set document html language
                            const documentLanguage =
                                supported_languages.find(
                                    (lang) => lang.locale_code === userLanguage
                                ) || defaulLanguage;
                            if (documentLanguage && documentLanguage.code) {
                                setDocumentLanguage(documentLanguage.code);
                            }

                            hideLoader();
                            this.setState({ show: true });
                        })
                        .catch((error) => {
                            console.error("error.response", error);
                            this.clearLocalStorage();
                            hideLoader();
                            this.setState({ error: true });
                        });
                } catch (e) {
                    console.error("error.code", e);
                    this.clearLocalStorage();
                }
            })
            .catch((error) => {
                console.error("error.response", error);
                hideLoader();
                this.setState({ error: true });
            });

        userService
            .getAppVersion()
            .then((res) => {
                console.log(
                    `%cApplication build: ${res.data.build}`,
                    "color: #497799; border: 2px solid #497799; padding: 5px; "
                );
            })
            .catch((error) => {
                console.error("error.response", error);
            });
    }

    getParametersArtifacts = () => {
        let manifest = JSON.parse(localStorage.getItem("manifest"));
        let artifact = manifest.artifacts.find(
            (item) => item.artifact_type_id === 2
        );
        return userService
            .getArtifact(artifact["id"] || "")
            .then((res) => {
                const artifact = res.data.data;
                this.props.onAddArtifacts(artifact);
                localStorage.setItem("parameters", JSON.stringify(artifact));
            })
            .catch((error) => {
                console.error("error.response", error);
                this.clearLocalStorage();
                hideLoader();
                this.setState({ error: true });
            });
    };

    getLocalizationArtifacts = () => {
        let manifest = JSON.parse(localStorage.getItem("manifest"));
        let artifact = manifest.artifacts.find(
            (item) => item.artifact_type_id === 8
        );
        return userService
            .getArtifact(artifact["id"] || "")
            .then((res) => {
                this.props.onAddlocalization(res.data.data);
                localStorage.setItem(
                    "localization",
                    JSON.stringify(res.data.data)
                );
            })
            .catch((error) => {
                console.error("error.response", error);
                this.clearLocalStorage();
                hideLoader();
                this.setState({ error: true });
            });
    };

    clearLocalStorage = () => {
        localStorage.removeItem("manifest");
        localStorage.removeItem("parameters");
        localStorage.removeItem("localization");
    };

    render() {
        const { show, error } = this.state;

        return (
            <>
                <FocusVisible className="js-focus-visible focus-visible">
                    {show && <Routes />}
                    {error && <InternalServer />}
                </FocusVisible>
            </>
        );
    }
}

export default connect(
    (state) => ({
        artifacts: state.artifacts,
        localization: state.localization,
    }),
    (dispatch) => ({
        onAddArtifacts: (artifacts) => {
            dispatch({ type: "UPDATE_ARTIFACTS", payload: artifacts });
        },
        onAddlocalization: (data) => {
            dispatch({ type: "ADD_LOCALIZATION", payload: data });
        },
        onSetLocalization: (localization) => {
            dispatch({ type: "SET_LOCALIZATION", payload: localization });
        },
        onSetExternalLocalization: (localization) => {
            dispatch({
                type: "SET_EXTERNAL_LOCALIZATION",
                payload: localization,
            });
        },
        onSetDefaultLocalization: (localization) => {
            dispatch({
                type: "SET_DEFAULT_LOCALIZATION",
                payload: localization,
            });
        },
    })
)(IndyGo);
