import React from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import { Button } from "../../components/lib/components/button";
import { Icon } from "../../components/lib/components/icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { faRedo } from "@fortawesome/pro-light-svg-icons";
import { Level } from "../../components/lib/components/level";
import { Modal } from "../../components/lib/components/modal";
import { Columns } from "../../components/lib/components/columns";
import { cardService } from "../../_services";
import { getStatusColor, setFocusPage, convertCurrencyToWords } from "../../_helpers";
import ModalAddFareCard from "./ModalAddFareCard";
import update from "immutability-helper";

class FareCards extends React.Component {

  _isMounted = false;
  state = {
    activeModal: "",
    show: false,
    showFormValue: true,
    showFormSuccess: false,
    showFormError: false,
    farecardnumber: "",
    securitycode: "",
    cardname: "",
    mobileCardNumber: null,
    mobileCardBalance: null,
    fareCards: null,
    errFareCardNumber: "",
    loadingDigital: false
  }

  componentDidMount() {
    this._isMounted = true;
    this.getMobileCard();
    this.getFareCards();
    // focus page
    setFocusPage("#fare-cards-page");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  open = activeModal => {
    this.setState({
      activeModal,
      showFormValue: true,
      showFormSuccess: false,
      showFormError: false,
      show: true
    }, () => {
      // focus page
      // setFocusPage(`#${this.state.activeModal}showFormValue`);
    })
  }

  close = () => {
    this.setState({
      show: false,
      showFormValue: false,
      showFormSuccess: false,
      showFormError: false,
      farecardnumber: "",
      securitycode: "",
      cardname: ""
    })
  }

  onChange = event => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value,
      errSecurityCode: ""
    }, () => {
      // check pin code
      const { securitycode } = this.state;
      if (securitycode && (securitycode.match(/[^0-9\.]/g) || securitycode.length !== 4)) {
        this.setState({
          errSecurityCode: this.props.labels["FARE_CARDS_SECURITY_CODE_ERROR_INCORECT"]
        })
      }
    })
  }

  onNumberFormatChange = event => {
    this.setState({
      farecardnumber: event.value,
      errFareCardNumber: ""
    }, () => {
      // validate fare card number
      const { farecardnumber } = this.state;
      if (farecardnumber && farecardnumber.length < 16) {
        this.setState({
          errFareCardNumber: this.props.labels["FARE_CARDS_NUMBER_ERROR_INCORECT"]
        })
      }
    })
  }

  setStep = step => {
    this.setState({
      showFormValue: false,
      showFormSuccess: false,
      showFormError: false,
      [step]: true
    }, () => {
      // setFocusPage(`#${this.state.activeModal + step}`);
    })
  }

  onSignIn = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onLoader("yes");

    const body = {
      "secret": this.state.securitycode,
      "ticket_number": this.state.farecardnumber.replace(/\s/g, ""),
      "label": this.state.cardname
    }
    cardService.addFareCard(body)
      .then(res => {
        console.log()
        this.setStep("showFormSuccess");
        this.props.onLoader("no");
        this.getFareCards();
      })
      .catch(err => {
        console.error("error.response", err.response);
        this.setStep("showFormError");
        this.props.onLoader("no");
        this.getFareCards();
        // redirect to login page
        if (err.response.status === 401) {
          this.props.router.push("/login");
        }
      })
  }

  getMobileCard = () => {
    this.props.onLoader("yes");
    this.setState({ loadingDigital: true });
    cardService.getFareCardDigital()
      .then(res => {
        if (this._isMounted) {
          this.setState({
            mobileCardBalance: res.data.data.balance.formatted,
            mobileCardNumber: res.data.data.id,
            loadingDigital: false
          });
          this.props.onLoader("no");
        }
      })
      .catch(err => {
        console.error("error.response", err.response);
        this.setState({ loadingDigital: false });
        this.props.onLoader("no");
        // redirect to login page
        if (err.response.status === 401) {
          this.props.router.push("/login");
        }
      })
  }

  getFareCards = () => {
    this.props.onLoader("yes");
    cardService.getCareCards()
      .then(res => {
        if (this._isMounted) {
          const fareCards = res.data.data || [];
          fareCards.map(card => card.isLoading = false);
          this.setState({ fareCards });
        }
        this.props.onLoader("no");
      })
      .catch(err => {
        console.error("error.response", err.response);
        this.props.onLoader("no");
        // redirect to login page
        if (err.response.status === 401) {
          this.props.router.push("/login");
        }
      })
  }

  updateFareCards = index => {
    const fareCards = this.state.fareCards;
    const updateFareCards = update(fareCards[index], { isLoading: { $set: fareCards[index].isLoading == true ? false : true } })
    const newFareCards = update(fareCards, {
      $splice: [[index, 1, updateFareCards]]
    });
    this.setState({ fareCards: newFareCards }, () => {
      this.getFareCards();
    });
  }

  render() {
    const { activeModal, showFormValue, showFormSuccess, showFormError, farecardnumber, securitycode, cardname, show, mobileCardBalance, fareCards, mobileCardNumber, errSecurityCode, errFareCardNumber, loadingDigital } = this.state;
    const { labels, tts, router } = this.props;

    return (
      <DocumentTitle title={labels["FARE_CARDS_PAGE_TITLE"] + " - IndyGo App"}>
        <div id="fare-cards-page" aria-label={labels["FARE_CARDS_PAGE_TITLE"]}>

          <div className="is-flex-tablet pad-bottom-xl pad-top-xl" style={{ alignItems: "flex-end" }}>
            <h1 className="is-size-3 is-size-2-tablet has-text-weight-bold push-right-lg push-bottom-sm" style={{ lineHeight: 1 }}>
              {labels["PROFILE_FARE_CARD"]}
            </h1>
            <Button
              color="primary"
              size="small"
              className="push-bottom-sm"
              onClick={() => this.open("modalAddFareCard")}
            >
              <Icon>
                <FontAwesomeIcon icon={faPlusCircle} size="2x" className="has-text-warning push-right-md" />
              </Icon>
              {labels["BUTTON_ADD_FARE_CARD"]}
            </Button>
          </div>

          {mobileCardBalance && mobileCardNumber && fareCards && (
            <Columns className="is-multiline">
              <Columns.Column
                tabIndex="0"
                renderAs="section"
                aria-label={`${labels["FARE_CARD_MOBILE_CARD"]}. ${tts["FARE_CARD_BALANCE_IS"]} ` + convertCurrencyToWords(mobileCardBalance)}
                size={10}
                className="line">
                <Level className="level--card push-bottom-xl">
                  <Level.Side align="left">
                    <Level.Item className="has-text-centered">
                      <div className="push-bottom-sm">
                        <FontAwesomeIcon style={{ width: 16, height: 16 }} className="has-text-success push-right-md push-bottom-xxl" icon={faCircle} />
                      </div>
                      <img src="/assets/img/navoverlay.png" height="140" alt={labels["FARE_CARD_MOBILE_CARD"]} />
                    </Level.Item>
                  </Level.Side>
                  <Level.Side align="right">
                    <div>
                      <p className="has-text-grey-dark" style={{ marginTop: -5 }}>{labels["WELCOME_BALANCE_TEXT"]}</p>
                      <p className="level__card-price" aria-label={convertCurrencyToWords(mobileCardBalance)}>
                        {mobileCardBalance}
                        <button
                          type="button"
                          className="pad-sm is-borderless"
                          onClick={this.getMobileCard}
                          aria-label={tts["FARE_CARD_RELOAD_BALANCE"]}
                        >
                          <FontAwesomeIcon icon={faRedo} spin={loadingDigital} className="is-size-6 has-text-primary" />
                        </button>
                      </p>
                    </div>
                    <Button
                      color="info"
                      size="small"
                      onClick={() => router.push("/fare-card-qr/" + mobileCardNumber)}
                      title={labels["BUTTON_MANAGE"]}
                      disabled={loadingDigital}
                    >
                      {labels["BUTTON_MANAGE"]}
                    </Button>
                  </Level.Side>
                </Level>
              </Columns.Column>

              {fareCards.map((card, idx) => (
                <Columns.Column
                  key={card.id}
                  tabIndex="0"
                  aria-label={`${card.label || labels["FARE_CARD"]}. ${tts["FARE_CARD_BALANCE_IS"]} ${convertCurrencyToWords(card.balance.formatted)}`}
                  renderAs="section"
                  size={10}
                >
                  <div className="has-text-error start centered has-text-weight-semibold push-bottom-md pad-bottom-sm push-top-sm">
                    <FontAwesomeIcon className={getStatusColor(card.status)} style={{ width: 16, height: 16 }} icon={faCircle} />
                    <div className="push-left-sm pad-left-xs is-size-6">
                      {card.label || labels["FARE_CARD"]}
                    </div>
                  </div>

                  <Level className="level level--card push-bottom-xl">
                    <Level.Side align="left">
                      <Level.Item className="is-flex is-column">
                        <img src="/assets/img/MyKey_Card.png" height="110" alt={labels["FARE_CARD"]} />
                      </Level.Item>
                    </Level.Side>
                    <Level.Side align="right">
                      <div>
                        <p className="has-text-grey-dark" style={{ marginTop: -5 }}>{labels["WELCOME_BALANCE_TEXT"]}</p>
                        <p className="level__card-price" aria-label={convertCurrencyToWords(card.balance.formatted)}>
                          {card.balance.formatted}
                          <button
                            type="button"
                            className="pad-sm is-borderless"
                            onClick={() => this.updateFareCards(idx)}
                            aria-label={tts["FARE_CARD_RELOAD_BALANCE"]}
                          >
                            <FontAwesomeIcon icon={faRedo} spin={card.isLoading} className="is-size-6 has-text-primary" />
                          </button>
                        </p>
                      </div>
                      <Button
                        color="info"
                        size="small"
                        onClick={() => router.push("/fare-card/" + card.id)}
                        title={labels["BUTTON_MANAGE"]}
                        disabled={card.isLoading}
                      >
                        {labels["BUTTON_MANAGE"]}
                      </Button>
                    </Level.Side>
                  </Level>
                </Columns.Column>
              ))}
            </Columns>
          )}

          <Modal showClose={false} show={show} onClose={this.close}>
            <Modal.Content>
              <Modal.Card>
                <Modal.Card.Head onClose={this.close}></Modal.Card.Head>
                <Modal.Card.Body className="centered">

                  {/* Modal Add FareCard */}
                  <ModalAddFareCard
                    activeModal={activeModal}
                    showFormValue={showFormValue}
                    showFormSuccess={showFormSuccess}
                    showFormError={showFormError}
                    farecardnumber={farecardnumber}
                    errFareCardNumber={errFareCardNumber}
                    errSecurityCode={errSecurityCode}
                    securitycode={securitycode}
                    cardname={cardname}
                    onSignIn={this.onSignIn}
                    onNumberFormatChange={this.onNumberFormatChange}
                    onChange={this.onChange}
                    close={this.close}
                    setStep={this.setStep}
                  ></ModalAddFareCard>

                </Modal.Card.Body>
              </Modal.Card>
            </Modal.Content>
          </Modal>

        </div>
      </DocumentTitle>
    )
  }
}

export default connect(
  state => ({
    loader: state.loader,
    labels: state.localization.labels,
    tts: state.localization.tts
  }),
  dispatch => ({
    onLoader: (status) => {
      dispatch({ type: 'SET_STATUS', payload: status })
    }
  })
)(FareCards)