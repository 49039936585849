import React from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import {
  Field,
  Control,
  Label,
  Input
} from "../components/lib/components/form";
import { Icon } from "../components/lib/components/icon";
import { Button } from "../components/lib/components/button";
import { Columns } from "../components/lib/components/columns";
import { userService } from "../_services";

class Login extends React.Component {

  state = {
    username: "",
    password: "",
    passIcon: false,
    loading: false,
    errors: {}
  }

  onChange = evt => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    this.setState({
      [evt.target.name]: value,
    });
    this.setState({ errors: {} })
  }

  onSignIn = event => {
    event.preventDefault();
    const { username, password } = this.state;
    const { onAddToken, router } = this.props;
    if (username && password) {
      this.setState({ loading: true });
      userService.login(username, password)
        .then(res => {
          const token = res.data.access_token;
          onAddToken(token);
          localStorage.setItem("token", token);
            
          if (!localStorage.getItem("lastUrl")) {
            router.push("/welcome");
          } else {
            // case automatic redirect to deep link after login
            router.push(localStorage.getItem("lastUrl"));
          }
        })
        .catch(error => {
          try {
            this.setState({ errors: { username: error.response.data.message }}, () => {
              let focusMessage = document.getElementById("login-message-error");
              focusMessage.setAttribute("tabindex", "-1");
              focusMessage.style.outline = "none";
              focusMessage.focus();
            });
            this.setState({ loading: false });
          } catch (e) { console.error(e) }
        })
    }
  }

  changePassIcon = () => {
    this.setState((state) => ({
      passIcon: !state.passIcon
    }))
  }

  render() {
    const { username, password, errors, loading, passIcon } = this.state;
    const { labels } = this.props;
    const isEnabled = username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); // && passwordRegex.test(password);

    return (
      <DocumentTitle title={labels["LOGIN_PAGE_TITLE"] + " - IndyGo App"}>
        <div className="container container--fluid-md pad-top-md pad-bottom-md">
          <Columns>

            <Columns.Column size={4}>
              <h1 className="title is-1 has-text-weight-bold has-letter-spacing has-text-primary">
                {labels["LOGIN_TITLE"]}
              </h1>
              <p className="has-text-grey-light push-bottom-lg">
                <span dangerouslySetInnerHTML={{__html: labels["LOGIN_TEXT"]}}></span>
                <Link 
                  title={labels["LOGIN_CREATE_ACCOUNT"]} 
                  to="/create-account" 
                  className="has-text-info"
                >
                  {labels["LOGIN_CREATE_ACCOUNT"]}
                </Link> {labels["LOGIN_TEXT_REGISTER"]}.
              </p>
            </Columns.Column>

            <Columns.Column size={4} offset={1}>
              <form className="pad-top-md pad-bottom-md" onSubmit={this.onSignIn}>
                
                <Field>
                  <Label
                    htmlFor="login-username"
                    className={`${username && !errors["username"] ? "has-text-grey-light" : ""} ${errors["username"] ? "has-text-danger" : ""}`}
                  >
                    {labels["LOGIN_USERNAME"]}
                  </Label>
                  <Control iconRight={true}>
                    <Input
                      id="login-username"
                      className={`${!errors["username"] ? "" : "is-danger"}`}
                      onChange={this.onChange}
                      value={username}
                      name="username"
                      placeholder={labels["LOGIN_USERNAME_PLACEHOLDER"]}
                    />
                    {errors["username"] && (
                      <>
                        <p id="login-message-error" className="help is-danger has-text-right">
                          {labels["LOGIN_ERROR_MESSAGE"]}
                        </p>
                        <Icon align="right">
                          <FontAwesomeIcon icon={faExclamationCircle} className="is-size-5 has-text-danger" />
                        </Icon>
                      </>
                    )}
                  </Control>
                </Field>

                <Field>
                  <Label
                    htmlFor="login-password"
                    className={`${password ? "has-text-grey-light" : ""}`}
                  >
                    {labels["LOGIN_PASSWORD"]}
                  </Label>
                  <Control className="has-icons-right-second" iconRight={true}>
                    <Input
                      id="login-password"
                      onChange={this.onChange}
                      value={password}
                      name="password"
                      type={passIcon ? "text" : "password"}
                      placeholder={labels["LOGIN_PASSWORD_PLACEHOLDER"]}
                    />
                    <Icon align="right">
                      <FontAwesomeIcon 
                        onClick={this.changePassIcon}
                        icon={passIcon ? faEye : faEyeSlash}
                        className="has-text-black"
                      />
                    </Icon>                     
                  </Control>
                </Field> 

                <Button
                  title={labels["BUTTON_SIGN_IN"]}
                  disabled={!isEnabled}
                  loading={loading}
                  color="info"
                  size="small"
                >
                  {labels["BUTTON_SIGN_IN"]}
                </Button>

                <p className="has-text-grey pad-top-md">
                  {labels["LOGIN_FORGOT_PASSWORD"]} 
                  <Link 
                    to="/password-reset"
                    title={labels["LOGIN_RESET_PASSWORD"]}
                    className="push-left-sm has-text-info"
                  >
                    {labels["LOGIN_RESET_PASSWORD"]}
                  </Link>
                </p>
              
              </form>
            </Columns.Column>

          </Columns>     
        </div>
      </DocumentTitle>
    )
  }
}
  
export default connect(
  state => ({
    labels: state.localization.external.labels
  }),
  dispatch => ({
    onAddToken: (token) => {
      dispatch({type: "ADD_TOKEN", payload: token})
    }
  })
)(Login)