import React from 'react'
import { connect } from "react-redux";
import { Button } from '../../components/lib/components/button';
import { Icon } from '../../components/lib/components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import NumberFormat from 'react-number-format';
import { Field, Checkbox } from '../../components/lib/components/form';
import { Dropdown } from '../../components/lib/components/dropdown';
import ModalView from '../../components/lib/components/view/ModalView';
import { setFocusElement } from '../../_helpers';

const ModalPayCitations = ({
    activeModal,
    showFormValue,
    showFormSuccess,
    showFormError,
    labels,
    onChange,
    close,
    setStep,
    openAddCard,
    paymentCardsList,
    onChangeWallet,
    onSubmit,
    fullAmountTitle,
    fullAmount,
    walletId,
    isTermsAccepted,
    refreshCitations,
    loader
}) => {
    return (
        <>
            <ModalView
                name="modalPayCitations"
                activeModal={activeModal}
                active={showFormValue}
                viewType="ShowFormValue"
                align="left"
                head={labels['CITATIONS_MODAL_PAYMENT_INFO']}
                messages={[labels["CITATIONS_MODAL_PAYMENT_CAPTION"]]}
                focusOptions={{
                    initialFocus: `#fullAmountTitle`,
                    setReturnFocus: `#btnPayCitations`,
                    onDeactivate: () => {
                        //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
                        setTimeout(() => {
                            setFocusElement(`#btnPayCitations`)
                        })
                    }
                }}
            >
                <div className="balance-card balance-card--medium push-top-xl pad-bottom-xl push-bottom-md line">
                    <p
                        // tabIndex="0"
                        aria-label={`${labels["CITATIONS_AMOUNT"]} is ${fullAmountTitle}`}
                        className="has-text-grey-dark is-size-4 has-text-weight-semibold"> {labels["CITATIONS_AMOUNT"]}</p>
                    <p
                        tabIndex="-1"
                        id="fullAmountTitle"
                        aria-label={`${labels["CITATIONS_AMOUNT"]} is ${fullAmountTitle}`}
                        className="is-flex space-between is-size-10 pull-top-lg has-text-weight-light">
                        <NumberFormat value={fullAmount} aria-label={fullAmountTitle} displayType={'text'} decimalSeparator={"."} fixedDecimalScale={true} decimalScale={2} prefix={'$'} />
                    </p>
                </div>
                {paymentCardsList.length === 0 && (
                    <div className="push-bottom-lg line" aria-describedby="no_payment_title">
                        <p id="no_payment_title" className="has-text-grey-dark push-bottom-sm">{labels["FARE_CARD_NO_PAYMENTS"]}</p>
                        <button
                            type="button"
                            className="is-size-7 has-text-primary is-clickable pad-sm is-borderless has-background-none"
                            onClick={() => openAddCard("modalAddCard")}
                        >
                            <Icon size="small" className="icon-add-new-card push-right-sm">
                                <FontAwesomeIcon icon={faPlus} className="is-size-6" />
                            </Icon>
                            {labels["BUTTON_ADD_NEW_CARD"]}
                        </button>
                    </div>
                )}
                {paymentCardsList.length !== 0 && (
                    <div className="line">
                        <p id="payment_modal_label" className="has-text-grey-dark has-text-weight-semibold push-bottom-md">
                            {labels["CITATIONS_MODAL_PAYMENT"]}
                        </p>
                        <Dropdown
                            id="payment_cards_dropdown"
                            value={walletId}
                            onChange={onChangeWallet}
                            className="pad-bottom-md"
                            label={labels["OPTIONS_DROPDOWN_LABEL"]}
                            labelledBy="payment_modal_label"
                        >
                            {paymentCardsList.map((data, k) => (
                                <Dropdown.Item
                                    id={`payment_${k + 1}`}
                                    key={k}
                                    value={data.id}
                                    aria-label={`${data.card_brand}: ${data.last_4_digits}. ${data.card_label ? data.card_label : ""}`}
                                >
                                    <div className="dropdown-item__box">
                                        <div className="dropdown-item__box__image">
                                            <Icon style={{ width: 20, height: 22 }}>
                                                <img
                                                    src={`/assets/img/brand/${data.card_brand.toUpperCase()}.png`}
                                                    alt=""
                                                    aria-hidden="true"
                                                />
                                            </Icon>
                                        </div>
                                        <div className="dropdown-item__box__description">
                                            <p>XXXX-XXXX-XXXX-{data.last_4_digits}</p>
                                            <p>{data.card_label}</p>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            ))}
                            <button
                                type="button"
                                className="item-add-card"
                                role={labels["BUTTON_ADD_NEW_CARD"]}
                                onClick={() => openAddCard("modalAddCard")}
                            >
                                <Icon size="small" className="push-right-md">
                                    <FontAwesomeIcon className="is-size-6" icon={faPlus} />
                                </Icon>
                                <span>{labels["BUTTON_ADD_NEW_CARD"]}</span>
                            </button>
                        </Dropdown>
                    </div>
                )}
                <Field className="push-top-md">
                    <label className="checkbox">
                        <Checkbox
                            name="isTermsAccepted"
                            checked={isTermsAccepted}
                            onChange={onChange}
                        />
                        <span className="is-size-8 has-text-grey-dark">{labels["GDPR_CHECKBOX_AGREE"]} <a tabIndex="-1" className="has-text-info" target="_blank" href="https://www.indygo.net/mykey-terms-and-conditions/">{labels["GDPR_CHECKBOX_TERMS"]}</a> {labels["GDPR_CHECKBOX_AND"]} <a tabIndex="-1" className="has-text-info" target="_blank" href="https://www.indygo.net/mykey-privacy-policy/">{labels["GDPR_CHECKBOX_PRIVACY"]}.</a></span>
                    </label>
                </Field>
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        loading={loader === 'yes'}
                        onClick={onSubmit}
                        disabled={!(isTermsAccepted == true) || loader === 'yes' || fullAmount <= 0}
                        title={labels["BUTTON_PAY_NOW"]}
                    >
                        {labels["BUTTON_PAY_NOW"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        disabled={loader === 'yes'}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
            <ModalView
                name="modalPayCitations"
                activeModal={activeModal}
                active={showFormSuccess}
                icon="success"
                viewType="ShowFormSuccess"
                head={labels["MODAL_TITLE_PAYMENT_CONFIRMED"]}
                messages={[labels["CITATIONS_MODAL_SUCCESS"]]}
            // containerLabel={labels["MODAL_TITLE_PAYMENT_CONFIRMED"]}
            >
                <Button
                    className="push-top-xl"
                    size="small"
                    color="info"
                    style={{ minWidth: 190 }}
                    title={labels["BUTTON_CONTINUE"]}
                    onClick={refreshCitations}
                >
                    {labels["BUTTON_CONTINUE"]}
                </Button>
            </ModalView>
            <ModalView
                name="modalPayCitations"
                activeModal={activeModal}
                active={showFormError}
                viewType="ShowFormError"
                icon="danger"
                head={labels["MODAL_TITLE_ERROR"]}
                messages={[labels["CITATIONS_MODAL_ERROR_TEXT_1"], labels["CITATIONS_MODAL_ERROR_TEXT_2"], labels["CITATIONS_MODAL_ERROR_TEXT_3"]]}
                focusOptions={{
                    setReturnFocus: `#btnPayCitations`,
                    onDeactivate: () => {
                        //TODO: Hard. Because setReturnFocus does not work. Need investigation setReturnFocus
                        setTimeout(() => {
                            setFocusElement(`#btnPayCitations`)
                        })
                    }
                }}
            >
                <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        color="info"
                        style={{ minWidth: 190, marginRight: 5 }}
                        onClick={() => setStep('showFormValue')}
                        title={labels["BUTTON_TRY_AGAIN"]}
                    >
                        {labels["BUTTON_TRY_AGAIN"]}
                    </Button>
                    <Button
                        outlined={true}
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={close}
                        title={labels["BUTTON_CANCEL"]}
                    >
                        {labels["BUTTON_CANCEL"]}
                    </Button>
                </Button.Group>
            </ModalView>
        </>
    )
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels,
      }),
      dispatch => ({})
)(ModalPayCitations)