import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';
import { Link } from "react-router";
import { Button } from '../../../components/lib/components/button';
import { Box } from '../../../components/lib/components/box';
import { Breadcrumb } from '../../../components/lib/components/breadcrumb';
import { Icon } from '../../../components/lib/components/icon';
import { Level } from '../../../components/lib/components/level';
import { Modal } from '../../../components/lib/components/modal';
import { Columns } from '../../../components/lib/components/columns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCircle } from '@fortawesome/free-solid-svg-icons'
import { faPencil, faRedo } from "@fortawesome/pro-light-svg-icons";
import { cardService, paymentService, personalService } from '../../../_services';
import { getStatusColor, loadScript, setFocusPage, convertCurrencyToWords, setFocusElement, convertFareCardNumber } from '../../../_helpers';
import ModalAddValue from '../modals/ModalAddValue';
import ModalEditLabel from '../modals/ModalEditLabel';
import ModalAutoReload from '../modals/ModalAutoReload';
import ModalTransfer from '../modals/ModalTransfer';
import ModalLinkFareCard from '../modals/ModalLinkFareCard';
import ModalDeleteCard from '../modals/ModalDeleteCard';
import ModalReportCard from '../modals/ModalReportCard';
import ModalTrapAddCard from '../../PaymentOptions/ModalTrapAddCard'
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import numWords from 'num-words';

class FareCard extends React.Component {
    state = {
        resultUrl: '',
        data: '',
        max: 50,
        min: 5,
        step: 5,
        email: "",
        paymentCardsList: [],
        activeModal: '',
        show: false,
        isTermsAccepted: false,
        termsAcceptedAddValue: false,
        showFormValue: false,
        showFormSuccess: false,
        showFormError: false,
        showFormLocked: false,
        amountReload: 20,
        amountReload_default: 20,
        balance: 5,
        balance_default: 10,
        addValueReload: 5,
        addValue: 5,
        addValueWallet: null,
        fareCardsList: [],
        isActivateAutoReload: false,
        loadingFareCardsList: false,
        personalEmail: "",
        disabledEmail: true,
        digitalCard: '',
        invitedUsers: [],
        isLoading: false,
        isLoadingPayment: false,
        digital_balance: " $-.--",
        digital_balance_word: ''
    }

    decrease = (field) => {
        let num = this.state[field];
        if (field === 'amountReload' && this.state.amountReload <= (this.state.balance + this.state.step)) {
            return '';
        }
        if (num > this.state.min) {
            num -= this.state.step;
        }
        this.setState({ [field]: Math.abs(num) }, () => {
            // setFocusPage(`#${field}`)
        });
    }

    increase = (field) => {
        let num = this.state[field];
        if (num < this.state.min) {
            num = this.state.min;
        }
        if (field === 'balance') {
            if (num < this.state.max - this.state.step) {
                num += this.state.step;
            }
        }
        else {
            if (num < this.state.max) {
                num += this.state.step;
            }
        }
        this.setState({ [field]: Math.abs(num) }, () => {
            // setFocusPage(`#${field}`)
        });
        if (field === 'balance' && (this.state.balance + this.state.step) >= this.state.amountReload) {
            this.setState({ 'amountReload': num + this.state.step });
        }
    }

    decreaseAutoReload = (field) => {
        const step = this.props.artifacts.parameters.reload_step / 100 || this.state.step;
        let num = this.state[field];
        if (field === 'amountReload' && this.state.amountReload <= (this.state.balance + this.state.step)) {
            return '';
        }
        if (num > this.state.min) {
            num -= step;
        }
        this.setState({ [field]: Math.abs(num) }, () => {
            // setFocusPage(`#${field}`)
        });
    }

    increaseAutoReload = (field) => {
        const max = this.props.artifacts.parameters.max_amount_auto_reload / 100 || this.state.max;
        const step = this.props.artifacts.parameters.reload_step / 100 || this.state.step;
        let num = this.state[field];
        if (num < this.state.min) {
            num = this.state.min;
        }
        if (field === 'balance') {
            if (num < max - step) {
                num += step;
            }
        }
        else {
            if (num < max) {
                num += step;
            }
        }
        this.setState({ [field]: Math.abs(num) }, () => {
            // setFocusPage(`#${field}`)
        });
        if (field === 'balance' && (this.state.balance + step) >= this.state.amountReload) {
            this.setState({ 'amountReload': num + step });
        }
    }

    open = (activeModal) => {
        if (activeModal == "modalAddValue") {
            this.getPaymentCardsList();
        }
        this.setState({
            activeModal,
            showFormValue: true,
            showFormSuccess: false,
            showFormError: false,
            showFormLocked: false,
            cardName: this.state.data.label ? this.state.data.label : this.props.labels["FARE_CARD"],
            show: true
        });
    }

    close = () => {
        if (localStorage.getItem('activeModal')) {
            switch (localStorage.getItem('activeModal')) {
                case 'modalAutoReload': this.onAutoReloas(); break;
                case 'modalAddValue': this.open('modalAddValue'); break;
                default: ;
            }
            const state = JSON.parse(localStorage.getItem('state'));
            if (typeof state === 'object' && state !== null) {
                this.setState({ ...state })
            }

            localStorage.removeItem('activeModal');
            localStorage.removeItem('state');
            return;
        }
        this.setState({
            show: false,
            showFormSuccess: false,
            showFormError: false,
            termsAcceptedAddValue: false,
            isTermsAccepted: false,
            showFormValue: true,
            showFormLocked: false,
            addValueReload: 1,
            addValue: 5,
            amountReload: (this.state.data['auto_refill'] && this.state.data['auto_refill']['auto_refill_amount_int']) ? this.state.data['auto_refill']['auto_refill_amount_int'] / 100 : this.state.amountReload_default,
            balance: (this.state.data['auto_refill'] && this.state.data['auto_refill']['auto_refill_threshold_int']) ? this.state.data['auto_refill']['auto_refill_threshold_int'] / 100 : this.state.balance_default
        });
    }

    onChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            [event.target.name]: value,
            disabledEmail: true
        }, () => {
            // enable send email
            if (this.state.email != '' && this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && this.state.email != this.state.personalEmail) {
                this.setState({
                    disabledEmail: false
                })
            }
        });
    }

    onContinueTranferBalance = () => {
        this.getFareCard();
        this.close();
        this.getPaymentCardsList();
        this.getDigital();
    }

    onChangeWalletAddValue = addValueWallet => this.setState({ addValueWallet });

    setStep = (step) => {
        this.setState({
            showFormValue: false,
            showFormSuccess: false,
            showFormError: false,
            showFormLocked: false,
            [step]: true
        });
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {

            // set resultUrl
            this.setState({
                resultUrl: window.location.origin + '/fare-card/' + this.props.params['id']
            });

            // add new payments list
            if (this.props.location.query.id != undefined) {
                this.props.onLoader('yes');
                let body = {
                    checkoutId: this.props.location.query.id
                }
                paymentService.checkCardStatus(body)
                    .then(res => {
                        this.setState({
                            activeModal: 'modalAddCard',
                            show: true,
                            showFormSuccess: true
                        })
                        setTimeout(() => {
                            this.props.router.push('/fare-card/' + this.props.params['id']);
                            this.getPaymentCardsList();
                        }, 500)
                    })
                    .catch((error) => {
                        this.props.onLoader('no');
                        this.setState({
                            activeModal: 'modalAddCard',
                            show: true,
                            showFormError: true
                        })
                        this.getPaymentCardsList();
                        setTimeout(() => {
                            this.setState({
                                error: ''
                            });
                            this.props.router.push('/fare-card/' + this.props.params['id']);
                        }, 1000)
                        // redirect to login page
                        if (error.response.status == 401) {
                            this.props.router.push('/login');
                        }
                    })
            } else {
                localStorage.removeItem('activeModal');
                localStorage.removeItem('state');
                this.getPaymentCardsList();
            }
            Promise.all([this.getFareCard()])
                .then(res => {
                    // focus page
                    setFocusPage(`#fare-card-page`);
                })
                .catch(error => {
                    this.props.onLoader('no');
                    console.error(`Error in executing ${error}`)
                })
            this.getDigital();
            this.getUserData();
        }
    }

    getDigital = () => {
        this.props.onLoader('yes');
        cardService.getFareCardDigital()
            .then(res => {
                try {
                    this.setState({
                        digitalCard: res.data.data
                    })
                    this.getFareCardsList();
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    getFareCard = () => {
        this.props.onLoader('yes');
        return cardService.getFareCard()
            .then(res => {
                try {
                    let data = res.data.data.filter(object => object.id == this.props.params['id'])[0];
                    this.setState({
                        data: data,
                        amountReload: (data['auto_refill'] && data['auto_refill']['auto_refill_amount_int']) ? data['auto_refill']['auto_refill_amount_int'] / 100 : this.state.amountReload_default,
                        balance: (data['auto_refill'] && data['auto_refill']['auto_refill_threshold_int']) ? data['auto_refill']['auto_refill_threshold_int'] / 100 : this.state.balance_default,
                        isActivateAutoReload: (data['auto_refill'] && data['auto_refill']['auto_refill_active']) ? true : false,
                        digital_balance: data.balance.formatted
                    }, () => {
                        // convert currency to words
                        const firtCurrency = this.state.digital_balance.split("$")[1].split(".")[0];
                        const lastCurrency = this.state.digital_balance.split("$")[1].split(".")[1];

                        this.setState({
                            digital_balance_word: (lastCurrency !== '00' && lastCurrency !== '00)') ? numWords(firtCurrency) + ' dollar ' + numWords(lastCurrency) + ' cents' : numWords(firtCurrency) + ' dollar'
                        })
                    })
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    getPaymentCardsList = () => {
        this.props.onLoader('yes');
        this.setState({
            isLoadingPayment: true
        })
        paymentService.getPaymentCardsList()
            .then(res => {
                try {
                    this.setState({
                        isLoadingPayment: false,
                        paymentCardsList: res.data.data,
                        addValueWallet: (res.data.data.length) ? res.data.data.filter((item, i) => item.is_primary == 1 || i == res.data.data.length - 1)[0]['id'] : null
                    })
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.props.onLoader('no');
                this.setState({
                    isLoadingPayment: false
                })
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    getFareCardsList = () => {
        this.props.onLoader('yes');
        this.setState({
            loadingFareCardsList: true
        })
        cardService.getCareCards()
            .then(res => {
                try {
                    this.setState({
                        fareCardsList: res.data.data.filter(item => item.id != this.state.data['id']),
                        loadingFareCardsList: false
                    }, () => {
                        this.setState({
                            fareCardsList: this.state.fareCardsList.concat(this.state.digitalCard)
                        })
                    })
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.props.onLoader('no');
                this.setState({
                    loadingFareCardsList: false
                })
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    onAddValue = () => {
        const body = {
            value: this.state.addValue * 100,
            payment_card_id: this.state.addValueWallet
        }
        this.props.onLoader('yes');
        cardService.addValue(this.state.data['id'], body)
            .then(res => {
                try {
                    this.setState({
                        data: res.data.data
                    }, () => {
                        this.getFareCard();
                        this.getDigital();
                    })
                    this.setStep('showFormSuccess');
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.setStep('showFormError');
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    onEditLabel = () => {
        const body = {
            label: this.state.cardName
        };
        this.props.onLoader('yes');
        cardService.editLabel(this.state.data['id'], body)
            .then(res => {
                try {
                    this.setState({
                        data: res.data.data
                    });
                    this.close();
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.setStep('showFormError');
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    onAutoRefill = () => {
        const body = {
            payment_card_id: this.state.addValueWallet,
            threshold: this.state.balance * 100,
            amount: this.state.amountReload * 100,
            is_active: (this.state.isActivateAutoReload) ? 1 : 0
        }
        this.props.onLoader('yes');
        cardService.autoRefill(this.state.data['id'], body)
            .then(res => {
                try {
                    this.setState({
                        data: res.data.data,
                        amountReload: res.data.data['auto_refill']['auto_refill_amount_int'] / 100,
                        balance: res.data.data['auto_refill']['auto_refill_threshold_int'] / 100,
                        isActivateAutoReload: res.data.data['auto_refill']['auto_refill_active']
                    })
                    this.setStep('showFormSuccess');
                } catch (e) {
                    console.error(e);

                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.setStep('showFormError');
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    onPress = () => {
        this.setState({
            isActivateAutoReload: !this.state.isActivateAutoReload
        }, () => {
            // this.onAutoRefill();
        })
    }

    onAutoReloas = () => {
        if (this.state.data) {
            this.setState({
                amountReload: (this.state.data['auto_refill'] && this.state.data['auto_refill']['auto_refill_amount_int']) ? this.state.data['auto_refill']['auto_refill_amount_int'] / 100 : this.state.amountReload_default,
                balance: (this.state.data['auto_refill'] && this.state.data['auto_refill']['auto_refill_threshold_int']) ? this.state.data['auto_refill']['auto_refill_threshold_int'] / 100 : this.state.balance_default,
                isActivateAutoReload: (this.state.data['auto_refill'] && this.state.data['auto_refill']['auto_refill_active']) ? true : false,
            }, () => {
                this.open('modalAutoReload');
            })
        }
    }

    onAutoLocked = () => {
        this.open('modalAutoReload');
        this.setStep('showFormLocked');
    }

    removeCard = () => {
        const { data } = this.state;
        this.props.onLoader('yes');
        cardService.deleteCard(data['id'])
            .then(res => {
                this.props.router.push('/fare-cards');
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.props.onLoader('no');
                this.setStep('showFormError')
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    reportCard = () => {
        const { data } = this.state;
        this.props.onLoader('yes');

        cardService.reportLostCard(data['id'])
            .then(res => {
                this.close();
                if (localStorage.getItem('token')) {
                    this.getFareCard();
                    this.getPaymentCardsList();
                    this.getDigital();
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.props.onLoader('no');
                this.setStep('showFormError')
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    onSignIn = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const body = {
            email: this.state.email
        }
        this.props.onLoader('yes');
        cardService.linkCard(this.props.params['id'], body)
            .then(res => {
                try {
                    this.open('modalLinkFareCard');
                    this.setStep('showFormSuccess');
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.open('modalLinkFareCard');
                this.setStep('showFormError');
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    getUserData = () => {
        // get personal data
        personalService.getPersonalData()
            .then(res => {
                const personalData = res.data.data;
                try {
                    this.setState({
                        personalEmail: personalData.email ? personalData.email : '',
                    });
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                this.setState({ errors: { username: error.response.data.message }, error });
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    getInvitedUsers = () => {
        this.open('modalLinkFareCard');
        this.setState({
            isLoading: true
        })
        cardService.getFareCardPatrons(this.props.params['id'])
            .then(res => {
                try {
                    this.setState({
                        invitedUsers: res.data.data,
                        isLoading: false
                    })
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                this.setState({ errors: { username: error.response.data.message }, error, isLoading: false });
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    deleteUser = (userId) => {
        cardService.deleteLinkOwn(this.props.params['id'], userId)
            .then(res => {
                try {
                    // refresh Invited Users
                    this.getInvitedUsers();
                } catch (e) {
                    console.error(e);
                }
                this.props.onLoader('no');
            })
            .catch((error) => {
                this.setState({ errors: { username: error.response.data.message }, error });
                this.props.onLoader('no');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    resendEmail = (userEmail) => {
        const body = {
            email: userEmail
        }

        cardService.linkCard(this.props.params['id'], body)
            .then(res => {
                try {
                    this.open('modalLinkFareCard');
                    this.setStep('showFormSuccess');
                } catch (e) {
                    console.error(e);
                }
            })
            .catch((error) => {
                console.error('error.response', error.response)
                this.open('modalLinkFareCard');
                this.setStep('showFormError');
                // redirect to login page
                if (error.response.status == 401) {
                    this.props.router.push('/login');
                }
            })
    }

    openAddCard = (activeModal) => {
        this.props.onLoader('yes');
        localStorage.setItem("activeModal", this.state.activeModal);
        localStorage.setItem("state", JSON.stringify({
            addValue: this.state['addValue'],
            termsAcceptedAddValue: this.state['termsAcceptedAddValue'],
            isActivateAutoReload: this.state['isActivateAutoReload'],
            amountReload: this.state['amountReload'],
            balance: this.state['balance'],
            isTermsAccepted: this.state['isTermsAccepted']
        }));
        this.setState({
            activeModal,
            showFormValue: true,
            showFormSuccess: false,
            showFormError: false,
            showFormLocked: false
        }, () => {
            paymentService.getPaymentFormData()
                .then(res => {
                    let body = {
                        id: "payment",
                        src: "/assets/js/payment.js?n=" + (new Date).getTime()
                    }
                    window['labels'] = this.props.labels;
                    loadScript(body, () => {
                        body = {
                            id: "form-payment",
                            src: res.data['url']
                        }
                        loadScript(body, () => {
                            this.setState({ show: true })
                            setTimeout(() => {
                                // focus page
                                setFocusElement(`#add-new-card-title`)
                                this.props.onLoader('no');
                            }, 1500)
                        });
                    });
                })
                .catch((error) => {
                    console.error('error.response', error.response);
                    this.props.onLoader('no');
                    toast.error('An error occurred while adding a payment card.', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.close();
                    // redirect to login page
                    if (error.response.status == 401) {
                        this.props.router.push('/login');
                    }
                })
        })

    }

    render() {
        const { isActivateAutoReload, activeModal, isTermsAccepted, email, amountReload, balance, termsAcceptedAddValue, showFormValue, showFormSuccess, showFormError, showFormLocked, show, cardName, paymentCardsList, addValueWallet, data, fareCardsList, disabledEmail, invitedUsers, isLoading, digital_balance, digital_balance_word, resultUrl, isLoadingPayment } = this.state;
        const { labels, tts, artifacts: { parameters } } = this.props;

        return (
            <DocumentTitle title={labels['FARE_CARD_PAGE_TITLE'] + ' - IndyGo App'}>
                <>
                    <Box>
                        <Breadcrumb
                            size="medium"
                            separatorIcon={<FontAwesomeIcon icon={faChevronRight} />}
                            items={[
                                {
                                    name: labels['PROFILE_FARE_CARD'],
                                    url: '/fare-cards',
                                },
                                {
                                    name: data['label'] ? data['label'] : labels["FARE_CARD"],
                                    url: '#2',
                                    active: true,
                                },
                            ]}
                        />
                    </Box>
                    {this.state && this.state.data &&
                        <div aria-label={data.label ? data.label + " page" : "Fare Card page"} id="fare-card-page">
                            <div className="is-flex pull-top-xl">
                                <h1 tabIndex="0" aria-label={data.label || labels["FARE_CARD"]} className="is-size-2 has-text-primary has-text-weight-bold">
                                    {data.label || labels["FARE_CARD"]}
                                </h1>
                                <Button
                                    className="is-size-3 has-text-grey-light push-left-lg"
                                    aria-label={`Edit ${data.label || labels["FARE_CARD"]}`}
                                    onClick={() => this.open('modalEditLabel')}
                                    style={{ background: 'none', border: 'none', minWidth: 'auto', height: 'auto', padding: 0, boxShadow: 'none' }}
                                >
                                    <FontAwesomeIcon icon={faPencil} />
                                </Button>
                            </div>

                            <p
                                tabIndex="0"
                                className="is-size-6 has-text-grey-light push-bottom-md"
                                aria-label={`${labels["FARE_CARD"]} ${convertFareCardNumber(data.id)}`}
                            >
                                <NumberFormat
                                    value={data.id}
                                    displayType="text"
                                    format="#### #### #### ####"
                                />
                            </p>

                            <Columns>
                                <Columns.Column size={11} className="line">
                                    <div
                                        tabIndex="0"
                                        className={`has-text-error start centered has-text-weight-semibold push-bottom-md ${getStatusColor(data.status)}`}>
                                        <FontAwesomeIcon style={{ width: 16, height: 16 }} icon={faCircle} />
                                        <div className="push-left-sm pad-left-xs is-size-6 ">
                                            {data.status}
                                        </div>
                                    </div>
                                    <Level className="level--card-medium push-bottom-xl">
                                        <Level.Side align="left">
                                            <Level.Item className="has-text-centered is-flex is-column push-right-md" style={{ alignItems: "start" }}>
                                                <img src="/assets/img/MyKey_Card.png" height="140" alt="" />
                                            </Level.Item>
                                        </Level.Side>
                                        <Level.Side align="right" className="level-right">
                                            <div>
                                                <p className="has-text-grey-dark" style={{ marginTop: -5 }}>{labels['WELCOME_BALANCE_TEXT']}</p>
                                                <p tabIndex="0" className="level__card-price is-size-11 pull-top-sm" aria-label={`${tts["FARE_CARD_BALANCE_IS"]} ` + digital_balance_word}>
                                                    {digital_balance}
                                                    <button
                                                        type="button"
                                                        className="pad-sm push-left-sm is-borderless"
                                                        onClick={this.getFareCard}
                                                        aria-label={tts["FARE_CARD_RELOAD_BALANCE"]}
                                                    >
                                                        <FontAwesomeIcon icon={faRedo} spin={this.props.loader === "yes"} className="is-size-6 has-text-primary" />
                                                    </button>
                                                </p>
                                            </div>
                                            <Button.Group>
                                                <Button
                                                    id="btnValue"
                                                    size="small"
                                                    color="info"
                                                    loading={isLoadingPayment}
                                                    onClick={() => this.open('modalAddValue')}
                                                    style={(data.status != 'Lost or Stolen') ? {} : { display: 'none' }}
                                                    title={labels["BUTTON_ADD_VALUE"]}
                                                >
                                                    {labels["BUTTON_ADD_VALUE"]}
                                                </Button>
                                                <Button
                                                    id="btnTransfer"
                                                    size="small"
                                                    color="primary"
                                                    loading={this.state.loadingFareCardsList == true}
                                                    onClick={() => this.open('modalTransfer')}
                                                    title={labels["BUTTON_TRANSFER_BALANCE"]}
                                                    style={(fareCardsList.length > 0 && data['balance_int'] > 0 && data['is_owner'] == true && data.status != 'Suspended') ? {} : { display: 'none' }}
                                                >
                                                    <Icon size="large" style={{ width: 30 }}>
                                                        <img src="/assets/icons/reply.svg" alt="" />
                                                    </Icon>
                                                    <span>{labels["BUTTON_TRANSFER_BALANCE"]}</span>
                                                </Button>
                                            </Button.Group>
                                        </Level.Side>
                                    </Level>
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <div role="actions list" className="column is-4 pad-bottom-lg">
                                    <Level className={`${isLoadingPayment == true ? "blocked" : ""} is-flex push-top-md`} style={(data.status != 'Lost or Stolen') ? {} : { display: 'none' }}>
                                        <Level.Side align="left">
                                            <Level.Item>
                                                <Icon size="large" style={{ width: 30 }}>
                                                    <img src="/assets/icons/loading.svg" alt="" aria-hidden="true" />
                                                </Icon>
                                                <div>
                                                    <Link
                                                        id="btnAutoReload"
                                                        to={this.props.location.pathname}
                                                        title={labels['FARE_CARD_AUTO_RELOAD']}
                                                        onClick={(data['auto_refill'] && data['auto_refill']['is_modifiable'] === false) ? () => this.onAutoLocked() : () => this.onAutoReloas()}
                                                        className="is-size-4 push-left-md has-text-primary">
                                                        {labels['FARE_CARD_AUTO_RELOAD']}
                                                    </Link>
                                                    {data && data['auto_refill'] && data['auto_refill']['auto_refill_active'] && (<div className="is-relative">
                                                        <p className="push-left-md is-overlay" aria-label={labels['FARE_CARD_RELOAD_SUBLINK_TEXT_1'] + ' ' + convertCurrencyToWords(data.auto_refill.auto_refill_amount) + ' ' + labels['FARE_CARD_RELOAD_SUBLINK_TEXT_2'] + ' ' + convertCurrencyToWords(data.auto_refill.auto_refill_threshold)} ></p>
                                                        <p aria-hidden="true" className="push-left-md"  >{labels['FARE_CARD_RELOAD_SUBLINK_TEXT_1'] + ' ' + data.auto_refill.auto_refill_amount + ' ' + labels['FARE_CARD_RELOAD_SUBLINK_TEXT_2'] + ' ' + data.auto_refill.auto_refill_threshold}</p>
                                                    </div>
                                                    )}
                                                </div>
                                            </Level.Item>
                                        </Level.Side>
                                        <Level.Side align="right">
                                            <Level.Item className="level-item">
                                            </Level.Item>
                                        </Level.Side>
                                    </Level>
                                    <Level className="is-flex push-top-md" style={(data['is_owner'] == true && data.status != 'Lost or Stolen') ? {} : { display: 'none' }}>
                                        <Level.Side align="left">
                                            <Level.Item>
                                                <Icon size="large" style={{ width: 30 }}>
                                                    <img src="/assets/icons/unlink.svg" alt="" aria-hidden="true" />
                                                </Icon>
                                                <Link
                                                    id="btnLinkMyCard"
                                                    title={labels['FARE_CARD_LINK']}
                                                    to={this.props.location.pathname} onClick={() => this.getInvitedUsers()} className="is-size-4 push-left-md has-text-primary">
                                                    {labels['FARE_CARD_LINK']}
                                                </Link>
                                            </Level.Item>
                                        </Level.Side>
                                    </Level>
                                    <Level className="is-flex push-top-md" style={(data['is_owner'] == true && data.status != 'Lost or Stolen') ? {} : { display: 'none' }}>
                                        <Level.Side align="left">
                                            <Level.Item>
                                                <Icon size="large" style={{ width: 30 }}>
                                                    <img src="/assets/icons/man.svg" alt="" aria-hidden="true" />
                                                </Icon>
                                                <Link
                                                    title={labels['FARE_CARD_PROFILES']}
                                                    to={`/fare-card-profile/card/${data['id']}`}
                                                    className="is-size-4 push-left-md has-text-primary">
                                                    {labels['FARE_CARD_PROFILES']}
                                                </Link>
                                            </Level.Item>
                                        </Level.Side>
                                    </Level>
                                    <Level className="is-flex push-top-md" style={(data.status != 'Lost or Stolen') ? {} : { display: 'none' }}>
                                        <Level.Side align="left">
                                            <Level.Item>
                                                <Icon size="large" style={{ width: 30 }}>
                                                    <img src="/assets/icons/trash_primary.svg" alt="" aria-hidden="true" />
                                                </Icon>
                                                <Link title={labels['OPTIONS_REMOVE']} to={this.props.location.pathname} onClick={() => this.open('modalDeleteCard')} className="is-size-4 push-left-md has-text-primary">
                                                    {labels['OPTIONS_REMOVE']}
                                                </Link>
                                            </Level.Item>
                                        </Level.Side>
                                    </Level>
                                    <Level className="is-flex push-top-md" style={(data.status != 'Lost or Stolen' && data.status != 'Suspended') ? {} : { display: 'none' }}>
                                        <Level.Side align="left">
                                            <Level.Item>
                                                <Icon size="large" className="pad-top-sm" style={{ width: 30 }}>
                                                    <img src="/assets/icons/repost_icon.svg" alt="" aria-hidden="true" />
                                                </Icon>
                                                <Link title={labels['FARE_CARD_REPORT_STOLEN']} to={this.props.location.pathname} onClick={() => this.open('modalReportCard')} className="is-size-4 push-left-md has-text-primary">
                                                    {labels['FARE_CARD_REPORT_STOLEN']}
                                                </Link>
                                            </Level.Item>
                                        </Level.Side>
                                    </Level>
                                </div>
                            </Columns>
                        </div>

                    }

                    {this.state && this.state.data &&
                        <Modal showClose={false} show={show} onClose={this.close}>
                            <Modal.Content>
                                <Modal.Card>
                                    <Modal.Card.Head onClose={this.close}>
                                    </Modal.Card.Head>
                                    <Modal.Card.Body className="centered">

                                        {/* Modal Edin Label */}
                                        <ModalEditLabel
                                            activeModal={activeModal}
                                            showFormValue={showFormValue}
                                            onChange={this.onChange}
                                            close={this.close}
                                            onSubmit={this.onEditLabel}
                                            cardName={cardName}
                                        ></ModalEditLabel>

                                        {/* Modal Add value */}
                                        <ModalAddValue
                                            activeModal={activeModal}
                                            showFormValue={showFormValue}
                                            showFormSuccess={showFormSuccess}
                                            showFormError={showFormError}
                                            data={this.state.data}
                                            onChange={this.onChange}
                                            close={this.close}
                                            setStep={this.setStep}
                                            openAddCard={this.openAddCard}
                                            paymentCardsList={paymentCardsList}
                                            addValue={this.state.addValue}
                                            minValue={this.state.min}
                                            maxValue={this.state.max}
                                            walletId={addValueWallet}
                                            onDecrease={this.decrease}
                                            onIncrease={this.increase}
                                            onChangeWalletId={this.onChangeWalletAddValue}
                                            onSubmit={this.onAddValue}
                                            termsAcceptedAddValue={termsAcceptedAddValue}
                                        ></ModalAddValue>

                                        {/* Modal Auto Reload */}
                                        <ModalAutoReload
                                            activeModal={activeModal}
                                            showFormValue={showFormValue}
                                            showFormSuccess={showFormSuccess}
                                            showFormError={showFormError}
                                            showFormLocked={showFormLocked}
                                            onChange={this.onChange}
                                            close={this.close}
                                            minValue={this.state.min}
                                            onDecrease={this.decreaseAutoReload}
                                            onIncrease={this.increaseAutoReload}
                                            setStep={this.setStep}
                                            isActivateAutoReload={isActivateAutoReload}
                                            isTermsAccepted={isTermsAccepted}
                                            openAddCard={this.openAddCard}
                                            paymentCardsList={paymentCardsList}
                                            amountReload={amountReload}
                                            balance={balance}
                                            walletId={addValueWallet}
                                            onChangeWalletId={this.onChangeWalletAddValue}
                                            onPress={this.onPress}
                                            onSubmit={this.onAutoRefill}
                                            step={parameters.reload_step / 100}
                                            maxAmount={parameters.max_amount_onetime_reload / 100}
                                            maxThreshold={parameters.max_amount_auto_reload / 100}
                                            {...this.props}
                                        ></ModalAutoReload>


                                        {/* Modal Transfer */}
                                        <ModalTransfer
                                            activeModal={activeModal}
                                            close={this.close}
                                            fromCard={data}
                                            fareCardsList={fareCardsList}
                                            onTransferedBalance={this.onContinueTranferBalance}
                                            maxTransfer={data && data.balance && data.balance.amount}
                                            {...this.props}
                                        ></ModalTransfer>

                                        {/* Modal Link Fare Crad */}
                                        <ModalLinkFareCard
                                            activeModal={activeModal}
                                            showFormValue={showFormValue}
                                            showFormSuccess={showFormSuccess}
                                            showFormError={showFormError}
                                            onSubmit={this.onSignIn}
                                            onChange={this.onChange}
                                            setStep={this.setStep}
                                            close={this.close}
                                            isLoading={isLoading}
                                            invitedUsers={invitedUsers}
                                            email={email}
                                            disabledEmail={disabledEmail}
                                            resendEmail={this.resendEmail}
                                            deleteUser={this.deleteUser}
                                            {...this.props}
                                        ></ModalLinkFareCard>

                                        {/* Modal Delete Fare Crad */}
                                        <ModalDeleteCard
                                            activeModal={activeModal}
                                            showFormValue={showFormValue}
                                            showFormError={showFormError}
                                            close={this.close}
                                            setStep={this.setStep}
                                            onSubmit={this.removeCard}
                                        ></ModalDeleteCard>

                                        {/* Modal Delete Fare Crad */}
                                        <ModalReportCard
                                            activeModal={activeModal}
                                            showFormValue={showFormValue}
                                            showFormError={showFormError}
                                            close={this.close}
                                            setStep={this.setStep}
                                            onSubmit={this.reportCard}
                                        ></ModalReportCard>

                                        {/* Modal Add Payment Card */}
                                        <ModalTrapAddCard
                                            activeModal={activeModal}
                                            showFormValue={showFormValue}
                                            showFormSuccess={showFormSuccess}
                                            showFormError={showFormError}
                                            resultUrl={resultUrl}
                                            delay={true}
                                            focusOfDeactivate={'#fare-card-page'}
                                            close={this.close}
                                            {...this.props}
                                        ></ModalTrapAddCard>

                                    </Modal.Card.Body>
                                </Modal.Card>
                            </Modal.Content>
                        </Modal>
                    }
                </>
            </DocumentTitle >
        )
    }
}

export default connect(
    state => ({
        loader: state.loader,
        labels: state.localization.labels,
        tts: state.localization.tts,
        artifacts: state.artifacts
    }),
    dispatch => ({
        onLoader: (status) => {
            dispatch({ type: 'SET_STATUS', payload: status })
        }
    })
)(FareCard)
