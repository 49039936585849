import axios from "axios";
import config from "../../config";
import { getToken } from "../_helpers";

export const citationsService = {
  getListcitations,
  payCitations
}

function getListcitations() {
  const requestOptions = {
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
  }
  return axios.get(`${config.API.URL}/api/v1/patron/citations`, requestOptions)
}

function payCitations(body) {
  const requestOptions = {
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
  }
  return axios.post(`${config.API.URL}/api/v1/patron/citations/pay`, body, requestOptions)
}