import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';
import { Button } from '../../components/lib/components/button';
import { Modal } from '../../components/lib/components/modal';
import { loadScript } from '../../_helpers/helper';
import { Checkbox } from '../../components/lib/components/form';
import { toast, ToastContainer } from 'react-toastify';
import { Columns } from '../../components/lib/components/columns';
import { citationsService, paymentService } from '../../_services';
import moment from "moment";
import NumberFormat from 'react-number-format';
import { setFocusPage, setFocusElement } from '../../_helpers';
import numWords from 'num-words';
import ModalPayCitations from './ModalPayCitations';
import ModalTrapAddCard from '../PaymentOptions/ModalTrapAddCard'
class PayCitations extends React.Component {

  _isMounted = false;
  state = {
    resultUrl: '',
    fullAmount: 0,
    fullAmountTitle: '',
    isTermsAccepted: false,
    activeModal: '',
    show: false,
    showFormValue: false,
    showFormSuccess: false,
    showFormError: false,
    isPay: false,
    paymentCardsList: [],
    citationIds: [],
    citationsListGroup: [],
    showData: false,
    walletId: null
  }

  open = (activeModal) => {
    this.setState({
      activeModal,
      showFormValue: true,
      showFormSuccess: false,
      showFormError: false,
      show: true
    }, () => {
      // focus page
      // setFocusPage(`#${this.state.activeModal}showFormValue`);
    })
  }

  componentDidMount() {
    this._isMounted = true;
    this.getListcitations();

    // focus page
    setFocusPage(`#pay-citations-page`);

    // set resultUrl
    this.setState({
      resultUrl: window.location.origin + '/pay-citations'
    });

    // add new payments list
    const checkoutId = this.props.location.query.id;
    if (checkoutId != undefined) {
      this.props.onLoader('yes');
      const body = {
        checkoutId
      }
      paymentService.checkCardStatus(body)
        .then(res => {
          this.setState({
            activeModal: 'modalAddCard',
            show: true,
            showFormSuccess: true
          })
          setTimeout(() => {
            this.props.router.push('/pay-citations');
            this.getpaymentCardsList();
          }, 500);
        })
        .catch(error => {
          this.props.onLoader('no');
          this.setState({
            activeModal: 'modalAddCard',
            show: true,
            showFormError: true
          })
          this.getpaymentCardsList();
          setTimeout(() => {
            // this.setState({ error: 'An error occurred while adding a payment card.' });
            // setTimeout(() => {
            this.setState({ error: '' });
            this.props.router.push('/pay-citations');
            // }, 3000);
          }, 1000)
          // redirect to login page
          if (error.response.status === 401) {
            this.props.router.push('/login');
          }
        })
    } else {
      localStorage.removeItem('activeModal');
      localStorage.removeItem('state');
      this.getpaymentCardsList()
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  formatToGroupList = (list) => {
    try {
      let linkedCitations = {};
      let listFormat = [];

      list.forEach((element, index) => {
        if (linkedCitations.hasOwnProperty(element['patron']['id'])) {
          linkedCitations[element['patron']['id']].push(element)
        } else {
          linkedCitations[element['patron']['id']] = [];
          linkedCitations[element['patron']['id']].push(element)
        }
      });
      Object.keys(linkedCitations).map(function (key) {
        listFormat.push({
          id: key,
          name: linkedCitations[key][0]['patron']['name'],
          list: linkedCitations[key]
        })
      });
      return listFormat;
    } catch (e) {
      console.error(e);
      return []
    }
  }

  getListcitations = () => {
    // this.props.onLoader('yes');
    // get personal data
    citationsService.getListcitations()
      .then(res => {
        if (this._isMounted) {
          try {
            // convert currency to words
            res.data.data.forEach(item => {
              item.list.forEach(jtem => {
                const firtCurrency = (jtem.value / 100).toString().split(".")[0];
                const lastCurrency = (jtem.value / 100).toString().split(".")[1];

                jtem.priceTitle = (lastCurrency && lastCurrency !== "00" && lastCurrency !== "00)")
                  ? numWords(firtCurrency) + " dollar " + numWords(lastCurrency) + " cents"
                  : numWords(firtCurrency) + " dollar";
              });
            });

            this.setState({
              citationsListGroup: res.data.data,
              showData: true
            });
            // this.getpaymentCardsList();
          } catch (e) { console.error(e) }
        }
      })
      .catch(error => {
        this.props.onLoader("no");
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push("/login");
        }
        if (this._isMounted) {
          this.setState({
            showData: true,
            errors: { citations: error.response.data.message }
          })
        }
      })
  }

  close = () => {
    if (localStorage.getItem('activeModal')) {
      switch (localStorage.getItem('activeModal')) {
        case 'modalPayCitations': this.open("modalPayCitations"); break;
        default: ;
      }
      const state = JSON.parse(localStorage.getItem('state'));
      if (typeof state === 'object' && state !== null) {
        this.setState({ ...state })
      }

      localStorage.removeItem('activeModal');
      localStorage.removeItem('state');
      return;
    }
    this.checkCitationList();
    this.setState({
      show: false,
      showFormValue: false,
      showFormSuccess: false,
      showFormError: false,
    })
  };
  checkCitationList = () => {
    let checkedList = 0;
    try {
      this.state.citationsListGroup.forEach((item) => {
        item.list.forEach((item) => {
          if (item.isChecked) { checkedList++ }
        })
      })
      if (checkedList !== this.state.citationIds.length) {
        this.setState({
          citationIds: [],
          fullAmountTitle: '',
          fullAmount: 0,
          isTermsAccepted: false
        })
      }
    } catch (e) { console.error(e) }
  }
  onChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value
    });
  }

  onChangeCheckboxGroup(index, item, id) {
    const citationsListGroup = this.state.citationsListGroup;
    try {
      citationsListGroup[id]['list'][index]['isChecked'] = !citationsListGroup[id]['list'][index]['isChecked'];

      this.setState({ citationsListGroup }, () => {
        if (this.state.citationsListGroup[id]['list'][index]['isChecked']) {
          this.setState({
            fullAmount: this.state.fullAmount + ((item.value) / 100),
            citationIds: [...this.state.citationIds, item.id]
          }, () => {
            const firtCurrency = this.state.fullAmount.toString().split(".")[0];
            const lastCurrency = this.state.fullAmount.toString().split(".")[1];
            this.setState({
              fullAmountTitle: (lastCurrency && lastCurrency !== '00' && lastCurrency !== '00)') ? numWords(firtCurrency) + ' dollar ' + numWords(lastCurrency) + ' cents' : numWords(firtCurrency) + ' dollar'
            })
          })
        } else {
          let idxCitationId = this.state.citationIds.indexOf(item.id);

          this.setState({
            fullAmount: this.state.fullAmount - ((item.value) / 100),
            citationIds: this.state.citationIds.slice(0, idxCitationId).concat(this.state.citationIds.slice(idxCitationId + 1, this.state.citationIds.length))
          }, () => {
            const firtCurrency = this.state.fullAmount.toString().split(".")[0];
            const lastCurrency = this.state.fullAmount.toString().split(".")[1];
            this.setState({
              fullAmountTitle: (lastCurrency && lastCurrency !== '00' && lastCurrency !== '00)') ? numWords(firtCurrency) + ' dollar ' + numWords(lastCurrency) + ' cents' : numWords(firtCurrency) + ' dollar'
            })
          })
        }
      });
    } catch (e) { console.error(e) }
  }

  onChangeDropDown = (walletId) => this.setState({ walletId });

  setStep = (step) => {
    this.setState({
      showFormValue: false,
      showFormSuccess: false,
      showFormError: false,
      [step]: true
    });
    // setFocusPage(`#${this.state.activeModal + step}`);
  }

  getpaymentCardsList = () => {
    this.props.onLoader('yes');
    paymentService.getPaymentCardsList()
      .then(res => {
        if (!this._isMounted) return;

        const paymentCardsList = res.data.data || [];
        this.setState({ paymentCardsList }, () => {
          const { paymentCardsList } = this.state;
          this.setState({
            walletId: (paymentCardsList.length) ? paymentCardsList.filter((item, i) => item.is_primary == 1 || i == paymentCardsList.length - 1)[0]['id'] : null
          });
        });
        this.props.onLoader('no');
      })
      .catch(error => {
        console.error('error.response', error.response);
        this.props.onLoader('no');
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  payCitations = () => {
    const body = {
      payment_card_id: this.state.walletId,
      citations: this.state.citationIds
    }
    this.props.onLoader("yes");
    citationsService.payCitations(body)
      .then(res => {
        try {
          this.setStep("showFormSuccess");
        } catch (e) { console.error(e) }
        this.props.onLoader("no");
      })
      .catch(error => {
        console.error("error.response", error.response);
        this.setStep("showFormError");
        this.props.onLoader("no");
        // redirect to login page
        if (error.response.status === 401) {
          this.props.router.push('/login');
        }
      })
  }

  refreshCitations = () => {
    this.close();
    this.getListcitations();
    this.setState({
      citationIds: [],
      fullAmountTitle: '',
      fullAmount: 0,
      isTermsAccepted: false
    })
  }

  openAddCard = (activeModal) => {
    this.props.onLoader("yes");
    localStorage.setItem("activeModal", this.state.activeModal);
    localStorage.setItem("state", JSON.stringify({
      citationIds: this.state['citationIds'],
      fullAmountTitle: this.state['fullAmountTitle'],
      fullAmount: this.state['fullAmount'],
      isTermsAccepted: this.state['isTermsAccepted']
    }));
    this.setState({
      activeModal,
      showFormValue: true,
      showFormSuccess: false,
      showFormError: false,
    }, () => {
      paymentService.getPaymentFormData()
        .then(res => {
          let body = {
            id: "payment",
            src: "/assets/js/payment.js?n=" + (new Date).getTime()
          }
          window['labels'] = this.props.labels;
          loadScript(body, () => {
            body = {
              id: "form-payment",
              src: res.data['url']
            }
            loadScript(body, () => {
              this.setState({ show: true })
              setTimeout(() => {
                // focus page
                setFocusElement(`#add-new-card-title`)
                this.props.onLoader("no");
              }, 1500);
            });
          });
        })
        .catch(error => {
          console.error("error.response", error.response);
          this.props.onLoader("no");
          toast.error("An error occurred while adding a payment card.", {
            position: toast.POSITION.TOP_RIGHT
          });
          this.close();
          // redirect to login page
          if (error.response.status === 401) {
            this.props.router.push("/login");
          }
        })
    })

  }

  render() {
    const {
      fullAmount, activeModal, fullAmountTitle, showFormValue, showFormSuccess, showFormError, show, resultUrl,
      paymentCardsList, showData, isTermsAccepted, walletId, citationsListGroup
    } = this.state;
    const { labels } = this.props;
    const isEnabled = fullAmount > 0;

    return (
      <DocumentTitle title={labels["PAY_CITATIONS_PAGE_TITLE"] + " - IndyGo App"}>
        <div id="pay-citations-page" aria-label={labels["PAY_CITATIONS_PAGE_TITLE"]} role={labels["PAY_CITATIONS_PAGE_TITLE"]}>

          <h1 className="is-size-2 has-text-weight-bold pad-bottom-md pad-top-xl">
            {labels["CITATIONS_TITLE"]}
          </h1>

          {showData && citationsListGroup.length === 0 && (
            <Columns>
              <Columns.Column size={10} className="push-top-md">
                <p className="has-text-weight-bold">{labels["CITATIONS_NO_DATA"]}</p>
              </Columns.Column>
            </Columns>
          )}

          <div className="list pay-list">
            {citationsListGroup && citationsListGroup.map((data, id) => (
              <Columns key={id}>

                <Columns.Column size={10}>
                  <h2 id={`media_${id}`} className="is-size-4 has-text-info">{data.name}</h2>
                </Columns.Column>
                <Columns.Column size={10}>
                  {data.list.length && data.list.map((item, idx) => (
                    <div key={idx} className="list-item" aria-describedby={`media_${id}`}>
                      <Columns>
                        <Columns.Column narrow={true} className="is-flex pad-left-none" style={{ width: 35, alignItems: "center" }}>
                          {item.value > 0 && (
                            <label className="checkbox">
                              <Checkbox
                                name={"termsAcceptedgroup_" + idx}
                                checked={item.isChecked}
                                style={{ marginLeft: 1 }}
                                onChange={() => this.onChangeCheckboxGroup(idx, item, id)}
                              />
                              <span className="is-sr-only">
                                {`${item.citation_type_label} # ${item.id}. 
                                  ${moment.utc(item.issued_at).local().format("MMMM. Do, YYYY - h:mm A")}. 
                                  ${(new Date() < new Date(item.payment_due_at) || item.value == 0) ? labels["CITATIONS_PAY_BY"] : labels["CITATIONS_PAST_DUE_DATE"]}:
                                  ${item.payment_due_at ? moment(item.payment_due_at).format("MM/DD/YYYY") : "-"}.
                                  ${labels["CITATIONS_AMOUNT"]}: 
                                `}
                                <NumberFormat
                                  aria-label={item.priceTitle}
                                  value={(item.value) / 100}
                                  displayType="text"
                                  decimalSeparator="."
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                  prefix="$"
                                />
                              </span>
                            </label>
                          )}
                        </Columns.Column>
                        <Columns.Column size={4}>
                          <p className="has-text-weight-bold">{item.citation_type_label} # {item.id}</p>
                          <p className="is-size-8 has-text-grey-dark">
                            {moment.utc(item.issued_at).local().format("MMM. Do, YYYY - h:mm A")}
                          </p>
                        </Columns.Column>
                        <Columns.Column size={3}>
                          <p className="has-text-weight-bold">{labels["CITATIONS_LABEL"]}</p>
                          <p>{item.citation_category_label}</p>
                        </Columns.Column>
                        <Columns.Column size={3}>
                          <p className="has-text-weight-bold">
                            {(new Date() < new Date(item.payment_due_at) || item.value == 0) ? labels["CITATIONS_PAY_BY"] : labels["CITATIONS_PAST_DUE_DATE"]}
                          </p>
                          {item.payment_due_at
                            ?
                            <p className={new Date() > new Date(item.payment_due_at) ? "has-text-danger" : "has-text-info"}>
                              {moment(item.payment_due_at).format("MM/DD/YYYY")}
                            </p>
                            :
                            <p>-</p>
                          }
                        </Columns.Column>
                        <Columns.Column size={1}>
                          <p className="has-text-weight-bold">{labels["CITATIONS_AMOUNT"]}</p>
                          <p>
                            <NumberFormat
                              aria-label={item.priceTitle}
                              value={(item.value) / 100}
                              displayType="text"
                              decimalSeparator="."
                              fixedDecimalScale={true}
                              decimalScale={2}
                              prefix="$"
                            />
                          </p>
                        </Columns.Column>
                      </Columns>
                    </div>
                  ))}
                </Columns.Column>
              </Columns>
            ))}
          </div>

          <Columns>
            {citationsListGroup && citationsListGroup.length !== 0 && (
              <Columns.Column size={10}>
                <Button
                  id={`btnPayCitations`}
                  disabled={!isEnabled}
                  className="is-pulled-right"
                  size="small"
                  color="info"
                  style={{ minWidth: 190 }}
                  onClick={() => this.open("modalPayCitations")}
                  title={labels["BUTTON_PAY"] + fullAmountTitle}
                >
                  {labels["BUTTON_PAY"]}
                  {fullAmount !== 0 && (
                    <NumberFormat
                      aria-label={fullAmountTitle}
                      value={fullAmount}
                      displayType="text"
                      decimalSeparator="."
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="&nbsp;$"
                    />
                  )}
                </Button>
              </Columns.Column>
            )}
          </Columns>

          <Modal showClose={false} show={show} onClose={this.close}>
            <Modal.Content>
              <Modal.Card>
                <Modal.Card.Head onClose={this.close}></Modal.Card.Head>
                <Modal.Card.Body className="centered">

                  {/* Modal  Modal Pay Citations*/}
                  <ModalPayCitations
                    activeModal={activeModal}
                    showFormValue={showFormValue}
                    showFormSuccess={showFormSuccess}
                    showFormError={showFormError}
                    onChange={this.onChange}
                    close={this.close}
                    setStep={this.setStep}
                    openAddCard={this.openAddCard}
                    paymentCardsList={paymentCardsList}
                    onChangeWallet={this.onChangeDropDown}
                    onSubmit={this.payCitations}
                    fullAmountTitle={fullAmountTitle}
                    fullAmount={fullAmount}
                    walletId={walletId}
                    isTermsAccepted={isTermsAccepted}
                    refreshCitations={this.refreshCitations}
                  ></ModalPayCitations>

                  {/* Modal Add Payment Card */}
                  <ModalTrapAddCard
                    activeModal={activeModal}
                    showFormValue={showFormValue}
                    showFormSuccess={showFormSuccess}
                    showFormError={showFormError}
                    resultUrl={resultUrl}
                    delay={true}
                    focusOfDeactivate={'#fare-card-page'}
                    close={this.close}
                    {...this.props}
                  ></ModalTrapAddCard>

                  {/* <div className={`${activeModal === 'modalAddCard' ? '' : 'is-sr-only'}`}>
                    <div className="is-transition-all" style={showFormAddCard ? { opacity: 1 } : { opacity: 0 }}>
                      <h2 className="is-size-4 has-text-primary has-text-weight-bold push-bottom-xs">
                        {labels["BUTTON_ADD_NEW_CARD"]}
                      </h2>
                      <p className="has-text-grey-dark">{labels["CITATIONS_MODAL_PAYMENT_TEXT"]}</p>
                      <form action={resultUrl} className="paymentWidgets" data-brands="VISA MASTER AMEX">
                        {labels["OPTIONS_MODAL_LOADING"]}
                      </form>
                      <p className="is-size-8 has-text-grey-dark">
                        {labels["OPTIONS_MODAL_POLICY"]}
                        <a target="_blank" href="https://www.indygo.net/mykey-privacy-policy/" className="has-text-info">
                          {labels["GDPR_CHECKBOX_PRIVACY"]}
                        </a>
                      </p>
                    </div>
                    <div className="push-bottom-xxl has-text-centered" style={showFormSuccess ? {} : { display: 'none' }}>
                      <FontAwesomeIcon icon={faCheckCircle} size="4x" className="has-text-success" />
                      <h2 className="is-size-3 has-text-info has-text-weight-bold push-bottom-sm push-top-sm">
                        {labels["MODAL_TITLE_SUCCESSFUL"]}
                      </h2>
                      <Button
                        className="push-top-xl"
                        size="small"
                        color="info"
                        style={{ minWidth: 190 }}
                        onClick={this.close}
                        title={labels["BUTTON_CONTINUE"]}
                      >
                        {labels["BUTTON_CONTINUE"]}
                      </Button>
                    </div>
                    <div className="push-bottom-xxl has-text-centered" style={showFormError ? {} : { display: 'none' }}>
                      <FontAwesomeIcon icon={faExclamationCircle} size="4x" className="has-text-danger" />
                      <h2 className="is-size-3 has-text-danger has-text-weight-bold push-bottom-sm push-top-sm">
                        {labels["MODAL_TITLE_ERROR"]}
                      </h2>
                      <p className="has-text-grey-dark push-bottom-md">{labels["OPTIONS_MODAL_ERROR_TEXT_1"]}</p>
                      <p className="has-text-grey-dark push-bottom-md">{labels["OPTIONS_MODAL_ERROR_TEXT_2"]}</p>
                      <p className="has-text-grey-dark">{labels["OPTIONS_MODAL_ERROR_TEXT_3"]}</p>
                      <Button.Group className="push-top-xl" style={{ justifyContent: 'space-between' }}>
                        <Button
                          size="small"
                          color="info"
                          style={{ minWidth: 190, marginRight: 5 }}
                          onClick={() => this.setStep("showFormAddCard")}
                          title={labels["BUTTON_TRY_AGAIN"]}
                        >
                          {labels["BUTTON_TRY_AGAIN"]}
                        </Button>
                        <Button
                          outlined={true}
                          size="small"
                          color="info"
                          style={{ minWidth: 190 }}
                          onClick={this.close}
                          title={labels["BUTTON_CANCEL"]}
                        >
                          {labels["BUTTON_CANCEL"]}
                        </Button>
                      </Button.Group>
                    </div>
                  </div> */}
                </Modal.Card.Body>
              </Modal.Card>
            </Modal.Content>
          </Modal>

          <ToastContainer autoClose={5000} newestOnTop closeOnClick />

        </div>
      </DocumentTitle>
    )
  }
}

export default connect(
  state => ({
    loader: state.loader,
    labels: state.localization.labels
  }),
  dispatch => ({
    onLoader: (status) => {
      dispatch({ type: 'SET_STATUS', payload: status })
    }
  })
)(PayCitations)