import React, { useRef } from "react";

const ActivateButton = ({ onPress, active, id, labelEnabled, labelDisabled }) => {
  const _switchBoxRef = useRef();
  const _switchViewRef = useRef();
  const onChange = () => {
    _switchBoxRef.current.focus()
    onPress()
    setTimeout(() => {
      _switchViewRef.current.focus()
    }, 400) // TODO: adding timeout for better speaking in JAWS  
  }
  return (
    <div
      ref={_switchBoxRef}
      className="switch"
      tabIndex="-1"
 >
      <input
        id={id}
        tabIndex="-1"
        type="checkbox"
        // role="switch"
        // aria-checked={active ? "true" : "false"}
        className="is-sr-only"
        checked={active}
        onChange={onChange}
      />
      <div
        ref={_switchViewRef}
        tabIndex="0"
        className="switch__view"
        onClick={onChange}
        onKeyPress={onChange}
        aria-label={`${active ? labelEnabled : labelDisabled}`}
        role="switch"
        aria-checked={active ? "true" : "false"}
      />
    </div>
  )
}
ActivateButton.defaultProps = {
  labelEnabled: "Switch on",
  labelDisabled: "Switch off"
};
export default ActivateButton;