import axios from 'axios';
import config from '../../config';

export const threadsService = {
    getThreads,
    addThread,
    getMessages,
    getThread,
    onDeleteThread,
    onReadThread,
    getMessage,
    onDeleteMessage,
    addComment,
    getMessagesUnread,
    getAttachment
};

function getThreads(page) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token }
    };
    return axios.get(`${config.API.URL}/api/v1/patron/messages/p/${page}`, requestOptions)
}
function getThread(id) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token }
    };
    return axios.get(`${config.API.URL}/api/v1/patron/messages/${id}`, requestOptions)
}
function addThread(body) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'multipart/form-data', 'Authorization' : 'Bearer ' + token }
    };
    return axios.post(`${config.API.URL}/api/v1/patron/messages`, body, requestOptions)
}
function getMessages(id) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token }
    };
    return axios.get(`${config.API.URL}/api/v1/patron/messages/${id}/replies`, requestOptions)
}
function addComment(id, body) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'multipart/form-data', 'Authorization' : 'Bearer ' + token }
    };
    return axios.post(`${config.API.URL}/api/v1/patron/messages/${id}/replies`, body, requestOptions)
}
function getMessage(threadID, id) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token }
    };
    return axios.get(`${config.API.URL}/api/v1/patron/messages/${threadID}/replies/${id}`, requestOptions)
}
function getMessagesUnread() {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token }
    };
    return axios.get(`${config.API.URL}/api/v1/patron/messages/unread`, requestOptions)
}
function onDeleteThread(id) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token }
    };
    return axios.delete(`${config.API.URL}/api/v1/patron/messages/${id}`, requestOptions)
}
function onDeleteMessage(threadID, id) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token }
    };
    return axios.delete(`${config.API.URL}/api/v1/patron/messages/${threadID}/replies/${id}`, requestOptions)
}
function onReadThread(id) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token }
    };
    return axios.put(`${config.API.URL}/api/v1/patron/threads/${id}/read`, {}, requestOptions)
}
function getAttachment(id) {
    const token = localStorage.getItem('token');
    const requestOptions = {
        responseType: 'blob',
        headers: { 'Accept' : 'application/json' , 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token }
    };
    return axios.get(`${config.API.URL}/api/v1/patron/messages/attachments/${id}`, requestOptions)
}