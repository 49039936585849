import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';
import { Link } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faExclamationCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import {
  Field,
  Control,
  Label,
  Input,
  Checkbox
} from '../components/lib/components/form';
import { Icon } from '../components/lib/components/icon';
import { Button } from '../components/lib/components/button';
import { Columns } from '../components/lib/components/columns';
import { userService } from '../_services';
import config from "../../config";
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify';
import { setFocusElement } from '../_helpers';

const passwordRegex = new RegExp(config.API.passwordValidation);

class CreateAccount extends React.Component {
  state = {
    passIcon: false,
    statusConfirm: false,
    username: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false,
    loading: false,
    errors: {},
    isMatchPassword: ''
  };
  componentDidMount() {
    setFocusElement(`#username`);
  }
  onChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value,
      errors: {},
      isMatchPassword: '',
      errNewPassword: '',
    }, () => {
      // check new password and confirm password
      if (this.state.password != '' && this.state.confirmPassword != '') {
        if (this.state.password != this.state.confirmPassword) {
          this.setState({
            isMatchPassword: this.props.labels['SIGNUP_ERROR_PASS_NOT_MATCH']
          })
        } else {
          this.setState({
            isMatchPassword: ''
          })
        }
      }
      // check new password
      if (this.state.password != '' && !passwordRegex.test(this.state.password)) {
        this.setState({
          errNewPassword: this.props.labels["PROFILE_PASS_ERROR_INCORECT_1"]
        })
      } else {
        this.setState({
          errNewPassword: ''
        })
      }
    });
  };

  handleSignUp = (event) => {
    event.preventDefault();
    const { username, password } = this.state;

    if (username && password) {
      this.setState({ submitted: true });
      this.setState({ loading: true });
      userService.signup(username, password)
        .then(response => {
          this.setState({ statusConfirm: true }, () => {
            setFocusElement(`#btnOk`);
          });
        })
        .catch((error) => {
          try {
            this.setState({ errors: { username: error.response.data.errors.email[0] } }, () => {
              setFocusElement(`#username`);
            });
            this.setState({ error, loading: false })
          } catch (e) {
            console.error(e);
            this.setState({ error, loading: false });
            toast.error('Bad Request. Please try again later', {
              position: toast.POSITION.TOP_RIGHT
            });
          }

        })
    }
  };
  changePassIcon = () => {
    this.setState({
      passIcon: !this.state.passIcon
    }, () => {
      setFocusElement(`#password`);
    })
  };
  render() {
    const { statusConfirm, username, password, confirmPassword, termsAccepted, loading, errors, isMatchPassword, errNewPassword } = this.state;
    const { labels, tts } = this.props;
    const isEnabled = username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && passwordRegex.test(password) && passwordRegex.test(confirmPassword) && password === confirmPassword && termsAccepted === true;
    return (
      <DocumentTitle title={labels['CREATE_ACCOUNT_PAGE_TITLE'] + ' - IndyGo App'}>
        <div className="container container--fluid-md">
          <Columns>
            <Columns.Column size={4}>
              <h1 className="title is-2 has-text-weight-bold has-letter-spacing has-text-primary"
                dangerouslySetInnerHTML={{ __html: labels["SIGNUP_TITLE"] }}></h1>
              <p className="has-text-grey-light push-bottom-lg">
                <span dangerouslySetInnerHTML={{ __html: labels["SIGNUP_TEXT"] }}></span>
                <Link title={labels["SIGNUP_BACK_LOGIN"]} to="/login" className="has-text-info">{labels["SIGNUP_BACK_LOGIN"]}</Link> {labels["SIGNUP_BACK_LOGIN_TEXT"]}</p>
              <Link
                title="IndyGo"
                to="https://www.indygo.net/mykey"
                target="_blank"
                rel="noopener noreferrer"
                className="link-about is-family-secondary has-text-primary has-text-weight-semibold">
                <span className="icon is-medium has-background-warning">
                  <FontAwesomeIcon icon={faAngleRight} size="2x" className="has-text-black" />
                </span>
                {labels['SIGNUP_ABOUT_MYKEY']}
              </Link>
            </Columns.Column>
            <Columns.Column size={1} />
            {!statusConfirm && <Columns.Column size={4} >
              <form onSubmit={this.handleSignUp} className="pad-top-md pad-bottom-md">
                <Field>
                  <Label
                    htmlFor="username"
                    className={`${!errors['username'] ? "" : "has-text-danger"} ${username === "" ? "" : "has-text-grey-light"}`}>{labels["LOGIN_USERNAME"]}</Label>
                  <Control iconRight={true}>
                    <Input
                      id="username"
                      className={`${!errors['username'] ? "" : "is-danger"}`}
                      onChange={this.onChange}
                      value={username}
                      name="username"
                      type="text"
                      placeholder={labels["LOGIN_USERNAME_PLACEHOLDER"]} />
                    <p className="help is-danger has-text-right" style={!errors['username'] ? { display: 'none' } : {}}>{labels['SIGNUP_ERROR_USER_ALREADY']} </p>
                    <Icon style={!errors['username'] ? { display: 'none' } : {}} align="right">
                      <FontAwesomeIcon style={{ fontSize: '19px' }} icon={faExclamationCircle} className="has-text-danger" />
                    </Icon>
                  </Control>
                </Field>
                <Field>
                  <Label
                    htmlFor="password"
                    className={`${(!errNewPassword) ? "" : "has-text-danger"} ${password === "" ? "" : "has-text-grey-light"}`}>{labels["LOGIN_PASSWORD"]}
                    <Icon align="right" style={{ verticalAlign: '-4px' }} data-tip data-for='tooltipPass'
                      className="push-left-sm" >
                      <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '18px' }} />
                    </Icon>
                    <ReactTooltip id='tooltipPass' aria-haspopup='true' html={true} className="has-text-centered	">
                      {labels["SIGNUP_TOOLTIP_PASSWORD"]}
                    </ReactTooltip>
                  </Label>
                  <Control iconRight={true} className="has-icons-right-second">
                    <Input id="password" className={`${(!errNewPassword) ? "" : "is-danger"}`} onChange={this.onChange} value={password} name="password" type={this.state.passIcon ? "text" : "password"} placeholder={labels["SIGNUP_PASS_PLACEHOLDER_TEXT"]} />
                    {errNewPassword && <p className="help is-danger has-text-right">{labels["PROFILE_PASS_ERROR_INCORECT_1"]}</p>}
                    {!errNewPassword && <Icon
                      role="button"
                      tabIndex="0"
                      align="right"
                      onClick={() => this.changePassIcon()}
                      onKeyPress={() => this.changePassIcon()}
                      aria-label={this.state.passIcon ? tts["HIDE_PASSWORD"] : tts["SHOW_PASSWORD"]}
                    >
                      <FontAwesomeIcon icon={this.state.passIcon ? faEye : faEyeSlash} className="has-text-black" />
                    </Icon>}
                    <Icon style={!errNewPassword ? { display: 'none' } : {}} align="right">
                      <FontAwesomeIcon style={{ fontSize: '19px' }} icon={faExclamationCircle} className="has-text-danger" />
                    </Icon>
                  </Control>
                </Field>
                <Field>
                  <Label
                    htmlFor="confirmPassword"
                    className={`${!isMatchPassword ? "" : "has-text-danger"} ${confirmPassword === "" ? "" : "has-text-grey-light"}`}>{labels["SIGNUP_REPASS_PLACEHOLDER_TEXT"]}</Label>
                  <Control iconRight={true} className="has-icons-right-second">
                    <Input
                      id="confirmPassword"
                      className={`${!isMatchPassword ? "" : "is-danger"}`}
                      onChange={this.onChange}
                      value={confirmPassword}
                      name="confirmPassword"
                      type={this.state.passIcon ? "text" : "password"}
                      placeholder={labels["SIGNUP_REPASS_PLACEHOLDER_TEXT"]} />
                    {isMatchPassword && <p className="help is-danger has-text-right">{labels['SIGNUP_ERROR_PASS_NOT_MATCH']}</p>}
                    <Icon style={!isMatchPassword ? { display: 'none' } : {}} align="right">
                      <FontAwesomeIcon style={{ fontSize: '19px' }} icon={faExclamationCircle} className="has-text-danger" />
                    </Icon>
                    {confirmPassword.length > 0 && password === confirmPassword && <Icon align="right">
                      <FontAwesomeIcon style={{ marginRight: '-80px' }} icon={faCheck} className="has-text-success is-size-6" />
                    </Icon>}
                  </Control>
                </Field>
                <Field>
                  <label className="checkbox">
                    <Checkbox
                      name="termsAccepted"
                      checked={termsAccepted}
                      onChange={this.onChange}
                    />
                    <span className="is-size-8 has-text-grey-dark">{labels["GDPR_CHECKBOX_AGREE"]} <Link rel="noopener noreferrer" className="has-text-info" target="_blank" to="https://www.indygo.net/mykey-terms-and-conditions/">{labels["GDPR_CHECKBOX_TERMS"]}</Link> {labels["GDPR_CHECKBOX_AND"]} <Link rel="noopener noreferrer" className="has-text-info" target="_blank" to="https://www.indygo.net/mykey-privacy-policy/">{labels["GDPR_CHECKBOX_PRIVACY"]}.</Link></span>
                  </label>
                </Field>
                <Button title={labels["LOGIN_CREATE_ACCOUNT"]} disabled={!isEnabled} loading={loading} color="info" size="small">{labels["LOGIN_CREATE_ACCOUNT"]}</Button>
              </form>
            </Columns.Column>}
            {statusConfirm && <Columns.Column size={4} >
              <p className="push-bottom-md" dangerouslySetInnerHTML={{ __html: labels["SIGNUP_CONFIRMATION_TEXT"] }}></p>
              <Button
                id="btnOk"
                title={labels["BUTTON_OK"]}
                onClick={() => this.props.router.push('/login')}
                color="info"
                size="small">{labels["BUTTON_OK"]}</Button>
            </Columns.Column>}
          </Columns>
        </div>
      </DocumentTitle>
    )
  }
}

export default connect(
  state => ({
    labels: state.localization.external.labels,
    tts: state.localization.external.tts
  }),
  dispatch => ({
  })
)(CreateAccount)