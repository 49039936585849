import React from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux';
import { Link } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import { Button } from '../components/lib/components/button';
import { Columns } from '../components/lib/components/columns';
import { toast } from 'react-toastify';
import { personalService } from '../_services';
import { setFocusElement } from '../_helpers';
class CreateAccountConfirm extends React.Component {
  state = {
    passIcon: false,
    statusConfirm: false,
    username: '',
    password: '',
    confirm_password: '',
    termsAccepted: false,
    loading: false,
    errors: {},
    isMatchPassword: ''
  };
  componentDidMount() {
    this._isMounted = true;
    const params = {
      "token": this.props.location.query.token
    }
    this.props.onLoader('yes');
    personalService.activateAccount(params)
      .then(res => {
        this.props.onLoader('no');
        this.setState({ statusConfirm: true }, () =>{
          setFocusElement(`#btnLogin`);
        })
      })
      .catch((error) => {
        // toast error
        toast.error(this.props.labels['PROFILE_UPDATE_STATUS_ERROR'], {
          position: toast.POSITION.TOP_RIGHT
        });
        this.props.router.push('/login');
        this.props.onLoader('no');
      })
  }
  render() {
    const { statusConfirm, loading } = this.state;
    const { labels } = this.props;
    return (
      <DocumentTitle title={labels['CREATE_ACCOUNT_PAGE_TITLE'] + ' - IndyGo App'}>
        <div className="container container--fluid-md">
          <Columns>
            <Columns.Column size={4}>
              <h1 className="title is-2 has-text-weight-bold has-letter-spacing has-text-primary"
                dangerouslySetInnerHTML={{ __html: labels["SIGNUP_TITLE"] }}></h1>
              <p className="has-text-grey-light push-bottom-lg">
                <span dangerouslySetInnerHTML={{ __html: labels["SIGNUP_TEXT"] }}></span>
                <Link to="/login" className="has-text-info">{labels["SIGNUP_BACK_LOGIN"]}</Link> {labels["SIGNUP_BACK_LOGIN_TEXT"]}</p>
              <Link to="/login" className="link-about is-family-secondary has-text-primary has-text-weight-semibold">
                <span className="icon is-medium has-background-warning">
                  <FontAwesomeIcon icon={faAngleRight} size="2x" className="has-text-black" />
                </span>
                {labels['SIGNUP_ABOUT_MYKEY']}
              </Link>
            </Columns.Column>
            <Columns.Column size={1} />
            <Columns.Column size={4} style={!statusConfirm ? { display: 'none' } : {}}>
              <p className="push-bottom-md">{labels["SIGNUP_CONFIRMATION_TITLE_1"]}</p>
              <p className="push-bottom-md">{labels["SIGNUP_CONFIRMATION_TITLE_2"]}</p>
              <Button id="btnLogin" title={labels["BUTTON_CONTINUE"]} onClick={() => this.props.router.push('/login')} loading={loading} color="info" size="small">{labels["BUTTON_CONTINUE"]}</Button>
            </Columns.Column>
          </Columns>
        </div>
      </DocumentTitle>
    )
  }
}

export default connect(
  state => ({
    labels: state.localization.external.labels,
    loader: state.loader
  }),
  dispatch => ({
    onLoader: (status) => {
      dispatch({ type: 'SET_STATUS', payload: status })
    }
  })
)(CreateAccountConfirm)