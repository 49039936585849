import axios from "axios";
import config from "../../config";
import { getToken } from "../_helpers";

export const userService = {
    login,
    forgotPass,
    resetPass,
    signup,
    deleteAccount,
    getAppParams,
    checkParametersArtifacts,
    getParametersArtifacts,
    getArtifact,
    checkManifest,
    setNotifiEmail,
    setNotifiText,
    setNotifiReceiptsEmail,
    getNotifiAll,
    setNotifiAll,
    getAppVersion
}

function login(email, password) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
    }
    return axios.post(`${config.API.URL}/oauth/token`, JSON.stringify({ username: email, password: password }), requestOptions)
}

function signup(email, password) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
    }
    return axios.post(`${config.API.URL}/api/v1/patron`, JSON.stringify({ email: email, password: password }), requestOptions)
}

function deleteAccount(password) {
    const request = axios.create({
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
    });
    return request.delete(`${config.API.URL}/api/v1/patron`, { data: { password: password } });
}

function forgotPass(params) {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    return axios.post(`${config.API.URL}/api/v1/patron/password/reset`, params, requestOptions)
}

function resetPass(params) {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/password/reset`, params, requestOptions)
}

function getAppParams() {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/app/artifacts`, requestOptions)
}

function checkParametersArtifacts($body) {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    return axios.post(`${config.API.URL}/api/v1/artifacts/parameters/check`, $body, requestOptions)
}

function checkManifest($body) {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    return axios.post(`${config.API.URL}/api/v1/manifest/check`, $body, requestOptions)
}

function getParametersArtifacts() {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    };
    let list = JSON.parse(localStorage.getItem("manifest"));
    let parametersArtifactlist = list.artifacts.filter((item) => item.artifact_type_id === 2).reverse();
    let id = parametersArtifactlist[0]['id'];
    return axios.get(`${config.API.URL}/api/v1/artifacts/parameters/${id}`, requestOptions)
}

function getArtifact(id) {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    return axios.get(`${config.API.URL}/api/v1/artifacts/${id}`, requestOptions)
}

function getAppVersion() {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    return axios.get(`${config.API.URL}/version`, requestOptions)
}

function getNotifiAll() {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
    }
    return axios.get(`${config.API.URL}/api/v1/patron/notifications/all`, requestOptions)
}

function setNotifiAll(data) {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/notifications/all`, data, requestOptions)
}

function setNotifiEmail(data) {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/notifications/email`, data, requestOptions)
}

function setNotifiText(data) {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/notifications/text`, data, requestOptions)
}

function setNotifiReceiptsEmail(data) {
    const requestOptions = {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
    }
    return axios.put(`${config.API.URL}/api/v1/patron/notifications/receipts`, data, requestOptions)
}